import {useEffect, useState} from 'react';
import {formatTime, MS_IN_SECOND} from '../format-time';

const DEFAULT_REFRESH_TIMEOUT = MS_IN_SECOND * 15;

export function useFormattedTime(timestamp: number, refreshTimeout: number = DEFAULT_REFRESH_TIMEOUT): string {

    const [time, setTime] = useState(() => {
        return formatTime(timestamp);
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const newTime = formatTime(timestamp);
            if (time !== newTime) {
                setTime(newTime);
            }
        }, refreshTimeout);
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    return time;

}