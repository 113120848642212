import {isAny, isBoolean, isId, isNumber, isObject, isString, optional, ValidationErrorsBag, Validator} from '@webaker/package-validation';
import {File} from './file';
import {FS_NODE_NAME_REGEXP, MAX_FS_NODE_NAME_LENGTH, MIN_FS_NODE_NAME_LENGTH} from './file-config';

export interface FileValidator {
    validateFile: (file: File) => boolean;
    assertFile: (file: File) => void;
    getFileErrors: (file: File) => ValidationErrorsBag;
}

export interface FileValidatorDeps {
    validator: Validator;
}

export function createFileValidator({validator}: FileValidatorDeps): FileValidator {

    // fix for TS2775
    const baseValidator: Validator = validator;

    const isFile = isObject<File>({
        properties: {
            id: isId(),
            parentDirectoryId: optional(isId()),
            name: isString({
                minLength: MIN_FS_NODE_NAME_LENGTH,
                maxLength: MAX_FS_NODE_NAME_LENGTH,
                match: FS_NODE_NAME_REGEXP
            }),
            public: optional(isBoolean()),
            alias: optional(isString()),
            size: optional(isNumber({min: 0})),
            url: optional(isString()),
            contents: optional(isAny())
        },
        // TODO: remove strict and handle specific file types validation
        strict: false
    });

    const validateFile = (item: unknown): item is File => {
        return baseValidator.validate(item, isFile);
    };

    const assertFile = (item: unknown): asserts item is File => {
        baseValidator.assert(item, isFile);
    };

    const getFileErrors = (item: unknown): ValidationErrorsBag => {
        return baseValidator.errors(item, isFile);
    };

    return {
        validateFile,
        assertFile,
        getFileErrors
    };

}