import {createContext, ReactNode} from 'react';
import {Config} from './config';

export interface ConfigContext {
    config: Config;
}

export const ConfigContext = createContext<ConfigContext | null>(null);

export interface ConfigContextProviderProps extends ConfigContext {
    children: ReactNode;
}

export function ConfigContextProvider({children, ...context}: ConfigContextProviderProps) {
    return (
        <ConfigContext.Provider value={context}>
            {children}
        </ConfigContext.Provider>
    );
}