import {State} from '../state';
import {Store} from '../store';

export interface StateSynchronizer {
    synchronizeState: <S extends State = {}, T extends State = Partial<S>>(store: Store<S>, options: StateSynchronizerOptions<S, T>) => void;
}

export interface StateSynchronizerOptions<S extends State = {}, T extends State = Partial<S>> {
    webStorage: Storage;
    exporter?: (currentState: S) => T;
    importer?: (currentState: S, loadedObject: T) => S;
}

export interface StateSynchronizerDeps {

}

export const STORAGE_KEY_PREFIX = 'store/';
export const STATE_SYNCHRONIZER_SOURCE = 'STATE_SYNCHRONIZER';

export function createStateSynchronizer({}: StateSynchronizerDeps = {}): StateSynchronizer {

    const synchronizeState = <S extends State = {}, T extends State = Partial<S>>(store: Store<S>, options: StateSynchronizerOptions<S, T>): void => {
        store.addEventListener('*:set', (event) => {
            if (event.method !== 'set' && event.params[1] !== STATE_SYNCHRONIZER_SOURCE) {
                saveToStorage(store, options);
            }
        });
        store.set(
            loadFromStorage(store, options),
            STATE_SYNCHRONIZER_SOURCE
        );
    };

    const saveToStorage = <S extends State = {}, T extends State = Partial<S>>(
        store: Store<S>,
        {webStorage, exporter}: StateSynchronizerOptions<S, T>
    ): void => {
        const currentState = store.get(STATE_SYNCHRONIZER_SOURCE);
        const objectToSave = exporter ? exporter(currentState) : currentState;
        const storageKey = getStorageKey(store);
        const storageValue = JSON.stringify(objectToSave);
        webStorage.setItem(storageKey, storageValue);
    };

    const loadFromStorage = <S extends State = {}, T extends State = Partial<S>>(
        store: Store<S>,
        {webStorage, importer}: StateSynchronizerOptions<S, T>
    ): S => {
        const currentState = store.get(STATE_SYNCHRONIZER_SOURCE);
        const storageKey = getStorageKey(store);
        const loadedObject = JSON.parse(webStorage.getItem(storageKey) ?? '{}');
        return importer ? importer(currentState, loadedObject) : {...currentState, ...loadedObject};
    };

    const getStorageKey = <S extends State = {}>(store: Store<S>): string => {
        return `${STORAGE_KEY_PREFIX}${store.name}`;
    };

    return {
        synchronizeState
    };

}