import {File, FileExtension} from './file';
import {FileTypeOptions} from './file-type-options';

export interface FileRegistry {
    registerFileType: <F extends File = File>(options: FileTypeOptions<F>) => void;
    getFileTypeOptions: <F extends File = File>(type: FileTypeOptions<F>['type']) => FileTypeOptions<F> | null;
    getFileTypeOptionsByExtension: <F extends File = File>(extension: FileExtension) => FileTypeOptions<F> | null;
    getFileExtensionsByTypes: (types: FileTypeOptions['type'][]) => FileExtension[];
}

export function createFileRegistry(): FileRegistry {

    const fileTypesOptionsMap: Map<FileTypeOptions['type'], FileTypeOptions<any>> = new Map();

    const registerFileType = <F extends File = File>(options: FileTypeOptions<F>): void => {
        fileTypesOptionsMap.set(options.type, options);
    };

    const getFileTypeOptions = <F extends File = File>(type: FileTypeOptions<F>['type']): FileTypeOptions<F> | null => {
        return fileTypesOptionsMap.get(type) ?? null;
    };

    const getFileTypeOptionsByExtension = <F extends File = File>(extension: FileTypeOptions<F>['extensions'][number]): FileTypeOptions<F> | null => {
        return Array.from(fileTypesOptionsMap.values()).find((fileTypeOptions: FileTypeOptions): boolean => {
            return fileTypeOptions.extensions.includes(extension);
        }) ?? null;
    };

    const getFileExtensionsByTypes = (types: FileTypeOptions['type'][]): FileExtension[] => {
        return types.reduce((extensions: FileExtension[], type: FileTypeOptions['type']): FileExtension[] => {
            const fileTypeOptions = fileTypesOptionsMap.get(type);
            return fileTypeOptions ? [...extensions, ...fileTypeOptions.extensions] : extensions;
        }, []);
    };

    return {
        registerFileType,
        getFileTypeOptions,
        getFileTypeOptionsByExtension,
        getFileExtensionsByTypes
    };

}