import {ValidationError, ValidationErrorsMap, ValidationNestedError} from './validation-error';

export interface ValidationErrorsBag {
    isEmpty: () => boolean;
    setError: (key: string, messages: string[]) => void;
    addError: (key: string, message: string) => void;
    deleteError: (key: string) => void;
    toString: (key?: string, separator?: string) => string;
    toArray: (key?: string) => string[];
    toMap: (key?: string) => ValidationErrorsMap;
    clone: () => ValidationErrorsBag;
}

const NESTED_KEY_SEPARATOR: string = '.';
const DEFAULT_MESSAGE_SEPARATOR: string = '. ';

export function createValidationErrorsBag(errors: ValidationErrorsMap | ValidationError[] = {}): ValidationErrorsBag {

    const isEmpty = (): boolean => {
        return flattenMap(errorsMap).length === 0;
    };

    const setError = (key: string, messages: string[]) => {
        errorsMap[key] = messages;
    };

    const addError = (key: string, message: string) => {
        errorsMap[key] = [...(errorsMap[key] ?? []), message];
    };

    const deleteError = (key: string) => {
        delete errorsMap[key];
    };

    const toString = (key: string = '', separator: string = DEFAULT_MESSAGE_SEPARATOR): string => {
        return toArray(key).join(separator);
    };

    const toArray = (key: string = ''): string[] => {
        return flattenMap(toMap(key));
    };

    const toMap = (key: string = ''): ValidationErrorsMap => {
        return filterMap(errorsMap, key);
    };

    const clone = (): ValidationErrorsBag => {
        return createValidationErrorsBag(errorsMap);
    };

    const convertToMap = (errors: ValidationError[]): ValidationErrorsMap => {
        const map: ValidationErrorsMap = {};
        errors.forEach((error: ValidationError): void => {
            if (isNestedError(error)) {
                const nestedMessagesMap = convertToMap(error.errors);
                Object.entries(nestedMessagesMap).forEach(([key, messages]): void => {
                    const nestedKey = (key ? error.key + NESTED_KEY_SEPARATOR + key : error.key).toString();
                    map[nestedKey] = [...(map[nestedKey] ?? []), ...messages];
                });
            } else {
                map[''] = [...(map[''] ?? []), error.message];
            }
        });
        return map;
    };

    const isNestedError = (item: unknown): item is ValidationNestedError => {
        return item !== null && typeof item === 'object' && 'key' in item && 'errors' in item;
    };

    const filterMap = (map: ValidationErrorsMap, key: string): ValidationErrorsMap => {
        if (!key) {
            return map;
        }
        const result: ValidationErrorsMap = {};
        Object.entries(map).forEach(([nestedKey, nestedValue]): void => {
            if (nestedKey === key) {
                result[''] = nestedValue;
            }
            if (nestedKey.startsWith(key + NESTED_KEY_SEPARATOR)) {
                const newKey = nestedKey.replace(key + NESTED_KEY_SEPARATOR, '');
                result[newKey] = nestedValue;
            }
        });
        return result;
    };

    const flattenMap = (map: ValidationErrorsMap): string[] => {
        const result: string[] = [];
        Object.values(map).forEach((messages: string[]): void => {
            result.push(...messages);
        });
        return result;
    };

    const errorsMap: ValidationErrorsMap = Array.isArray(errors) ? convertToMap(errors) : {...errors};

    return {
        isEmpty,
        setError,
        addError,
        deleteError,
        toString,
        toArray,
        toMap,
        clone
    };

}