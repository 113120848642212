import {Dependencies} from './dependencies';
import {DependencyFactory} from './dependency-factory';
import {Key, OptionalKey, RequiredKey} from './dependency-key';
import {DependencyNotRegisteredError} from './dependency-not-registered-error';
import {DependencyScope} from './dependency-scope';
import {DependencyStorage} from './dependency-storage';
import {MaximumStackDepthError} from './maximum-stack-depth-error';

export interface DependencyResolver<D extends Dependencies = {}> {
    resolve: <N extends RequiredKey<D>>(name: N) => D[N];
    resolveOptional: <N extends OptionalKey<D>>(name: N) => D[N] | undefined;
    count: <N extends Key<D>>(name: N) => number;
    scope: <SD extends Dependencies = D>(scope: DependencyScope) => DependencyResolver<SD>;
}

export const DEFAULT_MAX_STACK_DEPTH: number = 32;

interface DependencyResolverOptions<D extends Dependencies = {}> {
    dependencyStorage: DependencyStorage<D>;
    dependencyStack?: Key<D>[];
    maxDependencyStackDepth?: number;
}

export function createDependencyResolver<D extends Dependencies = {}>({
    dependencyStorage,
    dependencyStack = [],
    maxDependencyStackDepth = DEFAULT_MAX_STACK_DEPTH
}: DependencyResolverOptions<D>): DependencyResolver<D> {

    const scopedResolversMap: WeakMap<DependencyScope, DependencyResolver> = new WeakMap();

    const checkStack = (): void => {
        if (dependencyStack.length > maxDependencyStackDepth) {
            throw new MaximumStackDepthError(`Maximum stack depth of ${maxDependencyStackDepth} detected:\n- ${dependencyStack.join('\n- ')}`, {stack: dependencyStack});
        }
    };

    const resolve = <N extends Key<D>>(name: N): D[N] => {
        const resolver: DependencyResolver<D> = createNestedResolver(name);
        const factory: DependencyFactory<D, N> | null = dependencyStorage.getFactory(name);
        if (!factory) {
            return throwDependencyNotRegistered(name);
        }
        return factory(resolver);
    };

    const resolveOptional = <N extends Key<D>>(name: N): D[N] | undefined => {
        const resolver: DependencyResolver<D> = createNestedResolver(name);
        const factory: DependencyFactory<D, N> | null = dependencyStorage.getFactory(name);
        return factory ? factory(resolver) : undefined;
    };

    const count = (name: string): number => {
        return dependencyStorage.getInstancesCount(name);
    };

    const scope = <SD extends Dependencies = D>(scope: DependencyScope): DependencyResolver<SD> => {
        if (scopedResolversMap.has(scope)) {
            return scopedResolversMap.get(scope) as DependencyResolver<SD>;
        }
        const scopedResolver: DependencyResolver<SD> = createDependencyResolver<SD>({
            dependencyStorage: dependencyStorage.scope<SD>(scope),
            dependencyStack,
            maxDependencyStackDepth
        });
        scopedResolversMap.set(scope, scopedResolver);
        return scopedResolver;
    };

    const throwDependencyNotRegistered = <N extends Key<D>>(name: N): never => {
        throw new DependencyNotRegisteredError(`Dependency [${name}] is not registered`, {stack: dependencyStack, name});
    };

    const createNestedResolver = <N extends Key<D>>(name: N): DependencyResolver<D> => {
        return createDependencyResolver<D>({
            dependencyStack: [...dependencyStack, name],
            dependencyStorage,
            maxDependencyStackDepth
        });
    };

    checkStack();

    return {
        resolve,
        resolveOptional,
        count,
        scope
    };

}