import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type ModalClassName = 'modal' | 'title' | 'close';

export interface ModalCSSProps extends CSSThemeProps {

}

export function ModalCSS({theme}: ModalCSSProps) {
    return css<ModalClassName>`

        .modal {
            background: linear-gradient(${theme.color.white}, ${theme.color.veryLight});
            color: ${theme.color.black};
            border-radius: ${theme.borderRadius.medium};
            padding: ${theme.spacing.large};
            box-shadow: ${theme.boxShadow.large};
            position: relative;
            min-width: 300px;
            max-width: 100%;
            font-size: 1rem;
            line-height: 1.5;
        }

        .title {
            color: ${theme.color.white};
            text-shadow: ${theme.textShadow.large}, #000 0 0 2px;
            position: absolute;
            top: -1.5em;
            left: 0;
            font-size: ${theme.fontSize.veryLarge};
            font-weight: bold;
            padding: ${theme.spacing.small};
            line-height: 1;
        }

    `;
}