import {PageOptions} from '@webaker/app';
import {LOGIN_PAGE_TYPE, LoginPage} from './login-page';

export interface LoginPageOptions extends PageOptions<LoginPage> {

}

export function createLoginPageOptions(): LoginPageOptions {
    return {
        type: LOGIN_PAGE_TYPE,
        static: true,
        view: async () => (await import('@webaker/module-login-page/editor')).LoginPageView
    };
}