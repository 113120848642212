import {Component} from '../component/component';
import {Page} from '../page/page';
import {ComponentContent} from './component-content';
import {ComponentsTreeSelector} from './components-tree-selector';
import {PageContent} from './page-content';

type AnyPageContent = PageContent & Record<string, any>;

export interface PageContentSelector<P extends PageContent = AnyPageContent> {

    getPage: (content: P) => Page;

    getComponents: (content: P) => Component[];
    getComponentById: (content: P, componentId: Component['id']) => Component | null;
    getComponentsByIds: (content: P, componentsIds: Component['id'][]) => Component[];
    getComponentsByType: <C extends Component = Component>(content: P, componentType: C['type']) => C[];
    getRootComponent: (content: P) => Component | null;
    getParentComponent: (content: P, componentId: Component['id']) => Component | null;
    getAncestorComponents: (content: P, componentId: Component['id']) => Component[];
    getChildComponents: (content: P, componentId: Component['id']) => Component[];
    getChildComponentsByType: <C extends Component = Component>(content: P, componentId: Component['id'], componentType: C['type']) => C[];
    getDescendantComponents: (content: P, componentId: Component['id']) => Component[];
    getDescendantComponentsByType: <C extends Component = Component>(content: P, componentId: Component['id'], componentType: C['type']) => C[];
    getPreviousComponent: (content: P, componentId: Component['id']) => Component | null;
    getNextComponent: (content: P, componentId: Component['id']) => Component | null;
    getComponentContent: (content: P, componentId: Component['id']) => ComponentContent | null;

    getSharedComponents: (content: P) => Component[];
    getSharedComponentsContents: (content: P) => ComponentContent[];
    getSharedAncestorComponent: (content: P, componentId: Component['id']) => Component | null;

}

export interface PageContentSelectorDeps {
    componentsTreeSelector: ComponentsTreeSelector;
}

export function createPageContentSelector<P extends PageContent = AnyPageContent>({
    componentsTreeSelector
}: PageContentSelectorDeps): PageContentSelector<P> {

    const getPage = (content: P): Page => {
        return content.page;
    };

    const getComponents = (content: P): Component[] => {
        return componentsTreeSelector.getComponents(content.tree);
    };

    const getComponentById = (content: P, componentId: Component['id']): Component | null => {
        return componentsTreeSelector.getComponentById(content.tree, componentId);
    };

    const getComponentsByIds = (content: P, componentsIds: Component['id'][]): Component[] => {
        return componentsTreeSelector.getComponentsByIds(content.tree, componentsIds);
    };

    const getComponentsByType = <C extends Component = Component>(content: P, componentType: C['type']): C[] => {
        return componentsTreeSelector.getComponentsByType(content.tree, componentType);
    };

    const getRootComponent = (content: P): Component | null => {
        return componentsTreeSelector.getRootComponent(content.tree, content.page.id);
    };

    const getParentComponent = (content: P, componentId: Component['id']): Component | null => {
        return componentsTreeSelector.getParentComponent(content.tree, componentId);
    };

    const getAncestorComponents = (content: P, componentId: Component['id']): Component[] => {
        return componentsTreeSelector.getAncestorComponents(content.tree, componentId);
    };

    const getChildComponents = (content: P, componentId: Component['id']): Component[] => {
        return componentsTreeSelector.getChildComponents(content.tree, componentId);
    };

    const getChildComponentsByType = <C extends Component = Component>(content: P, componentId: Component['id'], componentType: C['type']): C[] => {
        return componentsTreeSelector.getChildComponentsByType(content.tree, componentId, componentType);
    };

    const getDescendantComponents = (content: P, componentId: Component['id']): Component[] => {
        return componentsTreeSelector.getDescendantComponents(content.tree, componentId);
    };

    const getDescendantComponentsByType = <C extends Component = Component>(content: P, componentId: Component['id'], componentType: C['type']): C[] => {
        return componentsTreeSelector.getDescendantComponentsByType(content.tree, componentId, componentType);
    };

    const getPreviousComponent = (content: P, componentId: Component['id']): Component | null => {
        return componentsTreeSelector.getPreviousComponent(content.tree, componentId);
    };

    const getNextComponent = (content: P, componentId: Component['id']): Component | null => {
        return componentsTreeSelector.getNextComponent(content.tree, componentId);
    };

    const getComponentContent = (content: P, componentId: Component['id']): ComponentContent | null => {
        return componentsTreeSelector.getComponentContent(content.tree, componentId);
    };

    const getSharedComponents = (content: P): Component[] => {
        return componentsTreeSelector.getSharedComponents(content.tree);
    };

    const getSharedComponentsContents = (content: P): ComponentContent[] => {
        return componentsTreeSelector.getSharedComponentsContents(content.tree);
    };

    const getSharedAncestorComponent = (content: P, componentId: Component['id']): Component | null => {
        return componentsTreeSelector.getSharedAncestorComponent(content.tree, componentId);
    };

    return {
        getPage,
        getComponents,
        getComponentById,
        getComponentsByIds,
        getComponentsByType,
        getRootComponent,
        getParentComponent,
        getAncestorComponents,
        getChildComponents,
        getChildComponentsByType,
        getDescendantComponents,
        getDescendantComponentsByType,
        getPreviousComponent,
        getNextComponent,
        getComponentContent,
        getSharedComponents,
        getSharedComponentsContents,
        getSharedAncestorComponent
    };

}