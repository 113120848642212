import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {useMemo} from 'react';
import {ThemeRenderer} from './theme-renderer';
import {ThemeStore} from './theme-store';

export const GENERATED_THEME_CSS_ID = 'theme-css';

export interface GeneratedThemeCSSProps {

}

export interface GeneratedThemeCSSDeps {
    themeRenderer: ThemeRenderer;
    themeStore: ThemeStore;
}

export function GeneratedThemeCSS({}: GeneratedThemeCSSProps) {

    const themeStore = useStore(useDependency<GeneratedThemeCSSDeps>()('themeStore'));
    const themeRenderer = useDependency<GeneratedThemeCSSDeps>()('themeRenderer');
    const themes = themeStore.getThemes();

    const cssText = useMemo(() => {
        return themeRenderer.renderThemes(themes);
    }, [themes]);

    return (
        <style id={GENERATED_THEME_CSS_ID}
               dangerouslySetInnerHTML={{__html: cssText}}/>
    );

}