export interface ComponentTypeNotFoundErrorDetails {
    type: string;
}

export class ComponentTypeNotFoundError extends Error {

    name = 'COMPONENT_TYPE_NOT_FOUND';

    details: ComponentTypeNotFoundErrorDetails;

    constructor(message: string, details: ComponentTypeNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}