import {UserRole} from './user-role';

export interface UserRoleFactory {
    createUserRole: (data: Partial<UserRole>) => UserRole;
}

export interface UserRoleFactoryDeps {

}

export function createUserRoleFactory({}: UserRoleFactoryDeps): UserRoleFactory {

    const createUserRole = (data: Partial<UserRole>): UserRole => {
        return {
            id: data.id ?? data.name?.replace(/[a-z0-9-]/i, '-').replace(/-/g, '').replace(/^-|-$/, '') ?? '',
            name: data.name ?? ''
        };
    };

    return {
        createUserRole
    };

}