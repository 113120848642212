import {Provider} from '@webaker/package-deps';
import {Serializer} from '@webaker/package-utils';
import {createValidator, Validator} from '@webaker/package-validation';

export interface ProvidedValidationClientDeps {
    validator: Validator;
}

export interface RequiredValidationClientDeps {
    serializer: Serializer;
}

export interface ValidationClientDeps extends ProvidedValidationClientDeps, RequiredValidationClientDeps {

}

export interface ValidationClientConfig {

}

export type ValidationClientProvider = Provider<ValidationClientDeps, ValidationClientConfig>;

export function createValidationClientProvider(): ValidationClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('validator', ({resolve}) => {
                return createValidator({
                    serializer: resolve('serializer')
                });
            });
        }

    };
}