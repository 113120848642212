import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';

export type SeparatorClassName = 'separator';

export function SeparatorCSS({theme}: CSSThemeProps) {
    return css<SeparatorClassName>`

        .separator {
            width: 100%;
            height: 1px;
            background-color: ${theme.color.light};
        }

    `;
}

export interface SeparatorPrivateCSSProps extends CSSThemeProps {
    margin: CSSThemeSize;
}

export function SeparatorPrivateCSS({theme, margin}: SeparatorPrivateCSSProps) {
    return css<SeparatorClassName>`

        .separator {
            margin: ${theme.spacing[margin]} 0;
        }

    `;
}