import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';
import {ButtonGroupAlign} from './button-group';

export type ButtonGroupClassName = 'buttonGroup' | 'is-stretched' | 'is-connected';

export function ButtonGroupCSS({theme}: CSSThemeProps) {

    return css<ButtonGroupClassName>`

        .buttonGroup {
            display: flex;
            flex-direction: row;
            gap: ${theme.spacing.medium};
        }

        .is-connected {
            gap: 0;

            & > :first-child, & > :first-child button {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            & > :not(:first-child, :last-child), & > :not(:first-child, :last-child) button {
                border-radius: 0;
            }

            & > :last-child, & > :last-child button {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

    `;
}

export interface ButtonGroupPrivateCSSProps extends CSSThemeProps {
    align: ButtonGroupAlign;
    margin: CSSThemeSize;
}

export function ButtonGroupPrivateCSS({theme, align, margin}: ButtonGroupPrivateCSSProps) {
    return css<ButtonGroupClassName>`

        .buttonGroup {
            margin: ${theme.spacing[margin]} 0;
            justify-content: ${mapButtonGroupAlignToCSS(align)};

            ${align === 'stretch' ? `
                width: 100%;
                
                & > * {
                    flex: 1;
                }
            ` : ``}
        }

    `;
}

function mapButtonGroupAlignToCSS(align: ButtonGroupAlign): string {
    return {
        start: 'flex-start',
        end: 'flex-end',
        center: 'center',
        stretch: 'stretch'
    }[align];
}