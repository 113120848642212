import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SlotComponentViewClassName = 'slot';

export function SlotComponentViewCSS({}: CSSThemeProps) {
    return css<SlotComponentViewClassName>`

        .slot {
            width: 100%;
            height: 100%;
        }

    `;
}