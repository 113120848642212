import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {contrast} from '@webaker/package-utils';

export type ToastClassName = 'toast' | 'wrapper' | 'message' | 'button';

export interface ToastCSSProps extends CSSThemeProps {

}

export function ToastCSS({theme}: ToastCSSProps) {
    return css<ToastClassName>`

        .toast {
            background: ${theme.color.info};
            color: ${contrast(theme.color.info)};
            border-radius: ${theme.borderRadius.medium};
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: ${theme.spacing.medium} ${theme.spacing.large};
            margin-bottom: ${theme.spacing.medium};
            gap: ${theme.spacing.small};
            font-size: 1rem;
            min-width: 200px;
        }

        .wrapper {
            position: fixed;
            left: ${theme.spacing.veryLarge};
            right: ${theme.spacing.veryLarge};
            padding: 0 ${theme.spacing.medium};
            height: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            z-index: 1000;
        }

        .message {
            flex: 1;
        }

        .button {
            font-size: ${theme.fontSize.small};
        }

    `;
}