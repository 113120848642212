import {Provider} from '@webaker/package-deps';
import {ClassNameRegistry, createClassNameRegistry} from '../class-name/class-name-registry';
import {css} from '../css-literal';
import {createCSSRegistry, CSSRegistry} from '../css-registry';

export interface ProvidedCSSClientDeps {
    classNameRegistry: ClassNameRegistry;
    cssRegistry: CSSRegistry;
}

export interface RequiredCSSClientDeps {

}

export interface CSSClientDeps extends ProvidedCSSClientDeps, RequiredCSSClientDeps {

}

export interface CSSClientConfig {
    minifyCSS: boolean;
}

export type CSSClientProvider = Provider<CSSClientDeps, CSSClientConfig>;

export function createCSSClientProvider(): CSSClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('classNameRegistry', () => {
                return createClassNameRegistry();
            });
            register('cssRegistry', () => {
                return createCSSRegistry();
            });
        },

        registerServices: async ({}, config) => {
            css.options.minify = config.minifyCSS;
        }

    };
}