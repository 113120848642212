import {WrapperComponentProps} from '@webaker/package-utils';
import {FunctionComponent} from 'react';
import {Page} from './page/page';
import {RenderPageFunction} from './page/page-renderer';

export type AppView = FunctionComponent<AppViewProps>;

export interface AppViewProps {
    render: RenderPageFunction;
    page: Page | null;
}

export type AppViewWrapper = FunctionComponent<AppViewWrapperProps>;

export type AppViewWrapperProps = WrapperComponentProps<AppViewProps, 'App'>;