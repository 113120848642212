import {ApiClientFactory} from '@webaker/package-api/client';
import {EXTENSION_API_NAME, ExtensionApi} from '../extension-api';

export interface ExtensionClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createExtensionClientApi({apiClientFactory}: ExtensionClientApiDeps): ExtensionApi {
    return apiClientFactory.createApi<ExtensionApi>(EXTENSION_API_NAME, {
        enableExtension: ['name'],
        disableExtension: ['name']
    });
}