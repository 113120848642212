import {useStore} from '@webaker/package-store';
import {clientOnly} from '@webaker/package-ui';
import {ReactWrapper} from '@webaker/package-utils';
import {Fragment, ReactElement} from 'react';
import {AppStore} from '../app-store';
import {ComponentRenderer} from '../component/component-renderer';
import {Page} from './page';
import {PageExtensionOptions} from './page-extension-options';
import {DEFAULT_PAGE_OPTIONS_SSR} from './page-options';
import {PageRegistry} from './page-registry';
import {PageView, PageViewProps, PageViewWrapper} from './page-view';
import {PageViewNotLoadedError} from './page-view-not-loaded-error';

export interface PageRenderer {
    renderPage: RenderPageFunction;
    loadPageView: (page: Page) => Promise<void>;
}

export type RenderPageFunction = (page: Page | null, params?: RenderPageParams) => ReactElement;

export interface RenderPageParams {

}

export interface RenderPageProps extends RenderPageParams {
    page: Page | null;
}

export interface PageRendererDeps {
    appStore: AppStore;
    componentRenderer: ComponentRenderer;
    pageRegistry: PageRegistry;
    reactWrapper: ReactWrapper;
}

export function createPageRenderer({
    appStore,
    componentRenderer,
    pageRegistry,
    reactWrapper
}: PageRendererDeps): PageRenderer {

    const loadedPagesViewsMap: Map<Page['type'], PageView<any> | null> = new Map();

    const RenderPage = ({page}: RenderPageProps): ReactElement => {
        const localAppStore = useStore(appStore);
        if (!page) {
            return <Fragment/>;
        }
        const PageView = getPageView(page);
        const rootComponent = localAppStore.getRootComponent();
        return (
            <PageView page={page}
                      rootComponent={rootComponent}
                      render={componentRenderer.renderComponent}/>
        );
    };

    const DefaultPageView = ({rootComponent, render}: PageViewProps): ReactElement => {
        return render(rootComponent);
    };

    const renderPage = (page: Page | null, {}: RenderPageParams = {}): ReactElement => {
        return (
            <RenderPage page={page}/>
        );
    };

    const loadPageView = async (page: Page): Promise<void> => {
        if (!loadedPagesViewsMap.has(page.type)) {
            const pageOptions = pageRegistry.getPageOptions(page.type);
            if (pageOptions.view) {
                const pageView = await pageOptions.view();
                loadedPagesViewsMap.set(page.type, pageView);
            } else {
                loadedPagesViewsMap.set(page.type, null);
            }
        }
    };

    const getPageView = (page: Page): PageView => {
        if (!loadedPagesViewsMap.has(page.type)) {
            throw new PageViewNotLoadedError(`Page View for type [${page.type}] is not loaded`, {
                type: page.type
            });
        }
        const pageView = loadedPagesViewsMap.get(page.type) ?? DefaultPageView;
        const pageViewWrappers = pageRegistry.getAllPagesExtensionsOptions().map((
            pageExtensionOptions: PageExtensionOptions
        ) => {
            return pageExtensionOptions.wrapper;
        }).filter(Boolean) as PageViewWrapper[];
        const wrappedComponent = reactWrapper.wrapComponent<PageViewProps, 'Page'>({
            component: pageView,
            wrappers: pageViewWrappers,
            originalProp: 'Page'
        });
        if (!isSSREnabled(page)) {
            return clientOnly(wrappedComponent);
        }
        return wrappedComponent;
    };

    const isSSREnabled = (page: Page): boolean => {
        const pageOptions = pageRegistry.getPageOptions(page.type);
        return pageOptions.ssr ?? DEFAULT_PAGE_OPTIONS_SSR;
    };

    return {
        renderPage,
        loadPageView
    };

}