import {Provider} from '@webaker/package-deps';
import {createHTMLTemplate, HTMLTemplate} from '../html-template';
import {createHTMLTemplateRegistry, HTMLTemplateRegistry} from '../html-template-registry';

export interface ProvidedHTMLTemplateClientDeps {
    htmlTemplate: HTMLTemplate;
    htmlTemplateRegistry: HTMLTemplateRegistry;
}

export interface RequiredHTMLTemplateClientDeps {

}

export interface HTMLTemplateClientDeps extends ProvidedHTMLTemplateClientDeps, RequiredHTMLTemplateClientDeps {

}

export interface HTMLTemplateClientConfig {

}

export type HTMLTemplateClientProvider = Provider<HTMLTemplateClientDeps, HTMLTemplateClientConfig>;

export function createHTMLTemplateClientProvider(): HTMLTemplateClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('htmlTemplate', () => {
                return createHTMLTemplate();
            });
            register('htmlTemplateRegistry', () => {
                return createHTMLTemplateRegistry();
            });
        }

    };
}