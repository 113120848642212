import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type WindowClassName = 'window' | 'header' | 'title' | 'closeButton' | 'content' | 'is-dragging';

export interface WindowCSSProps extends CSSThemeProps {

}

export function WindowCSS({theme}: WindowCSSProps) {
    return css<WindowClassName>`

        .window {
            position: fixed;
            background: linear-gradient(${theme.color.white}, ${theme.color.veryLight});
            border-radius: ${theme.borderRadius.medium};
            box-shadow: ${theme.boxShadow.large};
            min-width: 300px;
            transition: top ${theme.transition.medium};
        }

        .is-dragging {
            transition: none;
        }

        .header {
            color: ${theme.color.black};
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: ${theme.fontSize.large};
            cursor: move;
        }

        .title {
            flex: 1;
            padding: 0 ${theme.spacing.large};
        }

        .closeButton {
            padding: ${theme.spacing.medium} ${theme.spacing.large};
            cursor: pointer;

            &:hover {
                color: ${theme.color.danger};
            }
        }

        .content {
            padding: 0 ${theme.spacing.large} ${theme.spacing.large} ${theme.spacing.large};
        }

    `;
}