import {ValidationError} from '../validation-error';
import {ValidationModifier} from '../validation-modifier';
import {ValidationRule} from '../validation-rule';

export function not<T = unknown>(rule: ValidationRule<T>): ValidationModifier<T> {
    return (item: unknown): ValidationError[] => {
        const errors: ValidationError[] = rule(item);
        if (errors.length === 0) {
            return [{message: `Value cannot pass the rule`}];
        }
        return [];
    };
}