import {CSS} from './css';

export interface CSSRegistry {
    getCSS: () => CSS[];
    getCSSText: () => string;
    registerCSS: (css: CSS) => void;
    unregisterCSS: (css: CSS) => void;
}

export interface CSSRegistryDeps {

}

export function createCSSRegistry({}: CSSRegistryDeps = {}): CSSRegistry {

    const cssMap: Map<string, CSS> = new Map();

    const getCSS = (): CSS[] => {
        return Array.from(cssMap.values());
    };

    const getCSSText = (): string => {
        return getCSS().map((css: CSS): string => {
            return css.cssText;
        }).join('');
    };

    const registerCSS = (css: CSS): void => {
        cssMap.set(css.id, css);
        css.attach();
    };

    const unregisterCSS = (css: CSS): void => {
        cssMap.delete(css.id);
        css.detach();
    };

    return {
        getCSS,
        getCSSText,
        registerCSS,
        unregisterCSS
    };

}