import {Context, useContext} from 'react';
import {ContextNotFoundError} from '../context-not-found-error';

export function useRequiredContext<T>(context: Context<T | null>): T {
    const contextValue = useContext(context);
    if (!contextValue) {
        throw new ContextNotFoundError(
            context.displayName ? `Context [${context.displayName}] not found` : `Context not found`,
            {context}
        );
    }
    return contextValue;
}