import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames, useBooleanState} from '@webaker/package-utils';
import {Children, MutableRefObject, ReactNode, useCallback} from 'react';
import {MdIcon} from './md-icon';
import {TreeNodeCSS, TreeNodePrivateCSS} from './tree-node-css';

export interface TreeNodeProps {
    label: ReactNode;
    icon?: ReactNode;
    color?: CSSThemeColor;
    buttons?: ReactNode;
    sublabel?: ReactNode;
    expandable?: boolean;
    active?: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    children?: ReactNode;
    className?: string;
    itemClassName?: string;
    childrenClassName?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
    itemRef?: MutableRefObject<HTMLElement | null>;
    childrenRef?: MutableRefObject<HTMLElement | null>;
}

export function TreeNode({
    label,
    icon,
    color,
    buttons,
    sublabel,
    expandable,
    active,
    onClick,
    onDoubleClick,
    children,
    className,
    itemClassName,
    childrenClassName,
    elementRef,
    itemRef,
    childrenRef
}: TreeNodeProps) {

    const css = useThemedCSSBundle([TreeNodeCSS, TreeNodePrivateCSS], {
        color: color ?? null
    });
    const [isOpen, open, close] = useBooleanState(true);
    const isEmpty = Children.count(children) === 0;

    const handleToggle = useCallback(() => {
        if (!expandable) {
            return;
        }
        if (isOpen) {
            close();
        } else {
            open();
        }
    }, [expandable, isOpen]);

    return (
        <div ref={elementRef as any}
             className={mergeClassNames(
                 css['treeNode'],
                 active && css['is-active'],
                 expandable && css['is-expandable'],
                 isEmpty && css['is-empty'],
                 isOpen && css['is-open'],
                 onClick && css['is-clickable'],
                 className
             )}>
            <div ref={itemRef as any}
                 className={mergeClassNames(css['item'], itemClassName)}>
                <span className={css['arrow']}
                      onClick={handleToggle}>
                    {expandable && !isEmpty && (
                        <MdIcon name={isOpen ? 'expand_more' : 'chevron_right'}/>
                    )}
                </span>
                <div className={css['label']}
                     onClick={onClick}
                     onDoubleClick={onDoubleClick}>
                    {icon}
                    {label}
                    {sublabel && (
                        <div className={css['sublabel']}>
                            {sublabel}
                        </div>
                    )}
                </div>
                {buttons && (
                    <div className={css['buttons']}>
                        {buttons}
                    </div>
                )}
            </div>
            <div ref={childrenRef as any}
                 className={mergeClassNames(css['children'], childrenClassName)}>
                {children}
            </div>
        </div>
    );

}