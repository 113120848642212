import {ComponentOptions} from '@webaker/app';
import {AUTO_LOGIN_TYPE, AutoLogin} from './auto-login';
import {AutoLoginView} from './auto-login-view';

export interface AutoLoginOptions extends ComponentOptions<AutoLogin> {

}

export function createAutoLoginOptions(): AutoLoginOptions {
    return {

        type: AUTO_LOGIN_TYPE,

        view: AutoLoginView

    };
}