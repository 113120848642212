import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {StoreFactory, StoreRegistry} from '@webaker/package-store';
import {IdGenerator} from '@webaker/package-utils';
import {Validator} from '@webaker/package-validation';
import {createDirectoryFactory, DirectoryFactory} from '../directory-factory';
import {createDirectoryValidator, DirectoryValidator} from '../directory-validator';
import {FileApi} from '../file-api';
import {createFileFactory, FileFactory} from '../file-factory';
import {createFileNameParser, FileNameParser} from '../file-name-parser';
import {FilePicker} from '../file-picker';
import {createFileRegistry, FileRegistry} from '../file-registry';
import {createFileStore, FileStore} from '../file-store';
import {createFileValidator, FileValidator} from '../file-validator';
import {createFileClientApi} from './file-client-api';
import {createFileClientPicker} from './file-client-picker';

export interface ProvidedFileClientDeps {
    directoryFactory: DirectoryFactory;
    directoryValidator: DirectoryValidator;
    fileApi: FileApi;
    fileFactory: FileFactory;
    filePicker: FilePicker;
    fileRegistry: FileRegistry;
    fileStore: FileStore;
    fileNameParser: FileNameParser;
    fileValidator: FileValidator;
}

export interface RequiredFileClientDeps {
    idGenerator: IdGenerator;
    apiClientFactory: ApiClientFactory;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
    validator: Validator;
}

export interface FileClientDeps extends ProvidedFileClientDeps, RequiredFileClientDeps {

}

export interface FileClientConfig {

}

export type FileClientProvider = Provider<FileClientDeps, FileClientConfig>;

export function createFileClientProvider(): FileClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('directoryFactory', ({resolve}) => {
                return createDirectoryFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('directoryValidator', ({resolve}) => {
                return createDirectoryValidator({
                    validator: resolve('validator')
                });
            });
            register('fileApi', ({resolve}) => {
                return createFileClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
            register('fileFactory', ({resolve}) => {
                return createFileFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('filePicker', ({resolve}) => {
                return createFileClientPicker({
                    fileFactory: resolve('fileFactory'),
                    fileRegistry: resolve('fileRegistry')
                });
            });
            register('fileRegistry', () => {
                return createFileRegistry();
            });
            register('fileStore', ({resolve}) => {
                return createFileStore({
                    storeFactory: resolve('storeFactory')
                });
            });
            register('fileNameParser', () => {
                return createFileNameParser();
            });
            register('fileValidator', ({resolve}) => {
                return createFileValidator({
                    validator: resolve('validator')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const storeRegistry = resolve('storeRegistry');
            const fileStore = resolve('fileStore');
            storeRegistry.registerStore(fileStore);
        }

    };
}