import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface PhoneNumberRuleOptions {

}

export function isPhoneNumber<S extends string = string>({}: PhoneNumberRuleOptions = {}): ValidationRule<S> {
    return (item: unknown): ValidationError[] => {
        const regExp = /^\+?([0-9] ?){2,15}[0-9]$/;
        if (typeof item !== 'string' || item.match(regExp) === null) {
            return [
                {message: 'Value has to be valid phone number'}
            ];
        }
        return [];
    };
}