import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type FileManagerClassName = 'fileManager' | 'wrapper' | 'header' | 'separator' | 'pusher'

export interface FileManagerCSSProps extends CSSThemeProps {

}

export function FileManagerCSS({theme}: FileManagerCSSProps) {
    return css<FileManagerClassName>`

        .fileManager {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .header {
            display: flex;
            flex-wrap: wrap;
            row-gap: ${theme.spacing.medium};
        }

        .separator {
            width: ${theme.spacing.veryLarge};
        }

        .pusher {
            flex: 1;
            min-width: ${theme.spacing.veryLarge};
        }

    `;
}