import {WrapperComponentProps} from '@webaker/package-utils';
import {FunctionComponent} from 'react';
import {Component} from '../component/component';
import {RenderComponentFunction} from '../component/component-renderer';
import {Page} from './page';

export type PageView<P extends Page = Page> = FunctionComponent<PageViewProps<P>>;

export interface PageViewProps<P extends Page = Page> {
    page: P;
    rootComponent: Component | null;
    render: RenderComponentFunction;
}

export type AsyncPageView<P extends Page = Page> = () => Promise<PageView<P>>;

export type PageViewWrapper<P extends Page = Page> = FunctionComponent<PageViewWrapperProps<P>>;

export type PageViewWrapperProps<P extends Page = Page> = WrapperComponentProps<PageViewProps<P>, 'Page'>;