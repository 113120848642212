import {isReactElement, matchReactNode} from '@webaker/package-utils';
import React, {Fragment, HTMLAttributes, ReactNode} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';

export interface HTMLTemplate {
    addHTMLAttributes: (attributes: HTMLAttributes<HTMLHtmlElement>) => void;
    removeHTMLAttributes: (attributes: (keyof HTMLAttributes<HTMLHtmlElement>)[]) => void;
    addBodyAttributes: (attributes: HTMLAttributes<HTMLBodyElement>) => void;
    removeBodyAttributes: (attributes: (keyof HTMLAttributes<HTMLBodyElement>)[]) => void;
    addAppAttributes: (attributes: HTMLAttributes<HTMLDivElement>) => void;
    removeAppAttributes: (attributes: (keyof HTMLAttributes<HTMLDivElement>)[]) => void;
    addHeadContent: (content: ReactNode) => void;
    removeHeadContent: (content: ReactNode) => void;
    addBodyContent: (content: ReactNode) => void;
    removeBodyContent: (content: ReactNode) => void;
    addAppContent: (content: ReactNode) => void;
    removeAppContent: (content: ReactNode) => void;
    render: () => string;
}

export function createHTMLTemplate(): HTMLTemplate {

    const htmlAttributes: HTMLAttributes<HTMLHtmlElement> = {};
    const bodyAttributes: HTMLAttributes<HTMLBodyElement> = {};
    const appAttributes: HTMLAttributes<HTMLDivElement> = {};
    const headContent: ReactNode[] = [];
    const bodyContent: ReactNode[] = [];
    const appContent: ReactNode[] = [];

    const addHTMLAttributes = (attributes: HTMLAttributes<HTMLHtmlElement>): void => {
        addAttributes(htmlAttributes, attributes);
    };

    const removeHTMLAttributes = (attributes: (keyof HTMLAttributes<HTMLHtmlElement>)[]): void => {
        removeAttributes(htmlAttributes, attributes);
    };

    const addBodyAttributes = (attributes: HTMLAttributes<HTMLBodyElement>): void => {
        addAttributes(bodyAttributes, attributes);
    };

    const removeBodyAttributes = (attributes: (keyof HTMLAttributes<HTMLBodyElement>)[]): void => {
        removeAttributes(bodyAttributes, attributes);
    };

    const addAppAttributes = (attributes: HTMLAttributes<HTMLDivElement>): void => {
        addAttributes(appAttributes, attributes);
    };

    const removeAppAttributes = (attributes: (keyof HTMLAttributes<HTMLDivElement>)[]): void => {
        removeAttributes(appAttributes, attributes);
    };

    const addHeadContent = (content: ReactNode): void => {
        addContent(headContent, content);
    };

    const removeHeadContent = (content: ReactNode): void => {
        removeContent(headContent, content);
    };

    const addBodyContent = (content: ReactNode): void => {
        addContent(bodyContent, content);
    };

    const removeBodyContent = (content: ReactNode): void => {
        removeContent(bodyContent, content);
    };

    const addAppContent = (content: ReactNode): void => {
        addContent(appContent, content);
    };

    const removeAppContent = (content: ReactNode): void => {
        removeContent(appContent, content);
    };

    const render = (): string => {
        const appHTML = renderToStaticMarkup(
            <div {...appAttributes}>
                {prepareContent(appContent)}
            </div>
        );
        const headHTML = renderToStaticMarkup(
            <Fragment>
                {prepareContent(headContent)}
            </Fragment>
        );
        const bodyHTML = appHTML + renderToStaticMarkup(
            <Fragment>
                {prepareContent(bodyContent)}
            </Fragment>
        );
        return renderToStaticMarkup(
            <html {...htmlAttributes}>
            <head dangerouslySetInnerHTML={{__html: headHTML}}/>
            <body dangerouslySetInnerHTML={{__html: bodyHTML}} {...bodyAttributes}/>
            </html>
        );
    };

    const prepareContent = (content: ReactNode[]): ReactNode[] => {
        return content.map((node: ReactNode, index: number): ReactNode => {
            if (isReactElement(node) && !node.key) {
                return {...node, key: index.toString()};
            }
            return node;
        });
    };

    const addAttributes = <T = unknown>(attributes: HTMLAttributes<T>, attributesToAdd: HTMLAttributes<T>): void => {
        Object.assign(attributes, attributesToAdd);
    };

    const removeAttributes = <T = unknown>(attributes: HTMLAttributes<T>, attributesToRemove: (keyof HTMLAttributes<T>)[]): void => {
        for (const attribute of attributesToRemove) {
            delete attributes[attribute];
        }
    };

    const addContent = (content: ReactNode[], contentToAdd: ReactNode): void => {
        content.push(contentToAdd);
    };

    const removeContent = (content: ReactNode[], contentToRemove: ReactNode): void => {
        content.forEach((node, index) => {
            if (matchReactNode(node, contentToRemove)) {
                content.splice(index, 1);
            }
        });
    };

    return {
        addHTMLAttributes,
        removeHTMLAttributes,
        addBodyAttributes,
        removeBodyAttributes,
        addAppAttributes,
        removeAppAttributes,
        addHeadContent,
        removeHeadContent,
        addBodyContent,
        removeBodyContent,
        addAppContent,
        removeAppContent,
        render
    };

}