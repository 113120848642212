import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type PortalClassName = 'portal' | 'target' | 'is-fixed';

export interface PortalCSSProps extends CSSThemeProps {

}

export function PortalCSS({theme}: PortalCSSProps) {
    return css<PortalClassName>`

        .portal {
            width: 0;
            height: 0;
            visibility: hidden;
            pointer-events: none;

            &.is-fixed {
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }

        .target {
            position: fixed;
            z-index: ${theme.zIndex.front};
            pointer-events: none;

            & > * {
                pointer-events: auto;
            }
        }

    `;
}