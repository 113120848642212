import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type NodeListHeaderClassName = 'nodeListHeader' | 'iconColumn' | 'nameColumn' | 'sizeColumn' | 'accessColumn' | 'goUpButton';

export interface NodeListHeaderCSSProps extends CSSThemeProps {

}

export function NodeListHeaderCSS({theme}: NodeListHeaderCSSProps) {
    return css<NodeListHeaderClassName>`

        .nodeListHeader {
            display: flex;
            padding: ${theme.spacing.medium};
            background-color: ${theme.color.dark};
            border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
            color: ${theme.color.white};
            font-weight: bold;
            line-height: 1.25em;
            cursor: default;
            user-select: none;
        }

        .iconColumn {
            width: 50px;
        }

        .nameColumn {
            width: auto;
            flex: 1;
        }

        .sizeColumn {
            width: 100px;
            text-align: center;
        }

        .accessColumn {
            width: 100px;
            text-align: center;
        }

        .goUpButton {
            cursor: pointer;
        }

    `;
}