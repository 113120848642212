export interface JSONEncoder {
    stringify: (object: any) => string;
    parse: (text: string) => any;
}

export function createJSONEncoder(): JSONEncoder {

    const stringify = (object: any): string => {
        const map: string[] = [];
        const process = (item: any): any => {
            if (item && typeof item === 'object') {
                if (Array.isArray(item)) {
                    return item.map((value) => {
                        return process(value);
                    });
                }
                return Object.entries(item).reduce((newItem, [key, value]) => {
                    let newKey = map.indexOf(key);
                    if (newKey < 0) {
                        newKey = map.length;
                        map.push(key);
                    }
                    newItem[newKey] = process(value);
                    return newItem;
                }, {} as any);
            }
            return item;
        };
        const processedItem = process(object);
        return JSON.stringify([map.join(','), processedItem]);
    };

    const parse = (text: string): any => {
        const [keys, item] = JSON.parse(text);
        const map = keys.split(',');
        const process = (item: any): any => {
            if (item && typeof item === 'object') {
                if (Array.isArray(item)) {
                    return item.map((value) => {
                        return process(value);
                    });
                }
                return Object.entries(item).reduce((newItem, [key, value]) => {
                    let newKey = map[key];
                    newItem[newKey] = process(value);
                    return newItem;
                }, {} as any);
            }
            return item;
        };
        return process(item);
    };

    return {
        stringify,
        parse
    };

}