import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface NumberRuleOptions {
    min?: number;
    max?: number;
    integer?: boolean;
    strict?: boolean;
}

export function isNumber<N extends number = number>({min, max, integer, strict = true}: NumberRuleOptions = {}): ValidationRule<N> {
    return (item: unknown): ValidationError[] => {
        if (!strict && typeof item === 'string') {
            item = Number(item);
        }
        if (typeof item !== 'number' || isNaN(item)) {
            return [{message: `Value has to be number`}];
        }
        const errors: ValidationError[] = [];
        if (min && item < min) {
            errors.push({message: `Number has to be greater than or equal to ${min}`});
        }
        if (max && item > max) {
            errors.push({message: `Number has to be less than or equal to ${max}`});
        }
        if (integer && item !== Math.round(item)) {
            errors.push({message: `Number has to be integer`});
        }
        return errors;
    };
}