import {useEffect, useState} from 'react';
import {KeyboardKey} from '../types/keyboard-key';

export interface UseKeyPressedOptions {
    preventDefault?: boolean;
}

export function useKeyPressed(key: KeyboardKey, {preventDefault}: UseKeyPressedOptions = {}): boolean {
    const [isPressed, setIsPressed] = useState(false);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === key) {
                setIsPressed(true);
                if (preventDefault) {
                    event.preventDefault();
                }
            }
        };
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === key) {
                setIsPressed(false);
                if (preventDefault) {
                    event.preventDefault();
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [key]);
    return isPressed;
}