import {ClassName, ClassNamesMap, useCSS} from '@webaker/package-css';
import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {CSSThemeProps} from './css-theme-props';
import {CSSThemeStore} from './css-theme-store';
import {ThemedCSSComponent} from './themed-css-component';

type GetOptionalRest<T> = {} extends T ? [T?] : [T];

export interface UseThemedCSSDeps {
    cssThemeStore: CSSThemeStore;
}

export function useThemedCSS<P extends CSSThemeProps = CSSThemeProps, C extends ClassName = ClassName>(
    themedCSSComponent: ThemedCSSComponent<P, C>,
    ...[optionalCSSProps]: GetOptionalRest<Omit<P, keyof CSSThemeProps>>
): ClassNamesMap<C> {
    const cssProps = optionalCSSProps ?? {};
    const cssThemeStore = useStore(useDependency<UseThemedCSSDeps>()('cssThemeStore'));
    const {id, name, ...theme} = cssThemeStore.getCurrentCSSTheme() ?? {};
    return useCSS(themedCSSComponent, {theme, ...cssProps});
}