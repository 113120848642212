import {AppExtension} from '@webaker/app';
import {isEditorAvailable} from '@webaker/app/client';
import {createBatchProvider, DependencyContainer, Provider} from '@webaker/package-deps';
import {BootClientAppConfig, BootClientAppDeps, getBootClientAppProviders} from './providers/boot-client-app-providers';
import {BootClientEditorConfig, BootClientEditorDeps} from './providers/boot-client-editor-providers';

export interface BaseBootClientDeps {
    container: DependencyContainer<any>;
}

export type BootClientDeps = BaseBootClientDeps & BootClientAppDeps & BootClientEditorDeps;
export type BootClientConfig = BootClientAppConfig & BootClientEditorConfig;
export type BootClientProvider = Provider<BootClientDeps, BootClientConfig>;

export async function createBootClientProvider(extensions: AppExtension[]): Promise<BootClientProvider> {
    if (isEditorAvailable()) {
        const {getBootClientEditorProviders} = await import('./providers/boot-client-editor-providers');
        const [bootClientAppProviders, bootClientEditorProviders] = await Promise.all([
            getBootClientAppProviders(extensions),
            getBootClientEditorProviders(extensions)
        ]);
        return createBatchProvider<BootClientDeps, BootClientConfig>([
            ...bootClientAppProviders,
            ...bootClientEditorProviders
        ]);
    }
    const bootClientAppProviders = await getBootClientAppProviders(extensions);
    return createBatchProvider<BootClientDeps, BootClientConfig>(bootClientAppProviders);
}