import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {CSSProperties, MutableRefObject} from 'react';
import {MdIconCSS, MdIconPrivateCSS} from './md-icon-css';

export interface MdIconProps {
    name: MdIconName;
    size?: string;
    color?: CSSThemeColor;
    fill?: boolean;
    weight?: MdIconWeight;
    className?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
    style?: CSSProperties;
}

export type MdIconWeight = 'normal' | 'bold' | 'thin';

export function MdIcon({name, size, color, fill, weight, className, elementRef, style}: MdIconProps) {

    const css = useThemedCSSBundle([
        MdIconCSS,
        MdIconPrivateCSS
    ], {
        size: size ?? null,
        color: color ?? null,
        fill: fill ?? false,
        weight: weight ?? 'bold'
    });

    return (
        <i ref={elementRef}
           className={mergeClassNames(css['mdIcon'], className)}
           style={style}>
            {name}
        </i>
    );

}

export type MdIconName =
    '10k' |
    '10mp' |
    '11mp' |
    '123' |
    '12mp' |
    '13mp' |
    '14mp' |
    '15mp' |
    '16mp' |
    '17mp' |
    '18_up_rating' |
    '18mp' |
    '19mp' |
    '1k' |
    '1k_plus' |
    '1x_mobiledata' |
    '1x_mobiledata_badge' |
    '20mp' |
    '21mp' |
    '22mp' |
    '23mp' |
    '24mp' |
    '2d' |
    '2k' |
    '2k_plus' |
    '2mp' |
    '30fps' |
    '30fps_select' |
    '360' |
    '3d_rotation' |
    '3g_mobiledata' |
    '3g_mobiledata_badge' |
    '3k' |
    '3k_plus' |
    '3mp' |
    '3p' |
    '4g_mobiledata' |
    '4g_mobiledata_badge' |
    '4g_plus_mobiledata' |
    '4k' |
    '4k_plus' |
    '4mp' |
    '50mp' |
    '5g' |
    '5g_mobiledata_badge' |
    '5k' |
    '5k_plus' |
    '5mp' |
    '60fps' |
    '60fps_select' |
    '6_ft_apart' |
    '6k' |
    '6k_plus' |
    '6mp' |
    '7k' |
    '7k_plus' |
    '7mp' |
    '8k' |
    '8k_plus' |
    '8mp' |
    '9k' |
    '9k_plus' |
    '9mp' |
    'abc' |
    'ac_unit' |
    'accessibility' |
    'accessibility_new' |
    'accessible' |
    'accessible_forward' |
    'account_balance' |
    'account_balance_wallet' |
    'account_box' |
    'account_child' |
    'account_child_invert' |
    'account_circle' |
    'account_circle_off' |
    'account_tree' |
    'action_key' |
    'activity_zone' |
    'acute' |
    'ad' |
    'ad_group' |
    'ad_group_off' |
    'ad_off' |
    'ad_units' |
    'adb' |
    'add' |
    'add_a_photo' |
    'add_ad' |
    'add_alert' |
    'add_box' |
    'add_business' |
    'add_call' |
    'add_card' |
    'add_chart' |
    'add_circle' |
    'add_comment' |
    'add_home' |
    'add_home_work' |
    'add_link' |
    'add_location' |
    'add_location_alt' |
    'add_moderator' |
    'add_notes' |
    'add_photo_alternate' |
    'add_reaction' |
    'add_road' |
    'add_shopping_cart' |
    'add_task' |
    'add_to_drive' |
    'add_to_home_screen' |
    'add_to_photos' |
    'add_to_queue' |
    'adf_scanner' |
    'adjust' |
    'admin_meds' |
    'admin_panel_settings' |
    'ads_click' |
    'agender' |
    'agriculture' |
    'air' |
    'air_freshener' |
    'air_purifier' |
    'air_purifier_gen' |
    'airline_seat_flat' |
    'airline_seat_flat_angled' |
    'airline_seat_individual_suite' |
    'airline_seat_legroom_extra' |
    'airline_seat_legroom_normal' |
    'airline_seat_legroom_reduced' |
    'airline_seat_recline_extra' |
    'airline_seat_recline_normal' |
    'airline_stops' |
    'airlines' |
    'airplane_ticket' |
    'airplanemode_active' |
    'airplanemode_inactive' |
    'airplay' |
    'airport_shuttle' |
    'airware' |
    'airwave' |
    'alarm' |
    'alarm_add' |
    'alarm_off' |
    'alarm_on' |
    'alarm_smart_wake' |
    'album' |
    'align_center' |
    'align_end' |
    'align_flex_center' |
    'align_flex_end' |
    'align_flex_start' |
    'align_horizontal_center' |
    'align_horizontal_left' |
    'align_horizontal_right' |
    'align_items_stretch' |
    'align_justify_center' |
    'align_justify_flex_end' |
    'align_justify_flex_start' |
    'align_justify_space_around' |
    'align_justify_space_between' |
    'align_justify_space_even' |
    'align_justify_stretch' |
    'align_self_stretch' |
    'align_space_around' |
    'align_space_between' |
    'align_space_even' |
    'align_start' |
    'align_stretch' |
    'align_vertical_bottom' |
    'align_vertical_center' |
    'align_vertical_top' |
    'all_inbox' |
    'all_inclusive' |
    'all_match' |
    'all_out' |
    'allergies' |
    'allergy' |
    'alt_route' |
    'alternate_email' |
    'altitude' |
    'ambulance' |
    'amend' |
    'amp_stories' |
    'analytics' |
    'anchor' |
    'android' |
    'animation' |
    'aod' |
    'aod_tablet' |
    'aod_watch' |
    'apartment' |
    'api' |
    'apk_document' |
    'apk_install' |
    'app_badging' |
    'app_blocking' |
    'app_promo' |
    'app_registration' |
    'app_shortcut' |
    'apparel' |
    'approval' |
    'approval_delegation' |
    'apps' |
    'apps_outage' |
    'aq' |
    'aq_indoor' |
    'ar_on_you' |
    'ar_stickers' |
    'architecture' |
    'archive' |
    'area_chart' |
    'arming_countdown' |
    'arrow_and_edge' |
    'arrow_back' |
    'arrow_back_ios' |
    'arrow_back_ios_new' |
    'arrow_circle_down' |
    'arrow_circle_left' |
    'arrow_circle_right' |
    'arrow_circle_up' |
    'arrow_downward' |
    'arrow_downward_alt' |
    'arrow_drop_down' |
    'arrow_drop_down_circle' |
    'arrow_drop_up' |
    'arrow_forward' |
    'arrow_forward_ios' |
    'arrow_insert' |
    'arrow_left' |
    'arrow_left_alt' |
    'arrow_or_edge' |
    'arrow_outward' |
    'arrow_range' |
    'arrow_right' |
    'arrow_right_alt' |
    'arrow_selector_tool' |
    'arrow_split' |
    'arrow_top_left' |
    'arrow_top_right' |
    'arrow_upward' |
    'arrow_upward_alt' |
    'arrows_more_down' |
    'arrows_more_up' |
    'arrows_outward' |
    'art_track' |
    'article' |
    'article_shortcut' |
    'artist' |
    'aspect_ratio' |
    'assignment' |
    'assignment_add' |
    'assignment_ind' |
    'assignment_late' |
    'assignment_return' |
    'assignment_returned' |
    'assignment_turned_in' |
    'assist_walker' |
    'assistant_device' |
    'assistant_direction' |
    'assistant_navigation' |
    'assistant_on_hub' |
    'assured_workload' |
    'asterisk' |
    'atm' |
    'atr' |
    'attach_email' |
    'attach_file' |
    'attach_file_add' |
    'attach_file_off' |
    'attach_money' |
    'attachment' |
    'attractions' |
    'attribution' |
    'audio_description' |
    'audio_file' |
    'audio_video_receiver' |
    'auto_awesome_mosaic' |
    'auto_awesome_motion' |
    'auto_delete' |
    'auto_read_pause' |
    'auto_read_play' |
    'auto_stories' |
    'auto_towing' |
    'auto_transmission' |
    'autofps_select' |
    'autopause' |
    'autoplay' |
    'autorenew' |
    'autostop' |
    'av_timer' |
    'avg_pace' |
    'avg_time' |
    'award_star' |
    'azm' |
    'baby_changing_station' |
    'back_hand' |
    'back_to_tab' |
    'background_dot_large' |
    'background_dot_small' |
    'background_grid_small' |
    'background_replace' |
    'backlight_high' |
    'backlight_high_off' |
    'backlight_low' |
    'backpack' |
    'backspace' |
    'backup' |
    'backup_table' |
    'badge' |
    'badge_critical_battery' |
    'bakery_dining' |
    'balance' |
    'balcony' |
    'ballot' |
    'bar_chart' |
    'bar_chart_4_bars' |
    'barcode' |
    'barcode_reader' |
    'barcode_scanner' |
    'barefoot' |
    'batch_prediction' |
    'bath_outdoor' |
    'bath_private' |
    'bath_public_large' |
    'bathroom' |
    'bathtub' |
    'battery_0_bar' |
    'battery_1_bar' |
    'battery_2_bar' |
    'battery_3_bar' |
    'battery_4_bar' |
    'battery_5_bar' |
    'battery_6_bar' |
    'battery_alert' |
    'battery_change' |
    'battery_charging_20' |
    'battery_charging_30' |
    'battery_charging_50' |
    'battery_charging_60' |
    'battery_charging_80' |
    'battery_charging_90' |
    'battery_charging_full' |
    'battery_error' |
    'battery_full' |
    'battery_full_alt' |
    'battery_horiz_000' |
    'battery_horiz_050' |
    'battery_horiz_075' |
    'battery_low' |
    'battery_plus' |
    'battery_profile' |
    'battery_saver' |
    'battery_share' |
    'battery_status_good' |
    'battery_unknown' |
    'battery_very_low' |
    'beach_access' |
    'bed' |
    'bedroom_baby' |
    'bedroom_child' |
    'bedroom_parent' |
    'bedtime' |
    'bedtime_off' |
    'beenhere' |
    'bento' |
    'bia' |
    'bid_landscape' |
    'bid_landscape_disabled' |
    'bigtop_updates' |
    'bike_scooter' |
    'biotech' |
    'blanket' |
    'blender' |
    'blind' |
    'blinds' |
    'blinds_closed' |
    'block' |
    'blood_pressure' |
    'bloodtype' |
    'bluetooth' |
    'bluetooth_connected' |
    'bluetooth_disabled' |
    'bluetooth_drive' |
    'bluetooth_searching' |
    'blur_circular' |
    'blur_linear' |
    'blur_medium' |
    'blur_off' |
    'blur_on' |
    'blur_short' |
    'body_fat' |
    'body_system' |
    'bolt' |
    'bomb' |
    'book' |
    'book_2' |
    'book_3' |
    'book_4' |
    'book_5' |
    'book_online' |
    'bookmark' |
    'bookmark_add' |
    'bookmark_added' |
    'bookmark_manager' |
    'bookmark_remove' |
    'bookmarks' |
    'border_all' |
    'border_bottom' |
    'border_clear' |
    'border_color' |
    'border_horizontal' |
    'border_inner' |
    'border_left' |
    'border_outer' |
    'border_right' |
    'border_style' |
    'border_top' |
    'border_vertical' |
    'bottom_app_bar' |
    'bottom_drawer' |
    'bottom_navigation' |
    'bottom_panel_close' |
    'bottom_panel_open' |
    'bottom_right_click' |
    'bottom_sheets' |
    'box' |
    'box_add' |
    'box_edit' |
    'boy' |
    'brand_awareness' |
    'brand_family' |
    'branding_watermark' |
    'breakfast_dining' |
    'breaking_news' |
    'breaking_news_alt_1' |
    'breastfeeding' |
    'brightness_1' |
    'brightness_2' |
    'brightness_3' |
    'brightness_4' |
    'brightness_5' |
    'brightness_6' |
    'brightness_7' |
    'brightness_alert' |
    'brightness_auto' |
    'brightness_empty' |
    'brightness_high' |
    'brightness_low' |
    'brightness_medium' |
    'bring_your_own_ip' |
    'broadcast_on_home' |
    'broadcast_on_personal' |
    'broken_image' |
    'browse' |
    'browse_activity' |
    'browse_gallery' |
    'browser_updated' |
    'brunch_dining' |
    'brush' |
    'bubble' |
    'bubble_chart' |
    'bubbles' |
    'bug_report' |
    'build' |
    'build_circle' |
    'bungalow' |
    'burst_mode' |
    'bus_alert' |
    'business_center' |
    'business_chip' |
    'business_messages' |
    'buttons_alt' |
    'cabin' |
    'cable' |
    'cached' |
    'cake' |
    'cake_add' |
    'calculate' |
    'calendar_add_on' |
    'calendar_apps_script' |
    'calendar_clock' |
    'calendar_month' |
    'calendar_today' |
    'calendar_view_day' |
    'calendar_view_month' |
    'calendar_view_week' |
    'call' |
    'call_end' |
    'call_log' |
    'call_made' |
    'call_merge' |
    'call_missed' |
    'call_missed_outgoing' |
    'call_quality' |
    'call_received' |
    'call_split' |
    'call_to_action' |
    'camera' |
    'camera_front' |
    'camera_indoor' |
    'camera_outdoor' |
    'camera_rear' |
    'camera_roll' |
    'camera_video' |
    'cameraswitch' |
    'campaign' |
    'camping' |
    'cancel' |
    'cancel_presentation' |
    'cancel_schedule_send' |
    'candle' |
    'candlestick_chart' |
    'captive_portal' |
    'capture' |
    'car_crash' |
    'car_rental' |
    'car_repair' |
    'car_tag' |
    'card_membership' |
    'card_travel' |
    'cardiology' |
    'cards' |
    'carpenter' |
    'carry_on_bag' |
    'carry_on_bag_checked' |
    'carry_on_bag_inactive' |
    'carry_on_bag_question' |
    'cases' |
    'casino' |
    'cast' |
    'cast_connected' |
    'cast_for_education' |
    'cast_pause' |
    'cast_warning' |
    'castle' |
    'category' |
    'celebration' |
    'cell_merge' |
    'cell_tower' |
    'cell_wifi' |
    'center_focus_strong' |
    'center_focus_weak' |
    'chair' |
    'chair_alt' |
    'chalet' |
    'change_circle' |
    'change_history' |
    'charger' |
    'charging_station' |
    'chart_data' |
    'chat' |
    'chat_add_on' |
    'chat_apps_script' |
    'chat_bubble' |
    'chat_error' |
    'chat_info' |
    'chat_paste_go' |
    'check' |
    'check_box' |
    'check_box_outline_blank' |
    'check_circle' |
    'check_in_out' |
    'check_indeterminate_small' |
    'check_small' |
    'checkbook' |
    'checked_bag' |
    'checked_bag_question' |
    'checklist' |
    'checklist_rtl' |
    'checkroom' |
    'cheer' |
    'chess' |
    'chevron_left' |
    'chevron_right' |
    'child_care' |
    'child_friendly' |
    'chip_extraction' |
    'chips' |
    'chrome_reader_mode' |
    'chromecast_2' |
    'chromecast_device' |
    'chronic' |
    'church' |
    'cinematic_blur' |
    'circle' |
    'circle_notifications' |
    'circles' |
    'circles_ext' |
    'clarify' |
    'clean_hands' |
    'cleaning' |
    'cleaning_bucket' |
    'cleaning_services' |
    'clear_all' |
    'clear_day' |
    'clear_night' |
    'climate_mini_split' |
    'clinical_notes' |
    'clock_loader_10' |
    'clock_loader_20' |
    'clock_loader_40' |
    'clock_loader_60' |
    'clock_loader_80' |
    'clock_loader_90' |
    'close' |
    'close_fullscreen' |
    'close_small' |
    'closed_caption' |
    'closed_caption_disabled' |
    'cloud' |
    'cloud_circle' |
    'cloud_done' |
    'cloud_download' |
    'cloud_off' |
    'cloud_sync' |
    'cloud_upload' |
    'cloudy_snowing' |
    'co2' |
    'co_present' |
    'code' |
    'code_blocks' |
    'code_off' |
    'coffee' |
    'coffee_maker' |
    'cognition' |
    'collapse_all' |
    'collapse_content' |
    'collections_bookmark' |
    'colorize' |
    'colors' |
    'comedy_mask' |
    'comic_bubble' |
    'comment' |
    'comment_bank' |
    'comments_disabled' |
    'commit' |
    'communication' |
    'communities' |
    'commute' |
    'compare' |
    'compare_arrows' |
    'compass_calibration' |
    'component_exchange' |
    'compost' |
    'compress' |
    'computer' |
    'concierge' |
    'conditions' |
    'confirmation_number' |
    'congenital' |
    'connect_without_contact' |
    'connected_tv' |
    'connecting_airports' |
    'construction' |
    'contact_emergency' |
    'contact_mail' |
    'contact_page' |
    'contact_phone' |
    'contact_support' |
    'contactless' |
    'contactless_off' |
    'contacts' |
    'contacts_product' |
    'content_copy' |
    'content_cut' |
    'content_paste' |
    'content_paste_go' |
    'content_paste_off' |
    'content_paste_search' |
    'contract' |
    'contract_delete' |
    'contract_edit' |
    'contrast' |
    'contrast_rtl_off' |
    'control_camera' |
    'control_point_duplicate' |
    'controller_gen' |
    'conversion_path' |
    'conversion_path_off' |
    'conveyor_belt' |
    'cookie' |
    'cookie_off' |
    'cooking' |
    'cool_to_dry' |
    'copy_all' |
    'copyright' |
    'coronavirus' |
    'corporate_fare' |
    'cottage' |
    'counter_0' |
    'counter_1' |
    'counter_2' |
    'counter_3' |
    'counter_4' |
    'counter_5' |
    'counter_6' |
    'counter_7' |
    'counter_8' |
    'counter_9' |
    'countertops' |
    'create_new_folder' |
    'credit_card' |
    'credit_card_gear' |
    'credit_card_heart' |
    'credit_card_off' |
    'credit_score' |
    'crib' |
    'crisis_alert' |
    'crop' |
    'crop_16_9' |
    'crop_3_2' |
    'crop_5_4' |
    'crop_7_5' |
    'crop_9_16' |
    'crop_free' |
    'crop_landscape' |
    'crop_portrait' |
    'crop_rotate' |
    'crop_square' |
    'crossword' |
    'crowdsource' |
    'cruelty_free' |
    'css' |
    'csv' |
    'currency_bitcoin' |
    'currency_exchange' |
    'currency_franc' |
    'currency_lira' |
    'currency_pound' |
    'currency_ruble' |
    'currency_rupee' |
    'currency_yen' |
    'currency_yuan' |
    'curtains' |
    'curtains_closed' |
    'custom_typography' |
    'cut' |
    'cycle' |
    'cyclone' |
    'dangerous' |
    'dark_mode' |
    'dashboard' |
    'dashboard_customize' |
    'data_alert' |
    'data_array' |
    'data_check' |
    'data_exploration' |
    'data_info_alert' |
    'data_loss_prevention' |
    'data_object' |
    'data_saver_on' |
    'data_table' |
    'data_thresholding' |
    'data_usage' |
    'database' |
    'dataset' |
    'dataset_linked' |
    'date_range' |
    'deblur' |
    'deceased' |
    'decimal_decrease' |
    'decimal_increase' |
    'deck' |
    'dehaze' |
    'delete' |
    'delete_forever' |
    'delete_history' |
    'delete_sweep' |
    'demography' |
    'density_large' |
    'density_medium' |
    'density_small' |
    'dentistry' |
    'departure_board' |
    'deployed_code' |
    'deployed_code_account' |
    'deployed_code_alert' |
    'deployed_code_history' |
    'deployed_code_update' |
    'dermatology' |
    'description' |
    'deselect' |
    'design_services' |
    'desk' |
    'deskphone' |
    'desktop_access_disabled' |
    'desktop_mac' |
    'desktop_windows' |
    'destruction' |
    'details' |
    'detection_and_zone' |
    'detector' |
    'detector_alarm' |
    'detector_battery' |
    'detector_co' |
    'detector_offline' |
    'detector_smoke' |
    'detector_status' |
    'developer_board' |
    'developer_board_off' |
    'developer_guide' |
    'developer_mode' |
    'developer_mode_tv' |
    'device_hub' |
    'device_reset' |
    'device_thermostat' |
    'device_unknown' |
    'devices' |
    'devices_fold' |
    'devices_off' |
    'devices_other' |
    'devices_wearables' |
    'dew_point' |
    'diagnosis' |
    'dialer_sip' |
    'dialogs' |
    'dialpad' |
    'diamond' |
    'dictionary' |
    'difference' |
    'digital_out_of_home' |
    'digital_wellbeing' |
    'dining' |
    'dinner_dining' |
    'directions' |
    'directions_alt' |
    'directions_alt_off' |
    'directions_bike' |
    'directions_boat' |
    'directions_bus' |
    'directions_car' |
    'directions_off' |
    'directions_railway' |
    'directions_run' |
    'directions_subway' |
    'directions_walk' |
    'directory_sync' |
    'dirty_lens' |
    'disabled_by_default' |
    'disabled_visible' |
    'disc_full' |
    'discover_tune' |
    'dishwasher' |
    'dishwasher_gen' |
    'display_external_input' |
    'display_settings' |
    'distance' |
    'diversity_1' |
    'diversity_2' |
    'diversity_3' |
    'diversity_4' |
    'dns' |
    'do_not_disturb_off' |
    'do_not_disturb_on' |
    'do_not_disturb_on_total_silence' |
    'do_not_step' |
    'do_not_touch' |
    'dock' |
    'dock_to_bottom' |
    'dock_to_left' |
    'dock_to_right' |
    'docs_add_on' |
    'docs_apps_script' |
    'document_scanner' |
    'domain' |
    'domain_add' |
    'domain_disabled' |
    'domain_verification' |
    'domain_verification_off' |
    'domino_mask' |
    'done' |
    'done_all' |
    'done_outline' |
    'donut_large' |
    'donut_small' |
    'door_back' |
    'door_front' |
    'door_open' |
    'door_sensor' |
    'door_sliding' |
    'doorbell' |
    'doorbell_3p' |
    'doorbell_chime' |
    'double_arrow' |
    'downhill_skiing' |
    'download' |
    'download_2' |
    'download_done' |
    'download_for_offline' |
    'downloading' |
    'draft' |
    'draft_orders' |
    'drafts' |
    'drag_click' |
    'drag_handle' |
    'drag_indicator' |
    'drag_pan' |
    'draw' |
    'draw_abstract' |
    'draw_collage' |
    'dresser' |
    'drive_file_move' |
    'drive_folder_upload' |
    'dropdown' |
    'dry' |
    'dry_cleaning' |
    'dual_screen' |
    'duo' |
    'dvr' |
    'dynamic_feed' |
    'dynamic_form' |
    'e911_avatar' |
    'e911_emergency' |
    'e_mobiledata' |
    'e_mobiledata_badge' |
    'earbuds' |
    'earbuds_battery' |
    'early_on' |
    'earthquake' |
    'east' |
    'ecg' |
    'ecg_heart' |
    'eco' |
    'eda' |
    'edgesensor_high' |
    'edgesensor_low' |
    'edit' |
    'edit_attributes' |
    'edit_calendar' |
    'edit_document' |
    'edit_location' |
    'edit_location_alt' |
    'edit_note' |
    'edit_notifications' |
    'edit_off' |
    'edit_road' |
    'edit_square' |
    'editor_choice' |
    'egg' |
    'egg_alt' |
    'eject' |
    'elderly' |
    'elderly_woman' |
    'electric_bike' |
    'electric_bolt' |
    'electric_car' |
    'electric_meter' |
    'electric_moped' |
    'electric_rickshaw' |
    'electric_scooter' |
    'electrical_services' |
    'elevation' |
    'elevator' |
    'emergency' |
    'emergency_heat' |
    'emergency_heat_2' |
    'emergency_home' |
    'emergency_recording' |
    'emergency_share' |
    'emergency_share_off' |
    'emoji_events' |
    'emoji_flags' |
    'emoji_food_beverage' |
    'emoji_nature' |
    'emoji_objects' |
    'emoji_people' |
    'emoji_symbols' |
    'emoji_transportation' |
    'emoticon' |
    'empty_dashboard' |
    'enable' |
    'encrypted' |
    'endocrinology' |
    'energy' |
    'energy_program_saving' |
    'energy_program_time_used' |
    'energy_savings_leaf' |
    'engineering' |
    'enhanced_encryption' |
    'ent' |
    'enterprise' |
    'enterprise_off' |
    'equal' |
    'equalizer' |
    'error' |
    'error_med' |
    'escalator' |
    'escalator_warning' |
    'euro' |
    'euro_symbol' |
    'ev_charger' |
    'ev_mobiledata_badge' |
    'ev_shadow' |
    'ev_shadow_add' |
    'ev_shadow_minus' |
    'ev_station' |
    'event' |
    'event_available' |
    'event_busy' |
    'event_list' |
    'event_note' |
    'event_repeat' |
    'event_seat' |
    'event_upcoming' |
    'exclamation' |
    'exercise' |
    'exit_to_app' |
    'expand' |
    'expand_all' |
    'expand_circle_down' |
    'expand_circle_right' |
    'expand_circle_up' |
    'expand_content' |
    'expand_less' |
    'expand_more' |
    'experiment' |
    'explicit' |
    'explore' |
    'explore_nearby' |
    'explore_off' |
    'explosion' |
    'export_notes' |
    'exposure' |
    'exposure_neg_1' |
    'exposure_neg_2' |
    'exposure_plus_1' |
    'exposure_plus_2' |
    'exposure_zero' |
    'extension' |
    'extension_off' |
    'eyeglasses' |
    'face' |
    'face_2' |
    'face_3' |
    'face_4' |
    'face_5' |
    'face_6' |
    'face_retouching_off' |
    'fact_check' |
    'factory' |
    'falling' |
    'familiar_face_and_zone' |
    'family_history' |
    'family_home' |
    'family_link' |
    'family_restroom' |
    'family_star' |
    'farsight_digital' |
    'fast_forward' |
    'fast_rewind' |
    'fastfood' |
    'faucet' |
    'favorite' |
    'fax' |
    'feature_search' |
    'featured_play_list' |
    'featured_seasonal_and_gifts' |
    'featured_video' |
    'feed' |
    'feedback' |
    'female' |
    'femur' |
    'femur_alt' |
    'fence' |
    'fertile' |
    'festival' |
    'fiber_dvr' |
    'fiber_manual_record' |
    'fiber_new' |
    'fiber_pin' |
    'fiber_smart_record' |
    'file_copy' |
    'file_copy_off' |
    'file_download_done' |
    'file_download_off' |
    'file_map' |
    'file_open' |
    'file_present' |
    'file_save' |
    'file_save_off' |
    'file_upload_off' |
    'filter' |
    'filter_1' |
    'filter_2' |
    'filter_3' |
    'filter_4' |
    'filter_5' |
    'filter_6' |
    'filter_7' |
    'filter_8' |
    'filter_9' |
    'filter_9_plus' |
    'filter_alt' |
    'filter_alt_off' |
    'filter_b_and_w' |
    'filter_center_focus' |
    'filter_drama' |
    'filter_frames' |
    'filter_hdr' |
    'filter_list' |
    'filter_list_off' |
    'filter_none' |
    'filter_retrolux' |
    'filter_tilt_shift' |
    'filter_vintage' |
    'finance' |
    'finance_chip' |
    'finance_mode' |
    'find_in_page' |
    'find_replace' |
    'fingerprint' |
    'fire_extinguisher' |
    'fire_hydrant' |
    'fire_truck' |
    'fireplace' |
    'first_page' |
    'fit_page' |
    'fit_screen' |
    'fit_width' |
    'fitness_center' |
    'flag' |
    'flag_circle' |
    'flaky' |
    'flare' |
    'flash_auto' |
    'flash_off' |
    'flash_on' |
    'flashlight_off' |
    'flashlight_on' |
    'flatware' |
    'flex_direction' |
    'flex_no_wrap' |
    'flex_wrap' |
    'flight' |
    'flight_class' |
    'flight_land' |
    'flight_takeoff' |
    'flights_and_hotels' |
    'flightsmode' |
    'flip' |
    'flip_camera_android' |
    'flip_camera_ios' |
    'flip_to_back' |
    'flip_to_front' |
    'flood' |
    'floor' |
    'floor_lamp' |
    'flowsheet' |
    'fluid' |
    'fluid_balance' |
    'fluid_med' |
    'fluorescent' |
    'flutter' |
    'flutter_dash' |
    'fmd_bad' |
    'foggy' |
    'folded_hands' |
    'folder' |
    'folder_copy' |
    'folder_data' |
    'folder_delete' |
    'folder_limited' |
    'folder_managed' |
    'folder_off' |
    'folder_open' |
    'folder_shared' |
    'folder_special' |
    'folder_supervised' |
    'folder_zip' |
    'follow_the_signs' |
    'font_download' |
    'font_download_off' |
    'food_bank' |
    'foot_bones' |
    'footprint' |
    'for_you' |
    'forest' |
    'fork_left' |
    'fork_right' |
    'forklift' |
    'format_align_center' |
    'format_align_justify' |
    'format_align_left' |
    'format_align_right' |
    'format_bold' |
    'format_clear' |
    'format_color_fill' |
    'format_color_reset' |
    'format_color_text' |
    'format_h1' |
    'format_h2' |
    'format_h3' |
    'format_h4' |
    'format_h5' |
    'format_h6' |
    'format_image_left' |
    'format_image_right' |
    'format_indent_decrease' |
    'format_indent_increase' |
    'format_ink_highlighter' |
    'format_italic' |
    'format_letter_spacing' |
    'format_letter_spacing_2' |
    'format_letter_spacing_standard' |
    'format_letter_spacing_wide' |
    'format_letter_spacing_wider' |
    'format_line_spacing' |
    'format_list_bulleted' |
    'format_list_bulleted_add' |
    'format_list_numbered' |
    'format_list_numbered_rtl' |
    'format_overline' |
    'format_paint' |
    'format_paragraph' |
    'format_quote' |
    'format_shapes' |
    'format_size' |
    'format_strikethrough' |
    'format_text_clip' |
    'format_text_overflow' |
    'format_text_wrap' |
    'format_textdirection_l_to_r' |
    'format_textdirection_r_to_l' |
    'format_underlined' |
    'format_underlined_squiggle' |
    'forms_add_on' |
    'forms_apps_script' |
    'fort' |
    'forum' |
    'forward' |
    'forward_10' |
    'forward_30' |
    'forward_5' |
    'forward_circle' |
    'forward_media' |
    'forward_to_inbox' |
    'foundation' |
    'frame_inspect' |
    'frame_person' |
    'frame_person_off' |
    'frame_reload' |
    'frame_source' |
    'free_cancellation' |
    'front_hand' |
    'front_loader' |
    'full_coverage' |
    'full_hd' |
    'full_stacked_bar_chart' |
    'fullscreen' |
    'fullscreen_exit' |
    'function' |
    'functions' |
    'g_mobiledata' |
    'g_mobiledata_badge' |
    'g_translate' |
    'gallery_thumbnail' |
    'gamepad' |
    'garage' |
    'garage_door' |
    'garage_home' |
    'garden_cart' |
    'gas_meter' |
    'gastroenterology' |
    'gate' |
    'gavel' |
    'general_device' |
    'genetics' |
    'genres' |
    'gesture' |
    'gesture_select' |
    'gif' |
    'gif_box' |
    'girl' |
    'gite' |
    'glass_cup' |
    'globe' |
    'globe_asia' |
    'globe_uk' |
    'glucose' |
    'glyphs' |
    'go_to_line' |
    'golf_course' |
    'google_home_devices' |
    'google_tv_remote' |
    'google_wifi' |
    'gpp_bad' |
    'gpp_maybe' |
    'grade' |
    'gradient' |
    'grading' |
    'grain' |
    'graphic_eq' |
    'grass' |
    'grid_3x3' |
    'grid_3x3_off' |
    'grid_4x4' |
    'grid_goldenratio' |
    'grid_guides' |
    'grid_off' |
    'grid_on' |
    'grid_view' |
    'grocery' |
    'group' |
    'group_add' |
    'group_off' |
    'group_remove' |
    'group_work' |
    'grouped_bar_chart' |
    'groups' |
    'groups_2' |
    'groups_3' |
    'gynecology' |
    'h_mobiledata' |
    'h_mobiledata_badge' |
    'h_plus_mobiledata' |
    'h_plus_mobiledata_badge' |
    'hail' |
    'hallway' |
    'hand_bones' |
    'hand_gesture' |
    'handshake' |
    'handyman' |
    'hangout_video' |
    'hangout_video_off' |
    'hard_drive' |
    'hard_drive_2' |
    'hardware' |
    'hd' |
    'hdr_auto' |
    'hdr_auto_select' |
    'hdr_enhanced_select' |
    'hdr_off' |
    'hdr_off_select' |
    'hdr_on' |
    'hdr_on_select' |
    'hdr_plus' |
    'hdr_plus_off' |
    'hdr_strong' |
    'hdr_weak' |
    'headphones' |
    'headphones_battery' |
    'headset_mic' |
    'headset_off' |
    'healing' |
    'health_and_beauty' |
    'health_and_safety' |
    'health_metrics' |
    'heap_snapshot_large' |
    'heap_snapshot_multiple' |
    'heap_snapshot_thumbnail' |
    'hearing' |
    'hearing_disabled' |
    'heart_broken' |
    'heart_check' |
    'heart_minus' |
    'heart_plus' |
    'heat' |
    'heat_pump' |
    'heat_pump_balance' |
    'height' |
    'helicopter' |
    'help' |
    'help_center' |
    'help_clinic' |
    'hematology' |
    'hevc' |
    'hexagon' |
    'hide' |
    'hide_image' |
    'hide_source' |
    'high_density' |
    'high_quality' |
    'high_res' |
    'highlight' |
    'highlight_keyboard_focus' |
    'highlight_mouse_cursor' |
    'highlight_text_cursor' |
    'highlighter_size_1' |
    'highlighter_size_2' |
    'highlighter_size_3' |
    'highlighter_size_4' |
    'highlighter_size_5' |
    'hiking' |
    'history' |
    'history_edu' |
    'history_off' |
    'history_toggle_off' |
    'hive' |
    'hls' |
    'hls_off' |
    'holiday_village' |
    'home' |
    'home_and_garden' |
    'home_app_logo' |
    'home_health' |
    'home_improvement_and_tools' |
    'home_iot_device' |
    'home_max' |
    'home_max_dots' |
    'home_mini' |
    'home_pin' |
    'home_repair_service' |
    'home_speaker' |
    'home_storage' |
    'home_work' |
    'horizontal_distribute' |
    'horizontal_rule' |
    'horizontal_split' |
    'hot_tub' |
    'hotel' |
    'hotel_class' |
    'hourglass' |
    'hourglass_bottom' |
    'hourglass_disabled' |
    'hourglass_empty' |
    'hourglass_top' |
    'house' |
    'house_siding' |
    'house_with_shield' |
    'houseboat' |
    'household_supplies' |
    'how_to_reg' |
    'how_to_vote' |
    'hr_resting' |
    'html' |
    'http' |
    'hub' |
    'humerus' |
    'humerus_alt' |
    'humidity_high' |
    'humidity_indoor' |
    'humidity_low' |
    'humidity_mid' |
    'humidity_percentage' |
    'hvac' |
    'ice_skating' |
    'icecream' |
    'ifl' |
    'iframe' |
    'iframe_off' |
    'image' |
    'image_aspect_ratio' |
    'image_search' |
    'imagesearch_roller' |
    'imagesmode' |
    'immunology' |
    'import_contacts' |
    'important_devices' |
    'in_home_mode' |
    'inactive_order' |
    'inbox' |
    'inbox_customize' |
    'incomplete_circle' |
    'indeterminate_check_box' |
    'indeterminate_question_box' |
    'info' |
    'info_i' |
    'infrared' |
    'ink_eraser' |
    'ink_eraser_off' |
    'ink_highlighter' |
    'ink_highlighter_move' |
    'ink_marker' |
    'ink_pen' |
    'inpatient' |
    'input' |
    'input_circle' |
    'insert_chart' |
    'insert_page_break' |
    'insert_text' |
    'install_desktop' |
    'install_mobile' |
    'instant_mix' |
    'integration_instructions' |
    'interactive_space' |
    'interests' |
    'interpreter_mode' |
    'inventory' |
    'inventory_2' |
    'invert_colors' |
    'invert_colors_off' |
    'ios' |
    'ios_share' |
    'iron' |
    'jamboard_kiosk' |
    'javascript' |
    'join' |
    'join_inner' |
    'join_left' |
    'join_right' |
    'joystick' |
    'jump_to_element' |
    'kayaking' |
    'kebab_dining' |
    'kettle' |
    'key' |
    'key_off' |
    'key_vertical' |
    'key_visualizer' |
    'keyboard' |
    'keyboard_alt' |
    'keyboard_arrow_down' |
    'keyboard_arrow_left' |
    'keyboard_arrow_right' |
    'keyboard_arrow_up' |
    'keyboard_backspace' |
    'keyboard_capslock' |
    'keyboard_capslock_badge' |
    'keyboard_command_key' |
    'keyboard_control_key' |
    'keyboard_double_arrow_down' |
    'keyboard_double_arrow_left' |
    'keyboard_double_arrow_right' |
    'keyboard_double_arrow_up' |
    'keyboard_external_input' |
    'keyboard_full' |
    'keyboard_hide' |
    'keyboard_keys' |
    'keyboard_off' |
    'keyboard_onscreen' |
    'keyboard_option_key' |
    'keyboard_previous_language' |
    'keyboard_return' |
    'keyboard_tab' |
    'keyboard_tab_rtl' |
    'keyboard_voice' |
    'kid_star' |
    'king_bed' |
    'kitchen' |
    'kitesurfing' |
    'lab_panel' |
    'lab_profile' |
    'lab_research' |
    'label' |
    'label_important' |
    'label_off' |
    'labs' |
    'lan' |
    'landscape' |
    'landslide' |
    'language' |
    'language_chinese_array' |
    'language_chinese_cangjie' |
    'language_chinese_dayi' |
    'language_chinese_pinyin' |
    'language_chinese_quick' |
    'language_chinese_wubi' |
    'language_french' |
    'language_gb_english' |
    'language_international' |
    'language_japanese_kana' |
    'language_korean_latin' |
    'language_pinyin' |
    'language_spanish' |
    'language_us' |
    'language_us_colemak' |
    'language_us_dvorak' |
    'laps' |
    'laptop_chromebook' |
    'laptop_mac' |
    'laptop_windows' |
    'lasso_select' |
    'last_page' |
    'laundry' |
    'layers' |
    'layers_clear' |
    'lda' |
    'leaderboard' |
    'leak_add' |
    'leak_remove' |
    'left_click' |
    'left_panel_close' |
    'left_panel_open' |
    'legend_toggle' |
    'lens' |
    'lens_blur' |
    'letter_switch' |
    'library_add' |
    'library_add_check' |
    'library_books' |
    'library_music' |
    'license' |
    'lift_to_talk' |
    'light' |
    'light_group' |
    'light_mode' |
    'light_off' |
    'lightbulb' |
    'lightbulb_circle' |
    'lightning_stand' |
    'line_axis' |
    'line_curve' |
    'line_end' |
    'line_end_arrow' |
    'line_end_arrow_notch' |
    'line_end_circle' |
    'line_end_diamond' |
    'line_end_square' |
    'line_start' |
    'line_start_arrow' |
    'line_start_arrow_notch' |
    'line_start_circle' |
    'line_start_diamond' |
    'line_start_square' |
    'line_style' |
    'line_weight' |
    'linear_scale' |
    'link' |
    'link_off' |
    'linked_camera' |
    'linked_services' |
    'liquor' |
    'list' |
    'list_alt' |
    'list_alt_add' |
    'lists' |
    'live_help' |
    'live_tv' |
    'living' |
    'local_activity' |
    'local_atm' |
    'local_bar' |
    'local_cafe' |
    'local_car_wash' |
    'local_convenience_store' |
    'local_dining' |
    'local_drink' |
    'local_fire_department' |
    'local_florist' |
    'local_gas_station' |
    'local_hospital' |
    'local_laundry_service' |
    'local_library' |
    'local_mall' |
    'local_parking' |
    'local_pharmacy' |
    'local_pizza' |
    'local_police' |
    'local_post_office' |
    'local_see' |
    'local_shipping' |
    'local_taxi' |
    'location_away' |
    'location_chip' |
    'location_city' |
    'location_disabled' |
    'location_home' |
    'location_off' |
    'location_on' |
    'location_searching' |
    'lock' |
    'lock_clock' |
    'lock_open' |
    'lock_open_right' |
    'lock_person' |
    'lock_reset' |
    'login' |
    'logo_dev' |
    'logout' |
    'looks' |
    'looks_3' |
    'looks_4' |
    'looks_5' |
    'looks_6' |
    'looks_one' |
    'looks_two' |
    'loupe' |
    'low_density' |
    'low_priority' |
    'loyalty' |
    'lte_mobiledata' |
    'lte_mobiledata_badge' |
    'lte_plus_mobiledata' |
    'lte_plus_mobiledata_badge' |
    'luggage' |
    'lunch_dining' |
    'lyrics' |
    'macro_auto' |
    'macro_off' |
    'magnification_large' |
    'magnification_small' |
    'magnify_docked' |
    'magnify_fullscreen' |
    'mail' |
    'mail_lock' |
    'male' |
    'man' |
    'man_2' |
    'man_3' |
    'man_4' |
    'manage_accounts' |
    'manage_history' |
    'manage_search' |
    'manga' |
    'manufacturing' |
    'map' |
    'maps_ugc' |
    'margin' |
    'mark_as_unread' |
    'mark_chat_read' |
    'mark_chat_unread' |
    'mark_email_read' |
    'mark_email_unread' |
    'mark_unread_chat_alt' |
    'markdown' |
    'markdown_copy' |
    'markdown_paste' |
    'markunread_mailbox' |
    'masked_transitions' |
    'masks' |
    'match_case' |
    'match_word' |
    'matter' |
    'maximize' |
    'measuring_tape' |
    'media_bluetooth_off' |
    'media_bluetooth_on' |
    'media_link' |
    'media_output' |
    'media_output_off' |
    'mediation' |
    'medical_information' |
    'medical_mask' |
    'medical_services' |
    'medication' |
    'medication_liquid' |
    'meeting_room' |
    'memory' |
    'memory_alt' |
    'menstrual_health' |
    'menu' |
    'menu_book' |
    'menu_open' |
    'merge' |
    'merge_type' |
    'metabolism' |
    'mfg_nest_yale_lock' |
    'mic' |
    'mic_double' |
    'mic_external_off' |
    'mic_external_on' |
    'mic_off' |
    'microbiology' |
    'microwave' |
    'microwave_gen' |
    'military_tech' |
    'mimo' |
    'mimo_disconnect' |
    'mindfulness' |
    'minimize' |
    'minor_crash' |
    'mintmark' |
    'missed_video_call' |
    'missing_controller' |
    'mist' |
    'mitre' |
    'mixture_med' |
    'mms' |
    'mobile_friendly' |
    'mobile_off' |
    'mobile_screen_share' |
    'mobiledata_off' |
    'mode_comment' |
    'mode_cool' |
    'mode_cool_off' |
    'mode_dual' |
    'mode_fan' |
    'mode_fan_off' |
    'mode_heat' |
    'mode_heat_cool' |
    'mode_heat_off' |
    'mode_night' |
    'mode_of_travel' |
    'mode_off_on' |
    'mode_standby' |
    'model_training' |
    'monetization_on' |
    'money' |
    'money_off' |
    'monitor' |
    'monitor_heart' |
    'monitor_weight' |
    'monitor_weight_gain' |
    'monitor_weight_loss' |
    'monitoring' |
    'monochrome_photos' |
    'mood' |
    'mood_bad' |
    'mop' |
    'more' |
    'more_down' |
    'more_horiz' |
    'more_time' |
    'more_up' |
    'more_vert' |
    'mosque' |
    'motion_blur' |
    'motion_mode' |
    'motion_photos_auto' |
    'motion_photos_off' |
    'motion_photos_on' |
    'motion_photos_paused' |
    'motion_sensor_active' |
    'motion_sensor_alert' |
    'motion_sensor_idle' |
    'motion_sensor_urgent' |
    'motorcycle' |
    'mountain_flag' |
    'mouse' |
    'move' |
    'move_down' |
    'move_group' |
    'move_item' |
    'move_location' |
    'move_selection_down' |
    'move_selection_left' |
    'move_selection_right' |
    'move_selection_up' |
    'move_to_inbox' |
    'move_up' |
    'moved_location' |
    'movie' |
    'movie_edit' |
    'movie_info' |
    'moving' |
    'moving_beds' |
    'moving_ministry' |
    'mp' |
    'multicooker' |
    'multiline_chart' |
    'multiple_stop' |
    'museum' |
    'music_cast' |
    'music_note' |
    'music_off' |
    'music_video' |
    'my_location' |
    'mystery' |
    'nat' |
    'nature' |
    'nature_people' |
    'navigate_before' |
    'navigate_next' |
    'navigation' |
    'near_me' |
    'near_me_disabled' |
    'nearby' |
    'nearby_error' |
    'nearby_off' |
    'nephrology' |
    'nest_audio' |
    'nest_cam_floodlight' |
    'nest_cam_indoor' |
    'nest_cam_iq' |
    'nest_cam_iq_outdoor' |
    'nest_cam_magnet_mount' |
    'nest_cam_outdoor' |
    'nest_cam_stand' |
    'nest_cam_wall_mount' |
    'nest_cam_wired_stand' |
    'nest_clock_farsight_analog' |
    'nest_clock_farsight_digital' |
    'nest_connect' |
    'nest_detect' |
    'nest_display' |
    'nest_display_max' |
    'nest_doorbell_visitor' |
    'nest_eco_leaf' |
    'nest_farsight_weather' |
    'nest_found_savings' |
    'nest_gale_wifi' |
    'nest_heat_link_e' |
    'nest_heat_link_gen_3' |
    'nest_hello_doorbell' |
    'nest_mini' |
    'nest_multi_room' |
    'nest_protect' |
    'nest_remote' |
    'nest_remote_comfort_sensor' |
    'nest_secure_alarm' |
    'nest_sunblock' |
    'nest_tag' |
    'nest_thermostat' |
    'nest_thermostat_e_eu' |
    'nest_thermostat_gen_3' |
    'nest_thermostat_sensor' |
    'nest_thermostat_sensor_eu' |
    'nest_thermostat_zirconium_eu' |
    'nest_true_radiant' |
    'nest_wake_on_approach' |
    'nest_wake_on_press' |
    'nest_wifi_point' |
    'nest_wifi_pro' |
    'nest_wifi_pro_2' |
    'nest_wifi_router' |
    'network_cell' |
    'network_check' |
    'network_intelligence_history' |
    'network_intelligence_update' |
    'network_locked' |
    'network_manage' |
    'network_node' |
    'network_ping' |
    'network_wifi' |
    'network_wifi_1_bar' |
    'network_wifi_1_bar_locked' |
    'network_wifi_2_bar' |
    'network_wifi_2_bar_locked' |
    'network_wifi_3_bar' |
    'network_wifi_3_bar_locked' |
    'network_wifi_locked' |
    'neurology' |
    'new_label' |
    'new_releases' |
    'new_window' |
    'news' |
    'newsmode' |
    'newspaper' |
    'newsstand' |
    'next_plan' |
    'next_week' |
    'nfc' |
    'night_shelter' |
    'night_sight_auto' |
    'night_sight_auto_off' |
    'night_sight_max' |
    'nightlife' |
    'nightlight' |
    'nights_stay' |
    'no_accounts' |
    'no_adult_content' |
    'no_backpack' |
    'no_crash' |
    'no_drinks' |
    'no_encryption' |
    'no_flash' |
    'no_food' |
    'no_luggage' |
    'no_meals' |
    'no_meeting_room' |
    'no_photography' |
    'no_sim' |
    'no_sound' |
    'no_stroller' |
    'no_transfer' |
    'noise_aware' |
    'noise_control_off' |
    'noise_control_on' |
    'nordic_walking' |
    'north' |
    'north_east' |
    'north_west' |
    'not_accessible' |
    'not_accessible_forward' |
    'not_listed_location' |
    'not_started' |
    'note' |
    'note_add' |
    'note_alt' |
    'note_stack' |
    'note_stack_add' |
    'notes' |
    'notification_add' |
    'notification_important' |
    'notification_multiple' |
    'notifications' |
    'notifications_active' |
    'notifications_off' |
    'notifications_paused' |
    'notifications_unread' |
    'numbers' |
    'nutrition' |
    'ods' |
    'odt' |
    'offline_bolt' |
    'offline_pin' |
    'offline_share' |
    'oil_barrel' |
    'on_device_training' |
    'on_hub_device' |
    'oncology' |
    'online_prediction' |
    'onsen' |
    'opacity' |
    'open_in_browser' |
    'open_in_full' |
    'open_in_new' |
    'open_in_new_down' |
    'open_in_new_off' |
    'open_in_phone' |
    'open_jam' |
    'open_with' |
    'ophthalmology' |
    'oral_disease' |
    'order_approve' |
    'order_play' |
    'orders' |
    'orthopedics' |
    'other_admission' |
    'other_houses' |
    'outbound' |
    'outbox' |
    'outbox_alt' |
    'outdoor_garden' |
    'outdoor_grill' |
    'outgoing_mail' |
    'outlet' |
    'outpatient' |
    'outpatient_med' |
    'output' |
    'output_circle' |
    'oven' |
    'oven_gen' |
    'overview' |
    'overview_key' |
    'oxygen_saturation' |
    'p2p' |
    'pace' |
    'pacemaker' |
    'package' |
    'package_2' |
    'padding' |
    'page_control' |
    'page_info' |
    'pageless' |
    'pages' |
    'pageview' |
    'paid' |
    'palette' |
    'pallet' |
    'pan_tool' |
    'pan_tool_alt' |
    'pan_zoom' |
    'panorama' |
    'panorama_fish_eye' |
    'panorama_horizontal' |
    'panorama_photosphere' |
    'panorama_vertical' |
    'panorama_wide_angle' |
    'paragliding' |
    'park' |
    'partly_cloudy_day' |
    'partly_cloudy_night' |
    'partner_exchange' |
    'partner_reports' |
    'party_mode' |
    'passkey' |
    'password' |
    'patient_list' |
    'pattern' |
    'pause' |
    'pause_circle' |
    'pause_presentation' |
    'payments' |
    'pedal_bike' |
    'pediatrics' |
    'pen_size_1' |
    'pen_size_2' |
    'pen_size_3' |
    'pen_size_4' |
    'pen_size_5' |
    'pending' |
    'pending_actions' |
    'pentagon' |
    'percent' |
    'pergola' |
    'perm_camera_mic' |
    'perm_contact_calendar' |
    'perm_data_setting' |
    'perm_device_information' |
    'perm_media' |
    'perm_phone_msg' |
    'perm_scan_wifi' |
    'person' |
    'person_2' |
    'person_3' |
    'person_4' |
    'person_add' |
    'person_add_disabled' |
    'person_alert' |
    'person_apron' |
    'person_book' |
    'person_cancel' |
    'person_celebrate' |
    'person_check' |
    'person_edit' |
    'person_off' |
    'person_pin' |
    'person_pin_circle' |
    'person_play' |
    'person_raised_hand' |
    'person_remove' |
    'person_search' |
    'personal_bag' |
    'personal_bag_off' |
    'personal_bag_question' |
    'personal_injury' |
    'personal_places' |
    'pest_control' |
    'pest_control_rodent' |
    'pet_supplies' |
    'pets' |
    'phishing' |
    'phone_android' |
    'phone_bluetooth_speaker' |
    'phone_callback' |
    'phone_disabled' |
    'phone_enabled' |
    'phone_forwarded' |
    'phone_in_talk' |
    'phone_iphone' |
    'phone_locked' |
    'phone_missed' |
    'phone_paused' |
    'phonelink_erase' |
    'phonelink_lock' |
    'phonelink_off' |
    'phonelink_ring' |
    'phonelink_ring_off' |
    'phonelink_setup' |
    'photo' |
    'photo_album' |
    'photo_auto_merge' |
    'photo_camera' |
    'photo_camera_back' |
    'photo_camera_front' |
    'photo_frame' |
    'photo_library' |
    'photo_prints' |
    'photo_size_select_large' |
    'photo_size_select_small' |
    'php' |
    'physical_therapy' |
    'piano' |
    'piano_off' |
    'picture_as_pdf' |
    'picture_in_picture' |
    'picture_in_picture_alt' |
    'picture_in_picture_center' |
    'picture_in_picture_large' |
    'picture_in_picture_medium' |
    'picture_in_picture_mobile' |
    'picture_in_picture_off' |
    'picture_in_picture_small' |
    'pie_chart' |
    'pill' |
    'pill_off' |
    'pin' |
    'pin_drop' |
    'pin_end' |
    'pin_invoke' |
    'pinch' |
    'pinch_zoom_in' |
    'pinch_zoom_out' |
    'pip' |
    'pip_exit' |
    'pivot_table_chart' |
    'place_item' |
    'plagiarism' |
    'planner_banner_ad_pt' |
    'planner_review' |
    'play_arrow' |
    'play_circle' |
    'play_disabled' |
    'play_for_work' |
    'play_lesson' |
    'play_pause' |
    'play_shapes' |
    'playing_cards' |
    'playlist_add' |
    'playlist_add_check' |
    'playlist_add_check_circle' |
    'playlist_add_circle' |
    'playlist_play' |
    'playlist_remove' |
    'plumbing' |
    'podcasts' |
    'podiatry' |
    'podium' |
    'point_of_sale' |
    'point_scan' |
    'policy' |
    'polyline' |
    'polymer' |
    'pool' |
    'portable_wifi_off' |
    'position_bottom_left' |
    'position_bottom_right' |
    'position_top_right' |
    'post' |
    'post_add' |
    'potted_plant' |
    'power' |
    'power_input' |
    'power_off' |
    'power_settings_new' |
    'prayer_times' |
    'precision_manufacturing' |
    'pregnancy' |
    'pregnant_woman' |
    'preliminary' |
    'prescriptions' |
    'present_to_all' |
    'preview' |
    'preview_off' |
    'price_change' |
    'price_check' |
    'print' |
    'print_add' |
    'print_connect' |
    'print_disabled' |
    'print_error' |
    'print_lock' |
    'priority' |
    'priority_high' |
    'privacy' |
    'privacy_tip' |
    'private_connectivity' |
    'problem' |
    'procedure' |
    'process_chart' |
    'production_quantity_limits' |
    'productivity' |
    'progress_activity' |
    'prompt_suggestion' |
    'propane' |
    'propane_tank' |
    'psychiatry' |
    'psychology' |
    'psychology_alt' |
    'public' |
    'public_off' |
    'publish' |
    'published_with_changes' |
    'pulmonology' |
    'pulse_alert' |
    'punch_clock' |
    'push_pin' |
    'qr_code' |
    'qr_code_2' |
    'qr_code_2_add' |
    'qr_code_scanner' |
    'query_stats' |
    'question_exchange' |
    'question_mark' |
    'queue_music' |
    'queue_play_next' |
    'quick_phrases' |
    'quick_reference' |
    'quick_reference_all' |
    'quick_reorder' |
    'quickreply' |
    'quiet_time' |
    'quiet_time_active' |
    'quiz' |
    'r_mobiledata' |
    'radar' |
    'radio' |
    'radio_button_checked' |
    'radio_button_partial' |
    'radio_button_unchecked' |
    'radiology' |
    'railway_alert' |
    'rainy' |
    'rainy_heavy' |
    'rainy_light' |
    'rainy_snow' |
    'ramen_dining' |
    'ramp_left' |
    'ramp_right' |
    'range_hood' |
    'rate_review' |
    'raven' |
    'raw_off' |
    'raw_on' |
    'read_more' |
    'readiness_score' |
    'real_estate_agent' |
    'rear_camera' |
    'rebase' |
    'rebase_edit' |
    'receipt' |
    'receipt_long' |
    'recent_actors' |
    'recent_patient' |
    'recommend' |
    'record_voice_over' |
    'rectangle' |
    'recycling' |
    'redeem' |
    'redo' |
    'reduce_capacity' |
    'refresh' |
    'regular_expression' |
    'relax' |
    'release_alert' |
    'remember_me' |
    'reminder' |
    'remote_gen' |
    'remove' |
    'remove_done' |
    'remove_from_queue' |
    'remove_moderator' |
    'remove_road' |
    'remove_selection' |
    'remove_shopping_cart' |
    'reopen_window' |
    'reorder' |
    'repartition' |
    'repeat' |
    'repeat_on' |
    'repeat_one' |
    'repeat_one_on' |
    'replay' |
    'replay_10' |
    'replay_30' |
    'replay_5' |
    'reply' |
    'reply_all' |
    'report' |
    'report_off' |
    'request_page' |
    'request_quote' |
    'reset_image' |
    'reset_tv' |
    'reset_wrench' |
    'resize' |
    'respiratory_rate' |
    'responsive_layout' |
    'restart_alt' |
    'restaurant' |
    'restaurant_menu' |
    'restore_from_trash' |
    'restore_page' |
    'resume' |
    'reviews' |
    'rewarded_ads' |
    'rheumatology' |
    'rib_cage' |
    'rice_bowl' |
    'right_click' |
    'right_panel_close' |
    'right_panel_open' |
    'ring_volume' |
    'ripples' |
    'robot' |
    'robot_2' |
    'rocket' |
    'rocket_launch' |
    'roller_shades' |
    'roller_shades_closed' |
    'roller_skating' |
    'roofing' |
    'room_preferences' |
    'room_service' |
    'rotate_90_degrees_ccw' |
    'rotate_90_degrees_cw' |
    'rotate_left' |
    'rotate_right' |
    'roundabout_left' |
    'roundabout_right' |
    'rounded_corner' |
    'route' |
    'router' |
    'routine' |
    'rowing' |
    'rss_feed' |
    'rsvp' |
    'rtt' |
    'rubric' |
    'rule' |
    'rule_folder' |
    'rule_settings' |
    'run_circle' |
    'running_with_errors' |
    'rv_hookup' |
    'safety_check' |
    'safety_check_off' |
    'safety_divider' |
    'sailing' |
    'salinity' |
    'sanitizer' |
    'satellite' |
    'satellite_alt' |
    'sauna' |
    'save' |
    'save_as' |
    'saved_search' |
    'savings' |
    'scale' |
    'scan' |
    'scan_delete' |
    'scanner' |
    'scatter_plot' |
    'scene' |
    'schedule' |
    'schedule_send' |
    'schema' |
    'school' |
    'science' |
    'science_off' |
    'score' |
    'scoreboard' |
    'screen_lock_landscape' |
    'screen_lock_portrait' |
    'screen_lock_rotation' |
    'screen_record' |
    'screen_rotation' |
    'screen_rotation_alt' |
    'screen_rotation_up' |
    'screen_search_desktop' |
    'screen_share' |
    'screenshot' |
    'screenshot_frame' |
    'screenshot_keyboard' |
    'screenshot_monitor' |
    'screenshot_region' |
    'screenshot_tablet' |
    'scrollable_header' |
    'scuba_diving' |
    'sd' |
    'sd_card' |
    'sd_card_alert' |
    'sdk' |
    'search' |
    'search_check' |
    'search_hands_free' |
    'search_off' |
    'security' |
    'security_key' |
    'security_update_good' |
    'security_update_warning' |
    'segment' |
    'select' |
    'select_all' |
    'select_check_box' |
    'select_to_speak' |
    'select_window' |
    'select_window_off' |
    'self_care' |
    'self_improvement' |
    'sell' |
    'send' |
    'send_and_archive' |
    'send_money' |
    'send_time_extension' |
    'send_to_mobile' |
    'sensor_door' |
    'sensor_occupied' |
    'sensor_window' |
    'sensors' |
    'sensors_krx' |
    'sensors_krx_off' |
    'sensors_off' |
    'sentiment_calm' |
    'sentiment_content' |
    'sentiment_dissatisfied' |
    'sentiment_excited' |
    'sentiment_extremely_dissatisfied' |
    'sentiment_frustrated' |
    'sentiment_neutral' |
    'sentiment_sad' |
    'sentiment_satisfied' |
    'sentiment_stressed' |
    'sentiment_very_dissatisfied' |
    'sentiment_very_satisfied' |
    'sentiment_worried' |
    'service_toolbox' |
    'set_meal' |
    'settings' |
    'settings_accessibility' |
    'settings_account_box' |
    'settings_alert' |
    'settings_applications' |
    'settings_b_roll' |
    'settings_backup_restore' |
    'settings_bluetooth' |
    'settings_brightness' |
    'settings_cell' |
    'settings_cinematic_blur' |
    'settings_ethernet' |
    'settings_heart' |
    'settings_input_antenna' |
    'settings_input_component' |
    'settings_input_hdmi' |
    'settings_input_svideo' |
    'settings_motion_mode' |
    'settings_night_sight' |
    'settings_overscan' |
    'settings_panorama' |
    'settings_phone' |
    'settings_photo_camera' |
    'settings_power' |
    'settings_remote' |
    'settings_slow_motion' |
    'settings_system_daydream' |
    'settings_timelapse' |
    'settings_video_camera' |
    'settings_voice' |
    'settop_component' |
    'severe_cold' |
    'shadow' |
    'shadow_add' |
    'shadow_minus' |
    'shape_line' |
    'shapes' |
    'share' |
    'share_location' |
    'share_off' |
    'share_reviews' |
    'share_windows' |
    'sheets_rtl' |
    'shelf_auto_hide' |
    'shelf_position' |
    'shelves' |
    'shield' |
    'shield_lock' |
    'shield_locked' |
    'shield_moon' |
    'shield_person' |
    'shield_question' |
    'shield_with_heart' |
    'shield_with_house' |
    'shift' |
    'shift_lock' |
    'shop' |
    'shop_two' |
    'shopping_bag' |
    'shopping_basket' |
    'shopping_cart' |
    'shopping_cart_checkout' |
    'shopping_cart_off' |
    'shoppingmode' |
    'short_stay' |
    'short_text' |
    'show_chart' |
    'shower' |
    'shuffle' |
    'shuffle_on' |
    'shutter_speed' |
    'shutter_speed_add' |
    'shutter_speed_minus' |
    'sick' |
    'side_navigation' |
    'sign_language' |
    'signal_cellular_0_bar' |
    'signal_cellular_1_bar' |
    'signal_cellular_2_bar' |
    'signal_cellular_3_bar' |
    'signal_cellular_4_bar' |
    'signal_cellular_add' |
    'signal_cellular_alt' |
    'signal_cellular_alt_1_bar' |
    'signal_cellular_alt_2_bar' |
    'signal_cellular_connected_no_internet_0_bar' |
    'signal_cellular_connected_no_internet_4_bar' |
    'signal_cellular_nodata' |
    'signal_cellular_null' |
    'signal_cellular_off' |
    'signal_cellular_pause' |
    'signal_disconnected' |
    'signal_wifi_0_bar' |
    'signal_wifi_4_bar' |
    'signal_wifi_bad' |
    'signal_wifi_off' |
    'signal_wifi_statusbar_not_connected' |
    'signal_wifi_statusbar_null' |
    'signature' |
    'signpost' |
    'sim_card' |
    'sim_card_download' |
    'single_bed' |
    'sip' |
    'skateboarding' |
    'skeleton' |
    'skillet' |
    'skillet_cooktop' |
    'skip_next' |
    'skip_previous' |
    'skull' |
    'sledding' |
    'sleep_score' |
    'slide_library' |
    'sliders' |
    'slideshow' |
    'slow_motion_video' |
    'smart_display' |
    'smart_outlet' |
    'smart_screen' |
    'smart_toy' |
    'smartphone' |
    'smb_share' |
    'smoke_free' |
    'smoking_rooms' |
    'sms' |
    'snippet_folder' |
    'snooze' |
    'snowboarding' |
    'snowing' |
    'snowing_heavy' |
    'snowmobile' |
    'snowshoeing' |
    'soap' |
    'social_distance' |
    'social_leaderboard' |
    'solar_power' |
    'sort' |
    'sort_by_alpha' |
    'sos' |
    'sound_detection_dog_barking' |
    'sound_detection_glass_break' |
    'sound_detection_loud_sound' |
    'sound_sampler' |
    'soup_kitchen' |
    'source_environment' |
    'source_notes' |
    'south' |
    'south_america' |
    'south_east' |
    'south_west' |
    'spa' |
    'space_bar' |
    'space_dashboard' |
    'spatial_audio' |
    'spatial_audio_off' |
    'spatial_tracking' |
    'speaker' |
    'speaker_group' |
    'speaker_notes' |
    'speaker_notes_off' |
    'speaker_phone' |
    'special_character' |
    'specific_gravity' |
    'speech_to_text' |
    'speed' |
    'speed_0_5' |
    'speed_1_2' |
    'speed_1_5' |
    'speed_2x' |
    'spellcheck' |
    'splitscreen' |
    'splitscreen_add' |
    'splitscreen_bottom' |
    'splitscreen_left' |
    'splitscreen_right' |
    'splitscreen_top' |
    'splitscreen_vertical_add' |
    'spo2' |
    'spoke' |
    'sports' |
    'sports_and_outdoors' |
    'sports_bar' |
    'sports_baseball' |
    'sports_basketball' |
    'sports_cricket' |
    'sports_esports' |
    'sports_football' |
    'sports_golf' |
    'sports_gymnastics' |
    'sports_handball' |
    'sports_hockey' |
    'sports_kabaddi' |
    'sports_martial_arts' |
    'sports_mma' |
    'sports_motorsports' |
    'sports_rugby' |
    'sports_score' |
    'sports_soccer' |
    'sports_tennis' |
    'sports_volleyball' |
    'sprinkler' |
    'sprint' |
    'square' |
    'square_foot' |
    'ssid_chart' |
    'stack' |
    'stack_off' |
    'stack_star' |
    'stacked_bar_chart' |
    'stacked_email' |
    'stacked_inbox' |
    'stacked_line_chart' |
    'stacks' |
    'stadia_controller' |
    'stadium' |
    'stairs' |
    'star' |
    'star_half' |
    'star_rate' |
    'star_rate_half' |
    'stars' |
    'start' |
    'stat_0' |
    'stat_1' |
    'stat_2' |
    'stat_3' |
    'stat_minus_1' |
    'stat_minus_2' |
    'stat_minus_3' |
    'stay_current_landscape' |
    'stay_current_portrait' |
    'stay_primary_landscape' |
    'stay_primary_portrait' |
    'step' |
    'step_into' |
    'step_out' |
    'step_over' |
    'steppers' |
    'steps' |
    'stethoscope' |
    'stethoscope_arrow' |
    'stethoscope_check' |
    'sticky_note' |
    'sticky_note_2' |
    'stock_media' |
    'stockpot' |
    'stop' |
    'stop_circle' |
    'stop_screen_share' |
    'storage' |
    'store' |
    'storefront' |
    'storm' |
    'straight' |
    'straighten' |
    'strategy' |
    'stream' |
    'stream_apps' |
    'streetview' |
    'stress_management' |
    'strikethrough_s' |
    'stroke_full' |
    'stroke_partial' |
    'stroller' |
    'style' |
    'styler' |
    'stylus' |
    'stylus_laser_pointer' |
    'stylus_note' |
    'subdirectory_arrow_left' |
    'subdirectory_arrow_right' |
    'subheader' |
    'subject' |
    'subscript' |
    'subscriptions' |
    'subtitles' |
    'subtitles_off' |
    'subway' |
    'summarize' |
    'sunny' |
    'sunny_snowing' |
    'superscript' |
    'supervised_user_circle' |
    'supervised_user_circle_off' |
    'supervisor_account' |
    'support' |
    'support_agent' |
    'surfing' |
    'surgical' |
    'surround_sound' |
    'swap_calls' |
    'swap_driving_apps' |
    'swap_driving_apps_wheel' |
    'swap_horiz' |
    'swap_horizontal_circle' |
    'swap_vert' |
    'swap_vertical_circle' |
    'sweep' |
    'swipe' |
    'swipe_down' |
    'swipe_down_alt' |
    'swipe_left' |
    'swipe_left_alt' |
    'swipe_right' |
    'swipe_right_alt' |
    'swipe_up' |
    'swipe_up_alt' |
    'swipe_vertical' |
    'switch' |
    'switch_access' |
    'switch_access_2' |
    'switch_access_shortcut' |
    'switch_access_shortcut_add' |
    'switch_account' |
    'switch_camera' |
    'switch_left' |
    'switch_right' |
    'switch_video' |
    'switches' |
    'sword_rose' |
    'swords' |
    'symptoms' |
    'synagogue' |
    'sync' |
    'sync_alt' |
    'sync_disabled' |
    'sync_lock' |
    'sync_problem' |
    'sync_saved_locally' |
    'syringe' |
    'system_update' |
    'system_update_alt' |
    'tab' |
    'tab_close' |
    'tab_close_right' |
    'tab_duplicate' |
    'tab_group' |
    'tab_move' |
    'tab_new_right' |
    'tab_recent' |
    'tab_unselected' |
    'table' |
    'table_bar' |
    'table_chart' |
    'table_chart_view' |
    'table_lamp' |
    'table_restaurant' |
    'table_rows' |
    'table_rows_narrow' |
    'table_view' |
    'tablet' |
    'tablet_android' |
    'tablet_mac' |
    'tabs' |
    'tactic' |
    'tag' |
    'takeout_dining' |
    'tamper_detection_off' |
    'tamper_detection_on' |
    'tap_and_play' |
    'tapas' |
    'target' |
    'task' |
    'task_alt' |
    'taunt' |
    'taxi_alert' |
    'team_dashboard' |
    'temp_preferences_eco' |
    'temple_buddhist' |
    'temple_hindu' |
    'tenancy' |
    'terminal' |
    'text_ad' |
    'text_decrease' |
    'text_fields' |
    'text_fields_alt' |
    'text_format' |
    'text_increase' |
    'text_rotate_up' |
    'text_rotate_vertical' |
    'text_rotation_angledown' |
    'text_rotation_angleup' |
    'text_rotation_down' |
    'text_rotation_none' |
    'text_select_end' |
    'text_select_jump_to_beginning' |
    'text_select_jump_to_end' |
    'text_select_move_back_character' |
    'text_select_move_back_word' |
    'text_select_move_down' |
    'text_select_move_forward_character' |
    'text_select_move_forward_word' |
    'text_select_move_up' |
    'text_select_start' |
    'text_snippet' |
    'text_to_speech' |
    'texture' |
    'texture_add' |
    'texture_minus' |
    'theater_comedy' |
    'theaters' |
    'thermometer' |
    'thermometer_add' |
    'thermometer_gain' |
    'thermometer_loss' |
    'thermometer_minus' |
    'thermostat' |
    'thermostat_auto' |
    'thermostat_carbon' |
    'things_to_do' |
    'thread_unread' |
    'thumb_down' |
    'thumb_up' |
    'thumbnail_bar' |
    'thumbs_up_down' |
    'thunderstorm' |
    'tibia' |
    'tibia_alt' |
    'time_auto' |
    'timelapse' |
    'timeline' |
    'timer' |
    'timer_10' |
    'timer_10_alt_1' |
    'timer_10_select' |
    'timer_3' |
    'timer_3_alt_1' |
    'timer_3_select' |
    'timer_off' |
    'tire_repair' |
    'title' |
    'toast' |
    'toc' |
    'today' |
    'toggle_off' |
    'toggle_on' |
    'token' |
    'toll' |
    'tonality' |
    'toolbar' |
    'tools_flat_head' |
    'tools_installation_kit' |
    'tools_ladder' |
    'tools_level' |
    'tools_phillips' |
    'tools_pliers_wire_stripper' |
    'tools_power_drill' |
    'tooltip' |
    'top_panel_close' |
    'top_panel_open' |
    'topic' |
    'tornado' |
    'total_dissolved_solids' |
    'touch_app' |
    'touchpad_mouse' |
    'touchpad_mouse_off' |
    'tour' |
    'toys' |
    'toys_and_games' |
    'toys_fan' |
    'track_changes' |
    'traffic' |
    'trail_length' |
    'trail_length_medium' |
    'trail_length_short' |
    'train' |
    'tram' |
    'transcribe' |
    'transfer_within_a_station' |
    'transform' |
    'transgender' |
    'transit_enterexit' |
    'transition_chop' |
    'transition_dissolve' |
    'transition_fade' |
    'transition_push' |
    'transition_slide' |
    'translate' |
    'transportation' |
    'travel' |
    'travel_explore' |
    'travel_luggage_and_bags' |
    'trending_down' |
    'trending_flat' |
    'trending_up' |
    'trip' |
    'trip_origin' |
    'trolley' |
    'trophy' |
    'troubleshoot' |
    'tsunami' |
    'tsv' |
    'tty' |
    'tune' |
    'turn_left' |
    'turn_right' |
    'turn_sharp_left' |
    'turn_sharp_right' |
    'turn_slight_left' |
    'turn_slight_right' |
    'tv' |
    'tv_gen' |
    'tv_guide' |
    'tv_off' |
    'tv_options_edit_channels' |
    'tv_options_input_settings' |
    'tv_remote' |
    'tv_signin' |
    'tv_with_assistant' |
    'two_pager' |
    'two_wheeler' |
    'type_specimen' |
    'u_turn_left' |
    'u_turn_right' |
    'ulna_radius' |
    'ulna_radius_alt' |
    'umbrella' |
    'unarchive' |
    'undo' |
    'unfold_less' |
    'unfold_less_double' |
    'unfold_more' |
    'unfold_more_double' |
    'ungroup' |
    'universal_currency' |
    'universal_currency_alt' |
    'universal_local' |
    'unknown_2' |
    'unknown_5' |
    'unknown_document' |
    'unknown_med' |
    'unlicense' |
    'unpublished' |
    'unsubscribe' |
    'upcoming' |
    'update' |
    'update_disabled' |
    'upgrade' |
    'upload' |
    'upload_2' |
    'upload_file' |
    'urology' |
    'usb' |
    'usb_off' |
    'user_attributes' |
    'vaccines' |
    'vacuum' |
    'valve' |
    'vape_free' |
    'vaping_rooms' |
    'variable_add' |
    'variable_insert' |
    'variable_remove' |
    'variables' |
    'ventilator' |
    'verified' |
    'verified_user' |
    'vertical_align_bottom' |
    'vertical_align_center' |
    'vertical_align_top' |
    'vertical_distribute' |
    'vertical_shades' |
    'vertical_shades_closed' |
    'vertical_split' |
    'vibration' |
    'video_call' |
    'video_camera_back' |
    'video_camera_front' |
    'video_camera_front_off' |
    'video_chat' |
    'video_file' |
    'video_label' |
    'video_library' |
    'video_search' |
    'video_settings' |
    'video_stable' |
    'videocam' |
    'videocam_off' |
    'videogame_asset' |
    'videogame_asset_off' |
    'view_agenda' |
    'view_array' |
    'view_carousel' |
    'view_column' |
    'view_column_2' |
    'view_comfy' |
    'view_comfy_alt' |
    'view_compact' |
    'view_compact_alt' |
    'view_cozy' |
    'view_day' |
    'view_headline' |
    'view_in_ar' |
    'view_in_ar_off' |
    'view_kanban' |
    'view_list' |
    'view_module' |
    'view_quilt' |
    'view_sidebar' |
    'view_stream' |
    'view_timeline' |
    'view_week' |
    'vignette' |
    'villa' |
    'visibility' |
    'visibility_lock' |
    'visibility_off' |
    'vital_signs' |
    'voice_chat' |
    'voice_over_off' |
    'voice_selection' |
    'voicemail' |
    'volcano' |
    'volume_down' |
    'volume_down_alt' |
    'volume_mute' |
    'volume_off' |
    'volume_up' |
    'volunteer_activism' |
    'voting_chip' |
    'vpn_key' |
    'vpn_key_alert' |
    'vpn_key_off' |
    'vpn_lock' |
    'vr180_create2d' |
    'vr180_create2d_off' |
    'vrpano' |
    'wall_art' |
    'wall_lamp' |
    'wallet' |
    'wallpaper' |
    'wallpaper_slideshow' |
    'ward' |
    'warehouse' |
    'warning' |
    'warning_off' |
    'wash' |
    'watch' |
    'watch_button_press' |
    'watch_off' |
    'watch_screentime' |
    'watch_wake' |
    'water' |
    'water_bottle' |
    'water_bottle_large' |
    'water_damage' |
    'water_do' |
    'water_drop' |
    'water_ec' |
    'water_full' |
    'water_heater' |
    'water_lock' |
    'water_loss' |
    'water_lux' |
    'water_medium' |
    'water_orp' |
    'water_ph' |
    'water_pump' |
    'water_voc' |
    'waterfall_chart' |
    'waves' |
    'waving_hand' |
    'wb_auto' |
    'wb_incandescent' |
    'wb_iridescent' |
    'wb_shade' |
    'wb_sunny' |
    'wb_twilight' |
    'wc' |
    'weather_hail' |
    'weather_mix' |
    'weather_snowy' |
    'web' |
    'web_asset' |
    'web_asset_off' |
    'web_stories' |
    'web_traffic' |
    'webhook' |
    'weekend' |
    'weight' |
    'west' |
    'whatshot' |
    'wheelchair_pickup' |
    'where_to_vote' |
    'widgets' |
    'width' |
    'width_full' |
    'width_normal' |
    'width_wide' |
    'wifi' |
    'wifi_1_bar' |
    'wifi_2_bar' |
    'wifi_add' |
    'wifi_calling' |
    'wifi_calling_1' |
    'wifi_calling_2' |
    'wifi_calling_3' |
    'wifi_channel' |
    'wifi_find' |
    'wifi_home' |
    'wifi_lock' |
    'wifi_notification' |
    'wifi_off' |
    'wifi_password' |
    'wifi_protected_setup' |
    'wifi_proxy' |
    'wifi_tethering' |
    'wifi_tethering_error' |
    'wifi_tethering_off' |
    'wind_power' |
    'window' |
    'window_closed' |
    'window_open' |
    'window_sensor' |
    'wine_bar' |
    'woman' |
    'woman_2' |
    'work' |
    'work_alert' |
    'work_history' |
    'work_update' |
    'workspace_premium' |
    'workspaces' |
    'wounds_injuries' |
    'wrap_text' |
    'wrist' |
    'wrong_location' |
    'wysiwyg' |
    'yard' |
    'your_trips' |
    'youtube_activity' |
    'youtube_searched_for' |
    'zone_person_alert' |
    'zone_person_idle' |
    'zone_person_urgent' |
    'zoom_in' |
    'zoom_in_map' |
    'zoom_out' |
    'zoom_out_map';