export interface SynchronizerFactory {
    createSynchronizer: () => Synchronizer;
}

export interface Synchronizer {
    start: () => void;
    synchronize: () => Promise<void>;
    stop: () => void;
}

export function createSynchronizerFactory(): SynchronizerFactory {

    const createSynchronizer = (): Synchronizer => {

        let syncLimit = 0;

        let syncCount = 0;

        let syncResolve = () => {
            // empty by design
        };

        let syncPromise = new Promise<void>((resolve) => {
            syncResolve = resolve;
        });

        const start = (): void => {
            syncLimit++;
        };

        const synchronize = async (): Promise<void> => {
            syncCount++;
            if (isSynchronized()) {
                resetSynchronization();
            } else {
                await waitForSynchronization();
            }
        };

        const stop = (): void => {
            syncLimit--;
            if (isSynchronized()) {
                resetSynchronization();
            }
        };

        const waitForSynchronization = async () => {
            await syncPromise;
        };

        const isSynchronized = (): boolean => {
            return syncCount === syncLimit;
        };

        const resetSynchronization = (): void => {
            syncCount = 0;
            syncResolve();
            syncPromise = new Promise<void>((resolve) => {
                syncResolve = resolve;
            });
        };

        return {
            start,
            synchronize,
            stop
        };

    };

    return {
        createSynchronizer
    };

}