import {WrapperComponentProps} from '@webaker/package-utils';
import {FunctionComponent, MutableRefObject} from 'react';
import {Component} from './component';
import {RenderComponentsFunction} from './component-renderer';

export type ComponentView<
    C extends Component = Component,
    H extends Component = Component
> = FunctionComponent<ComponentViewProps<C, H>>;

export interface ComponentViewProps<
    C extends Component = Component,
    H extends Component = Component
> {
    component: C;
    childComponents: H[];
    render: RenderComponentsFunction<H>;
    className: string;
    elementRef: MutableRefObject<any>;
}

export type ComponentViewWrapper<
    C extends Component = Component,
    H extends Component = Component
> = FunctionComponent<ComponentViewWrapperProps<C, H>>;

export type ComponentViewWrapperProps<
    C extends Component = Component,
    H extends Component = Component
> = WrapperComponentProps<ComponentViewProps<C, H>, 'Component'>;