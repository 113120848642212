export interface ComponentExtensionNotFoundErrorDetails {
    name: string;
}

export class ComponentExtensionNotFoundError extends Error {

    name = 'COMPONENT_EXTENSION_NOT_FOUND';

    details: ComponentExtensionNotFoundErrorDetails;

    constructor(message: string, details: ComponentExtensionNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}