import {useOptionalDependency} from '@webaker/package-deps';
import {useRerender} from '@webaker/package-utils';
import {useEffect, useState} from 'react';
import {Store} from './store';
import {StoreWatcher, WatchedStore} from './store-watcher';

export interface UseStoreDeps {
    storeWatcher?: StoreWatcher;
}

export function useStore<S extends Store = Store>(store: S): S {

    const rerender = useRerender();
    const storeWatcher = useOptionalDependency<UseStoreDeps>()('storeWatcher');
    const [watchedStore, setWatchedStore] = useState<WatchedStore | null>(null);

    useEffect(() => {
        if (storeWatcher) {
            let shouldRerender = true;
            const newWatchedStore = storeWatcher.watchStore(store, () => {
                if (shouldRerender) {
                    rerender();
                }
            });
            setWatchedStore(newWatchedStore);
            return () => {
                shouldRerender = false;
                newWatchedStore.killStore();
            };
        }
        if (typeof window !== 'undefined') {
            console.warn('Store Watcher not found, state will not be reactive');
        }
        return;
    }, []);

    if (watchedStore) {
        watchedStore.resetStore();
    }

    return watchedStore ?? store;

}