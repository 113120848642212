import {Event} from '@webaker/package-event-bus';

export interface AppManager {
    runApp: () => Promise<void>;
}

export const INIT_APP_EVENT = 'initApp';

export interface InitAppEvent extends Event {
    name: typeof INIT_APP_EVENT;
}