export type ValidationError = ValidationFlatError | ValidationNestedError;

export interface ValidationFlatError {
    message: string;
}

export interface ValidationNestedError {
    key: string | number;
    errors: ValidationError[];
}

export type ValidationErrorsMap = Record<string, string[]>;