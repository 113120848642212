import {MemoFactory} from '@webaker/package-utils';
import {Store, StoreSource, StoreState} from './store';
import {StoreEvent, StoreListener, WildcardStoreEventName} from './store-events';
import {StoreMethodName} from './store-method';
import {StoreSchema} from './store-schema';

export interface StoreFactory {
    createStore: <S extends Store>(schema: StoreSchema<S>) => S;
}

export interface StoreFactoryDeps {
    memoFactory: MemoFactory;
}

export function createStoreFactory({memoFactory}: StoreFactoryDeps): StoreFactory {

    const createStore = <S extends Store = any>(schema: StoreSchema<S>): S => {

        let state: StoreState<S> = schema.state;

        const listenersMap: Map<string, Set<StoreListener<S>>> = new Map();

        const runListeners = (event: StoreEvent<S>): void => {
            const listeners = listenersMap.get(event.method) ?? [];
            for (const listener of listeners) {
                listener(event);
            }
            if (description.getters.includes(event.method)) {
                const onGetListeners = listenersMap.get('*:get') ?? [];
                for (const listener of onGetListeners) {
                    listener(event);
                }
            }
            if (description.setters.includes(event.method)) {
                const onSetListeners = listenersMap.get('*:set') ?? [];
                for (const listener of onSetListeners) {
                    listener(event);
                }
            }
            const onAnyListeners = listenersMap.get('*') ?? [];
            for (const listener of onAnyListeners) {
                listener(event);
            }
        };

        const name: string = schema.name;

        const get = (source?: StoreSource): StoreState<S> => {
            runListeners({state, method: 'get', params: source ? [source] : [], result: state} as StoreEvent<S>);
            return state;
        };

        const set = (newState: StoreState<S>, source?: StoreSource): void => {
            state = newState;
            runListeners({state, method: 'set', params: source ? [newState, source] : [newState], result: undefined} as StoreEvent<S>);
        };

        const description = {
            getters: ['get', ...Object.keys(schema.getters ?? {})],
            setters: ['set', ...Object.keys(schema.setters ?? {})],
            events: [
                '*',
                '*:get',
                '*:set',
                'get',
                'set',
                ...Object.keys(schema.getters ?? {}),
                ...Object.keys(schema.setters ?? {})
            ],
        };

        const addEventListener = (eventName: StoreMethodName<S> | WildcardStoreEventName, listener: StoreListener<S>): void => {
            const listeners = listenersMap.get(eventName) ?? new Set();
            listeners.add(listener as StoreListener<S>);
            listenersMap.set(eventName, listeners);
        };

        const removeEventListener = (eventName: string & StoreMethodName<S> | WildcardStoreEventName, listener: StoreListener<S>): void => {
            const listeners = listenersMap.get(eventName) ?? new Set();
            listeners.delete(listener as StoreListener<S>);
            listenersMap.set(eventName, listeners);
        };

        const store = {
            name,
            description,
            get,
            set,
            addEventListener,
            removeEventListener
        } as any as S;

        Object.entries(schema.getters ?? {}).forEach(([method, getter]: [string, any]) => {
            const memo = memoFactory.createMemo();
            (store as any)[method] = (...params: any[]) => {
                const result = getter({state, memo}, ...params);
                runListeners({state, method, params, result} as StoreEvent<S>);
                return result;
            };
        });

        Object.entries(schema.setters ?? {}).forEach(([method, setter]: [string, any]) => {
            (store as any)[method] = (...params: any[]) => {
                state = setter({state}, ...params);
                runListeners({state, method, params, result: undefined} as StoreEvent<S>);
            };
        });

        return store;

    };

    return {
        createStore
    };

}