import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface EqualRuleOptions<T = unknown> {
    to: T;
    strict?: boolean;
}

export function isEqual<T = unknown>({to, strict = true}: EqualRuleOptions): ValidationRule<T> {
    return (item: unknown): ValidationError[] => {
        if (strict ? item != to : item !== to) {
            return [{message: `Value has to be equal to: ${to}`}];
        }
        return [];
    };
}