import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';

export type GridClassName = 'grid';

export function GridCSS({}: CSSThemeProps) {
    return css<GridClassName>`

        .grid {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    `;
}

export interface GridPrivateCSSProps extends CSSThemeProps {
    columns?: string[];
    rows?: string[];
    margin: CSSThemeSize;
    gap: CSSThemeSize;
}

export function GridPrivateCSS({theme, columns, rows, margin, gap}: GridPrivateCSSProps) {
    return css<GridClassName>`

        .grid {
            grid-gap: ${theme.spacing[gap!]};
            margin: ${theme.spacing[margin!]} 0;
            grid-template-columns: ${columns ? columns.join(' ') : 'auto'};
            grid-template-rows: ${rows ? rows.join(' ') : 'auto'};
            grid-auto-flow: ${rows && !columns ? 'column' : !rows && columns ? 'row' : 'dense'};
        }

    `;
}