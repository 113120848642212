import {Page} from '../page/page';
import {PagesTree} from './pages-tree';
import {PagesTreeSelector} from './pages-tree-selector';

export interface PagesTreeMutator {
    setPages: (state: PagesTree, pages: Page[]) => PagesTree;
    addPage: (state: PagesTree, page: Page) => PagesTree;
    updatePage: (state: PagesTree, page: Page) => PagesTree;
    deletePage: (state: PagesTree, pageId: Page['id']) => PagesTree;
    movePage: (state: PagesTree, pageId: Page['id'], targetPageId: Page['id']) => PagesTree;
    movePageBefore: (state: PagesTree, pageId: Page['id'], targetPageId: Page['id']) => PagesTree;
    movePageAfter: (state: PagesTree, pageId: Page['id'], targetPageId: Page['id']) => PagesTree;
}

export interface PagesTreeMutatorDeps {
    pagesTreeSelector: PagesTreeSelector;
}

export function createPagesTreeMutator({pagesTreeSelector}: PagesTreeMutatorDeps): PagesTreeMutator {

    const {getChildPages, getRootPages, getPageById} = pagesTreeSelector;

    const setPages = (state: PagesTree, pages: Page[]): PagesTree => {
        return {
            ...state,
            pages
        };
    };

    const addPage = (state: PagesTree, page: Page): PagesTree => {
        return {
            ...state,
            pages: [
                ...state.pages,
                page
            ]
        };
    };

    const updatePage = (state: PagesTree, page: Page): PagesTree => {
        return {
            ...state,
            pages: state.pages.map((statePage: Page) => {
                if (statePage.id === page.id) {
                    return page;
                }
                return statePage;
            })
        };
    };

    const deletePage = (state: PagesTree, pageId: Page['id']): PagesTree => {
        return {
            ...state,
            pages: state.pages.filter((page: Page): boolean => {
                return page.id !== pageId;
            })
        };
    };

    const movePage = (state: PagesTree, pageId: Page['id'], targetPageId: Page['id']): PagesTree => {
        if (shouldFixIndices(state, targetPageId)) {
            state = fixIndices(state, targetPageId);
        }
        const childPages = pagesTreeSelector.getChildPages(state, targetPageId);
        return {
            ...state,
            pages: state.pages.map((page: Page) => {
                if (page.id === pageId) {
                    return {
                        ...page,
                        parentId: targetPageId,
                        index: childPages.length
                    };
                }
                return page;
            })
        };
    };

    const movePageBefore = (state: PagesTree, pageId: Page['id'], targetPageId: Page['id']): PagesTree => {
        return movePageBeforeOrAfter(state, pageId, targetPageId, 'before');
    };

    const movePageAfter = (state: PagesTree, pageId: Page['id'], targetPageId: Page['id']): PagesTree => {
        return movePageBeforeOrAfter(state, pageId, targetPageId, 'after');
    };

    const calculateMaxIndex = (pages: Page[]): number => {
        return pages.reduce((max: number, page: Page): number => {
            if (page.index === undefined || page.index < max) {
                return max;
            }
            return page.index;
        }, -1);
    };

    const movePageBeforeOrAfter = (
        state: PagesTree,
        pageId: Page['id'],
        targetPageId: Page['id'],
        mode: 'before' | 'after'
    ): PagesTree => {
        let movedPage = getPageById(state, pageId)!;
        let targetPage = getPageById(state, targetPageId)!;
        if (!movedPage || !targetPage) {
            return state;
        }
        if (shouldFixIndices(state, targetPage.parentId)) {
            state = fixIndices(state, targetPage.parentId);
            movedPage = getPageById(state, pageId)!;
            targetPage = getPageById(state, targetPageId)!;
        }
        const newIndex = calculateNewIndex(movedPage, targetPage, mode);
        state = {
            ...state,
            pages: state.pages.map((page: Page) => {
                if (page.id === pageId) {
                    return {
                        ...page,
                        parentId: targetPage.parentId,
                        index: newIndex
                    };
                }
                if (movedPage.parentId !== targetPage.parentId) {
                    if (page.parentId === movedPage.parentId && page.index !== undefined && movedPage.index !== undefined && page.index > movedPage.index) {
                        return {
                            ...page,
                            index: page.index - 1
                        };
                    }
                    if (page.parentId === targetPage.parentId) {
                        return {
                            ...page,
                            index: targetPage.index !== undefined && page.index !== undefined ?
                                (mode === 'before' && page.index >= targetPage.index) || (mode == 'after' && page.index > targetPage.index) ?
                                    page.index + 1 : page.index : undefined
                        };
                    }
                } else {
                    if (movedPage.index !== undefined && targetPage.index !== undefined) {
                        if (movedPage.index < targetPage.index) {
                            return {
                                ...page,
                                index: page.index !== undefined ? page.index <= newIndex && page.index > movedPage.index ? page.index - 1 : page.index : 0
                            };
                        } else {
                            return {
                                ...page,
                                index: page.index !== undefined ? page.index >= newIndex && page.index < movedPage.index ? page.index + 1 : page.index : 0
                            };
                        }
                    }
                }
                return page;
            })
        };
        return state;
    };

    const calculateNewIndex = (movedPage: Page, targetPage: Page, mode: 'before' | 'after'): number => {
        if (movedPage.index === undefined || targetPage.index === undefined) {
            return 0;
        }
        if (mode == 'before') {
            if (movedPage.index < targetPage.index) {
                return targetPage.index - 1;
            } else {
                return targetPage.index;
            }
        } else {
            if (movedPage.index < targetPage.index) {
                return targetPage.index;
            } else {
                return targetPage.index + 1;
            }
        }
    };

    const shouldFixIndices = (state: PagesTree, parentId: Page['parentId']): boolean => {
        const childPages = parentId ? getChildPages(state, parentId) : getRootPages(state);
        return childPages.some((page: Page) => {
            return page.index === undefined;
        });
    };

    const fixIndices = (state: PagesTree, parentId: Page['parentId']): PagesTree => {
        const childPages = parentId ? getChildPages(state, parentId) : getRootPages(state);
        let maxIndex = calculateMaxIndex(childPages);
        return {
            ...state,
            pages: state.pages.map((page: Page) => {
                if (page.parentId === parentId && page.index === undefined) {
                    return {
                        ...page,
                        index: ++maxIndex
                    };
                }
                return page;
            })
        };
    };

    return {
        setPages,
        addPage,
        updatePage,
        deletePage,
        movePage,
        movePageBefore,
        movePageAfter
    };

}