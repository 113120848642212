import {Provider} from '@webaker/package-deps';
import {FileRegistry, FileTypeOptions} from '@webaker/package-file';
import {createImageFileClientOptions} from './image-file-client-options';

export interface ProvidedImageFileClientDeps {
    imageFileOptions: FileTypeOptions;
}

export interface RequiredImageFileClientDeps {
    fileRegistry: FileRegistry;
}

export interface ImageFileClientDeps extends ProvidedImageFileClientDeps, RequiredImageFileClientDeps {

}

export interface ImageFileClientConfig {

}

export type ImageFileClientProvider = Provider<ImageFileClientDeps, ImageFileClientConfig>;

export function createImageFileClientProvider(): ImageFileClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('imageFileOptions', () => {
                return createImageFileClientOptions();
            });
        },

        registerServices: async ({resolve}) => {
            const fileRegistry = resolve('fileRegistry');
            const imageFileOptions = resolve('imageFileOptions');
            fileRegistry.registerFileType(imageFileOptions);
        }

    };
}