import {escapeRegExp} from '@webaker/package-utils';
import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface UrlRuleOptions {
    protocols?: string[];
    domains?: string[];
}

export function isUrl<S extends string = string>({protocols, domains}: UrlRuleOptions = {}): ValidationRule<S> {
    return (item: unknown): ValidationError[] => {
        const protocol: string = protocols
            ? '(' + protocols.map(escapeRegExp).join('|') + '):\\/\\/'
            : 'https?:\\/\\/';
        const domain: string = domains
            ? '(' + domains.map(escapeRegExp).join('|').replace(/\\\*\\\./g, '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)*') + ')'
            : '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))';
        const port: string = '(\\:\\d+)?';
        const path: string = '(\\/[-a-z\\d%_.~+]*)*';
        const query: string = '(\\?[;&a-z\\d%_.~+=\\-!]*)?';
        const hash: string = '(\\#[-a-z\\d_]*)?';
        const regExp: RegExp = new RegExp('^' + protocol + domain + port + path + query + hash + '$', 'i');
        if (typeof item !== 'string' || item.match(regExp) === null) {
            return [
                {
                    message: `Value has to be valid ${protocols ? `${protocols.join(', ')} URL` : 'URL'}`
                        + (domains ? ` from ${domains.join(' / ')} domain` : '')
                }
            ];
        }
        return [];
    };
}