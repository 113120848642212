import {omitUndefined} from '@webaker/package-utils';
import {Hook} from '../hook/hook';
import {HooksTree} from './hooks-tree';

type AnyHooksTree = HooksTree & Record<string, any> & any;

export interface HooksTreeMutator<T extends HooksTree = AnyHooksTree> {

    addHook: (tree: T, hook: Hook) => T;
    updateHook: (tree: T, hook: Hook) => T;
    deleteHookById: (tree: T, hookId: Hook['id']) => T;

}

export interface HooksTreeMutatorDeps {

}

export function createHooksTreeMutator<T extends HooksTree = AnyHooksTree>({}: HooksTreeMutatorDeps = {}): HooksTreeMutator<T> {

    const addHook = (tree: T, hook: Hook): T => {
        return {
            ...tree,
            hooks: [
                ...tree.hooks,
                omitUndefined(hook)
            ]
        };
    };

    const updateHook = (tree: T, hook: Hook): T => {
        return {
            ...tree,
            hooks: tree.hooks.map((treeHook: Hook): Hook => {
                if (treeHook.id === hook.id) {
                    return omitUndefined(hook);
                }
                return treeHook;
            })
        };
    };

    const deleteHookById = (tree: T, hookId: Hook['id']): T => {
        return {
            ...tree,
            hooks: tree.hooks.filter((hook: Hook): boolean => {
                return hook.id !== hookId;
            })
        };
    };

    return {
        addHook,
        updateHook,
        deleteHookById
    };

}