import {Provider} from '@webaker/package-deps';
import {createEventBus, EventBus} from '../event-bus';

export interface ProvidedEventBusClientDeps {
    eventBus: EventBus;
}

export interface RequiredEventBusClientDeps {

}

export interface EventBusClientDeps extends ProvidedEventBusClientDeps, RequiredEventBusClientDeps {

}

export interface EventBusClientConfig {

}

export type EventBusClientProvider = Provider<EventBusClientDeps, EventBusClientConfig>;

export function createEventBusClientProvider(): EventBusClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('eventBus', () => {
                return createEventBus();
            });
        }

    };
}