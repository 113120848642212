const NUMBER_TO_LETTER_CHAR_CODE_DIFF = 'a'.charCodeAt(0) - '0'.charCodeAt(0);

export interface CSSHashOptions {
    length: number;
}

export function cssHash(string: string, {length}: CSSHashOptions): string {
    let hashNumber = 0;
    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);
        hashNumber = (hashNumber << 5) - hashNumber + char;
        hashNumber &= hashNumber;
    }
    const hashString = new Uint32Array([hashNumber])[0].toString(36).slice(-length);
    return !isNaN(parseInt(hashString.charAt(0)))
        ? String.fromCharCode(hashString.charCodeAt(0) + NUMBER_TO_LETTER_CHAR_CODE_DIFF) + hashString.slice(1)
        : hashString;
}