import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {File, FileApi, FileExtension} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {mergeClassNames, useClickOutside} from '@webaker/package-utils';
import React, {useEffect, useRef} from 'react';
import {FileManagerStore} from '../file-manager-store';
import {AccessFileButton} from './buttons/access-file-button';
import {CopyFileButton} from './buttons/copy-file-button';
import {CreateDirectoryButton} from './buttons/create-directory-button';
import {CreateFileButton} from './buttons/create-file-button';
import {CutFileButton} from './buttons/cut-file-button';
import {DeleteFileButton} from './buttons/delete-file-button';
import {DownloadFileButton} from './buttons/download-file-button';
import {PasteFileButton} from './buttons/paste-file-button';
import {RenameFileButton} from './buttons/rename-file-button';
import {UploadFileButton} from './buttons/upload-file-button';
import {DirectoryBreadcrumbs} from './directory-breadcrumbs';
import {FileManagerCSS} from './file-manager-css';
import {FileSearch} from './file-search';
import {NodeList} from './list/node-list';

export interface FileManagerProps {
    file?: File | null;
    extensions?: FileExtension[];
    className?: string;
}

export interface FileManagerDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function FileManager({file, extensions, className}: FileManagerProps) {

    const css = useThemedCSS(FileManagerCSS);
    const fileManagerStore = useStore(useDependency<FileManagerDeps>()('fileManagerStore'));
    const fileApi = useDependency<FileManagerDeps>()('fileApi');
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        (async () => {
            const workingDirectoryId = file?.parentDirectoryId ?? fileManagerStore.getWorkingDirectoryId();
            if (workingDirectoryId === fileManagerStore.getWorkingDirectory()?.id) {
                return;
            }
            fileManagerStore.startLoading();
            const directoryTree = await fileApi.getDirectoryTree(workingDirectoryId);
            fileManagerStore.openDirectory(directoryTree);
            if (file) {
                fileManagerStore.selectNode(file.id);
            } else {
                fileManagerStore.deselectAllNodes();
            }
            fileManagerStore.stopLoading();
        })();
    }, [file?.id]);

    useClickOutside(wrapperRef, () => {
        fileManagerStore.deselectAllNodes();
    });

    return (
        <div className={mergeClassNames(css['fileManager'], className)}>
            <div ref={wrapperRef}
                 className={css['wrapper']}>

                <div className={css['header']}>
                    <UploadFileButton/>
                    <DownloadFileButton/>
                    <div className={css['separator']}/>
                    <CreateDirectoryButton/>
                    <CreateFileButton/>
                    <RenameFileButton/>
                    <AccessFileButton/>
                    <DeleteFileButton/>
                    <div className={css['separator']}/>
                    <CopyFileButton/>
                    <CutFileButton/>
                    <PasteFileButton/>
                    <div className={css['pusher']}/>
                    <FileSearch/>
                </div>

                <DirectoryBreadcrumbs/>

                <NodeList extensions={extensions}/>

            </div>
        </div>
    );

}