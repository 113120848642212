import {DEFAULT_ID_ALPHABET, DEFAULT_ID_LENGTH} from '@webaker/package-utils';
import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface IdRuleOptions {
    minLength?: number;
    maxLength?: number;
    alphabet?: string;
}

export function isId<S extends string = string>({
    minLength = DEFAULT_ID_LENGTH,
    maxLength = DEFAULT_ID_LENGTH,
    alphabet = DEFAULT_ID_ALPHABET
}: IdRuleOptions = {}): ValidationRule<S> {
    return (item: unknown): ValidationError[] => {
        if (typeof item !== 'string') {
            return [{message: `ID has to be string`}];
        }
        const errors: ValidationError[] = [];
        if (minLength && item.length < minLength) {
            errors.push({message: `ID has to contain at least ${minLength} characters`});
        }
        if (maxLength && item.length > maxLength) {
            errors.push({message: `ID has to contain at most ${maxLength} characters`});
        }
        if (alphabet && !isTextOfAlphabet(item, alphabet)) {
            errors.push({message: `ID contains invalid characters`});
        }
        return errors;
    };
}

function isTextOfAlphabet(text: string, alphabet: string): boolean {
    return text.split('').every((char: string): boolean => {
        return alphabet.includes(char);
    });
}