import {PageOptions} from '@webaker/app';
import {NOT_FOUND_PAGE_TYPE, NotFoundPage} from './not-found-page';

export interface NotFoundPageOptions extends PageOptions<NotFoundPage> {

}

export function createNotFoundPageOptions(): NotFoundPageOptions {
    return {
        type: NOT_FOUND_PAGE_TYPE,
        static: true
    };
}