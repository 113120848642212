import {createContext, FunctionComponent, useContext} from 'react';
import {pickProperties} from '../pick-properties';

export interface ReactWrapper {
    wrapComponent: <
        P = unknown,
        O extends string = typeof ORIGINAL_PROP,
        C extends keyof P = never
    >(options: WrapComponentOptions<P, O, C>) => FunctionComponent<P>;
}

export interface WrapComponentOptions<
    P = unknown,
    O extends string = typeof ORIGINAL_PROP,
    C extends keyof P = never
> {
    component: FunctionComponent<P>;
    wrappers: WrapperComponent<P, O, C>[];
    originalProp?: O;
    constantProps?: C[];
}

export type WrapperComponent<
    P = unknown,
    O extends string = typeof ORIGINAL_PROP,
    C extends keyof P = never
> = FunctionComponent<WrapperComponentProps<P, O, C>>;

export type WrapperComponentProps<
    P = unknown,
    O extends string = typeof ORIGINAL_PROP,
    C extends keyof P = never
> = Omit<P, C> & {
    [K in O]: FunctionComponent<P>;
};

export const ORIGINAL_PROP = 'Original';

export function createReactWrapper(): ReactWrapper {

    const cacheMap = new Map<FunctionComponent<any>[], FunctionComponent<any>>();

    const wrapComponent = <
        P = unknown,
        O extends string = typeof ORIGINAL_PROP,
        C extends keyof P = never
    >({
        component: Component,
        wrappers,
        originalProp = ORIGINAL_PROP as O,
        constantProps
    }: WrapComponentOptions<P, O, C>): FunctionComponent<P> => {
        const components = [...wrappers, Component] as FunctionComponent<P>[];
        const CachedComponent = getCachedComponent(components);
        if (CachedComponent) {
            return CachedComponent;
        }
        const PropsContext = createContext<Partial<P>>({});
        const WrapperComponent = wrappers.reduce((
            CurrentComponent: FunctionComponent<P>,
            WrapperComponent: WrapperComponent<P, O, C>,
            index: number
        ): FunctionComponent<P> => {
            if (constantProps && constantProps.length > 0 && index === wrappers.length - 1) {
                return ((props: WrapperComponentProps<P, O, C>) => {
                    const extendedProps = {...props, [originalProp]: CurrentComponent};
                    return (
                        <PropsContext.Provider value={pickProperties(props, constantProps as any) as Partial<P>}>
                            <WrapperComponent {...extendedProps}/>
                        </PropsContext.Provider>
                    );
                }) as FunctionComponent<P>;
            }
            return ((props: WrapperComponentProps<P, O>) => {
                const extendedProps = {...props, [originalProp]: CurrentComponent};
                return <WrapperComponent {...extendedProps}/>;
            }) as FunctionComponent<P>;
        }, constantProps && constantProps.length > 0 ? (
            ((props: P) => {
                const constantPropsFromContext = useContext(PropsContext);
                const extendedProps = {...props, ...constantPropsFromContext};
                return (
                    <Component {...extendedProps}/>
                );
            }) as FunctionComponent<P>
        ) : Component);
        setCachedComponent(components, WrapperComponent);
        return WrapperComponent;
    };

    const getCachedComponent = <P = unknown>(components: FunctionComponent<P>[]): FunctionComponent<P> | null => {
        return Array.from(cacheMap.entries()).find(([key]) => {
            return areArraysEqual(key, components);
        })?.[1] ?? null;
    };

    const setCachedComponent = <P = unknown>(components: FunctionComponent<P>[], Component: FunctionComponent<P>): void => {
        cacheMap.set(components, Component);
    };

    const areArraysEqual = (arrayA: unknown[], arrayB: unknown[]): boolean => {
        return arrayA.length === arrayB.length &&
            arrayA.every((_, index) => arrayA[index] === arrayB[index]);
    };

    return {
        wrapComponent
    };

}