import {isObject, isString, Validator} from '@webaker/package-validation';
import {UserRole} from './user-role';

export interface UserRoleValidator {
    validateUserRole: (item: unknown) => item is UserRole;
    assertUserRole: (item: unknown) => asserts item is UserRole;
}

export interface UserRoleValidatorDeps {
    validator: Validator;
}

export function createUserRoleValidator({validator}: UserRoleValidatorDeps): UserRoleValidator {

    // fix for TS2775
    const baseValidator: Validator = validator;

    const isUserRole = isObject<UserRole>({
        properties: {
            id: isString(),
            name: isString()
        }
    });

    const validateUserRole = (item: unknown): item is UserRole => {
        return baseValidator.validate(item, isUserRole);
    };

    const assertUserRole = (item: unknown): asserts item is UserRole => {
        baseValidator.assert(item, isUserRole);
    };

    return {
        validateUserRole,
        assertUserRole
    };

}