export interface PublicDirsConfig {
    publicChunksDir: string;
    publicImagesDir: string;
    publicFontsDir: string;
}

export function getPublicDirsConfig(): PublicDirsConfig {

    const publicChunksDir = 'chunks';
    const publicImagesDir = 'images';
    const publicFontsDir = 'fonts';

    return {
        publicChunksDir,
        publicImagesDir,
        publicFontsDir
    };

}