import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {CardCSS} from './card-css';

export interface CardProps {
    size?: CardSize;
    title?: ReactNode;
    subtitle?: ReactNode;
    icon?: ReactNode;
    className?: string;
    children?: ReactNode;
}

export const NARROW_CONTAINER_TYPE = 'narrow';
export const WIDE_CONTAINER_TYPE = 'wide';
export const FULL_CONTAINER_TYPE = 'full';

export type CardSize =
    typeof NARROW_CONTAINER_TYPE |
    typeof WIDE_CONTAINER_TYPE |
    typeof FULL_CONTAINER_TYPE;

export function Card({
    size = FULL_CONTAINER_TYPE,
    title,
    subtitle,
    icon,
    children,
    className
}: CardProps) {

    const css = useThemedCSS(CardCSS);

    return (
        <section className={mergeClassNames(
            css['card'],
            css[`size-${size}`],
            className
        )}>
            {title && (
                <header className={css['header']}>
                    <div className={css['title']}>
                        {icon}
                        {title}
                    </div>
                    {subtitle && (
                        <div className={css['subtitle']}>
                            {subtitle}
                        </div>
                    )}
                </header>
            )}
            {children && (
                <div className={css['content']}>
                    {children}
                </div>
            )}
        </section>
    );

}