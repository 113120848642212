import {Component} from '../component/component';
import {Hook} from '../hook/hook';
import {Page} from '../page/page';
import {ComponentContent} from './component-content';
import {ComponentsTree} from './components-tree';
import {HooksTreeSelector} from './hooks-tree-selector';

type AnyComponentsTree = ComponentsTree & Record<string, any>;

export interface ComponentsTreeSelector<T extends ComponentsTree = AnyComponentsTree> {

    getComponents: (tree: T) => Component[];
    getComponentById: (tree: T, componentId: Component['id']) => Component | null;
    getComponentsByIds: (tree: T, componentsIds: Component['id'][]) => Component[];
    getComponentsByType: <C extends Component = Component>(tree: T, componentType: C['type']) => C[];

    getRootComponent: (tree: T, pageId: Page['id']) => Component | null;
    getParentComponent: (tree: T, componentId: Component['id']) => Component | null;
    getAncestorComponents: (tree: T, componentId: Component['id']) => Component[];
    getChildComponents: (tree: T, componentId: Component['id']) => Component[];
    getChildComponentsByType: <C extends Component = Component>(tree: T, componentId: Component['id'], componentType: C['type']) => C[];
    getDescendantComponents: (tree: T, componentId: Component['id']) => Component[];
    getDescendantComponentsByType: <C extends Component = Component>(tree: T, componentId: Component['id'], componentType: C['type']) => C[];
    getPreviousComponent: (tree: T, componentId: Component['id']) => Component | null;
    getNextComponent: (tree: T, componentId: Component['id']) => Component | null;
    getComponentContent: (tree: T, componentId: Component['id']) => ComponentContent | null;

    getSharedComponents: (tree: T) => Component[];
    getSharedComponentsContents: (tree: T) => ComponentContent[];
    getSharedAncestorComponent: (tree: T, componentId: Component['id']) => Component | null;

}

export interface ComponentsTreeSelectorDeps<T extends ComponentsTree = AnyComponentsTree> {
    hooksTreeSelector: HooksTreeSelector<T>;
}

export function createComponentsTreeSelector<T extends ComponentsTree = AnyComponentsTree>({
    hooksTreeSelector
}: ComponentsTreeSelectorDeps<T>): ComponentsTreeSelector<T> {

    const {getPageHook, getComponentHook, getComponentsHooks, getParentHook, getNestedHooks} = hooksTreeSelector;

    const getComponents = (tree: T): Component[] => {
        return tree.components;
    };

    const getComponentById = (tree: T, componentId: Component['id']): Component | null => {
        return tree.components.find((component: Component): boolean => {
            return component.id === componentId;
        }) ?? null;
    };

    const getComponentsByIds = (tree: T, componentsIds: Component['id'][]): Component[] => {
        return tree.components.filter((component: Component): boolean => {
            return componentsIds.includes(component.id);
        }).sort((componentA: Component, componentB: Component): number => {
            return componentsIds.indexOf(componentA.id) - componentsIds.indexOf(componentB.id);
        });
    };

    const getComponentsByType = <C extends Component = Component>(tree: T, componentType: C['type']): C[] => {
        return tree.components.filter((component: Component): boolean => {
            return component.type === componentType;
        }) as C[];
    };

    const getRootComponent = (tree: T, pageId: Page['id']): Component | null => {
        const pageHook = getPageHook(tree, pageId);
        const rootComponentId = pageHook?.childComponentsIds[0];
        return rootComponentId ? getComponentById(tree, rootComponentId) : null;
    };

    const getParentComponent = (tree: T, componentId: Component['id']): Component | null => {
        const parentComponentHook = getParentHook(tree, componentId);
        return parentComponentHook?.componentId
            ? getComponentById(tree, parentComponentHook.componentId)
            : null;
    };

    const getAncestorComponents = (tree: T, componentId: Component['id']): Component[] => {
        const parentComponentHook = getParentHook(tree, componentId);
        if (!parentComponentHook) {
            return [];
        }
        const ancestorComponentsIds: Component['id'][] = [];
        let ancestorHook: Hook | null = parentComponentHook;
        while (ancestorHook?.componentId) {
            ancestorComponentsIds.unshift(ancestorHook.componentId);
            ancestorHook = getParentHook(tree, ancestorHook.componentId);
        }
        return getComponentsByIds(tree, ancestorComponentsIds);
    };

    const getChildComponents = (tree: T, componentId: Component['id']): Component[] => {
        const hook = getComponentHook(tree, componentId);
        return hook ? getComponentsByIds(tree, hook.childComponentsIds) : [];
    };

    const getChildComponentsByType = <C extends Component = Component>(
        tree: T,
        componentId: Component['id'],
        componentType: C['type']
    ): C[] => {
        return getChildComponents(tree, componentId).filter((component: Component): boolean => {
            return component.type === componentType;
        }) as C[];
    };

    const getDescendantComponents = (tree: T, componentId: Component['id']): Component[] => {
        const componentHook = getComponentHook(tree, componentId);
        if (!componentHook) {
            return [];
        }
        const descendantComponentsIds: Component['id'][] = [];
        let descendantHooks: Hook[] = [componentHook];
        while (descendantHooks.length) {
            const childComponentsIds = flattenChildComponentsIds(descendantHooks);
            descendantComponentsIds.push(...childComponentsIds);
            descendantHooks = getComponentsHooks(tree, childComponentsIds);
        }
        return getComponentsByIds(tree, descendantComponentsIds);
    };

    const getDescendantComponentsByType = <C extends Component = Component>(
        tree: T,
        componentId: Component['id'],
        componentType: C['type']
    ): C[] => {
        return getDescendantComponents(tree, componentId).filter((component: Component): boolean => {
            return component.type === componentType;
        }) as C[];
    };

    const getPreviousComponent = (tree: T, componentId: Component['id']): Component | null => {
        const parentComponentHook = getParentHook(tree, componentId);
        const index = parentComponentHook?.childComponentsIds.indexOf(componentId) ?? -1;
        const previousComponentId = index >= 0 ? parentComponentHook?.childComponentsIds[index - 1] : null;
        return previousComponentId ? getComponentById(tree, previousComponentId) : null;
    };

    const getNextComponent = (tree: T, componentId: Component['id']): Component | null => {
        const parentComponentHook = getParentHook(tree, componentId);
        const index = parentComponentHook?.childComponentsIds.indexOf(componentId) ?? -1;
        const nextComponentId = index >= 0 ? parentComponentHook?.childComponentsIds[index + 1] : null;
        return nextComponentId ? getComponentById(tree, nextComponentId) : null;
    };

    const getComponentContent = (tree: T, componentId: Component['id']): ComponentContent | null => {
        const component = getComponentById(tree, componentId);
        if (!component) {
            return null;
        }
        const hooks = getNestedHooks(tree, componentId);
        const components = getChildComponentsByHooks(tree, hooks);
        return {
            component,
            tree: {
                components,
                hooks
            }
        };
    };

    const flattenChildComponentsIds = (hooks: Hook[]): Component['id'][] => {
        return hooks.reduce((componentsIds: Component['id'][], hook: Hook): Component['id'][] => {
            return [...componentsIds, ...hook.childComponentsIds];
        }, []);
    };

    const getSharedComponents = (tree: T): Component[] => {
        return tree.components.filter((component: Component): boolean => {
            return !!component.share;
        });
    };

    const getSharedComponentsContents = (tree: T): ComponentContent[] => {
        return getSharedComponents(tree).map((component: Component): ComponentContent => {
            const hooks = getSharedNestedHooks(tree, component.id);
            const components = getChildComponentsByHooks(tree, hooks);
            return {
                component,
                tree: {
                    components,
                    hooks
                }
            };
        });
    };

    const getSharedAncestorComponent = (tree: T, componentId: Component['id']): Component | null => {
        let component = getParentComponent(tree, componentId);
        while (component) {
            if (component.share) {
                return component;
            }
            component = getParentComponent(tree, component.id);
        }
        return null;
    };

    const getSharedNestedHooks = (tree: T, componentId: Component['id']): Hook[] => {
        const componentHook = getComponentHook(tree, componentId);
        if (!componentHook || componentHook.pageId) {
            return [];
        }
        return componentHook.childComponentsIds.reduce((hooksTree: Hook[], childComponentId: Component['id']): Hook[] => {
            return [...hooksTree, ...getSharedNestedHooks(tree, childComponentId)];
        }, [componentHook]);
    };

    const getChildComponentsByHooks = (tree: T, hooks: Hook[]): Component[] => {
        const componentsIds = getChildComponentsIdsByHooks(hooks);
        return tree.components.filter((component: Component): boolean => {
            return componentsIds.includes(component.id);
        });
    };

    const getChildComponentsIdsByHooks = (hooks: Hook[]): Component['id'][] => {
        return [
            ...new Set(
                hooks.reduce((componentsIds: Component['id'][], hook: Hook): Component['id'][] => {
                    return [...componentsIds, ...hook.childComponentsIds];
                }, [])
            )
        ];
    };

    return {
        getComponents,
        getComponentById,
        getComponentsByIds,
        getComponentsByType,
        getRootComponent,
        getParentComponent,
        getAncestorComponents,
        getChildComponents,
        getChildComponentsByType,
        getDescendantComponents,
        getDescendantComponentsByType,
        getPreviousComponent,
        getNextComponent,
        getComponentContent,
        getSharedComponents,
        getSharedComponentsContents,
        getSharedAncestorComponent
    };

}