import {Event, EventBus} from '@webaker/package-event-bus';
import {AppApi} from './app-api';
import {AppStore} from './app-store';
import {PageContent} from './content/page-content';
import {Page} from './page/page';
import {PageRenderer} from './page/page-renderer';

export interface AppRouter {
    openPage: (page: Page) => Promise<void>;
    openPageById: (id: Page['id']) => Promise<void>;
    openPageByPath: (path: Page['path']) => Promise<void>;
    openPageByType: (type: Page['type']) => Promise<void>;
    reloadPage: () => Promise<void>;
}

export interface AppRouterDeps {
    appApi: AppApi;
    appStore: AppStore;
    eventBus: EventBus;
    pageRenderer: PageRenderer;
}

export const OPEN_PAGE_EVENT = 'openPage';
export const BEFORE_OPEN_PAGE_EVENT = 'beforeOpenPage';
export const AFTER_OPEN_PAGE_EVENT = 'afterOpenPage';

export interface OpenPageEvent extends Event {
    name: typeof OPEN_PAGE_EVENT;
    pageContent: PageContent;
}

export interface BeforeOpenPageEvent extends Event {
    name: typeof BEFORE_OPEN_PAGE_EVENT;
}

export interface AfterOpenPageEvent extends Event {
    name: typeof AFTER_OPEN_PAGE_EVENT;
    pageContent: PageContent;
}

export function createAppRouter({appApi, appStore, eventBus, pageRenderer}: AppRouterDeps): AppRouter {

    const openPage = async (page: Page): Promise<void> => {
        if (appStore.getPage()?.id === page.id) {
            return;
        }
        await eventBus.dispatchEvent<BeforeOpenPageEvent>({
            name: BEFORE_OPEN_PAGE_EVENT
        });
        const pageContent = await appApi.getPageContentById(page.id);
        if (pageContent) {
            await openPageContent(pageContent);
        }
    };

    const openPageById = async (id: Page['id']): Promise<void> => {
        if (appStore.getPage()?.id === id) {
            return;
        }
        await eventBus.dispatchEvent<BeforeOpenPageEvent>({
            name: BEFORE_OPEN_PAGE_EVENT
        });
        const pageContent = await appApi.getPageContentById(id);
        if (pageContent) {
            await openPageContent(pageContent);
        }
    };

    const openPageByPath = async (path: Page['path']): Promise<void> => {
        if (appStore.getPage()?.path === path) {
            return;
        }
        await eventBus.dispatchEvent<BeforeOpenPageEvent>({
            name: BEFORE_OPEN_PAGE_EVENT
        });
        const pageContent = await appApi.getPageContentByPath(path);
        if (pageContent) {
            await openPageContent(pageContent);
        }
    };

    const openPageByType = async (type: Page['type']): Promise<void> => {
        if (appStore.getPage()?.type === type) {
            return;
        }
        await eventBus.dispatchEvent<BeforeOpenPageEvent>({
            name: BEFORE_OPEN_PAGE_EVENT
        });
        const pageContent = await appApi.getPageContentByType(type);
        if (pageContent) {
            await openPageContent(pageContent);
        }
    };

    const reloadPage = async (): Promise<void> => {
        const page = appStore.getPage();
        if (!page) {
            return;
        }
        await eventBus.dispatchEvent<BeforeOpenPageEvent>({
            name: BEFORE_OPEN_PAGE_EVENT
        });
        const pageContent = await appApi.getPageContentById(page.id);
        if (pageContent) {
            await openPageContent(pageContent);
        }
    };

    const openPageContent = async (pageContent: PageContent): Promise<void> => {
        await pageRenderer.loadPageView(pageContent.page);
        await eventBus.dispatchEvent<OpenPageEvent>({
            name: OPEN_PAGE_EVENT,
            pageContent
        });
        appStore.openPage(pageContent);
        await eventBus.dispatchEvent<AfterOpenPageEvent>({
            name: AFTER_OPEN_PAGE_EVENT,
            pageContent
        });
    };

    return {
        openPage,
        openPageById,
        openPageByPath,
        openPageByType,
        reloadPage
    };

}