import {FlexBox} from './flex-box';

export interface FlexBoxFactory {
    createFlexBox: (data?: Partial<FlexBox>) => FlexBox;
}

export interface FlexBoxFactoryDeps {

}

export function createFlexBoxFactory({}: FlexBoxFactoryDeps = {}): FlexBoxFactory {

    const createFlexBox = (data: Partial<FlexBox> = {}): FlexBox => {
        return {
            direction: data.direction ?? 'column',
            align: data.align ?? 'stretch',
            justify: data.justify ?? 'start',
            gap: data.gap ?? '0',
            wrap: data.wrap ?? false
        };
    };

    return {
        createFlexBox
    };

}