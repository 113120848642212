import {ComponentViewProps} from '@webaker/app';
import {useConfig, useDependency} from '@webaker/package-deps';
import {User, UserManager, UserStore} from '@webaker/package-user';
import {useEffect} from 'react';
import {AutoLogin} from './auto-login';

export interface AutoLoginViewProps extends ComponentViewProps<AutoLogin> {

}

export interface AutoLoginViewDeps {
    userManager: UserManager;
    userStore: UserStore;
}

export interface AutoLoginViewConfig {
    adminUserEmail: User['email'];
}

const EMPTY_PASSWORD = '';

export function AutoLoginView({}: AutoLoginViewProps) {
    const userStore = useDependency<AutoLoginViewDeps>()('userStore');
    const userManager = useDependency<AutoLoginViewDeps>()('userManager');
    const adminUserEmail = useConfig<AutoLoginViewConfig>()('adminUserEmail');
    const loggedUser = userStore.getLoggedUser();

    useEffect(() => {
        if (!loggedUser) {
            userManager.loginUser(adminUserEmail, EMPTY_PASSWORD);
        }
    }, [loggedUser]);

    return null;
}