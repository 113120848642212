import {Store} from '../../store';
import {StoreEvent} from '../../store-events';
import {StorePlugin} from '../../store-plugin';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: any;
    }
}

export interface DevtoolsStorePluginDeps {
    name?: string;
}

const INIT_ACTION_NAME = 'init';
const DISPATCH_MESSAGE_TYPE = 'DISPATCH';
const ACTION_MESSAGE_TYPE = 'ACTION';
const JUMP_TO_ACTION_PAYLOAD_TYPE = 'JUMP_TO_ACTION';
const TOGGLE_ACTION_PAYLOAD_TYPE = 'TOGGLE_ACTION';
const JUMP_TO_STATE_PAYLOAD_TYPE = 'JUMP_TO_STATE';
const IMPORT_STATE_PAYLOAD_TYPE = 'IMPORT_STATE';
const DEVTOOLS_SOURCE = 'DEVTOOLS';

export function createDevtoolsStorePlugin({name}: DevtoolsStorePluginDeps = {}): StorePlugin {
    return (store: Store): void => {

        if (typeof window === 'undefined' || !window.__REDUX_DEVTOOLS_EXTENSION__) {
            return;
        }

        const devtools = window.__REDUX_DEVTOOLS_EXTENSION__.connect({
            name: name ? name + ' - ' + store.name : store.name,
            features: {
                pause: true,
                lock: true,
                persist: true,
                export: false,
                import: false,
                jump: true,
                skip: false,
                reorder: false,
                dispatch: true,
                test: false
            }
        });

        devtools.send(INIT_ACTION_NAME, store.get());

        // @ts-ignore
        devtools.subscribe((message: any) => {
            if (message.type === DISPATCH_MESSAGE_TYPE && (
                message.payload.type === JUMP_TO_ACTION_PAYLOAD_TYPE ||
                message.payload.type === JUMP_TO_STATE_PAYLOAD_TYPE
            )) {
                const state = JSON.parse(message.state);
                store.set(state, DEVTOOLS_SOURCE);
            } else if (message.type === ACTION_MESSAGE_TYPE) {
                const {type, params} = JSON.parse(message.payload);
                // @ts-ignore
                if (typeof store[type] === 'function') {
                    // @ts-ignore
                    store[type](...(params ?? []));
                }
            } else if (message.type === DISPATCH_MESSAGE_TYPE && message.payload.type === TOGGLE_ACTION_PAYLOAD_TYPE) {
                console.error('Store: Skipping action is not supported');
            } else if (message.type === DISPATCH_MESSAGE_TYPE && message.payload.type === IMPORT_STATE_PAYLOAD_TYPE) {
                console.error('Store: Importing is not supported');
            }
        });

        store.addEventListener('*:set', ({method, params, state}: StoreEvent): void => {
            if (method === 'set' && params[1] === DEVTOOLS_SOURCE) {
                return;
            }
            devtools.send({type: method, params}, state);
        });

    };
}