import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createFileManagerPageOptions} from '../file-manager-page-options';

export interface ProvidedFileManagerPageClientDeps {

}

export interface RequiredFileManagerPageClientDeps {
    pageRegistry: PageRegistry;
}

export interface FileManagerPageClientDeps extends ProvidedFileManagerPageClientDeps, RequiredFileManagerPageClientDeps {

}

export interface FileManagerPageClientConfig {

}

export type FileManagerPageClientProvider = Provider<FileManagerPageClientDeps, FileManagerPageClientConfig>;

export function createFileManagerPageClientProvider(): FileManagerPageClientProvider {

    return {

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const fileManagerPageOptions = createFileManagerPageOptions();
            pageRegistry.registerPage(fileManagerPageOptions);
        }

    };
}