export interface StoreNotFoundErrorDetails {
    name: string;
}

export class StoreNotFoundError extends Error {

    name = 'STORE_NOT_FOUND';

    details: StoreNotFoundErrorDetails;

    constructor(message: string, details: StoreNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}