import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type DirectoryBreadcrumbsClassName = 'directoryBreadcrumbs' | 'root' | 'breadcrumb' | 'icon' | 'name';

export interface DirectoryBreadcrumbsCSSProps extends CSSThemeProps {

}

export function DirectoryBreadcrumbsCSS({theme}: DirectoryBreadcrumbsCSSProps) {
    return css<DirectoryBreadcrumbsClassName>`

        .directoryBreadcrumbs {
            display: flex;
            align-items: center;
            margin: ${theme.spacing.large} 0;
            padding: ${theme.spacing.medium} ${theme.spacing.large};
            font-weight: bold;
            box-shadow: ${theme.boxShadow.verySmall};
            border-radius: ${theme.borderRadius.medium};
            background: ${theme.color.white};
        }

        .breadcrumb, .root {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            margin-right: ${theme.spacing.small};
            gap: ${theme.spacing.small};
            cursor: pointer;

            &:hover {
                .name {
                    text-decoration: underline;
                }
            }
        }

        .root {
            gap: ${theme.spacing.large};

            .icon {
                font-size: ${theme.fontSize.large};
            }
        }

    `;
}