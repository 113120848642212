export interface CircularDirectoryErrorDetails {
    directoryId: string;
    parentDirectoryId: string | null;
}

export class CircularDirectoryError extends Error {

    name: string = 'CIRCULAR_DIRECTORY';

    details: CircularDirectoryErrorDetails;

    constructor(message: string, details: CircularDirectoryErrorDetails) {
        super(message);
        this.details = details;
    }

}