import {omitUndefined} from '@webaker/package-utils';
import {Component} from '../component/component';
import {Hook} from '../hook/hook';
import {HookFactory} from '../hook/hook-factory';
import {Page} from '../page/page';
import {ComponentsTree} from './components-tree';
import {ComponentsTreeSelector} from './components-tree-selector';
import {HooksTreeMutator} from './hooks-tree-mutator';
import {HooksTreeSelector} from './hooks-tree-selector';

type AnyComponentsTree = ComponentsTree & Record<string, any> & any;

export interface ComponentsTreeMutator<T extends ComponentsTree = AnyComponentsTree> {

    mergeComponentsTrees: (tree: T, anotherTree: T) => T;

    addComponent: <C extends Component = Component>(tree: T, component: C) => T;
    updateComponent: <C extends Component = Component>(tree: T, component: C) => T;
    deleteComponent: (tree: T, componentId: Component['id']) => T;

    moveComponent: (tree: T, componentId: Component['id'], targetComponentId: Component['id']) => T;
    moveComponentBefore: (tree: T, componentId: Component['id'], targetComponentId: Component['id']) => T;
    moveComponentAfter: (tree: T, componentId: Component['id'], targetComponentId: Component['id']) => T;
    moveComponentToRoot: (tree: T, componentId: Component['id'], targetPageId: Page['id']) => T;

    shareComponent: (tree: T, componentId: Component['id']) => T;
    unshareComponent: (tree: T, componentId: Component['id']) => T;
    attachComponentContent: (tree: T, componentId: Component['id']) => T;
    detachComponentContent: (tree: T, componentId: Component['id']) => T;

}

export interface ComponentsTreeMutatorDeps<T extends ComponentsTree = AnyComponentsTree> {
    hookFactory: HookFactory;
    componentsTreeSelector: ComponentsTreeSelector<T>;
    hooksTreeMutator: HooksTreeMutator<T>;
    hooksTreeSelector: HooksTreeSelector<T>;
}

export function createComponentsTreeMutator<T extends ComponentsTree = AnyComponentsTree>({
    hookFactory,
    componentsTreeSelector,
    hooksTreeMutator,
    hooksTreeSelector
}: ComponentsTreeMutatorDeps<T>): ComponentsTreeMutator<T> {

    const {getComponentById, getChildComponents, getDescendantComponents, getParentComponent} = componentsTreeSelector;
    const {addHook, updateHook} = hooksTreeMutator;
    const {getPageHook, getComponentHook, getRootHook, getParentHook} = hooksTreeSelector;

    const mergeComponentsTrees = (tree: T, anotherTree: T): T => {
        return {
            ...tree,
            ...anotherTree,
            components: [
                ...tree.components,
                ...anotherTree.components
            ],
            hooks: [
                ...tree.hooks,
                ...anotherTree.hooks
            ]
        };
    };

    const addComponent = <C extends Component = Component>(tree: T, component: C): T => {
        return {
            ...tree,
            components: [
                ...tree.components,
                omitUndefined(component)
            ]
        };
    };

    const updateComponent = <C extends Component = Component>(tree: T, component: C): T => {
        return {
            ...tree,
            components: tree.components.map((treeComponent: Component): Component => {
                if (treeComponent.id === component.id) {
                    return omitUndefined(component);
                }
                return treeComponent;
            })
        };
    };

    const deleteComponent = (tree: T, componentId: Component['id']): T => {
        const componentsIdsToDelete = getDescendantComponents(tree, componentId).map((descendantComponent: Component): Component['id'] => {
            return descendantComponent.id;
        }).concat([componentId]);
        return {
            ...tree,
            components: tree.components.filter((component: Component): boolean => {
                return !componentsIdsToDelete.includes(component.id);
            }),
            hooks: tree.hooks.filter((hook: Hook): boolean => {
                return !hook.componentId || !componentsIdsToDelete.includes(hook.componentId);
            }).map((hook: Hook): Hook => {
                const hasDeletedComponent = hook.childComponentsIds.some((childComponentId: Component['id']): boolean => {
                    return componentsIdsToDelete.includes(childComponentId);
                });
                if (hasDeletedComponent) {
                    const newChildComponentsIds = hook.childComponentsIds.filter((childComponentId: Component['id']): boolean => {
                        return !componentsIdsToDelete.includes(childComponentId);
                    });
                    return {
                        ...hook,
                        childComponentsIds: newChildComponentsIds
                    };
                }
                return hook;
            })
        };
    };

    const moveComponent = (tree: T, componentId: Component['id'], targetComponentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        const parentComponentHook = getParentHook(tree, componentId);
        const targetComponentHook = getComponentHook(tree, targetComponentId);
        if (!component || parentComponentHook && parentComponentHook?.id === targetComponentHook?.id) {
            return tree;
        }
        if (parentComponentHook) {
            tree = updateHook(tree, {
                ...parentComponentHook,
                childComponentsIds: parentComponentHook.childComponentsIds.filter((childComponentId: Component['id']): boolean => {
                    return childComponentId !== componentId;
                })
            });
        }
        if (targetComponentHook) {
            tree = updateHook(tree, {
                ...targetComponentHook,
                childComponentsIds: [
                    ...targetComponentHook.childComponentsIds,
                    componentId
                ]
            });
        } else {
            tree = addHook(
                tree,
                hookFactory.createHook({
                    pageId: getRootHook(tree, targetComponentId)?.pageId,
                    componentId: targetComponentId,
                    childComponentsIds: [componentId]
                })
            );
        }
        return fixAttachments(tree, componentId);
    };

    const moveComponentBefore = (tree: T, componentId: Component['id'], targetComponentId: Component['id'], pageId?: Page['id']): T => {
        return moveComponentBeforeOrAfter(tree, componentId, targetComponentId, pageId, 'before');
    };

    const moveComponentAfter = (tree: T, componentId: Component['id'], targetComponentId: Component['id'], pageId?: Page['id']): T => {
        return moveComponentBeforeOrAfter(tree, componentId, targetComponentId, pageId, 'after');
    };

    const moveComponentBeforeOrAfter = (
        tree: T,
        componentId: Component['id'],
        targetComponentId: Component['id'],
        pageId: Page['id'] | undefined,
        mode: 'before' | 'after'
    ): T => {
        if (componentId === targetComponentId) {
            return tree;
        }
        let parentComponentHook = getParentHook(tree, componentId);
        let targetParentComponentHook = getParentHook(tree, targetComponentId);
        if (parentComponentHook && parentComponentHook.id === targetParentComponentHook?.id) {
            return updateHook(tree, {
                ...parentComponentHook,
                childComponentsIds: parentComponentHook.childComponentsIds.reduce((
                    childComponentsIds: Component['id'][],
                    childComponentId: Component['id']
                ) => {
                    if (childComponentId === componentId) {
                        return childComponentsIds;
                    }
                    if (childComponentId === targetComponentId) {
                        return mode === 'before'
                            ? [...childComponentsIds, componentId, targetComponentId]
                            : [...childComponentsIds, targetComponentId, componentId];
                    }
                    return [...childComponentsIds, childComponentId];
                }, [])
            });
        }
        if (parentComponentHook) {
            tree = updateHook(tree, {
                ...parentComponentHook,
                childComponentsIds: parentComponentHook.childComponentsIds.filter((childComponentId: Component['id']): boolean => {
                    return childComponentId !== componentId;
                })
            });
        }
        if (targetParentComponentHook) {
            tree = updateHook(tree, {
                ...targetParentComponentHook,
                childComponentsIds: targetParentComponentHook.childComponentsIds.reduce((
                    childComponentsIds: Component['id'][],
                    childComponentId: Component['id']
                ) => {
                    if (childComponentId === targetComponentId) {
                        return mode === 'before'
                            ? [...childComponentsIds, componentId, targetComponentId]
                            : [...childComponentsIds, targetComponentId, componentId];
                    }
                    return [...childComponentsIds, childComponentId];
                }, [])
            });
        }
        return fixAttachments(tree, componentId);
    };

    const moveComponentToRoot = (tree: T, componentId: Component['id'], targetPageId: Page['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component) {
            return tree;
        }
        const pageHook = getPageHook(tree, targetPageId);
        tree = pageHook ? updateHook(tree, {
            ...pageHook,
            childComponentsIds: [componentId]
        }) : addHook(tree, hookFactory.createHook({
            pageId: targetPageId,
            childComponentsIds: [componentId]
        }));
        const componentsIds = getDescendantComponents(tree, componentId).map((descendantComponent: Component): Component['id'] => {
            return descendantComponent.id;
        }).concat(componentId);
        tree = {
            ...tree,
            components: tree.components.filter((component: Component): boolean => {
                return componentsIds.includes(component.id);
            }),
            hooks: tree.hooks.filter((hook: Hook): boolean => {
                return !hook.componentId || componentsIds.includes(hook.componentId);
            })
        };
        return fixAttachments(tree, componentId);
    };

    const shareComponent = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component) {
            return tree;
        }
        tree = updateComponent(tree, {
            ...component,
            share: true
        });
        tree = attachComponentContent(tree, componentId);
        return tree;
    };

    const unshareComponent = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component) {
            return tree;
        }
        tree = updateComponent(tree, {
            ...component,
            share: undefined
        });
        tree = detachComponentContent(tree, componentId);
        return tree;
    };

    const attachComponentContent = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component || component.attach) {
            return tree;
        }
        tree = attachComponent(tree, componentId);
        tree = attachNestedComponents(tree, componentId);
        return tree;
    };

    const detachComponentContent = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component || !component.attach) {
            return tree;
        }
        tree = detachComponent(tree, componentId);
        tree = detachNestedComponents(tree, componentId);
        return tree;
    };

    const fixAttachments = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component || component.share) {
            return tree;
        }
        const hasAttach = !!component.attach;
        const canAttach = canBeAttached(tree, componentId);
        if (!hasAttach && canAttach) {
            tree = attachComponent(tree, componentId);
            tree = attachNestedComponents(tree, componentId);
        }
        if (hasAttach && !canAttach) {
            tree = detachComponent(tree, componentId);
            tree = detachNestedComponents(tree, componentId);
        }
        return tree;
    };

    const canBeAttached = (tree: T, componentId: Component['id']): boolean => {
        if (getComponentById(tree, componentId)?.share) {
            return true;
        }
        let component = getParentComponent(tree, componentId);
        while (component) {
            if (!component.attach) {
                return false;
            }
            if (component.share) {
                return true;
            }
            component = getParentComponent(tree, component.id);
        }
        return false;
    };

    const attachComponent = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component || component.attach) {
            return tree;
        }
        tree = updateComponent(tree, {
            ...component,
            attach: true
        });
        const hook = getComponentHook(tree, componentId);
        if (!hook) {
            tree = addHook(tree, hookFactory.createHook({
                componentId,
                childComponentsIds: []
            }));
        } else {
            tree = updateHook(tree, {
                ...hook,
                pageId: undefined
            });
        }
        return tree;
    };

    const detachComponent = (tree: T, componentId: Component['id']): T => {
        const component = getComponentById(tree, componentId);
        if (!component || !component.attach) {
            return tree;
        }
        tree = updateComponent(tree, {
            ...component,
            attach: undefined
        });
        const hook = getComponentHook(tree, componentId);
        if (!hook) {
            return tree;
        }
        tree = updateHook(tree, {
            ...hook,
            pageId: getRootHook(tree, componentId)?.pageId
        });
        return tree;
    };

    const attachNestedComponents = (tree: T, componentId: Component['id']): T => {
        getChildComponents(tree, componentId).forEach((childComponent: Component): void => {
            if (!childComponent.share) {
                tree = attachComponent(tree, childComponent.id);
                tree = attachNestedComponents(tree, childComponent.id);
            }
        });
        return tree;
    };

    const detachNestedComponents = (tree: T, componentId: Component['id']): T => {
        getChildComponents(tree, componentId).forEach((childComponent: Component): void => {
            if (!childComponent.share) {
                tree = detachComponent(tree, childComponent.id);
                tree = detachNestedComponents(tree, childComponent.id);
            }
        });
        return tree;
    };

    return {
        mergeComponentsTrees,
        addComponent,
        updateComponent,
        deleteComponent,
        moveComponent,
        moveComponentBefore,
        moveComponentAfter,
        moveComponentToRoot,
        shareComponent,
        unshareComponent,
        attachComponentContent,
        detachComponentContent
    };

}