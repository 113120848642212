import {ValidationError} from '../validation-error';
import {ValidationModifier} from '../validation-modifier';
import {ValidationRule} from '../validation-rule';

export function and<T = unknown>(rules: ValidationRule<T>[]): ValidationModifier<T> {
    return (item: unknown): ValidationError[] => {
        const errors: ValidationError[] = [];
        for (const rule of rules) {
            const nestedErrors: ValidationError[] = rule(item);
            if (nestedErrors.length > 0) {
                errors.push(...nestedErrors);
            }
        }
        return errors;
    };
}