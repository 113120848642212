import {Store, StoreFactory} from '@webaker/package-store';
import {Theme} from './theme';

export const THEME_STORE_NAME = 'theme';

export interface ThemeStoreState {
    themes: Theme[];
    deletedThemesIds: Theme['id'][];
}

export interface ThemeStore extends Store<ThemeStoreState> {
    name: typeof THEME_STORE_NAME;
    getThemes: () => Theme[];
    getSharedThemes: () => Theme[];
    getThemeById: (id: Theme['id']) => Theme | null;
    setThemes: (themes: Theme[]) => void;
    addTheme: (theme: Theme) => void;
    addThemes: (themes: Theme[]) => void;
    updateTheme: (theme: Theme) => void;
    deleteTheme: (id: Theme['id']) => void;
}

export interface ThemeStoreDeps {
    storeFactory: StoreFactory;
}

export function createThemeStore({storeFactory}: ThemeStoreDeps): ThemeStore {
    return storeFactory.createStore<ThemeStore>({

        name: THEME_STORE_NAME,

        state: {
            themes: [],
            deletedThemesIds: []
        },

        getters: {

            getThemes: ({state}): Theme[] => {
                return state.themes;
            },

            getSharedThemes: ({state, memo}): Theme[] => {
                return memo(() => {
                    return state.themes.filter((theme: Theme): boolean => {
                        return !!theme.shared;
                    }).sort((themeA: Theme, themeB: Theme): number => {
                        return themeA.name > themeB.name ? 1 : themeA.name < themeB.name ? -1 : 0;
                    });
                }, [state.themes]);
            },

            getThemeById: ({state}, id: Theme['id']): Theme | null => {
                return state.themes.find((theme: Theme): boolean => {
                    return theme.id === id;
                }) ?? null;
            }

        },

        setters: {

            setThemes: ({state}, themes: Theme[]): ThemeStoreState => {
                return {
                    ...state,
                    themes
                };
            },

            addTheme: ({state}, theme: Theme): ThemeStoreState => {
                const hasTheme = state.themes.some((stateTheme: Theme): boolean => {
                    return stateTheme.id === theme.id;
                });
                return hasTheme ? state : {
                    ...state,
                    themes: [
                        ...state.themes,
                        theme
                    ]
                };
            },

            addThemes: ({state}, themes: Theme[]): ThemeStoreState => {
                const existingThemesIds = state.themes.map((theme: Theme): Theme['id'] => {
                    return theme.id;
                });
                const newThemes = themes.filter((theme: Theme): boolean => {
                    return !existingThemesIds.includes(theme.id);
                });
                return newThemes.length === 0 ? state : {
                    ...state,
                    themes: [
                        ...state.themes,
                        ...newThemes
                    ]
                };
            },

            updateTheme: ({state}, theme: Theme): ThemeStoreState => {
                return {
                    ...state,
                    themes: state.themes.map((stateTheme: Theme): Theme => {
                        if (stateTheme.id === theme.id) {
                            return theme;
                        }
                        return stateTheme;
                    })
                };
            },

            deleteTheme: ({state}, id: Theme['id']): ThemeStoreState => {
                return {
                    ...state,
                    themes: state.themes.filter((theme: Theme): boolean => {
                        return theme.id !== id;
                    })
                };
            }

        }

    });
}