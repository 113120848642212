import {useDependency} from '@webaker/package-deps';
import {useDebugValue, useInsertionEffect, useMemo} from 'react';
import {ClassName} from '../class-name/class-name';
import {ClassNamesMap} from '../class-name/class-names-map';
import {CSSRegistry} from '../css-registry';

export interface UseEmptyCSSDeps {
    cssRegistry: CSSRegistry;
}

export function useEmptyCSS<C extends ClassName = ClassName>(): ClassNamesMap<C> {

    useDependency<UseEmptyCSSDeps>()('cssRegistry');
    useMemo<undefined>(() => void 0, [0, 0]);

    if (typeof window !== 'undefined') {
        useInsertionEffect(() => void 0, [0]);
    }

    useDebugValue('css');

    return {} as ClassNamesMap<C>;

}