import {parseUnit} from '@webaker/package-utils';
import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';
import {isNumber, NumberRuleOptions} from './is-number';

export interface NumberWithUnitRuleOptions extends Omit<NumberRuleOptions, 'strict'> {
    units?: string[];
}

export function isNumberWithUnit<N extends string = string>({units, min, max, integer, ...props}: NumberWithUnitRuleOptions = {}): ValidationRule<N> {
    const numberRule = isNumber(props);
    return (item: unknown): ValidationError[] => {
        if (typeof item !== 'string') {
            return [{message: `Value has to be string`}];
        }
        const errors: ValidationError[] = [];
        const number = parseFloat(item);
        if (isNaN(number)) {
            return [{message: 'Value has to be number with unit'}];
        }
        errors.push(...numberRule(number));
        try {
            const unit = parseUnit(item);
            if (units && !units.includes(unit ?? '')) {
                errors.push({message: `Number unit has be be one of: ${units.join(', ')}`});
            }
        } catch {
            errors.push({message: 'Value has to be number with unit'});
        }
        return errors;
    };
}