import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {ColumnCSS, ColumnPrivateCSS} from './column-css';

export interface ColumnProps {
    rows?: string[];
    align?: ColumnAlign;
    margin?: CSSThemeSize;
    gap?: CSSThemeSize;
    children?: ReactNode;
    className?: string;
}

export type ColumnAlign = 'start' | 'center' | 'end';

export function Column({
    rows = ['none'],
    align = 'start',
    margin = 'medium',
    gap = 'medium',
    children,
    className
}: ColumnProps) {

    const css = useThemedCSSBundle([
        ColumnCSS,
        ColumnPrivateCSS
    ], {
        rows,
        align,
        margin,
        gap
    });

    return (
        <div className={mergeClassNames(css['column'], className)}>
            {children}
        </div>
    );

}