import {useRequiredContext} from '@webaker/package-utils';
import {useDebugValue} from 'react';
import {Config} from '../config';
import {ConfigContext} from '../config-context';
import {ConfigNotRegisteredError} from '../config-not-registered-error';
import {RequiredKey} from '../dependency-key';

export function useConfig<C extends Config = {}>() {
    return function useConfig<K extends RequiredKey<C> = RequiredKey<C>>(key: K): C[K] {
        useDebugValue(key);
        const config = useRequiredContext(ConfigContext).config as C;
        if (!(key in config)) {
            throw new ConfigNotRegisteredError(`Configuration [${key}] is not registered`, {key});
        }
        return config[key];
    };
}