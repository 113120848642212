export const PRODUCTION = 'production';
export const DEVELOPMENT = 'development';

export type AppMode = typeof PRODUCTION | typeof DEVELOPMENT;

export function getAppMode(): AppMode {
    const appMode = (process.env.APP_MODE ?? PRODUCTION) as AppMode;
    if (appMode !== PRODUCTION && appMode !== DEVELOPMENT) {
        throw new Error(`App mode [${appMode}] is invalid, available options: ${PRODUCTION}, ${DEVELOPMENT}`);
    }
    return appMode;
}