import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {StoreFactory, StoreRegistry} from '@webaker/package-store';
import {SettingsApi} from '../settings-api';
import {createSettingsFactory, SettingsFactory} from '../settings-factory';
import {createSettingsStore, SettingsStore} from '../settings-store';
import {createSettingsClientApi} from './settings-client-api';

export interface ProvidedSettingsClientDeps {
    settingsApi: SettingsApi;
    settingsFactory: SettingsFactory;
    settingsStore: SettingsStore;
}

export interface RequiredSettingsClientDeps {
    apiClientFactory: ApiClientFactory;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
}

export interface SettingsClientDeps extends ProvidedSettingsClientDeps, RequiredSettingsClientDeps {

}

export interface SettingsClientConfig {

}

export type SettingsClientProvider = Provider<SettingsClientDeps, SettingsClientConfig>;

export function createSettingsClientProvider(): SettingsClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('settingsApi', ({resolve}) => {
                return createSettingsClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
            register('settingsFactory', () => {
                return createSettingsFactory();
            });
            register('settingsStore', ({resolve}) => {
                return createSettingsStore({
                    storeFactory: resolve('storeFactory')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const storeRegistry = resolve('storeRegistry');
            const settingsStore = resolve('settingsStore');
            storeRegistry.registerStore(settingsStore);
        }

    };
}