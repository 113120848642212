import {CSSThemeColor, CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject, ReactElement, ReactNode} from 'react';
import {AlertCSS, AlertPrivateCSS} from './alert-css';

export interface AlertProps {
    color?: CSSThemeColor;
    margin?: CSSThemeSize;
    icon?: ReactElement;
    children?: ReactNode;
    className?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
}

export function Alert({
    color = 'info',
    margin = 'medium',
    icon,
    children,
    className,
    elementRef
}: AlertProps) {

    const css = useThemedCSSBundle([
        AlertCSS,
        AlertPrivateCSS
    ], {
        color,
        margin
    });

    return (
        <div ref={elementRef as any}
             className={mergeClassNames(css['alert'], className)}>
            {icon && (
                <span className={css['icon']}>
                    {icon}
                </span>
            )}
            <div className={css['message']}>
                {children}
            </div>
        </div>
    );

}