import {isArray, isId, isObject, isString, optional, Validator} from '@webaker/package-validation';
import {Hook} from './hook';

export interface HookValidator {
    validateHook: (item: unknown, options?: HookRuleOptions) => item is Hook;
    assertHook: (item: unknown, options?: HookRuleOptions) => asserts item is Hook;
}

export interface HookValidatorDeps {
    validator: Validator;
}

export interface HookRuleOptions {
    partial?: boolean;
}

export function createHookValidator({validator: validatorAlias}: HookValidatorDeps): HookValidator {

    // fix for TS2775
    const validator: Validator = validatorAlias;

    const validateHook = (item: unknown, options: HookRuleOptions = {}): item is Hook => {
        return validator.validate(item, isHook(options));
    };

    const assertHook = (item: unknown, options: HookRuleOptions = {}): asserts item is Hook => {
        validator.assert(item, isHook(options));
    };

    const isHook = ({partial}: HookRuleOptions = {}) => {
        return isObject<Hook>({
            properties: {
                id: isId(),
                pageId: optional(isString()),
                componentId: isString(),
                childComponentsIds: isArray({
                    rule: isString()
                })
            },
            partial
        });
    };

    return {
        validateHook,
        assertHook
    };

}