import {Setting} from './setting';

export interface SettingsFactory {
    createSettings: <S extends Setting = Setting>(data: S) => S;
}

export function createSettingsFactory(): SettingsFactory {

    const createSettings = <S extends Setting = Setting>(data: S): S => {
        return {
            key: data.key,
            value: data.value,
        } as S;
    };

    return {
        createSettings,
    };

}