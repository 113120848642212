import {IdGenerator} from '@webaker/package-utils';
import {Directory} from './directory';

export interface DirectoryFactory {
    createDirectory: (data?: Partial<Directory>) => Directory;
}

export interface DirectoryFactoryDeps {
    idGenerator: IdGenerator;
}

export function createDirectoryFactory({idGenerator}: DirectoryFactoryDeps): DirectoryFactory {

    const createDirectory = (data: Partial<Directory> = {}): Directory => {
        return {
            id: data.id ?? idGenerator.generateId(),
            name: data.name ?? '',
            parentDirectoryId: data.parentDirectoryId ?? undefined,
            size: data.size ?? 0
        };
    };

    return {
        createDirectory
    };

}