import {File, FileExtension} from '../file';
import {FileTypeEditorOptions} from './file-type-editor-options';

export interface FileEditorRegistry {
    registerFileType: <F extends File = File>(options: FileTypeEditorOptions<F>) => void;
    getFileTypeOptions: <F extends File = File>(type: FileTypeEditorOptions<F>['type']) => FileTypeEditorOptions<F> | null;
    getFileTypeOptionsByExtension: <F extends File = File>(extension: FileExtension) => FileTypeEditorOptions<F> | null;
}

export function createFileEditorRegistry(): FileEditorRegistry {

    const fileTypesOptionsMap: Map<FileTypeEditorOptions['type'], FileTypeEditorOptions<any>> = new Map();

    const registerFileType = <F extends File = File>(options: FileTypeEditorOptions<F>): void => {
        fileTypesOptionsMap.set(options.type, options);
    };

    const getFileTypeOptions = <F extends File = File>(type: FileTypeEditorOptions<F>['type']): FileTypeEditorOptions<F> | null => {
        return fileTypesOptionsMap.get(type) ?? null;
    };

    const getFileTypeOptionsByExtension = <F extends File = File>(extension: FileTypeEditorOptions<F>['extensions'][number]): FileTypeEditorOptions<F> | null => {
        return Array.from(fileTypesOptionsMap.values()).find((fileTypeOptions: FileTypeEditorOptions): boolean => {
            return fileTypeOptions.extensions.includes(extension);
        }) ?? null;
    };

    return {
        registerFileType,
        getFileTypeOptions,
        getFileTypeOptionsByExtension
    };

}