import {ComponentExtensionOptions} from '@webaker/app';
import {ThemeComponent} from './theme-component';
import {ThemeComponentViewWrapper} from './theme-component-view-wrapper';

export const THEMED_COMPONENT_EXTENSION_NAME = 'theme';

export function createThemeComponentOptions(): ComponentExtensionOptions<ThemeComponent> {
    return {
        name: THEMED_COMPONENT_EXTENSION_NAME,
        wrapper: ThemeComponentViewWrapper
    };
}