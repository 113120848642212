export interface PageExtensionNotFoundErrorDetails {
    name: string;
}

export class PageExtensionNotFoundError extends Error {

    name = 'PAGE_EXTENSION_NOT_FOUND';

    details: PageExtensionNotFoundErrorDetails;

    constructor(message: string, details: PageExtensionNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}