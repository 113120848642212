export interface CallStackItem {
    context: string;
    file: string;
    line: string;
    column: string;
}

const STACK_LINES_TO_SKIP = 2;

export function getCallStack(): CallStackItem[] {
    try {
        throw new Error();
    } catch (error) {
        return error.stack.split(/\n/g).slice(STACK_LINES_TO_SKIP).map((line: string): CallStackItem | null => {
            const match = line.match(/at\s+(.+)\s+\((.+):(\d+):(\d+)\)/);
            return match ? {
                context: match[1],
                file: match[2],
                line: match[3],
                column: match[4]
            } : null;
        }).filter(Boolean);
    }
}