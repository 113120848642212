import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type NodeListClassName = 'nodeList';

export function NodeListCSS({theme}: CSSThemeProps) {
    return css<NodeListClassName>`

        .nodeList {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: auto;
            min-height: 0;
            border-radius: ${theme.borderRadius.medium};
            box-shadow: ${theme.boxShadow.verySmall};
        }

    `;
}