import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {adjustColor} from '@webaker/package-utils';
import {SideMenuAlignment} from './side-menu';

export type SideMenuItemClassName = 'sideMenuItem' | 'icon' | 'label' | `align-${SideMenuAlignment}` | 'is-active' | 'is-disabled';

export function SideMenuItemCSS({theme}: CSSThemeProps) {

    const arrowSize = '5px';

    return css<SideMenuItemClassName>`

        .sideMenuItem {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: var(--color);
            background: none;
            border: none;
            border-radius: ${theme.borderRadius.medium};
            transition: ${theme.transition.short} background-color;
            aspect-ratio: 1;
            z-index: 0;

            &:not(.is-disabled, .is-active):hover {
                background-color: var(--color-alpha-1);
            }

            &:not(.is-disabled):hover .label {
                margin-left: ${theme.spacing.large};
                opacity: 1;
            }
        }

        .icon {
            display: inline-block;
            text-align: center;
            transition: ${theme.transition.short} opacity;
            width: 100%;
        }

        .label {
            position: absolute;
            display: inline-block;
            height: 100%;
            margin: 0;
            padding: ${theme.spacing.medium};
            background: ${theme.color.dark};
            color: ${theme.color.white};
            border-radius: ${theme.borderRadius.medium};
            transition: ${theme.transition.short} margin, ${theme.transition.short} opacity;
            font-weight: bold;
            white-space: nowrap;
            pointer-events: none;
            opacity: 0;
            outline: none;

            &:before {
                content: ' ';
                position: absolute;
                width: 0;
                height: 0;
                top: calc(50% - ${arrowSize});
                border-style: solid;
                border-width: ${arrowSize};
                border-color: transparent;
            }
        }

        .sideMenuItem:not(.is-disabled) {
            &:hover, &:focus-visible {
                .label {
                    margin: 0 calc(calc(${theme.spacing.small}) * 2);
                    opacity: 1;
                }
            }
        }

        .is-disabled {
            cursor: default;
            opacity: 0.5;
        }

        .is-active {
            background: ${theme.color.white};
            box-shadow: ${theme.boxShadow.small};
            z-index: 1;
        }

        .align-left .label {
            left: 100%;

            &:before {
                left: calc(1px - ${arrowSize} * 2);
                border-right-color: ${theme.color.dark};
            }
        }

        .align-right .label {
            right: 100%;

            &:before {
                right: calc(1px - ${arrowSize} * 2);
                border-left-color: ${theme.color.dark};
            }
        }

    `;

}

export interface SideMenuItemPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
}

export function SideMenuItemPrivateCSS({theme, color}: SideMenuItemPrivateCSSProps) {
    return css<SideMenuItemClassName>`

        .sideMenuItem {
            --color: ${theme.color[color]};
            --color-alpha-1: ${adjustColor(theme.color[color], {alpha: 0.1})};
        }

    `;
}