import {ValidationError} from './validation-error';

export interface InvalidErrorDetails {
    item: any;
    errors: ValidationError[];
}

export class InvalidError extends Error {

    name = 'INVALID';

    details: InvalidErrorDetails;

    constructor(message: string, details: InvalidErrorDetails) {
        super(message);
        this.details = details;
    }

}