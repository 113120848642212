import {PageOptions} from '@webaker/app';
import {PRIVATE_PAGE_TYPE, PrivatePage} from './private-page';

export interface PrivatePageOptions extends PageOptions<PrivatePage> {

}

export function createPrivatePageOptions(): PrivatePageOptions {
    return {

        type: PRIVATE_PAGE_TYPE,

        editor: true

    };
}