import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {File, FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {MdIcon, Tooltip} from '@webaker/package-ui';
import {formatBytes, mergeClassNames, useBooleanState} from '@webaker/package-utils';
import {Fragment, MouseEvent, useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';
import {FileUsageErrorModal} from '../modals/file-usage-error-modal';
import {FileUsageModal} from '../modals/file-usage-modal';
import {FileListItemCSS} from './file-list-item-css';
import {NodeListItem} from './node-list-item';
import {NodeNameField} from './node-name-field';

export interface FileListItemProps {
    muted?: boolean;
    file: File;
}

export interface FileListItemDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function FileListItem({muted, file}: FileListItemProps) {

    const fileManagerStore = useStore(useDependency<FileListItemDeps>()('fileManagerStore'));
    const fileApi = useDependency<FileListItemDeps>()('fileApi');
    const css = useThemedCSS(FileListItemCSS, {});
    const [isUsageModalOpen, openUsageModal, closeUsageModal] = useBooleanState(false);
    const [isFileUsageErrorModalOpen, openFileUsageErrorModal, closeFileUsageErrorModal] = useBooleanState(false);
    const isSelected = fileManagerStore.isNodeSelected(file.id);

    const handleClick = useCallback(async (event: MouseEvent) => {
        if (event.ctrlKey) {
            fileManagerStore.toggleSelectNode(file.id);
        } else if (event.shiftKey) {
            fileManagerStore.selectNodesRange(file.id);
        } else {
            fileManagerStore.selectNode(file.id);
        }
    }, []);

    const handleDoubleClick = useCallback(async () => {
        if (file.url) {
            window.open(file.url, '_blank');
        }
    }, [file.url]);

    const handleNameChange = useCallback(async (newFile: File) => {
        fileManagerStore.updateFile(newFile);
        const savedFile = await fileApi.saveFile(newFile);
        fileManagerStore.updateFile(savedFile);
    }, []);

    const handleNameCancel = useCallback(async (newFile: File) => {
        const originalFile = await fileApi.getFileById(newFile.id);
        if (!originalFile) {
            fileManagerStore.deleteFile(newFile.id);
        }
    }, []);

    const toggleAccess = useCallback(async () => {
        if (await fileApi.isAnyFileInUse([file.id])) {
            openFileUsageErrorModal();
            return;
        }

        const newFile = {...file, public: !file.public};
        fileManagerStore.updateFile(newFile);
        await fileApi.saveFile(newFile);
    }, [file]);

    return (
        <Fragment>
            <NodeListItem onClick={handleClick}
                          onDoubleClick={handleDoubleClick}
                          selected={isSelected}
                          muted={muted}
                          className={css['fileListItem']}
                          iconColumn={<MdIcon name="draft"/>}
                          nameColumn={(
                              <Fragment>
                                  <NodeNameField node={file}
                                                 onChange={handleNameChange}
                                                 onCancel={handleNameCancel}/>
                                  {file.alias && file.public && (
                                      <span className={css['alias']}>
                                          as {file.alias}
                                      </span>
                                  )}
                              </Fragment>
                          )}
                          sizeColumn={file.size !== undefined ? formatBytes(file.size) : null}
                          accessColumn={(
                              <Fragment>
                                  <Tooltip title={file.public ? 'Public' : 'Private'}>
                                <span className={mergeClassNames(css['accessIcon'], file.public && css['is-public'])}
                                      onClick={toggleAccess}>
                                    <MdIcon name={file.public ? 'public' : 'public_off'}/>
                                </span>
                                  </Tooltip>
                                  <Tooltip title="Usage">
                                    <span className={css['usageIcon']}
                                          onClick={openUsageModal}>
                                        <MdIcon name="wifi_find"/>
                                    </span>
                                  </Tooltip>
                              </Fragment>
                          )}>
                {isUsageModalOpen && (
                    <FileUsageModal file={file}
                                    onClose={closeUsageModal}/>
                )}
            </NodeListItem>
            {isFileUsageErrorModalOpen && (
                <FileUsageErrorModal info="This file is in use, you cannot make it private."
                                     onClose={closeFileUsageErrorModal}/>
            )}
        </Fragment>
    );

}