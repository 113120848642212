export const MS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;

export const MS_IN_MINUTE = MS_IN_SECOND * SECONDS_IN_MINUTE;
export const MS_IN_HOUR = MS_IN_MINUTE * MINUTES_IN_HOUR;
export const MS_IN_DAY = MS_IN_HOUR * HOURS_IN_DAY;

export function formatTime(timestamp: number): string {
    const currentTimestamp = new Date().getTime();
    const inFuture = timestamp > currentTimestamp;
    const msDiff = Math.abs(currentTimestamp - timestamp);
    const secondsDiff = Math.floor(msDiff / MS_IN_SECOND);
    const minutesDiff = Math.floor(secondsDiff / SECONDS_IN_MINUTE);
    const hoursDiff = Math.floor(minutesDiff / MINUTES_IN_HOUR);
    const daysDiff = Math.floor(hoursDiff / HOURS_IN_DAY);
    if (daysDiff >= 1) {
        const noun = daysDiff === 1 ? 'day' : 'days';
        return inFuture ? `in ${daysDiff} ${noun}` : `${daysDiff} ${noun} ago`;
    }
    if (hoursDiff >= 1) {
        const noun = hoursDiff === 1 ? 'hour' : 'hours';
        return inFuture ? `in ${hoursDiff} ${noun}` : `${hoursDiff} ${noun} ago`;
    }
    if (minutesDiff >= 1) {
        const noun = minutesDiff === 1 ? 'minute' : 'minutes';
        return inFuture ? `in ${minutesDiff} ${noun}` : `${minutesDiff} ${noun} ago`;
    }
    return inFuture ? `in a moment` : `a moment ago`;
}