import {CSSThemeProps} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {TupleToIntersection, TupleToUnion} from '@webaker/package-utils';
import {useDebugValue, useInsertionEffect, useMemo} from 'react';
import {ClassName} from '../class-name/class-name';
import {ClassNamesMap} from '../class-name/class-names-map';
import {CSS} from '../css';
import {CSSComponent} from '../css-component';
import {CSSRegistry} from '../css-registry';

type GetOptionalRest<T> = {} extends T ? [T?] : [T];
type GetBundleProps<T extends readonly CSSComponent<any, any>[]> = Omit<TupleToIntersection<{ [K in keyof T]: GetProps<T[K]> }>, keyof CSSThemeProps>;
type GetBundleClassName<T extends readonly CSSComponent<any, any>[]> = TupleToUnion<{ [K in keyof T]: GetClassName<T[K]> }>;
type GetProps<T> = T extends CSSComponent<infer P, any> ? P : {};
type GetClassName<T> = T extends CSSComponent<any, infer C> ? C : never;

export interface UseCSSBundleDeps {
    cssRegistry: CSSRegistry;
}

export function useCSSBundle<T extends readonly CSSComponent<any, any>[]>(
    cssComponents: T,
    ...[optionalCSSProps]: GetOptionalRest<GetBundleProps<T>>
): ClassNamesMap<GetBundleClassName<T>> {

    const cssProps = optionalCSSProps ?? {} as GetBundleProps<T>;
    const cssRegistry = useDependency<UseCSSBundleDeps>()('cssRegistry');
    const cssBundle = useMemo<CSS[]>(() => {
        return cssComponents.map((cssComponent: CSSComponent): CSS => {
            return cssComponent(cssProps);
        });
    }, [...cssComponents, JSON.stringify(cssProps)]);

    if (typeof window !== 'undefined') {
        useInsertionEffect(() => {
            cssBundle.forEach((css: CSS) => {
                cssRegistry.registerCSS(css);
            });
            return () => {
                cssBundle.forEach((css: CSS) => {
                    cssRegistry.unregisterCSS(css);
                });
            };
        }, [cssBundle]);
    } else {
        cssBundle.forEach((css: CSS) => {
            cssRegistry.registerCSS(css);
        });
    }

    useDebugValue(cssBundle.map((css: CSS) => {
        return css.id;
    }).join(', '));

    return mergeClassNames(cssBundle) as ClassNamesMap<GetBundleClassName<T>>;

}

function mergeClassNames<T extends ClassName = ClassName>(cssBundle: CSS<T>[]): ClassNamesMap<T> {
    return cssBundle.reduce((classNames: ClassNamesMap<T>, css: CSS<T>): ClassNamesMap<T> => {
        Object.entries(css.classNames).forEach(([key, value]: [T, ClassName]) => {
            if (classNames[key]) {
                classNames[key] += ` ${value}`;
            } else {
                classNames[key] = value;
            }
        });
        return classNames;
    }, {} as ClassNamesMap<T>);
}