import {ComponentType} from 'react';
import {makeComponentOverridable} from './jsx-override';

export interface JSXRegistry {
    registerComponent: <P extends {} = {}>(component: ComponentType<P>, newComponent: ComponentType<P>) => void;
    resolveComponent: <P extends {} = {}>(component: ComponentType<P>) => ComponentType<P>;
}

export interface ViewStorageDeps {

}

export function createJSXRegistry({}: ViewStorageDeps = {}): JSXRegistry {

    const componentsMap: Map<ComponentType<any>, ComponentType<any>> = new Map();

    const registerComponent = <P extends {} = {}>(component: ComponentType<P>, newComponent: ComponentType<P>): void => {
        makeComponentOverridable(component);
        componentsMap.set(component, newComponent);
    };

    const resolveComponent = <P extends {} = {}>(component: ComponentType<P>): ComponentType<P> => {
        return componentsMap.get(component) ?? component;
    };

    return {
        registerComponent,
        resolveComponent
    };

}