import {PageOptions} from '@webaker/app';
import {USER_MANAGER_PAGE_TYPE, UserManagerPage} from './user-manager-page';

export interface UserManagerPageOptions extends PageOptions<UserManagerPage> {

}

export function createUserManagerPageOptions(): UserManagerPageOptions {
    return {

        type: USER_MANAGER_PAGE_TYPE,

        static: true,

        editor: true,

        ssr: false,

        view: async () => (await import('@webaker/module-user-manager-page/editor')).UserManagerPageView

    };
}