export interface FlexBox {
    direction: FlexDirection;
    align: FlexAlign;
    justify: FlexJustify;
    wrap: FlexWrap;
    gap: FlexGap;
}

export type FlexDirection = 'row' | 'column' | 'rowReverse' | 'columnReverse';

export type FlexAlign = 'start' | 'end' | 'center' | 'stretch';

export type FlexJustify = 'start' | 'end' | 'center' | 'stretch' | 'spaceBetween' | 'spaceEvenly';

export type FlexWrap = boolean;

export type FlexGap = string;