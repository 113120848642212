import {debounce} from '@webaker/package-utils';
import {Store} from './store';
import {DecoratedStore, StoreDecorator} from './store-decorator';
import {StateListener, StoreEvent} from './store-events';

export interface StoreWatcher {
    watchStore: <S extends Store = any>(store: S, listener: StateListener<S>) => WatchedStore<S>;
}

export type WatchedStore<S extends Store = any> = DecoratedStore<S> & {
    resetStore: () => void;
};

export interface StoreWatcherDeps {
    storeDecorator: StoreDecorator;
    timeout?: number;
}

export function createStoreWatcher({storeDecorator, timeout = 0}: StoreWatcherDeps): StoreWatcher {

    const watchStore = <S extends Store = any>(store: S, listener: StateListener<S>): WatchedStore<S> => {

        const eventsHistory: Map<string, StoreEvent<S>> = new Map();

        const onGet = (event: StoreEvent<S>): void => {
            const key: string = JSON.stringify([event.method, ...event.params]);
            eventsHistory.set(key, event);
        };

        const onGlobalSet = timeout ? debounce(({state}: StoreEvent<S>): void => {
            if (isDirty()) {
                listener({state});
            }
        }, timeout) : ({state}: StoreEvent<S>): void => {
            if (isDirty()) {
                listener({state});
            }
        };

        const isDirty = () => {
            return Array.from(eventsHistory.values()).some(
                ({method, params, result}: StoreEvent<S>): boolean => {
                    // @ts-ignore
                    return store[method](...params) !== result;
                }
            );
        };

        const resetStore = () => {
            eventsHistory.clear();
        };

        const decoratedStore = storeDecorator.decorateStore(store, {
            onGet,
            onGlobalSet
        });

        return {
            ...decoratedStore,
            resetStore
        };

    };

    return {
        watchStore
    };

}