import {Component} from '../component/component';
import {Hook} from '../hook/hook';
import {Page} from '../page/page';
import {HooksTree} from './hooks-tree';

type AnyHooksTree = HooksTree & Record<string, any>;

export interface HooksTreeSelector<T extends HooksTree = AnyHooksTree> {

    getHooks: (tree: T) => Hook[];
    getHookById: (tree: T, hookId: Hook['id']) => Hook | null;
    getHooksByIds: (tree: T, hooksIds: Hook['id'][]) => Hook[];

    getPageHook: (tree: T, pageId: Page['id']) => Hook | null;
    getPagesHooks: (tree: T) => Hook[];

    getComponentHook: (tree: T, componentId: Component['id']) => Hook | null;
    getComponentsHooks: (tree: T, componentsIds: Component['id'][]) => Hook[];

    getRootHook: (tree: T, componentId: Component['id']) => Hook | null;
    getParentHook: (tree: T, componentId: Component['id']) => Hook | null;
    getNestedHooks: (tree: T, componentId: Component['id']) => Hook[];

}

export interface HooksTreeSelectorDeps {

}

export function createHooksTreeSelector<T extends HooksTree = AnyHooksTree>({}: HooksTreeSelectorDeps = {}): HooksTreeSelector<T> {

    const getHooks = (tree: T): Hook[] => {
        return tree.hooks;
    };

    const getHookById = (tree: T, hookId: Hook['id']): Hook | null => {
        return tree.hooks.find((hook: Hook): boolean => {
            return hook.id === hookId;
        }) ?? null;
    };

    const getHooksByIds = (tree: T, hooksIds: Hook['id'][]): Hook[] => {
        return tree.hooks.filter((hook: Hook): boolean => {
            return hooksIds.includes(hook.id);
        }).sort((hookA: Hook, hookB: Hook): number => {
            return hooksIds.indexOf(hookA.id) - hooksIds.indexOf(hookB.id);
        });
    };

    const getPageHook = (tree: T, pageId: Page['id']): Hook | null => {
        return tree.hooks.find((hook: Hook): boolean => {
            return !hook.componentId && hook.pageId === pageId;
        }) ?? null;
    };

    const getPagesHooks = (tree: T): Hook[] => {
        return tree.hooks.filter((hook: Hook): boolean => {
            return !hook.componentId;
        });
    };

    const getComponentHook = (tree: T, componentId: Component['id']): Hook | null => {
        return tree.hooks.find((hook: Hook): boolean => {
            return hook.componentId === componentId;
        }) ?? null;
    };

    const getComponentsHooks = (tree: T, componentsIds: Component['id'][]): Hook[] => {
        return tree.hooks.filter((hook: Hook): boolean => {
            return !!hook.componentId && componentsIds.includes(hook.componentId);
        });
    };

    const getParentHook = (tree: T, componentId: Component['id']): Hook | null => {
        return tree.hooks.find((hook: Hook): boolean => {
            return hook.childComponentsIds.includes(componentId);
        }) ?? null;
    };

    const getRootHook = (tree: T, componentId: Component['id']): Hook | null => {
        let rootHook: Hook | null = getParentHook(tree, componentId);
        while (rootHook && rootHook.componentId) {
            rootHook = getParentHook(tree, rootHook.componentId);
        }
        return rootHook;
    };

    const getNestedHooks = (tree: T, componentId: Component['id']): Hook[] => {
        const componentHook = getComponentHook(tree, componentId);
        if (!componentHook) {
            return [];
        }
        return [
            componentHook,
            ...componentHook.childComponentsIds.reduce((hooks: Hook[], childComponentId: Component['id']): Hook[] => {
                return [...hooks, ...getNestedHooks(tree, childComponentId)];
            }, [])
        ];
    };

    return {
        getHooks,
        getHookById,
        getHooksByIds,
        getPageHook,
        getPagesHooks,
        getComponentHook,
        getComponentsHooks,
        getRootHook,
        getParentHook,
        getNestedHooks
    };

}