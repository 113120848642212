import {FileTypeOptions, WithContents} from '@webaker/package-file';
import {IMAGE_FILE_EXTENSIONS, IMAGE_FILE_NAME, IMAGE_FILE_TYPE, ImageFile} from '../image-file';

export interface ImageFileClientOptions extends FileTypeOptions<ImageFile> {

}

export function createImageFileClientOptions(): ImageFileClientOptions {

    const processImage = async (imageFile: WithContents<ImageFile>): Promise<void> => {
        const dimensions = await getImageDimensions(imageFile);
        imageFile.width = dimensions.width;
        imageFile.height = dimensions.height;
    };

    const getImageDimensions = (imageFile: WithContents<ImageFile>): Promise<Pick<ImageFile, 'width' | 'height'>> => {
        return new Promise(resolve => {
            const image = document.createElement('img');
            image.src = imageFile.url ?? '';
            image.addEventListener('load', () => {
                resolve({
                    width: image.width,
                    height: image.height
                });
            });
        });
    };

    return {
        type: IMAGE_FILE_TYPE,
        name: IMAGE_FILE_NAME,
        extensions: IMAGE_FILE_EXTENSIONS,
        process: processImage
    };

}