import {JSONEncoder} from '@webaker/package-utils';
import {MultiState} from '../multi-state';
import {decodeHTMLEntities, STATE_ELEMENT_ID} from '../state-injector';
import {StoreRegistry} from '../store-registry';

export interface StateInjector {
    injectState: () => Promise<void>;
}

export interface StateInjectorDeps {
    jsonEncoder?: JSONEncoder;
    storeRegistry: StoreRegistry;
}

export interface StateInjectorConfig {
    encodeState: boolean;
}

export const STATE_INJECTOR_SOURCE = 'STATE_INJECTOR';

export function createStateInjector({jsonEncoder, storeRegistry}: StateInjectorDeps, {encodeState}: StateInjectorConfig): StateInjector {

    const injectState = async (): Promise<void> => {
        let stateFromJSON = getStateFromJSON();
        if (stateFromJSON) {
            storeRegistry.setGlobalState(stateFromJSON, STATE_INJECTOR_SOURCE);
        }
    };

    const getStateFromJSON = (): MultiState | null => {
        const script = document.getElementById(STATE_ELEMENT_ID);
        const stateJson = script ? decodeHTMLEntities(script.innerText.trim()) : null;
        return stateJson ? ((encodeState ? jsonEncoder : null) ?? JSON).parse(stateJson) : null;
    };

    return {
        injectState
    };

}