export interface ChunksConfig {
    vendorsChunkName: string;
    appChunkName: string;
    editorChunkName: string;
}

export function getChunksConfig(): ChunksConfig {

    const vendorsChunkName = 'vendors';
    const appChunkName = 'app';
    const editorChunkName = 'editor';

    return {
        vendorsChunkName,
        appChunkName,
        editorChunkName
    };

}