import {escapeRegExp} from '@webaker/package-utils';
import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export type EmailRuleOptions = {
    domains?: string[];
};

export function isEmail<S extends string = string>({domains}: EmailRuleOptions = {}): ValidationRule<S> {
    return (item: unknown): ValidationError[] => {
        const name = '(([^<>()\\[\\].,;:\\s@"]+(\\.[^<>()\\[\\].,;:\\s@"]+)*)|(".+"))';
        const domain = domains
            ? '(' + domains.map(escapeRegExp).join('|').replace(/\\\*\\\./g, '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)*') + ')'
            : '((([a-z\\d]([a-z\\d-]*[a-z\\d])+)\\.)*[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))';
        const regExp = new RegExp('^' + name + '@' + domain + '$', 'i');
        if (typeof item !== 'string' || item.match(regExp) === null) {
            return [
                {message: `Value has to be valid email address` + (domains ? ` from ${domains.join(' / ')} domain` : '')}
            ];
        }
        return [];
    };
}