import {AppExtension} from '@webaker/app';
import {ContainerExtension} from '@webaker/extension-container/boot';
import {GoogleMapExtension} from '@webaker/extension-google-map/boot';
import {GridExtension} from '@webaker/extension-grid/boot';
import {HTMLCodeExtension} from '@webaker/extension-html-code/boot';
import {ImageExtension} from '@webaker/extension-image/boot';
import {JSCodeExtension} from '@webaker/extension-js-code/boot';
import {LinkExtension} from '@webaker/extension-link/boot';
import {MdIconExtension} from '@webaker/extension-md-icon/boot';
import {RichTextExtension} from '@webaker/extension-rich-text/boot';
import {RowAndColumnExtension} from '@webaker/extension-row-and-column/boot';
import {SchemaExtension} from '@webaker/extension-schema/boot';
import {SEOExtension} from '@webaker/extension-seo/boot';
import {SiIconExtension} from '@webaker/extension-si-icon/boot';
import {TextExtension} from '@webaker/extension-text/boot';
import {YoutubeExtension} from '@webaker/extension-youtube/boot';
import {ValueOf} from '@webaker/package-utils';

export type ExtensionsClientAppProvider =
    Awaited<
        ReturnType<
            NonNullable<
                ValueOf<
                    ReturnType<
                        typeof getExtensionsClientAppProvidersMap
                    >
                >
            >
        >
    >;

export async function getExtensionsClientAppProviders(extensions: AppExtension[]): Promise<ExtensionsClientAppProvider[]> {
    const providersMap = getExtensionsClientAppProvidersMap();
    return await Promise.all(
        extensions.map(({name}) => {
            return providersMap[name as keyof typeof providersMap]();
        })
    );
}

export function getExtensionsClientAppProvidersMap() {
    return {
        [ContainerExtension.name]: (async () => (await import('@webaker/extension-container/client')).createContainerClientProvider()),
        [GoogleMapExtension.name]: (async () => (await import('@webaker/extension-google-map/client')).createGoogleMapClientProvider()),
        [GridExtension.name]: (async () => (await import('@webaker/extension-grid/client')).createGridClientProvider()),
        [HTMLCodeExtension.name]: (async () => (await import('@webaker/extension-html-code/client')).createHTMLCodeClientProvider()),
        [ImageExtension.name]: (async () => (await import('@webaker/extension-image/client')).createImageClientProvider()),
        [JSCodeExtension.name]: (async () => (await import('@webaker/extension-js-code/client')).createJSCodeClientProvider()),
        [LinkExtension.name]: (async () => (await import('@webaker/extension-link/client')).createLinkClientProvider()),
        [MdIconExtension.name]: (async () => (await import('@webaker/extension-md-icon/client')).createMdIconClientProvider()),
        [RichTextExtension.name]: (async () => (await import('@webaker/extension-rich-text/client')).createRichTextClientProvider()),
        [RowAndColumnExtension.name]: (async () => (await import('@webaker/extension-row-and-column/client')).createRowAndColumnClientProvider()),
        [SchemaExtension.name]: (async () => (await import('@webaker/extension-schema/client')).createSchemaClientProvider()),
        [SEOExtension.name]: (async () => (await import('@webaker/extension-seo/client')).createSEOClientProvider()),
        [SiIconExtension.name]: (async () => (await import('@webaker/extension-si-icon/client')).createSiIconClientProvider()),
        [TextExtension.name]: (async () => (await import('@webaker/extension-text/client')).createTextClientProvider()),
        [YoutubeExtension.name]: (async () => (await import('@webaker/extension-youtube/client')).createYoutubeClientProvider())
    } as const;
}