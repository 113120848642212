import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SideContentClassName = 'sideContent';

export interface SideContentCSSProps extends CSSThemeProps {

}

export function SideContentCSS({}: SideContentCSSProps) {
    return css<SideContentClassName>`

        .sideContent {
            width: fit-content;
            max-width: 100%;
            flex: 1;
        }

    `;
}