export interface FileInUseErrorDetails {
    fileUsages: string[];
}

export class FileInUseError extends Error {

    name: string = 'FILE_IN_USE';

    details: FileInUseErrorDetails;

    constructor(message: string, details: FileInUseErrorDetails) {
        super(message);
        this.details = details;
    }

}