import {APP_ELEMENT_ID, EDITOR_ELEMENT_ID} from '../app-config';

export function getAppElement(): HTMLElement {
    const element = document.getElementById(APP_ELEMENT_ID);
    if (!element) {
        throw new Error(`Element #${APP_ELEMENT_ID} not found`);
    }
    return element;
}

export function isEditorAvailable(): boolean {
    return document.getElementById(EDITOR_ELEMENT_ID) !== null;
}