export interface GeneralErrorDetails {

}

export class GeneralError extends Error {

    name = 'GENERAL';

    details: GeneralErrorDetails;

    constructor(message: string = 'Unknown error', details: GeneralErrorDetails = {}) {
        super(message);
        this.details = details;
    }

}