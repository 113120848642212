import {isBoolean, isEnum, isNumberWithUnit, isObject, ValidationRule} from '@webaker/package-validation';
import {FlexBox} from './flex-box';

export type FlexBoxValidationRule = ValidationRule<FlexBox>;

export interface FlexBoxValidationRuleDeps {

}

export function createFlexBoxValidationRule({}: FlexBoxValidationRuleDeps = {}): FlexBoxValidationRule {
    return isObject<FlexBox>({
        properties: {
            direction: isEnum({
                values: ['row', 'column', 'rowReverse', 'columnReverse']
            }),
            align: isEnum({
                values: ['start', 'end', 'center', 'stretch']
            }),
            justify: isEnum({
                values: ['start', 'end', 'center', 'stretch']
            }),
            gap: isNumberWithUnit({
                min: 0
            }),
            wrap: isBoolean()
        }
    });
}