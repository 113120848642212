import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type TreeClassName = 'tree';

export interface TreeCSSProps extends CSSThemeProps {

}

export function TreeCSS({}: TreeCSSProps) {
    return css<TreeClassName>`

        .tree {
            width: fit-content;
            min-width: 100%;
        }

    `;
}