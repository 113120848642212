import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type HeaderClassName = 'header';

export interface HeaderCSSProps extends CSSThemeProps {

}

export function HeaderCSS({theme}: HeaderCSSProps) {
    return css<HeaderClassName>`

        .header {
            font-size: ${theme.fontSize.large};
            font-weight: bold;
            margin: ${theme.spacing.large} 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    `;
}