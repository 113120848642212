import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {Hook} from './hook';

export interface HookFactory {
    createHook: (data: Partial<Hook>) => Hook;
}

export interface HookFactoryDeps {
    idGenerator: IdGenerator;
}

export function createHookFactory({idGenerator}: HookFactoryDeps): HookFactory {

    const createHook = (data: Partial<Hook>): Hook => {
        return omitUndefined({
            id: data.id ?? idGenerator.generateId(),
            pageId: data.pageId ?? undefined,
            componentId: data.componentId ?? undefined,
            childComponentsIds: data.childComponentsIds ?? []
        });
    };

    return {
        createHook
    };

}