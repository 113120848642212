import {createContext, ReactNode} from 'react';
import {DependencyContainer} from './dependency-container';

export interface DependencyContext {
    container: DependencyContainer<any>;
}

export const DependencyContext = createContext<DependencyContext | null>(null);

export interface DependencyContextProviderProps extends DependencyContext {
    children: ReactNode;
}

export function DependencyContextProvider({children, ...context}: DependencyContextProviderProps) {
    return (
        <DependencyContext.Provider value={context}>
            {children}
        </DependencyContext.Provider>
    );
}