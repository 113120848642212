import {FlexBoxFactory} from '@webaker/package-flex-box';
import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {Component} from './component';
import {ComponentRegistry} from './component-registry';
import {OmitComponent, PartialComponent} from './component-types';

export type ComponentFactoryFunction<C extends Component = Component> = (data: PartialComponent<C>) => OmitComponent<C>;

export interface ComponentFactory {
    registerFactory: <C extends Component = Component>(factoryFunction: ComponentFactoryFunction<C>) => void;
    registerFactoryByType: <C extends Component = Component>(type: C['type'], factoryFunction: ComponentFactoryFunction<C>) => void;
    createComponent: <C extends Component = Component>(data: PartialComponent<C>) => C;
}

export interface ComponentFactoryDeps {
    componentRegistry: ComponentRegistry;
    flexBoxFactory: FlexBoxFactory;
    idGenerator: IdGenerator;
}

export function createComponentFactory({
    componentRegistry,
    flexBoxFactory,
    idGenerator
}: ComponentFactoryDeps): ComponentFactory {

    const factoriesSet: Set<ComponentFactoryFunction<any>> = new Set();
    const factoriesByTypeMap: Map<string, ComponentFactoryFunction<any>> = new Map();

    const registerFactory = <C extends Component = Component>(factoryFunction: ComponentFactoryFunction<C>): void => {
        factoriesSet.add(factoryFunction);
    };

    const registerFactoryByType = <C extends Component = Component>(type: C['type'], factoryFunction: ComponentFactoryFunction<C>): void => {
        factoriesByTypeMap.set(type, factoryFunction);
    };

    const createComponent = <C extends Component = Component>(data: PartialComponent<C>): C => {
        const baseData = getBaseData(data);
        const slotData = getSlotData(data);
        const extensionsData = getExtensionsData(data);
        const typeData = getTypeData(data);
        return omitUndefined({
            ...baseData,
            ...slotData,
            ...extensionsData,
            ...typeData
        }) as C;
    };

    const getBaseData = (data: PartialComponent): Component => {
        return {
            id: data.id ?? idGenerator.generateId(),
            type: data.type,
            name: data.name ?? undefined,
            share: data.share ?? undefined
        };
    };

    const getSlotData = (data: PartialComponent): Pick<Component, 'slot'> => {
        const componentOptions = componentRegistry.getComponentOptions(data.type);
        const slot = componentOptions.slot;
        if (slot) {
            return {
                slot: data.slot ?? flexBoxFactory.createFlexBox(slot === true ? {} : slot)
            };
        }
        return {};
    };

    const getExtensionsData = <C extends Component = Component>(data: PartialComponent<C>): OmitComponent<C> => {
        return Array.from(factoriesSet.values()).reduce((
            result: Partial<C>,
            factoryFunction: ComponentFactoryFunction<C>
        ): Partial<C> => {
            return {...result, ...factoryFunction(data)};
        }, {}) as OmitComponent<C>;
    };

    const getTypeData = <C extends Component = Component>(data: PartialComponent<C>): OmitComponent<C> => {
        return factoriesByTypeMap.get(data.type)?.(data) ?? {};
    };

    return {
        registerFactory,
        registerFactoryByType,
        createComponent
    };

}