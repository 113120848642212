import {Dependencies} from './dependencies';
import {DependencyDecorator} from './dependency-decorator';
import {DependencyFactory} from './dependency-factory';
import {Key} from './dependency-key';
import {DependencyScope} from './dependency-scope';
import {DependencyStorage} from './dependency-storage';

export interface DependencyRegistry<D extends Dependencies = {}> {
    register: <N extends Key<D>>(name: N, factory: DependencyFactory<D, N>) => void;
    registerTransient: <N extends Key<D>>(name: N, factory: DependencyFactory<D, N>) => void;
    registerDecorator: <N extends Key<D>>(name: N, decorator: DependencyDecorator<D, N>) => void;
    scope: <SD extends Dependencies = D>(scope: DependencyScope) => DependencyRegistry<SD>;
}

export interface DependencyRegistryOptions<D extends Dependencies = {}> {
    dependencyStorage: DependencyStorage<D>;
}

export function createDependencyRegistry<D extends Dependencies = {}>({dependencyStorage}: DependencyRegistryOptions<D>): DependencyRegistry<D> {

    const scopedRegistriesMap: WeakMap<DependencyScope, DependencyRegistry> = new WeakMap();

    const register = <N extends Key<D>>(name: N, factory: DependencyFactory<D, N>): void => {
        dependencyStorage.setFactory(name, factory);
        dependencyStorage.setTransiency(name, false);
    };

    const registerTransient = <N extends Key<D>>(name: N, factory: DependencyFactory<D, N>): void => {
        dependencyStorage.setFactory(name, factory);
        dependencyStorage.setTransiency(name, true);
    };

    const registerDecorator = <N extends Key<D>>(name: N, decorator: DependencyDecorator<D, N>): void => {
        dependencyStorage.setDecorator(name, decorator);
    };

    const scope = <SD extends Dependencies = D>(scope: DependencyScope): DependencyRegistry<SD> => {
        if (scopedRegistriesMap.has(scope)) {
            return scopedRegistriesMap.get(scope) as DependencyRegistry<SD>;
        }
        const scopedRegistry: DependencyRegistry<SD> = createDependencyRegistry<SD>({
            dependencyStorage: dependencyStorage.scope(scope)
        });
        scopedRegistriesMap.set(scope, scopedRegistry);
        return scopedRegistry;
    };

    return {
        register,
        registerTransient,
        registerDecorator,
        scope
    };

}