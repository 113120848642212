import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type ResetClassName = never;

export interface ResetCSSProps extends CSSThemeProps {

}

export function ResetCSS({theme}: ResetCSSProps) {
    return css<ResetClassName>`

        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
            border: 0;
            margin: 0;
            padding: 0;
            vertical-align: baseline;
        }

        article, aside, details, figcaption, figure,
        footer, header, hgroup, menu, nav, section {
            display: block;
        }

        input, textarea, button {
            font: inherit;
        }

        body {
            font-size: ${theme.fontSize.medium};
            font-family: sans-serif;
            line-height: 1;
            min-height: 100vh;
            margin: 0;
            padding: 0;
        }

        ol, ul {
            list-style: none;
        }

        blockquote, q {
            quotes: none;
        }

        blockquote:before, blockquote:after, q:before, q:after {
            content: none;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
        }

        *, *:before, *:after {
            box-sizing: border-box;
        }

        *::selection {
            background: ${color(theme.color.gray, {alpha: 0.2})};
        }

        a {
            text-decoration: none;
            color: inherit;
        }

    `;
}