import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {File} from './file';
import {PartialFile} from './file-types';

export interface FileFactory {
    createFile: <F extends File = File>(data?: PartialFile<F>) => F;
}

export interface FileFactoryDeps {
    idGenerator: IdGenerator;
}

export function createFileFactory({idGenerator}: FileFactoryDeps): FileFactory {

    const createFile = <F extends File = File>(data: PartialFile<F> = {}): F => {
        return omitUndefined({
            id: data.id ?? idGenerator.generateId(),
            parentDirectoryId: data.parentDirectoryId ?? undefined,
            name: data.name ?? '',
            size: data.size ?? 0,
            public: data.public ?? false,
            alias: data.alias ?? undefined,
            url: data.url ?? undefined,
            contents: data.contents ?? undefined
        }) as F;
    };

    return {
        createFile
    };

}