import {ApiClientFactory} from '@webaker/package-api/client';
import {SETTINGS_API_NAME} from '../settings-api';

export interface SettingsClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createSettingsClientApi({apiClientFactory}: SettingsClientApiDeps) {

    return apiClientFactory.createApi(SETTINGS_API_NAME, {
        getSettingsMap: [],
        saveSettingsMap: ['map']
    });

}