import {Dependencies} from './dependencies';
import {DependencyDecorator} from './dependency-decorator';
import {DependencyFactory} from './dependency-factory';
import {Key} from './dependency-key';
import {DependencyResolver} from './dependency-resolver';
import {DependencyScope} from './dependency-scope';

export interface DependencyStorage<D extends Dependencies = {}> {
    getFactory: <N extends Key<D>>(name: N) => DependencyFactory<D, N> | null;
    getFactoriesNames: () => Key<D>[];
    getInstancesCount: <N extends Key<D>>(name: N) => number;
    setFactory: <N extends Key<D>>(name: N, factory: DependencyFactory<D, N>) => void;
    setTransiency: <N extends Key<D>>(name: N, transient: boolean) => void;
    isTransient: <N extends Key<D>>(name: N) => boolean;
    setDecorator: <N extends Key<D>>(name: N, decorator: DependencyDecorator<D, N>) => void;
    scope: <SD extends Dependencies = D>(scope: DependencyScope) => DependencyStorage<SD>;
}

export interface DependencyStorageOptions<D extends Dependencies = {}> {
    parentStorage?: DependencyStorage<Partial<D>>;
}

export function createDependencyStorage<D extends Dependencies = {}>({parentStorage}: DependencyStorageOptions<D> = {}): DependencyStorage<D> {

    const factoriesMap: Map<string, DependencyFactory<any, any>> = new Map();
    const decoratorsMap: Map<string, DependencyDecorator<any, any>[]> = new Map();
    const transiencyMap: Map<string, boolean> = new Map();
    const instancesMap: Map<string, any> = new Map();
    const instancesCountsMap: Map<string, number> = new Map();
    const scopedStoragesMap: WeakMap<object, DependencyStorage<any>> = new WeakMap();

    const getFactory = <N extends Key<D>>(name: N): DependencyFactory<D, N> | null => {
        const factory = factoriesMap.get(name) ?? parentStorage?.getFactory(name) ?? null;
        if (!factory) {
            return null;
        }
        if (!hasOwnFactory(name)) {
            return factory;
        }
        return (resolver: DependencyResolver<D>) => {
            const cachedInstance = getCachedInstance(name);
            if (cachedInstance !== null) {
                return cachedInstance;
            }
            const instance = factory(resolver);
            const decoratedInstance = decorateInstance(name, instance, resolver);
            cacheInstance(name, decoratedInstance);
            incrementInstancesCount(name);
            return decoratedInstance;
        };
    };

    const getFactoriesNames = (): Key<D>[] => {
        return Array.from(
            new Set([
                ...parentStorage?.getFactoriesNames() || [],
                ...Array.from(factoriesMap.keys())
            ])
        );
    };

    const getInstancesCount = <N extends Key<D>>(name: N): number => {
        if (!hasOwnFactory(name)) {
            return parentStorage?.getInstancesCount(name) ?? 0;
        }
        return instancesCountsMap.get(name) ?? 0;
    };

    const setFactory = <N extends Key<D>>(name: N, factory: DependencyFactory<D, N>): void => {
        factoriesMap.set(name, factory);
        instancesMap.delete(name);
        instancesCountsMap.delete(name);
    };

    const setTransiency = <N extends Key<D>>(name: N, transient: boolean): void => {
        transiencyMap.set(name, transient);
    };

    const isTransient = <N extends Key<D>>(name: N): boolean => {
        return (transiencyMap.has(name) ? transiencyMap.get(name) : parentStorage?.isTransient(name)) ?? false;
    };

    const setDecorator = <N extends Key<D>>(name: N, decorator: DependencyDecorator<D, N>): void => {
        if (!decoratorsMap.has(name)) {
            decoratorsMap.set(name, []);
        }
        decoratorsMap.get(name)?.push(decorator);
        instancesMap.delete(name);
        instancesCountsMap.delete(name);
    };

    const scope = <SD extends Dependencies = D>(scope: DependencyScope): DependencyStorage<SD> => {
        if (scopedStoragesMap.has(scope)) {
            return scopedStoragesMap.get(scope)!;
        }
        const scopedStorage: DependencyStorage<SD> = createDependencyStorage<SD>({
            parentStorage: currentStorage as any
        });
        scopedStoragesMap.set(scope, scopedStorage);
        return scopedStorage;
    };

    const hasOwnFactory = <N extends Key<D>>(name: N): boolean => {
        const ownFactory = factoriesMap.get(name);
        const ownDecorators = decoratorsMap.get(name);
        return !!(ownFactory || ownDecorators?.length);
    };

    const decorateInstance = <N extends Key<D>>(name: N, instance: D[N], resolver: DependencyResolver<D>): D[N] => {
        return decoratorsMap.get(name)?.reduce((instance: D[N], decorator: DependencyDecorator<D, N>) => {
            return decorator(instance, resolver);
        }, instance) ?? instance;
    };

    const cacheInstance = <N extends Key<D>>(name: N, instance: D[N]): void => {
        if (transiencyMap.has(name) && !transiencyMap.get(name)) {
            instancesMap.set(name, instance);
        }
    };

    const getCachedInstance = <N extends Key<D>>(name: N): D[N] | null => {
        return instancesMap.get(name) ?? null;
    };

    const incrementInstancesCount = <N extends Key<D>>(name: N): void => {
        instancesCountsMap.set(name, (instancesCountsMap.get(name) ?? 0) + 1);
    };

    const currentStorage: DependencyStorage<D> = {
        getFactory,
        getFactoriesNames,
        getInstancesCount,
        setFactory,
        setTransiency,
        isTransient,
        setDecorator,
        scope
    };

    return currentStorage;

}