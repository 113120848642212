import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode, useContext} from 'react';
import {SideMenuContext} from './side-menu';
import {SideMenuItemCSS, SideMenuItemPrivateCSS} from './side-menu-item-css';

export interface SideMenuItemProps {
    icon: ReactNode;
    label?: string;
    active?: boolean;
    color?: CSSThemeColor;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
    children?: ReactNode;
}

export function SideMenuItem({
    icon,
    label,
    active,
    color = 'dark',
    disabled,
    onClick,
    className,
    children
}: SideMenuItemProps) {

    const css = useThemedCSSBundle([
        SideMenuItemCSS,
        SideMenuItemPrivateCSS
    ], {
        color
    });

    const align = useContext(SideMenuContext).align;

    return (
        <button onClick={disabled ? undefined : onClick}
                tabIndex={0}
                className={mergeClassNames(
                    css['sideMenuItem'],
                    css[`align-${align}`],
                    active && css['is-active'],
                    disabled && css['is-disabled'],
                    className
                )}>
            <span className={css['icon']}>
                {icon}
            </span>
            {label && (
                <span className={css['label']}>
                    {label}
                </span>
            )}
            {children}
        </button>
    );

}