import {useThemedCSS} from '@webaker/package-css-theme';
import {Edge, mergeClassNames} from '@webaker/package-utils';
import {ReactNode, useCallback} from 'react';
import {EdgeBoxCSS} from './edge-box-css';

export interface EdgeBoxPropsProps {
    children: (props: EdgeBoxEvent) => ReactNode;
    center?: ReactNode;
    label?: (props: EdgeBoxEvent) => string | null;
    onSelect?: (event: EdgeBoxSelectEvent) => void;
    className?: string;
}

export interface EdgeBoxEvent {
    edge: Edge;
    oppositeEdge: Edge;
}

export interface EdgeBoxSelectEvent extends EdgeBoxEvent {

}

export const TOP_EVENT: EdgeBoxEvent = {
    edge: 'top',
    oppositeEdge: 'bottom'
};

export const RIGHT_EVENT: EdgeBoxEvent = {
    edge: 'right',
    oppositeEdge: 'left'
};

export const BOTTOM_EVENT: EdgeBoxEvent = {
    edge: 'bottom',
    oppositeEdge: 'top'
};

export const LEFT_EVENT: EdgeBoxEvent = {
    edge: 'left',
    oppositeEdge: 'right'
};

export function EdgeBox({
    children,
    center,
    onSelect,
    label,
    className
}: EdgeBoxPropsProps) {

    const css = useThemedCSS(EdgeBoxCSS, {});

    const selectTop = useCallback(() => {
        onSelect?.(TOP_EVENT);
    }, [onSelect]);

    const selectRight = useCallback(() => {
        onSelect?.(RIGHT_EVENT);
    }, [onSelect]);

    const selectBottom = useCallback(() => {
        onSelect?.(BOTTOM_EVENT);
    }, [onSelect]);

    const selectLeft = useCallback(() => {
        onSelect?.(LEFT_EVENT);
    }, [onSelect]);

    const topChildren = children(TOP_EVENT);
    const rightChildren = children(RIGHT_EVENT);
    const bottomChildren = children(BOTTOM_EVENT);
    const leftChildren = children(LEFT_EVENT);

    return (
        <div className={mergeClassNames(css['edgeBox'], className)}>
            <div className={css['topRow']}>
                <div className={css['top']}>
                    {topChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectTop : undefined}>
                            {label?.(TOP_EVENT) ?? 'Top'}
                        </div>
                    )}
                    {topChildren}
                </div>
            </div>
            <div className={css['middleRow']}>
                <div className={css['left']}>
                    {leftChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectLeft : undefined}>
                            {label?.(LEFT_EVENT) ?? 'Left'}
                        </div>
                    )}
                    {leftChildren}
                </div>
                <div className={css['center']}>
                    {center}
                </div>
                <div className={css['right']}>
                    {rightChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectRight : undefined}>
                            {label?.(RIGHT_EVENT) ?? 'Right'}
                        </div>
                    )}
                    {rightChildren}
                </div>
            </div>
            <div className={css['bottomRow']}>
                <div className={css['bottom']}>
                    {bottomChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectBottom : undefined}>
                            {label?.(BOTTOM_EVENT) ?? 'Bottom'}
                        </div>
                    )}
                    {bottomChildren}
                </div>
            </div>
        </div>
    );

}