import {Provider} from '@webaker/package-deps';
import {createFlexBoxFactory, FlexBoxFactory} from '../flex-box-factory';
import {createFlexBoxValidationRule, FlexBoxValidationRule} from '../flex-box-validation-rule';

export interface ProvidedFlexBoxClientDeps {
    flexBoxFactory: FlexBoxFactory;
    flexBoxValidationRule: FlexBoxValidationRule;
}

export interface RequiredFlexBoxClientDeps {

}

export interface FlexBoxClientDeps extends ProvidedFlexBoxClientDeps, RequiredFlexBoxClientDeps {

}

export interface FlexBoxClientConfig {

}

export type FlexBoxClientProvider = Provider<FlexBoxClientDeps, FlexBoxClientConfig>;

export function createFlexBoxClientProvider(): FlexBoxClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('flexBoxFactory', () => {
                return createFlexBoxFactory();
            });
            register('flexBoxValidationRule', () => {
                return createFlexBoxValidationRule();
            });
        }

    };
}