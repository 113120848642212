import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type FileListItemClassName = 'fileListItem' | 'alias' | 'accessIcon' | 'usageIcon' | 'is-public';

export interface FileListItemCSSProps extends CSSThemeProps {

}

export function FileListItemCSS({theme}: FileListItemCSSProps) {
    return css<FileListItemClassName>`

        .fileListItem {

        }

        .alias {
            margin-left: ${theme.spacing.medium};
            opacity: 0.5;
        }

        .accessIcon {
            cursor: pointer;
            color: ${theme.color.danger};

            &.is-public {
                color: ${theme.color.dark};
            }
        }

        .usageIcon {
            cursor: pointer;
            color: ${theme.color.dark};
        }

    `;
}