import {ValidationError} from '../validation-error';
import {ValidationModifier} from '../validation-modifier';
import {ValidationRule} from '../validation-rule';

export function nullable<T = unknown>(rule: ValidationRule<T>): ValidationModifier<T> {
    return (item: unknown): ValidationError[] => {
        if (item === null) {
            return [];
        }
        return rule(item);
    };
}