import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {mdIconsWoff2} from './assets/md-icons';
import {MdIconWeight} from './md-icon';

export type MdIconClassName = 'mdIcon';

export function MdIconCSS({}: CSSThemeProps) {
    // noinspection CssNoGenericFontName
    return css<MdIconClassName>`

        @font-face {
            font-family: 'Material Symbols Outlined';
            src: url(${mdIconsWoff2}) format('woff2');
            font-style: normal;
            font-weight: 100 700;
            font-display: block;
        }

        .mdIcon {
            font-family: 'Material Symbols Outlined';
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: inherit;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }

    `;
}

export interface MdIconPrivateCSSProps extends CSSThemeProps {
    size: string | null;
    color: CSSThemeColor | null;
    fill: boolean;
    weight: MdIconWeight;
}

export function MdIconPrivateCSS({theme, size, color, fill, weight}: MdIconPrivateCSSProps) {
    return css<MdIconClassName>`

        .mdIcon {
            ${size ? `font-size: ${size};` : ``}
            ${color ? `color: ${theme.color[color]};` : ``}
            font-variation-settings: 'FILL' ${fill ? 1 : 0}, 'wght' ${weight === 'thin' ? 100 : weight === 'bold' ? 700 : 400}, 'GRAD' 0, 'opsz' 48;
        }

    `;
}