import {Event, EventBus} from '@webaker/package-event-bus';
import {User} from './user';
import {UserApi} from './user-api';
import {UserStore} from './user-store';

export interface UserManager {
    loginUser: (email: User['email'], password: NonNullable<User['password']>) => Promise<void>;
    logoutUser: () => Promise<void>;
}

export const LOGIN_USER_EVENT = 'loginUser';
export const LOGOUT_USER_EVENT = 'logoutUser';

export interface LoginUserEvent extends Event {
    name: typeof LOGIN_USER_EVENT;
    user: User;
}

export interface LogoutUserEvent extends Event {
    name: typeof LOGOUT_USER_EVENT;
}

export interface UserManagerDeps {
    eventBus: EventBus;
    userApi: UserApi;
    userStore: UserStore;
}

export function createUserManager({eventBus, userApi, userStore}: UserManagerDeps): UserManager {

    const loginUser = async (email: User['email'], password: NonNullable<User['password']>): Promise<void> => {
        const loggedUser = await userApi.loginUserByCredentials(email, password);
        if (loggedUser) {
            await eventBus.dispatchEvent<LoginUserEvent>({
                name: LOGIN_USER_EVENT,
                user: loggedUser
            });
            userStore.setLoggedUser(loggedUser);
        }
    };

    const logoutUser = async (): Promise<void> => {
        await userApi.logoutUser();
        await eventBus.dispatchEvent<LogoutUserEvent>({
            name: LOGOUT_USER_EVENT
        });
        userStore.setLoggedUser(null);
    };

    return {
        loginUser,
        logoutUser
    };

}