export interface PageTypeNotFoundErrorDetails {
    type: string;
}

export class PageTypeNotFoundError extends Error {

    name = 'PAGE_TYPE_NOT_FOUND';

    details: PageTypeNotFoundErrorDetails;

    constructor(message: string, details: PageTypeNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}