import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type PanelClassName = 'panel' | 'header' | 'title' | 'subtitle' | 'buttons' | 'content' | 'has-shadow';

export function PanelCSS({theme}: CSSThemeProps) {
    return css<PanelClassName>`

        .panel {
            width: 100%;
            padding: ${theme.spacing.large};

            .panel {
                padding: 0;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
            }
        }

        .has-shadow {
            background: linear-gradient(to bottom, ${color(theme.color.veryLight, {alpha: 0.7})}, transparent);
        }

        .panel:has(.title):not(:first-child) {
            border-top: 1px solid ${theme.color.veryLight};
        }

        .header {
            margin-bottom: ${theme.spacing.large};
            position: relative;
        }

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: ${theme.spacing.medium};
            width: 100%;
            height: ${theme.fontSize.large};
            font-size: ${theme.fontSize.large};
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .header:has(.button) .title {
            padding-right: ${theme.spacing.veryLarge};
        }

        .subtitle {
            color: ${theme.color.gray};
            font-weight: normal;
            margin-top: ${theme.spacing.medium};
        }

        .buttons {
            position: absolute;
            top: -${theme.spacing.medium};
            right: -${theme.spacing.medium};
            display: flex;
            font-size: ${theme.fontSize.verySmall};
        }

        .content {
            width: 100%;
        }

    `;
}

export interface PanelPrivateCSSProps extends CSSThemeProps {
    margin: CSSThemeSize;
}

export function PanelPrivateCSS({theme, margin}: PanelPrivateCSSProps) {
    return css<PanelClassName>`

        .panel {
            margin: ${theme.spacing[margin] ?? 0} 0;
        }

    `;
}