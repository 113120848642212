export type NumberWithUnit<S extends string = string> = `0` | `${number}${S}`;

type NumberUnitModifier = number | `${number}` | `${MathOperator}${number}`;
type MathOperator = '=' | '+' | '-' | '*' | '/';

const NUMBER_UNIT_REGEXP = /^(?<number>-?\d+(\.\d+)?)(?<unit>.*)$/;
const NUMBER_UNIT_MODIFIER_REGEXP = /^(?<operator>[=+\-*/])?(?<number>-?\d+(\.\d+)?)$/;

export function parseUnit(numberWithUnit: number | string | null): string | null {
    if (numberWithUnit === '' || numberWithUnit === null) {
        return null;
    }
    const unitMatch = numberWithUnit.toString().match(NUMBER_UNIT_REGEXP);
    if (!unitMatch || !unitMatch.groups) {
        return null;
    }
    return unitMatch.groups.unit || null;
}

export function stringifyNumber(number: number | null, unit: string | null): string | null {
    if (number === null) {
        return null;
    }
    if (unit === null) {
        return number.toString();
    }
    return number + unit;
}

export function adjustNumber<S extends string = string>(numberWithUnit: NumberWithUnit<S>, modifier: NumberUnitModifier): NumberWithUnit<S> {
    const unitMatch = numberWithUnit.toString().match(NUMBER_UNIT_REGEXP) as any;
    if (!unitMatch) {
        throw new Error(`Invalid number format [${numberWithUnit}]`);
    }
    const modifierMatch = modifier.toString().match(NUMBER_UNIT_MODIFIER_REGEXP) as any;
    if (!modifierMatch) {
        throw new Error(`Invalid number modifier [${modifier}]`);
    }
    const number: number = parseFloat(unitMatch.groups.number);
    const unit: string = unitMatch.groups.unit;
    const modifierOperator: MathOperator = modifierMatch.groups.operator ?? '=';
    const modifierNumber: number = parseFloat(modifierMatch.groups.number);
    if (modifierOperator === '=') {
        return modifierNumber + unit as NumberWithUnit<S>;
    } else if (modifierOperator === '+') {
        return (number + modifierNumber) + unit as NumberWithUnit<S>;
    } else if (modifierOperator === '-') {
        return (number - modifierNumber) + unit as NumberWithUnit<S>;
    } else if (modifierOperator === '*') {
        return (number * modifierNumber) + unit as NumberWithUnit<S>;
    } else if (modifierOperator === '/') {
        return (number / modifierNumber) + unit as NumberWithUnit<S>;
    }
    throw new Error(`Invalid number modifier operator [${modifierOperator}]`);
}