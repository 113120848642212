import {Component} from './component';
import {ComponentExtensionNotFoundError} from './component-extension-not-found-error';
import {ComponentExtensionOptions} from './component-extension-options';
import {ComponentOptions} from './component-options';
import {ComponentTypeNotFoundError} from './component-type-not-found-error';

export interface ComponentRegistry {

    registerComponent: <C extends Component = Component>(options: ComponentOptions<C>) => void;
    getAllComponentsTypes: () => Component['type'][];
    getAllComponentsOptions: () => ComponentOptions[];
    getComponentOptions: <C extends Component = Component>(type: C['type']) => ComponentOptions<C>;

    registerComponentExtension: <C extends Component = Component>(options: ComponentExtensionOptions<C>) => void;
    getAllComponentsExtensionsOptions: () => ComponentExtensionOptions[];
    getComponentExtensionOptions: <C extends Component = Component>(name: string) => ComponentExtensionOptions<C>;

}

export interface ComponentRegistryDeps {

}

export function createComponentRegistry({}: ComponentRegistryDeps = {}): ComponentRegistry {

    const componentsOptionsMap: Map<string, ComponentOptions<any>> = new Map();
    const componentsExtensionsOptionsMap: Map<string, ComponentExtensionOptions<any>> = new Map();

    const registerComponent = <C extends Component = Component>(options: ComponentOptions<C>): void => {
        componentsOptionsMap.set(options.type, options);
    };

    const getAllComponentsTypes = (): Component['type'][] => {
        return Array.from(componentsOptionsMap.keys());
    };

    const getComponentOptions = <C extends Component = Component>(type: C['type']): ComponentOptions<C> => {
        if (!componentsOptionsMap.has(type)) {
            throw new ComponentTypeNotFoundError(`Component type [${type}] not found`, {type});
        }
        return componentsOptionsMap.get(type)!;
    };

    const getAllComponentsOptions = (): ComponentOptions[] => {
        return Array.from(componentsOptionsMap.values());
    };

    const registerComponentExtension = <C extends Component = Component>(options: ComponentExtensionOptions<C>): void => {
        componentsExtensionsOptionsMap.set(options.name, options);
    };

    const getAllComponentsExtensionsOptions = (): ComponentExtensionOptions[] => {
        return Array.from(componentsExtensionsOptionsMap.values());
    };

    const getComponentExtensionOptions = <C extends Component = Component>(name: string): ComponentExtensionOptions<C> => {
        if (!componentsExtensionsOptionsMap.has(name)) {
            throw new ComponentExtensionNotFoundError(`Component extension [${name}] not found`, {name});
        }
        return componentsExtensionsOptionsMap.get(name)!;
    };

    return {
        registerComponent,
        getAllComponentsTypes,
        getAllComponentsOptions,
        getComponentOptions,
        registerComponentExtension,
        getAllComponentsExtensionsOptions,
        getComponentExtensionOptions
    };

}