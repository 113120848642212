import {DependencyContainer} from '../dependency-container';

const DEFAULT_LOG_TITLE = 'Dependencies';

export function logContainer(container: DependencyContainer<any>, title: string = DEFAULT_LOG_TITLE): void {
    const allNames = container.names();
    console.groupCollapsed(`${title} (${allNames.length})`);
    allNames.forEach((name: string) => {
        const count = container.count(name);
        console.log(`${name} (${count})`);
    });
    console.groupEnd();
}