import {UnionToIntersection} from '@webaker/package-utils';
import {Config} from './config';
import {Dependencies} from './dependencies';
import {DependencyContainer} from './dependency-container';
import {DependencyRegistry} from './dependency-registry';
import {DependencyResolver} from './dependency-resolver';

export type ProviderDependencies<P extends Provider<any, any> = Provider<any, any>> = UnionToIntersection<P extends Provider<infer D, any> ? D : never>;
export type ProviderConfig<P extends Provider<any, any> = Provider<any, any>> = UnionToIntersection<P extends Provider<any, infer C> ? C : never>;

export interface Provider<D extends Dependencies = {}, C extends Config = {}> {
    registerDependencies?: (registry: DependencyRegistry<D>, config: C) => Promise<void>;
    overrideDependencies?: (registry: DependencyRegistry<D>, config: C) => Promise<void>;
    registerServices?: (resolver: DependencyResolver<D>, config: C) => Promise<void>;
    runServices?: (resolver: DependencyResolver<D>, config: C) => Promise<void>;
    runMain?: (resolver: DependencyResolver<D>, config: C) => Promise<void>;
    handleError?: (error: unknown, resolver: DependencyResolver<D>, config: C) => Promise<boolean | void>;
}

export async function runProvider<D extends Dependencies = {}, C extends Config = {}>(
    provider: Provider<D, C>,
    container: DependencyContainer<D>,
    config: C
): Promise<void> {
    try {
        await provider.registerDependencies?.(container, config);
        await provider.overrideDependencies?.(container, config);
        await provider.registerServices?.(container, config);
        await provider.runServices?.(container, config);
        await provider.runMain?.(container, config);
    } catch (error) {
        const isErrorHandled = await provider.handleError?.(error, container, config) ?? false;
        if (!isErrorHandled) {
            throw error;
        }
    }
}

export function createBatchProvider<D extends Dependencies = {}, C extends Config = {}>(providers: Provider<Partial<D>, Partial<C>>[]): Provider<D> {

    const createBatchFunction = <N extends keyof Provider<D>>(name: N): Provider<D>[N] => {
        return async (...args: [any, any, any?]): Promise<any> => {
            return (await Promise.all(
                providers.map(async (provider: Provider<D>): Promise<any> => {
                    await provider[name]?.(...args);
                })
            )).some(Boolean);
        };
    };

    return {
        registerDependencies: createBatchFunction('registerDependencies'),
        overrideDependencies: createBatchFunction('overrideDependencies'),
        registerServices: createBatchFunction('registerServices'),
        runServices: createBatchFunction('runServices'),
        runMain: createBatchFunction('runMain'),
        handleError: createBatchFunction('handleError')
    };

}