import {Dependencies} from './dependencies';
import {Key} from './dependency-key';
import {createDependencyRegistry, DependencyRegistry} from './dependency-registry';
import {createDependencyResolver, DependencyResolver} from './dependency-resolver';
import {DependencyScope} from './dependency-scope';
import {createDependencyStorage, DependencyStorage} from './dependency-storage';

export interface DependencyContainer<D extends Dependencies = {}> extends DependencyRegistry<D>, DependencyResolver<D> {
    names: () => Key<D>[];
    scope: <SD extends Dependencies = D>(scope: DependencyScope) => DependencyContainer<SD>;
}

export interface DependencyContainerOptions<D extends Dependencies = {}> {
    dependencyStorage?: DependencyStorage<D>;
}

export function createContainer<D extends Dependencies = {}>({
    dependencyStorage = createDependencyStorage()
}: DependencyContainerOptions<D> = {}): DependencyContainer<D> {

    const scopedContainersMap = new WeakMap<DependencyScope, DependencyContainer<any>>();
    const registry = createDependencyRegistry({dependencyStorage});
    const resolver = createDependencyResolver({dependencyStorage});

    const names = (): Key<D>[] => {
        return dependencyStorage.getFactoriesNames().slice().sort();
    };

    const scope = <SD extends Dependencies = D>(scope: DependencyScope): DependencyContainer<SD> => {
        if (scopedContainersMap.has(scope)) {
            return scopedContainersMap.get(scope) as DependencyContainer<SD>;
        }
        const scopedDepsStorage: DependencyStorage<SD> = dependencyStorage.scope(scope);
        const scopedContainer = createContainer<SD>({dependencyStorage: scopedDepsStorage});
        scopedContainersMap.set(scope, scopedContainer);
        return scopedContainer;
    };

    return {
        ...registry,
        ...resolver,
        names,
        scope
    };

}