import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {IdGenerator} from '@webaker/package-utils';
import {PermissionApi} from '../permission-api';
import {createPermissionFactory, PermissionFactory} from '../permission-factory';
import {createPermissionClientApi} from './permission-client-api';

export interface ProvidedPermissionClientDeps {
    permissionApi: PermissionApi;
    permissionFactory: PermissionFactory;
}

export interface RequiredPermissionClientDeps {
    apiClientFactory: ApiClientFactory;
    idGenerator: IdGenerator;
}

export interface PermissionClientDeps extends ProvidedPermissionClientDeps, RequiredPermissionClientDeps {

}

export interface PermissionClientConfig {

}

export type PermissionClientProvider = Provider<PermissionClientDeps, PermissionClientConfig>;

export function createPermissionClientProvider(): PermissionClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('permissionApi', ({resolve}) => {
                return createPermissionClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
            register('permissionFactory', ({resolve}) => {
                return createPermissionFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
        }

    };
}