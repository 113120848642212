import {FlexAlign, FlexDirection, FlexJustify, FlexWrap} from './flex-box';

export function mapFlexDirectionToCSS(direction: FlexDirection): string {
    return {
        column: 'column',
        columnReverse: 'column-reverse',
        row: 'row',
        rowReverse: 'row-reverse'
    }[direction];
}

export function mapFlexAlignToCSS(align: FlexAlign): string {
    return {
        start: 'flex-start',
        end: 'flex-end',
        center: 'center',
        stretch: 'stretch'
    }[align];
}

export function mapFlexJustifyToCSS(justify: FlexJustify): string {
    return {
        start: 'flex-start',
        end: 'flex-end',
        center: 'center',
        stretch: 'stretch',
        spaceBetween: 'space-between',
        spaceEvenly: 'space-evenly'
    }[justify];
}

export function mapFlexWrapToCSS(wrap: FlexWrap): string {
    return wrap ? 'wrap' : 'nowrap';
}