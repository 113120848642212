import {AppExtension} from '@webaker/app';
import {AppClientProvider, createAppClientProvider} from '@webaker/app/client';
import {ProviderConfig, ProviderDependencies} from '@webaker/package-deps';
import {createBaseClientAppProvider} from './base-client-app-provider';
import {ExtensionsClientAppProvider, getExtensionsClientAppProviders} from './extensions-client-app-providers';
import {getModulesClientAppProviders, ModulesClientAppProvider} from './modules-client-app-providers';
import {getPackagesClientAppProviders, PackagesClientAppProvider} from './packages-client-app-provider';

export type BootClientAppProviders = AppClientProvider | ModulesClientAppProvider | PackagesClientAppProvider | ExtensionsClientAppProvider;
export type BootClientAppDeps = ProviderDependencies<BootClientAppProviders>;
export type BootClientAppConfig = ProviderConfig<BootClientAppProviders>;

export async function getBootClientAppProviders(extensions: AppExtension[]): Promise<BootClientAppProviders[]> {
    return [
        createAppClientProvider(),
        createBaseClientAppProvider(),
        ...getModulesClientAppProviders(),
        ...getPackagesClientAppProviders(),
        ...(await getExtensionsClientAppProviders(extensions))
    ];
}