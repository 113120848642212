import {useDependency} from '@webaker/package-deps';
import {FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {useBooleanState, useSafeHotkey} from '@webaker/package-utils';
import {Fragment, useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';
import {DeleteFilesModal} from '../modals/delete-files-modal';
import {FileUsageErrorModal} from '../modals/file-usage-error-modal';

export interface DeleteFileButtonProps {

}

export interface DeleteFileButtonDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function DeleteFileButton({}: DeleteFileButtonProps) {

    const fileManagerStore = useStore(useDependency<DeleteFileButtonDeps>()('fileManagerStore'));
    const fileApi = useDependency<DeleteFileButtonDeps>()('fileApi');
    const [isDeleteFileModalOpen, openDeleteFileModal, closeDeleteFileModal] = useBooleanState(false);
    const [isFileUsageErrorModalOpen, openFileUsageErrorModal, closeFileUsageErrorModal] = useBooleanState(false);
    const isAnyNodeSelected = fileManagerStore.getSelectedNodes().length > 0;

    const openProperModal = useCallback(async () => {
        const selectedFilesIds = fileManagerStore.getSelectedFilesIds();
        if (await fileApi.isAnyFileInUse(selectedFilesIds)) {
            openFileUsageErrorModal();
            return;
        }
        openDeleteFileModal();
    }, []);

    useSafeHotkey('Delete', async () => {
        if (isAnyNodeSelected) {
            await openProperModal();
        }
    }, [isAnyNodeSelected]);

    return (
        <Fragment>

            <Tooltip title="Delete"
                     color={isAnyNodeSelected ? 'dark' : 'gray'}>
                <Button color="danger"
                        style="text"
                        icon={<MdIcon name="delete"/>}
                        onClick={openProperModal}
                        disabled={!isAnyNodeSelected}/>
            </Tooltip>

            {isDeleteFileModalOpen && (
                <DeleteFilesModal onClose={closeDeleteFileModal}/>
            )}

            {isFileUsageErrorModalOpen && (
                <FileUsageErrorModal info="One or more selected files are in use, you cannot delete them."
                                     onClose={closeFileUsageErrorModal}/>
            )}

        </Fragment>
    );

}