import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames, useBooleanState} from '@webaker/package-utils';
import {Fragment, ReactNode, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Portal} from './portal';
import {TooltipCSS, TooltipPrivateCSS} from './tooltip-css';

export interface TooltipProps {
    title: string | undefined | null;
    color?: CSSThemeColor;
    visible?: boolean;
    children?: ReactNode;
    className?: string;
    wrapperClassName?: string;
}

type TooltipAlignment = 'left' | 'right';

export function Tooltip({title, color = 'dark', visible, children, className, wrapperClassName}: TooltipProps) {

    const css = useThemedCSSBundle([
        TooltipCSS,
        TooltipPrivateCSS
    ], {
        color
    });

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, open, close] = useBooleanState(false);
    const [alignment, setAlignment] = useState<TooltipAlignment>('left');

    useEffect(() => {
        if (visible === true) {
            open();
        } else if (visible === false) {
            close();
        }
    }, [visible]);

    if (typeof window !== 'undefined') {
        useLayoutEffect(() => {
            if (wrapperRef.current) {
                const {left, width} = wrapperRef.current.getBoundingClientRect();
                setAlignment(left + width / 2 > window.innerWidth / 2 ? 'right' : 'left');
            }
        }, [isOpen]);
    }

    if (!title) {
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }

    return (
        <div ref={wrapperRef}
             className={mergeClassNames(css['wrapper'], wrapperClassName)}
             onMouseOver={visible === undefined ? open : undefined}
             onMouseOut={visible === undefined ? close : undefined}>
            {children}
            {isOpen && (
                <Portal>
                    <div className={mergeClassNames(
                        css['tooltip'],
                        css[`align-${alignment}`],
                        className
                    )}>
                        {title}
                    </div>
                </Portal>
            )}
        </div>
    );

}