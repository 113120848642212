import {ClassName} from './class-name/class-name';
import {CSS} from './css';
import {createCSS} from './css-factory';
import {CSSOptions} from './css-options';
import {CSSValue} from './css-value';

export type CSSLiteral = (<C extends ClassName = ClassName>(strings: TemplateStringsArray, ...values: CSSValue[]) => CSS<C>) & {
    options: CSSOptions;
};

export const css: CSSLiteral = <C extends ClassName = ClassName>(strings: TemplateStringsArray, ...values: CSSValue[]): CSS<C> => {
    return createCSS({
        strings: strings as any,
        values,
        options: css.options
    });
};

const DEFAULT_HASH_LENGTH = 5;

css.options = {
    minify: process.env.NODE_ENV === 'production',
    hashLength: DEFAULT_HASH_LENGTH
};