import {Directory, File} from '@webaker/package-file';

export const FILE_CLIPBOARD_COPY = 'copy';
export const FILE_CLIPBOARD_CUT = 'cut';

export interface FileClipboard {
    type: FileClipboardType | null;
    filesIds: File['id'][];
    directoriesIds: Directory['id'][];
    parentDirectoryId: Directory['id'] | null;
    ancestorDirectoriesIds: Directory['id'][];
}

export type FileClipboardType = typeof FILE_CLIPBOARD_COPY | typeof FILE_CLIPBOARD_CUT;