import {createContext, ReactNode} from 'react';
import {JSXRegistry} from './jsx-registry';

export interface JSXContext {
    jsxRegistry: JSXRegistry;
}

export const JSXContext = createContext<JSXContext | null>(null);

export interface JSXContextProviderProps extends JSXContext {
    children: ReactNode;
}

export function JSXContextProvider({children, ...context}: JSXContextProviderProps) {
    return (
        <JSXContext.Provider value={context}>
            {children}
        </JSXContext.Provider>
    );
}