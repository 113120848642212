import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface AnyRuleOptions<T = unknown> {

}

export function isAny<T = unknown>({}: AnyRuleOptions = {}): ValidationRule<T> {
    return (): ValidationError[] => {
        return [];
    };
}