import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type FileModalClassName = 'fileModal' | 'fileManager';

export interface FileModalCSSProps extends CSSThemeProps {

}

export function FileModalCSS({}: FileModalCSSProps) {
    return css<FileModalClassName>`

        .fileModal {

        }

        .fileManager {
            height: 50% !important;
            min-height: 600px !important;
            max-height: 80% !important;
        }

    `;
}