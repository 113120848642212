import {Provider} from '@webaker/package-deps';
import {createIdGenerator, IdGenerator} from '../services/id-generator';
import {createJSONEncoder, JSONEncoder} from '../services/json-encoder';
import {createMemoFactory, MemoFactory} from '../services/memo-factory';
import {createReactWrapper, ReactWrapper} from '../services/react-wrapper';
import {createSerializer, Serializer} from '../services/serializer';
import {createSynchronizerFactory, SynchronizerFactory} from '../services/synchronizer-factory';
import {createTextGenerator, TextGenerator} from '../services/text-generator';

export interface ProvidedUtilsClientDeps {
    idGenerator: IdGenerator;
    jsonEncoder: JSONEncoder;
    memoFactory: MemoFactory;
    reactWrapper: ReactWrapper;
    serializer: Serializer;
    synchronizerFactory: SynchronizerFactory;
    textGenerator: TextGenerator;
}

export interface RequiredUtilsClientDeps {

}

export interface UtilsClientDeps extends ProvidedUtilsClientDeps, RequiredUtilsClientDeps {

}

export interface UtilsClientConfig {

}

export type UtilsClientProvider = Provider<UtilsClientDeps, UtilsClientConfig>;

export function createUtilsClientProvider(): UtilsClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('idGenerator', () => {
                return createIdGenerator();
            });
            register('jsonEncoder', () => {
                return createJSONEncoder();
            });
            register('memoFactory', () => {
                return createMemoFactory();
            });
            register('reactWrapper', () => {
                return createReactWrapper();
            });
            register('serializer', () => {
                return createSerializer();
            });
            register('synchronizerFactory', () => {
                return createSynchronizerFactory();
            });
            register('textGenerator', () => {
                return createTextGenerator();
            });
        }

    };
}