import {ForwardedRef} from 'react';

export type MergeCondition = (key: string, value: unknown) => boolean;
export type MergeResolver = (valueA: unknown, valueB: unknown) => any;
export type MergeStrategy = {
    condition: MergeCondition;
    resolver: MergeResolver;
};

export function mergeClassNames(...classNames: (string | any)[]): string {
    return classNames.filter((className: string | any) => {
        return className && typeof className === 'string';
    }).join(' ');
}

type EventListener = (...args: any[]) => void;

export function mergeEventListeners(...listeners: EventListener[]): EventListener {
    return (...args: any[]): void => {
        listeners.forEach((listener: EventListener): void => {
            listener(...args);
        });
    };
}

export function mergeRefs<T>(...refs: ForwardedRef<T>[]): ForwardedRef<T> {
    return (instance: T): void => {
        refs.forEach((ref: ForwardedRef<T>): void => {
            if (!ref) {
                return;
            }
            if (typeof ref === 'object' && 'current' in ref) {
                ref.current = instance;
            } else if (typeof ref === 'function') {
                ref(instance);
            }
        });
    };
}

function mergeObjects<T>(...objects: T[]): T {
    return objects.reduce((mergedObject: T, object: T): T => {
        return {...mergedObject, ...object};
    }, {} as T);
}

function mergeArrays<T>(arrays: T[][]): T[] {
    return arrays.reduce((mergedArray: T[], array: T[]): T[] => {
        return [...mergedArray, ...array];
    }, []);
}

const mergeStrategies: MergeStrategy[] = [
    {
        condition: (key: string) => key === 'className',
        resolver: mergeClassNames
    },
    {
        condition: (key: string) => key === 'ref',
        resolver: mergeRefs
    },
    {
        condition: (key: string, value: unknown) => key.startsWith('on') && typeof value === 'function',
        resolver: mergeEventListeners
    },
    {
        condition: (key: string, value: unknown) => !!value && typeof value === 'object' && !Array.isArray(value),
        resolver: mergeObjects
    },
    {
        condition: (key: string, value: unknown) => Array.isArray(value),
        resolver: mergeArrays
    }
];

export function mergeRef(...classNames: string[]): string {
    return classNames.filter(Boolean).join(' ');
}

export function mergeProps<T extends any = any>(...props: Partial<T>[]): T {
    const mergedProps: Partial<T> = props[0] ? {...props[0]} : {};
    for (let i = 1; i < props.length; i++) {
        for (const key in props[i]) {
            const currentValue = mergedProps[key];
            const newValue = props[i][key];
            if (typeof currentValue !== 'undefined' && typeof newValue === 'undefined') {
                mergedProps[key] = currentValue;
                // @ts-ignore
            } else if (typeof currentValue === 'undefined' && typeof newValue !== 'undefined') {
                mergedProps[key] = newValue;
            } else {
                const mergeStrategy = mergeStrategies.find((mergeStrategy: MergeStrategy): boolean => {
                    return mergeStrategy.condition(key, newValue);
                });
                if (mergeStrategy) {
                    // @ts-ignore
                    mergedProps[key] = mergeStrategy.resolver(currentValue, newValue);
                } else {
                    mergedProps[key] = props[i][key];
                }
            }
        }
    }
    return mergedProps as T;
}