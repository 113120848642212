import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {useCallback} from 'react';
import {SwitchCSS} from './switch-css';

export interface SwitchProps {
    value: boolean;
    mode?: SwitchMode;
    onChange?: (event: SwitchChangeEvent) => void;
    disabled?: boolean;
    className?: string;
}

export const SWITCH_MODE_DEFAULT = 'default';
export const SWITCH_MODE_DIRECTION = 'direction';

export type SwitchMode = typeof SWITCH_MODE_DEFAULT | typeof SWITCH_MODE_DIRECTION;

export interface SwitchChangeEvent {
    value: boolean;
}

export function Switch({
    value,
    mode = SWITCH_MODE_DEFAULT,
    onChange,
    disabled,
    className
}: SwitchProps) {

    const css = useThemedCSS(SwitchCSS);

    const handleClick = useCallback(() => {
        onChange?.({value: !value});
    }, [value, onChange]);

    return (
        <button onClick={handleClick}
                tabIndex={0}
                disabled={disabled}
                className={mergeClassNames(
                    css['switch'],
                    mode === SWITCH_MODE_DEFAULT && css['mode-default'],
                    mode === SWITCH_MODE_DIRECTION && css['mode-direction'],
                    value && css['is-enabled'],
                    className
                )}/>
    );

}