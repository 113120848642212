import {Component} from '../component/component';
import {Page} from '../page/page';
import {ComponentContent} from './component-content';
import {ComponentsTreeMutator} from './components-tree-mutator';
import {PageContent} from './page-content';

type AnyPageContent = PageContent & Record<string, any> & any;

export interface PageContentMutator<P extends PageContent = AnyPageContent> {

    updatePage: (content: P, page: Page) => P;

    addComponent: <C extends Component = Component>(content: P, component: C) => P;
    updateComponent: <C extends Component = Component>(content: P, component: C) => P;
    deleteComponent: (content: P, componentId: Component['id']) => P;
    moveComponent: (content: P, componentId: Component['id'], targetComponentId: Component['id']) => P;
    moveComponentBefore: (content: P, componentId: Component['id'], targetComponentId: Component['id']) => P;
    moveComponentAfter: (content: P, componentId: Component['id'], targetComponentId: Component['id']) => P;
    moveComponentToRoot: (content: P, componentId: Component['id']) => P;

    shareComponent: (content: P, componentId: Component['id']) => P;
    unshareComponent: (content: P, componentId: Component['id']) => P;
    attachComponentContent: (content: P, componentId: Component['id']) => P;
    detachComponentContent: (content: P, componentId: Component['id']) => P;

    addComponentContent: (content: P, componentContent: ComponentContent) => P;

}

export interface PageContentMutatorDeps {
    componentsTreeMutator: ComponentsTreeMutator;
}

export function createPageContentMutator<P extends PageContent = AnyPageContent>({
    componentsTreeMutator
}: PageContentMutatorDeps): PageContentMutator<P> {

    const updatePage = (content: P, page: Page): P => {
        return {
            ...content,
            page
        };
    };

    const addComponent = <C extends Component = Component>(content: P, component: C): P => {
        return {
            ...content,
            tree: componentsTreeMutator.addComponent(content.tree, component)
        };
    };

    const updateComponent = <C extends Component = Component>(content: P, component: C): P => {
        return {
            ...content,
            tree: componentsTreeMutator.updateComponent(content.tree, component)
        };
    };

    const deleteComponent = (content: P, componentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.deleteComponent(content.tree, componentId)
        };
    };

    const moveComponent = (content: P, componentId: Component['id'], targetComponentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.moveComponent(content.tree, componentId, targetComponentId)
        };
    };

    const moveComponentBefore = (content: P, componentId: Component['id'], targetComponentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.moveComponentBefore(content.tree, componentId, targetComponentId)
        };
    };

    const moveComponentAfter = (content: P, componentId: Component['id'], targetComponentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.moveComponentAfter(content.tree, componentId, targetComponentId)
        };
    };

    const moveComponentToRoot = (content: P, componentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.moveComponentToRoot(content.tree, componentId, content.page.id)
        };
    };

    const shareComponent = (content: P, componentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.shareComponent(content.tree, componentId)
        };
    };

    const unshareComponent = (content: P, componentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.unshareComponent(content.tree, componentId)
        };
    };

    const attachComponentContent = (content: P, componentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.attachComponentContent(content.tree, componentId)
        };
    };

    const detachComponentContent = (content: P, componentId: Component['id']): P => {
        return {
            ...content,
            tree: componentsTreeMutator.detachComponentContent(content.tree, componentId)
        };
    };

    const addComponentContent = (content: P, componentContent: ComponentContent): P => {
        return {
            ...content,
            tree: componentsTreeMutator.mergeComponentsTrees(
                componentsTreeMutator.addComponent(content.tree, componentContent.component),
                componentContent.tree
            )
        };
    };

    return {
        updatePage,
        addComponent,
        updateComponent,
        deleteComponent,
        moveComponent,
        moveComponentBefore,
        moveComponentAfter,
        moveComponentToRoot,
        shareComponent,
        unshareComponent,
        attachComponentContent,
        detachComponentContent,
        addComponentContent
    };

}