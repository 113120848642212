import {ComponentOptions} from '@webaker/app';
import {SLOT_TYPE, SlotComponent} from './slot-component';
import {SlotComponentView} from './slot-component-view';

export function createSlotComponentOptions(): ComponentOptions<SlotComponent> {
    return {
        type: SLOT_TYPE,
        slot: true,
        view: SlotComponentView
    };
}