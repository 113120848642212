import {useThemedCSS} from '@webaker/package-css-theme';
import {Corner, mergeClassNames} from '@webaker/package-utils';
import {ReactNode, useCallback} from 'react';
import {CornerBoxCSS} from './corner-box-css';

export interface CornerBoxPropsProps {
    children: (props: CornerBoxEvent) => ReactNode;
    center?: ReactNode;
    label?: (props: CornerBoxEvent) => string | null;
    onSelect?: (event: CornerBoxSelectEvent) => void;
    className?: string;
}

export interface CornerBoxEvent {
    corner: Corner;
    oppositeCorner: Corner;
}

export interface CornerBoxSelectEvent extends CornerBoxEvent {

}

export const TOP_LEFT_EVENT: CornerBoxEvent = {
    corner: 'top-left',
    oppositeCorner: 'bottom-right'
};

export const TOP_RIGHT_EVENT: CornerBoxEvent = {
    corner: 'top-right',
    oppositeCorner: 'bottom-left'
};

export const BOTTOM_RIGHT_EVENT: CornerBoxEvent = {
    corner: 'bottom-right',
    oppositeCorner: 'top-left'
};

export const BOTTOM_LEFT_EVENT: CornerBoxEvent = {
    corner: 'bottom-left',
    oppositeCorner: 'top-right'
};

export function CornerBox({
    children,
    center,
    onSelect,
    label,
    className
}: CornerBoxPropsProps) {

    const css = useThemedCSS(CornerBoxCSS, {});

    const selectTopLeft = useCallback(() => {
        onSelect?.(TOP_LEFT_EVENT);
    }, [onSelect]);

    const selectTopRight = useCallback(() => {
        onSelect?.(TOP_RIGHT_EVENT);
    }, [onSelect]);

    const selectBottomRight = useCallback(() => {
        onSelect?.(BOTTOM_RIGHT_EVENT);
    }, [onSelect]);

    const selectBottomLeft = useCallback(() => {
        onSelect?.(BOTTOM_LEFT_EVENT);
    }, [onSelect]);

    const topLeftChildren = children(TOP_LEFT_EVENT);
    const topRightChildren = children(TOP_RIGHT_EVENT);
    const bottomRightChildren = children(BOTTOM_RIGHT_EVENT);
    const bottomLeftChildren = children(BOTTOM_LEFT_EVENT);

    return (
        <div className={mergeClassNames(css['cornerBox'], className)}>
            <div className={css['topRow']}>
                <div className={css['topLeft']}>
                    {topLeftChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectTopLeft : undefined}>
                            {label?.(TOP_LEFT_EVENT) ?? 'Top Left'}
                        </div>
                    )}
                    {topLeftChildren}
                </div>
                <div className={css['topRight']}>
                    {topRightChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectTopRight : undefined}>
                            {label?.(TOP_RIGHT_EVENT) ?? 'Top Right'}
                        </div>
                    )}
                    {topRightChildren}
                </div>
            </div>
            <div className={css['middleRow']}>
                <div className={css['center']}>
                    {center}
                </div>
            </div>
            <div className={css['bottomRow']}>
                <div className={css['bottomLeft']}>
                    {bottomRightChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectBottomLeft : undefined}>
                            {label?.(BOTTOM_LEFT_EVENT) ?? 'Bottom Left'}
                        </div>
                    )}
                    {bottomLeftChildren}
                </div>
                <div className={css['bottomRight']}>
                    {bottomLeftChildren && (
                        <div className={mergeClassNames(css['label'], onSelect && css['is-clickable'])}
                             onClick={onSelect ? selectBottomRight : undefined}>
                            {label?.(BOTTOM_RIGHT_EVENT) ?? 'Bottom Right'}
                        </div>
                    )}
                    {bottomRightChildren}
                </div>
            </div>
        </div>
    );

}