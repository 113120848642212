import {useThemedCSS} from '@webaker/package-css-theme';
import {SideMenuSeparatorCSS} from './side-menu-separator-css';

export interface SideMenuSeparatorProps {

}

export function SideMenuSeparator({}: SideMenuSeparatorProps) {

    const css = useThemedCSS(SideMenuSeparatorCSS, {});

    return (
        <div className={css['sideMenuSeparator']}/>
    );

}