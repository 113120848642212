import {AppRouter, AppStore, Page, PageRenderer} from '@webaker/app';
import {GENERATED_CSS_ID} from '@webaker/package-css';
import {ConfigContextProvider, DependencyContainer, DependencyContextProvider} from '@webaker/package-deps';
import {EventBus} from '@webaker/package-event-bus';
import {GENERATED_THEME_CSS_ID} from '@webaker/package-theme';
import React, {ReactElement, StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';
import {AppManager, INIT_APP_EVENT, InitAppEvent} from '../app-manager';
import {AppRenderer} from '../app-renderer';
import {getAppElement} from './app-client-utils';

export interface AppClientManagerDeps {
    appRouter: AppRouter;
    appRenderer: AppRenderer;
    appStore: AppStore;
    container: DependencyContainer<any>;
    eventBus: EventBus;
    pageRenderer: PageRenderer;
}

export interface AppClientManagerConfig {

}

export function createAppClientManager({
    appRouter,
    appRenderer,
    appStore,
    container,
    eventBus,
    pageRenderer
}: AppClientManagerDeps, config: AppClientManagerConfig): AppManager {

    const runApp = async (): Promise<void> => {
        await initApp();
        await appRouter.openPageByPath(getCurrentNavigationPath());
        const appElement = getAppElement();
        const appView = getAppView();
        hydrateRoot(appElement, appView);
        await new Promise<void>((resolve) => {
            requestIdleCallback(() => {
                removeGeneratedCSS();
                resolve();
            });
        });
    };

    const initApp = async (): Promise<void> => {
        const page = appStore.getPage();
        if (page) {
            await pageRenderer.loadPageView(page);
            await eventBus.dispatchEvent<InitAppEvent>({
                name: INIT_APP_EVENT
            });
        }
    };

    const getAppView = (): ReactElement => {
        const app = appRenderer.renderApp();
        return wrapView(app);
    };

    const wrapView = (element: ReactElement): ReactElement => {
        return (
            <StrictMode>
                <DependencyContextProvider container={container}>
                    <ConfigContextProvider config={config}>
                        {element}
                    </ConfigContextProvider>
                </DependencyContextProvider>
            </StrictMode>
        );
    };

    const removeGeneratedCSS = (): void => {
        document.getElementById(GENERATED_CSS_ID)?.remove();
        document.getElementById(GENERATED_THEME_CSS_ID)?.remove();
    };

    const getCurrentNavigationPath = (): Page['path'] => {
        const url = window.navigation.currentEntry?.url ? new URL(window.navigation.currentEntry.url) : null;
        return url?.pathname ?? '/';
    };

    return {
        runApp
    };

}