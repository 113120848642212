export interface RoutesConfig {
    apiRoute: Route;
    fileRoute: Route;
}

export type Route = `/${string}`;

export function getRoutesConfig(): RoutesConfig {

    const apiRoute = '/api';
    const fileRoute = '/files';

    return {
        apiRoute,
        fileRoute
    };

}