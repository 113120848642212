import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {SeparatorCSS, SeparatorPrivateCSS} from './separator-css';

export interface SeparatorProps {
    margin?: CSSThemeSize;
    className?: string;
}

export function Separator({margin = 'large', className}: SeparatorProps) {

    const css = useThemedCSSBundle([
        SeparatorCSS,
        SeparatorPrivateCSS
    ], {
        margin
    });

    return (
        <div className={mergeClassNames(css['separator'], className)}/>
    );

}