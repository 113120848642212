export interface ApiError {
    name: string;
    message: string;
    details?: any;
    stack?: string;
}

export class ApiError extends Error {

    name: string;

    message: string;

    details?: any;

    constructor(name: string, message: string, details?: any) {
        super(message);
        this.name = name;
        this.details = details;
    }

}