import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {RowCSS, RowPrivateCSS} from './row-css';

export interface RowProps {
    columns?: string[];
    align?: RowAlign;
    margin?: CSSThemeSize;
    gap?: CSSThemeSize;
    children?: ReactNode;
    className?: string;
}

export type RowAlign = 'start' | 'center' | 'end';

export function Row({
    columns = ['none'],
    align = 'start',
    margin = 'medium',
    gap = 'medium',
    children,
    className
}: RowProps) {

    const css = useThemedCSSBundle([
        RowCSS,
        RowPrivateCSS
    ], {
        columns,
        align,
        margin,
        gap
    });

    return (
        <div className={mergeClassNames(css['row'], className)}>
            {children}
        </div>
    );

}