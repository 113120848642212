export type Edge = 'top' | 'right' | 'bottom' | 'left';

export interface EdgeValues {
    top: string | null;
    right: string | null;
    bottom: string | null;
    left: string | null;
}

export function parseEdgeValues(value: string): EdgeValues {
    const parts = value.split(/\s+/g);
    return {
        top: parts[0] ?? null,
        right: parts[1] ?? parts[0] ?? null,
        bottom: parts[2] ?? parts[0] ?? null,
        left: parts[3] ?? parts[1] ?? parts[0] ?? null,
    };
}

export function stringifyEdgeValues({top, right, bottom, left}: EdgeValues): string | null {
    if (top === null || right === null || bottom === null || left === null) {
        return null;
    }
    if (top === right && right === bottom && bottom === left) {
        return `${top}`;
    }
    if (top === bottom && right === left) {
        return `${top} ${right}`;
    }
    if (right === left) {
        return `${top} ${right} ${bottom}`;
    }
    return `${top} ${right} ${bottom} ${left}`;
}