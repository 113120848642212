import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createSettingsPageOptions} from '../settings-page-options';

export interface ProvidedSettingsPageClientDeps {

}

export interface RequiredSettingsPageClientDeps {
    pageRegistry: PageRegistry;
}

export interface SettingsPageClientDeps extends ProvidedSettingsPageClientDeps, RequiredSettingsPageClientDeps {

}

export interface SettingsPageClientConfig {

}

export type SettingsPageClientProvider = Provider<SettingsPageClientDeps, SettingsPageClientConfig>;

export function createSettingsPageClientProvider(): SettingsPageClientProvider {

    return {

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const settingsPageOptions = createSettingsPageOptions();
            pageRegistry.registerPage(settingsPageOptions);
        }

    };

}