import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {TileAlign} from './tile';

export type TileClassName = 'tile' | 'header' | 'title' | 'subtitle' | 'content';

export function TileCSS({theme}: CSSThemeProps) {
    return css<TileClassName>`

        .tile {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: ${theme.spacing.medium};
            background: ${theme.color.white};
            box-shadow: ${theme.boxShadow.verySmall};
            border-radius: ${theme.borderRadius.large};
        }

        .header {
            position: relative;
            margin-bottom: ${theme.spacing.medium};
        }

        .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: ${theme.spacing.medium};
            width: 100%;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .subtitle {
            color: ${theme.color.gray};
            font-weight: normal;
            margin-top: ${theme.spacing.medium};
        }

        .content {

        }

    `;
}

export interface TilePrivateCSSProps extends CSSThemeProps {
    align: TileAlign;
}

export function TilePrivateCSS({align}: TilePrivateCSSProps) {
    return css<TileClassName>`

        .tile {
            align-items: ${align === 'left' ? 'flex-start' : align === 'center' ? 'center' : 'flex-end'};
            text-align: ${align === 'left' ? 'left' : align === 'center' ? 'center' : 'right'};
        }

    `;
}