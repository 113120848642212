import {useDependency, useOptionalDependency} from '@webaker/package-deps';
import {File, FileApi, FilePicker} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';

export interface UploadFileButtonProps {

}

export interface UploadFileButtonDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
    filePicker?: FilePicker;
}

export function UploadFileButton({}: UploadFileButtonProps) {

    const fileManagerStore = useStore(useDependency<UploadFileButtonDeps>()('fileManagerStore'));
    const fileApi = useDependency<UploadFileButtonDeps>()('fileApi');
    const filePicker = useOptionalDependency<UploadFileButtonDeps>()('filePicker');

    const uploadFiles = useCallback(async () => {
        const files = await filePicker?.selectFiles() ?? [];
        const workingDirectoryId = fileManagerStore.getWorkingDirectory()?.id;
        fileManagerStore.startLoading();
        const savedFiles = await Promise.all(
            files.map(async (file: File): Promise<File> => {
                return await fileApi.saveFile({
                    ...file,
                    parentDirectoryId: workingDirectoryId
                });
            })
        );
        fileManagerStore.deselectAllNodes();
        savedFiles.forEach((file: File) => {
            fileManagerStore.addFile(file);
            fileManagerStore.toggleSelectNode(file.id);
        });
        fileManagerStore.stopLoading();
    }, []);

    return (
        <Tooltip title="Upload">
            <Button color="info"
                    style="text"
                    icon={<MdIcon name="upload"/>}
                    onClick={uploadFiles}/>
        </Tooltip>
    );

}