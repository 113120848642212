import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createLoginPageOptions} from '../login-page-options';

export interface ProvidedLoginPageClientDeps {

}

export interface RequiredLoginPageClientDeps {
    pageRegistry: PageRegistry;
}

export interface LoginPageClientDeps extends ProvidedLoginPageClientDeps, RequiredLoginPageClientDeps {

}

export interface LoginPageClientConfig {

}

export type LoginPageClientProvider = Provider<LoginPageClientDeps, LoginPageClientConfig>;

export function createLoginPageClientProvider(): LoginPageClientProvider {
    return {

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const loginPageOptions = createLoginPageOptions();
            pageRegistry.registerPage(loginPageOptions);
        }

    };
}