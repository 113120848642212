import {Config} from '../config';

const DEFAULT_LOG_TITLE = 'Config';

export function logConfig(config: Config, title: string = DEFAULT_LOG_TITLE): void {
    console.groupCollapsed(`${title} (${Object.keys(config).length})`);
    Object.entries(config).forEach(([key, value]) => {
        console.log(key, '=', value);
    });
    console.groupEnd();
}