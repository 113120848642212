import {getCallStack} from '@webaker/package-utils';
import {Store} from '../store';
import {StoreEvent} from '../store-events';
import {StorePlugin} from '../store-plugin';

export interface LoggerStorePluginDeps {

}

export interface LoggerStorePluginConfig {
    filter?: (event: StoreEvent) => boolean;
}

const STACK_LINES_TO_SKIP = 2;

export function createLoggerStorePlugin(
    {}: LoggerStorePluginDeps = {},
    {filter}: LoggerStorePluginConfig = {}
): StorePlugin {
    return (store: Store): void => {

        const logEvent = (event: StoreEvent): void => {
            if (filter && !filter(event)) {
                return;
            }
            console.groupCollapsed('Store:', `${store.name}/${event.method}`);
            console.group('Params:');
            console.log(event.params);
            console.groupEnd();
            console.group('Result:');
            console.log(event.result);
            console.groupEnd();
            console.group('State:');
            console.log(event.state);
            console.groupEnd();
            console.group('Stack:');
            console.log(getCallStack().slice(STACK_LINES_TO_SKIP));
            console.groupEnd();
            console.groupEnd();
        };

        store.addEventListener('*', logEvent);

    };
}