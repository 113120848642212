import {Store} from './store';
import {StoreDecoratorKilledError} from './store-decorator-killed-error';
import {StoreDescriptionNotAvailableError} from './store-description-not-available-error';
import {StateListener, StoreEvent, StoreListener} from './store-events';

export interface StoreDecorator {
    decorateStore: <S extends Store = any>(store: S, descriptor: DecoratedStoreSchema<S>) => DecoratedStore<S>;
}

export interface StoreDecoratorDeps {

}

export type DecoratedStore<S extends Store = any> = S & {
    killStore: () => void;
}

export interface DecoratedStoreSchema<S extends Store = any> {
    onGet?: StoreListener<S>;
    onSet?: StoreListener<S>;
    onGlobalGet?: StoreListener<S>;
    onGlobalSet?: StoreListener<S>;
    onKill?: StateListener<S>;
}

export function createStoreDecorator({}: StoreDecoratorDeps = {}): StoreDecorator {

    const decorateStore = <S extends Store = any>(store: S, {
        onGet,
        onSet,
        onGlobalGet,
        onGlobalSet,
        onKill
    }: DecoratedStoreSchema<S>): DecoratedStore<S> => {

        const initStore = (): void => {

            if (!store.description) {
                throw new StoreDescriptionNotAvailableError(`Cannot decorate store, because store description is not available`);
            }

            if (onGet) {
                store.description.getters.forEach((method: string): void => {
                    // @ts-ignore
                    decoratedStore[method] = (...params: any[]) => {
                        // @ts-ignore
                        const result = store[method](...params);
                        onGet({state: store.get(), method, params, result} as StoreEvent<S>);
                        return result;
                    };
                });
            }

            if (onSet) {
                store.description.setters.forEach((method: string): void => {
                    // @ts-ignore
                    decoratedStore[method] = (...params: any[]) => {
                        // @ts-ignore
                        store[method](...params);
                        onSet({state: store.get(), method, params, result: undefined} as StoreEvent<S>);
                    };
                });
            }

            if (onGlobalGet) {
                store.addEventListener('*:get', onGlobalGet);
            }

            if (onGlobalSet) {
                store.addEventListener('*:set', onGlobalSet);
            }

        };

        const killStore = (): void => {

            if (!store.description) {
                throw new StoreDescriptionNotAvailableError(`Cannot decorate store, because store description is not available`);
            }

            const throwStoreDecoratorKilledError = (): never => {
                throw new StoreDecoratorKilledError(`Store Decorator has already been killed and cannot be used anymore`);
            };

            store.description.getters.forEach((method: string): void => {
                // @ts-ignore
                decoratedStore[method] = throwStoreDecoratorKilledError;
            });

            store.description.getters.forEach((method: string): void => {
                // @ts-ignore
                decoratedStore[method] = throwStoreDecoratorKilledError;
            });

            if (onGlobalGet) {
                store.removeEventListener('*:get', onGlobalGet);
            }

            if (onGlobalSet) {
                store.removeEventListener('*:set', onGlobalSet);
            }

            if (onKill) {
                onKill({state: decoratedStore.get() as any});
            }

        };

        const decoratedStore: DecoratedStore<S> = {...store, killStore};

        initStore();

        return decoratedStore;
    };

    return {
        decorateStore
    };

}