import {useRequiredContext} from '@webaker/package-utils';
import {useDebugValue} from 'react';
import {Config} from '../config';
import {ConfigContext} from '../config-context';
import {OptionalKey} from '../dependency-key';

export function useOptionalConfig<C extends Config = {}>() {
    return function useOptionalConfig<K extends OptionalKey<C> = OptionalKey<C>>(key: K): C[K] {
        useDebugValue(key);
        const config = useRequiredContext(ConfigContext).config as C;
        return config[key];
    };
}