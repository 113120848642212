import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface RequiredRuleOptions {

}

export function isRequired<T = unknown>({}: RequiredRuleOptions = {}): ValidationRule<T> {
    return (item: unknown): ValidationError[] => {
        if (typeof item === 'undefined' || item === null) {
            return [{message: 'Value is required'}];
        }
        return [];
    };
}