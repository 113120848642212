import {useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {EmptyIndicatorCSS, EmptyIndicatorPrivateCSS} from './empty-indicator-css';

export interface EmptyIndicatorProps {
    size?: string;
    className?: string;
}

export function EmptyIndicator({size = '20%', className}: EmptyIndicatorProps) {

    const css = useThemedCSSBundle([
        EmptyIndicatorCSS,
        EmptyIndicatorPrivateCSS
    ], {
        size
    });

    return (
        <span className={mergeClassNames(css['emptyIndicator'], className)}/>
    );

}