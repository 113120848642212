import {as, isEnum, isEqual, isId, isNumber, isObject, isString, optional, ValidationErrorsBag, ValidationRule, ValidationRulesMap, Validator} from '@webaker/package-validation';
import {Page} from './page';
import {PageRegistry} from './page-registry';
import {OmitPage} from './page-types';

export interface PageValidator {
    registerRules: <P extends Page = Page>(rules: PageValidationRules<P>) => void;
    registerRulesByType: <P extends Page = Page>(type: P['type'], rules: PageValidationRules<P>) => void;
    validatePage: <P extends Page = Page>(item: unknown) => item is P;
    assertPage: <P extends Page = Page>(item: unknown) => asserts item is P;
    getPageErrors: (item: unknown) => ValidationErrorsBag;
}

export interface PageValidatorDeps {
    validator: Validator;
    pageRegistry: PageRegistry;
}

export type PageValidationRules<P extends Page = Page> = ValidationRulesMap<OmitPage<P>>;

export function createPageValidator({
    pageRegistry,
    validator: validatorAlias
}: PageValidatorDeps): PageValidator {

    const validationRulesSet: Set<PageValidationRules<any>> = new Set();
    const validationRulesByTypeMap: Map<string, PageValidationRules<any>> = new Map();

    // fix for TS2775
    const validator: Validator = validatorAlias;

    const registerRules = <P extends Page = Page>(rules: PageValidationRules<P>): void => {
        validationRulesSet.add(rules);
    };

    const registerRulesByType = <P extends Page = Page>(type: P['type'], rules: PageValidationRules<P>): void => {
        validationRulesByTypeMap.set(type, rules);
    };

    const validatePage = <P extends Page = Page>(item: unknown): item is P => {
        return validator.validate(item, isPage())
            && validator.validate(item, isPage(item.type));
    };

    const assertPage = <P extends Page = Page>(item: unknown): asserts item is P => {
        validator.assert(item, isPage());
        validator.assert(item, isPage(item.type));
    };

    const getPageErrors = (item: unknown): ValidationErrorsBag => {
        const errors = validator.errors(item, isPage());
        if (!errors.isEmpty()) {
            return errors;
        }
        return validator.errors(item, isPage((item as Page).type));
    };

    const isPage = <P extends Page = Page>(type?: P['type']): ValidationRule<P> => {
        const baseRules = getBaseRules(type);
        const extensionsRules = getExtensionsRules();
        const typeRules = getTypeRules(type);
        return isObject<Page>({
            properties: {
                ...baseRules,
                ...extensionsRules,
                ...typeRules
            },
            strict: !!type
        }) as ValidationRule<P>;
    };

    const getBaseRules = (type?: Page['type']): ValidationRulesMap<Page> => {
        return {
            id: isId(),
            type: type ? isEqual({
                to: type
            }) : isEnum({
                values: pageRegistry.getAllPagesTypes()
            }),
            title: as(
                isString({
                    match: /\S/i
                }),
                'Page title cannot be empty'
            ),
            path: as(
                isString({
                    match: /^(\/|(\/[a-z0-9-]+)+)$/i
                }),
                'Page path has to start with "/" and can contain only letters, numbers and hyphens separated by "/"'
            ),
            index: optional(isNumber()),
            parentId: optional(isString())
        };
    };

    const getExtensionsRules = <P extends Page = Page>(): PageValidationRules<P> => {
        return Array.from(validationRulesSet.values()).reduce((
            result: ValidationRulesMap<Partial<P>>,
            rules: PageValidationRules<P>
        ): ValidationRulesMap<Partial<P>> => {
            return {...result, ...rules};
        }, {} as ValidationRulesMap<Partial<P>>) as PageValidationRules<P>;
    };

    const getTypeRules = <P extends Page = Page>(type?: P['type']): PageValidationRules<P> => {
        const typeRules = type ? validationRulesByTypeMap.get(type) : null;
        return typeRules ?? {};
    };

    return {
        registerRules,
        registerRulesByType,
        validatePage,
        assertPage,
        getPageErrors
    };

}