export interface ExtensionNotFoundErrorDetails {
    name: string;
}

export class ExtensionNotFoundError extends Error {

    name = 'EXTENSION_NOT_FOUND';

    details: ExtensionNotFoundErrorDetails;

    constructor(message: string, details: ExtensionNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}