import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export type DateRuleOptions = {
    string?: boolean;
    min?: Date | string;
    max?: Date | string;
};

export function isDate<D extends string | Date = string | Date>({string, min, max}: DateRuleOptions = {}): ValidationRule<D> {
    return (item: unknown): ValidationError[] => {
        if (string && typeof item === 'string') {
            item = new Date(item);
            if (!(item instanceof Date) || isNaN(item.getTime())) {
                return [{message: `Value has to be valid date string`}];
            }
        }
        if (!(item instanceof Date)) {
            return [{message: `Value has to be date object`}];
        }
        const errors: ValidationError[] = [];
        if (min) {
            const minDate: Date = typeof min === 'string' ? new Date(min) : min;
            if (!isNaN(minDate.getTime()) && item < minDate) {
                errors.push({message: `Date has to be after ${minDate.toLocaleString()}`});
            }
        }
        if (max) {
            const maxDate: Date = typeof max === 'string' ? new Date(max) : max;
            if (!isNaN(maxDate.getTime()) && item > maxDate) {
                errors.push({message: `Date has to be before ${maxDate.toLocaleString()}`});
            }
        }
        return errors;
    };
}