import {useDependency} from '@webaker/package-deps';
import {useDebugValue, useInsertionEffect, useMemo} from 'react';
import {ClassName} from '../class-name/class-name';
import {ClassNamesMap} from '../class-name/class-names-map';
import {CSS} from '../css';
import {CSSComponent} from '../css-component';
import {CSSRegistry} from '../css-registry';

type GetOptionalRest<T> = {} extends T ? [T?] : [T];

export interface UseCSSDeps {
    cssRegistry: CSSRegistry;
}

export function useCSS<P extends {} = {}, C extends ClassName = ClassName>(
    cssComponent: CSSComponent<P, C>,
    ...[optionalCSSProps]: GetOptionalRest<P>
): ClassNamesMap<C> {

    const cssProps = optionalCSSProps ?? {} as P;
    const cssRegistry = useDependency<UseCSSDeps>()('cssRegistry');
    const css = useMemo<CSS<C>>(() => {
        return cssComponent(cssProps);
    }, [cssComponent, JSON.stringify(cssProps)]);

    if (typeof window !== 'undefined') {
        useInsertionEffect(() => {
            cssRegistry.registerCSS(css);
            return () => {
                cssRegistry.unregisterCSS(css);
            };
        }, [css]);
    } else {
        cssRegistry.registerCSS(css);
    }

    useDebugValue(css.id);

    return css.classNames;

}