import {useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {SidePanelCSS, SidePanelPrivateCSS} from './side-panel-css';

export interface SidePanelProps {
    position: SidePanelPosition;
    open: boolean;
    size: string;
    children?: ReactNode;
    className?: string;
}

export type SidePanelPosition = 'left' | 'right';

export function SidePanel({position, open, size, children, className}: SidePanelProps) {

    const css = useThemedCSSBundle([
        SidePanelCSS,
        SidePanelPrivateCSS
    ], {
        size
    });

    return (
        <aside className={mergeClassNames(
            css['sidePanel'],
            open && css['is-open'],
            css[`position-${position}`]
        )}>
            <div className={mergeClassNames(css['wrapper'], className)}>
                {children}
            </div>
        </aside>
    );

}