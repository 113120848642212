import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {GridCSS, GridPrivateCSS} from './grid-css';

export interface GridProps {
    columns?: string[];
    rows?: string[];
    margin?: CSSThemeSize;
    gap?: CSSThemeSize;
    children?: ReactNode;
    className?: string;
}

export function Grid({
    columns,
    rows,
    margin = 'medium',
    gap = 'medium',
    children,
    className
}: GridProps) {

    const css = useThemedCSSBundle([
        GridCSS,
        GridPrivateCSS
    ], {
        columns,
        rows,
        margin,
        gap
    });

    return (
        <div className={mergeClassNames(css['grid'], className)}>
            {children}
        </div>
    );

}