import {useOptionalDependency} from '@webaker/package-deps';
import {useRerender} from '@webaker/package-utils';
import {useEffect, useState} from 'react';
import {Store} from './store';
import {StoreHelper} from './store-helper';
import {StoreWatcher, WatchedStore} from './store-watcher';

export interface UseStoreHelperDeps {
    storeWatcher?: StoreWatcher;
}

export function useStoreHelper<H extends StoreHelper = StoreHelper>(helper: H): H {

    const rerender = useRerender();
    const storeWatcher = useOptionalDependency<UseStoreHelperDeps>()('storeWatcher');
    const [watchedStores, setWatchedStores] = useState<WatchedStore[]>([]);
    const [watchedHelper, setWatchedHelper] = useState<H | null>(helper);

    useEffect(() => {
        if (storeWatcher) {
            let shouldRerender = true;
            let newWatchedStores: WatchedStore[] = [];
            const newWatchedHelper = helper.withStore(<S extends Store = Store>(store: S): S => {
                const newWatchedStore = storeWatcher.watchStore(store, () => {
                    if (shouldRerender) {
                        rerender();
                    }
                });
                newWatchedStores.push(newWatchedStore);
                return newWatchedStore;
            });
            setWatchedStores(newWatchedStores);
            setWatchedHelper(newWatchedHelper);
            return () => {
                shouldRerender = false;
                newWatchedStores.forEach((watchedStore: WatchedStore): void => {
                    watchedStore.killStore();
                });
            };
        }
        if (typeof window !== 'undefined') {
            console.warn('Store Watcher not found, state will not be reactive');
        }
        return;
    }, []);

    watchedStores.forEach((watchedStore: WatchedStore): void => {
        watchedStore.resetStore();
    });

    return watchedHelper as H;

}