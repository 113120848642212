import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';
import {TabGroupDirection} from './tab-group';

export type TabGroupClassName = 'tabGroup' | `direction-${TabGroupDirection}`;

export interface TabGroupCSSProps extends CSSThemeProps {

}

export function TabGroupCSS({theme}: TabGroupCSSProps) {
    return css<TabGroupClassName>`

        .tabGroup {
            position: relative;
            display: flex;
            margin: ${theme.spacing.large} 0;
            padding: ${theme.spacing.medium};
            justify-content: space-evenly;

            &:before {
                content: '';
                position: absolute;
                background-color: ${color(theme.color.black, {alpha: 0.05})};
                border-radius: ${theme.borderRadius.small};
                filter: blur(8px);
                z-index: 0;
            }

            & > * {
                z-index: 1;
            }
        }

        .direction-horizontal {
            width: 100%;
            flex-direction: row;

            &::before {
                width: 100%;
                height: 1em;
                top: calc(50% - 0.5em);
            }
        }

        .direction-vertical {
            flex-direction: column;
            border-left: 1px solid ${theme.color.light};

            &::before {
                width: 1em;
                height: 100%;
                left: calc(50% - 0.5em);
            }
        }

    `;
}