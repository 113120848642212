import {State} from './state';
import {StoreEventFunction} from './store-events';

export interface Store<S extends State = {}> {
    name: string;
    description?: StoreDescription;
    get: (source?: StoreSource) => S;
    set: (state: S, source?: StoreSource) => void;
    addEventListener: StoreEventFunction<this>;
    removeEventListener: StoreEventFunction<this>;
}

export interface StoreDescription {
    getters: readonly string[];
    setters: readonly string[];
    events: readonly string[];
}

export type StoreState<S extends Store = any> = S extends Store<infer T> ? T : object;

export type StoreSource = string;