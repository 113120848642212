import {ClassName, ClassNamesMap, useEmptyCSS} from '@webaker/package-css';
import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {CSSThemeStore} from './css-theme-store';

export interface UseEmptyThemedCSSDeps {
    cssThemeStore: CSSThemeStore;
}

export function useEmptyThemedCSS<C extends ClassName = ClassName>(): ClassNamesMap<C> {
    useStore(useDependency<UseEmptyThemedCSSDeps>()('cssThemeStore'));
    return useEmptyCSS();
}