import {Page} from './page';
import {PageExtensionNotFoundError} from './page-extension-not-found-error';
import {PageExtensionOptions} from './page-extension-options';
import {PageOptions} from './page-options';
import {PageTypeNotFoundError} from './page-type-not-found-error';

export interface PageRegistry {

    registerPage: <P extends Page = Page>(options: PageOptions<P>) => void;
    getAllPagesTypes: () => Page['type'][];
    getAllPagesOptions: () => PageOptions[];
    getPageOptions: <P extends Page = Page>(type: P['type']) => PageOptions<P>;

    registerPageExtension: <P extends Page = Page>(options: PageExtensionOptions<P>) => void;
    getAllPagesExtensionsOptions: () => PageExtensionOptions[];
    getPageExtensionOptions: <P extends Page = Page>(name: string) => PageExtensionOptions<P>;

}

export interface PageRegistryDeps {

}

export function createPageRegistry({}: PageRegistryDeps = {}): PageRegistry {

    const pagesOptionsMap: Map<string, PageOptions<any>> = new Map();
    const pagesExtensionsOptionsMap: Map<string, PageExtensionOptions<any>> = new Map();

    const registerPage = <P extends Page = Page>(options: PageOptions<P>): void => {
        pagesOptionsMap.set(options.type, options);
    };

    const getAllPagesTypes = (): Page['type'][] => {
        return Array.from(pagesOptionsMap.keys());
    };

    const getAllPagesOptions = (): PageOptions[] => {
        return Array.from(pagesOptionsMap.values());
    };

    const getPageOptions = <P extends Page = Page>(type: P['type']): PageOptions<P> => {
        if (!pagesOptionsMap.has(type)) {
            throw new PageTypeNotFoundError(`Page type [${type}] not found`, {type});
        }
        return pagesOptionsMap.get(type)!;
    };

    const registerPageExtension = <P extends Page = Page>(options: PageExtensionOptions<P>): void => {
        pagesExtensionsOptionsMap.set(options.name, options);
    };

    const getAllPagesExtensionsOptions = (): PageExtensionOptions[] => {
        return Array.from(pagesExtensionsOptionsMap.values());
    };

    const getPageExtensionOptions = <P extends Page = Page>(name: string): PageExtensionOptions<P> => {
        if (!pagesExtensionsOptionsMap.has(name)) {
            throw new PageExtensionNotFoundError(`Page extension [${name}] not found`, {name});
        }
        return pagesExtensionsOptionsMap.get(name)!;
    };

    return {
        registerPage,
        getAllPagesTypes,
        getAllPagesOptions,
        getPageOptions,
        registerPageExtension,
        getAllPagesExtensionsOptions,
        getPageExtensionOptions
    };

}