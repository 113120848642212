import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {User} from './user';

export interface UserFactory {
    createUser: (data?: Partial<User>) => User;
}

export interface UserFactoryDeps {
    idGenerator: IdGenerator;
}

export function createUserFactory({idGenerator}: UserFactoryDeps): UserFactory {

    const createUser = (data: Partial<User> = {}): User => {
        return omitUndefined({
            id: data.id ?? idGenerator.generateId(),
            rolesIds: data.rolesIds ?? [],
            name: data.name ?? '',
            email: data.email ?? '',
            password: data.password ?? undefined
        });
    };

    return {
        createUser
    };

}