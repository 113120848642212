import {ValidationError} from '../validation-error';
import {ValidationRule, ValidationRulesMap} from '../validation-rule';

export interface ObjectRuleProps<O extends object = object> {
    properties?: ValidationRulesMap<O>;
    strict?: boolean;
    partial?: boolean;
}

export function isObject<O extends object = object>({
    properties,
    strict = true,
    partial = false
}: ObjectRuleProps<O> = {}): ValidationRule<O> {
    return (item: unknown): ValidationError[] => {
        if (!item || typeof item !== 'object' || !(item instanceof Object) || Array.isArray(item)) {
            return [{message: `Value has to be object`}];
        }
        const errors: ValidationError[] = [];
        if (properties) {
            for (const [nestedKey, nestedRule] of Object.entries(properties)) {
                // @ts-ignore
                if (partial && item[nestedKey] === undefined) {
                    continue;
                }
                // @ts-ignore
                const nestedErrors: ValidationError[] = nestedRule(item[nestedKey]);
                if (nestedErrors.length > 0) {
                    errors.push({key: nestedKey, errors: nestedErrors});
                }
            }
            if (strict) {
                const allowedProperties: string[] = Object.keys(properties);
                for (const nestedKey of Object.keys(item)) {
                    if (!allowedProperties.includes(nestedKey)) {
                        errors.push({key: nestedKey, errors: [{message: 'Property is forbidden'}]});
                    }
                }
            }
        }
        return errors;
    };
}