import {Store, StoreFactory} from '@webaker/package-store';
import {IdGenerator} from '@webaker/package-utils';
import {CSSTheme} from './css-theme';

export const CSS_THEME_STORE_NAME = 'cssTheme';

export interface CSSThemeStoreState {
    currentCSSTheme: CSSTheme | null;
    cssThemes: CSSTheme[];
}

export interface CSSThemeStore extends Store<CSSThemeStoreState> {
    name: typeof CSS_THEME_STORE_NAME;
    getCurrentCSSTheme: () => CSSTheme;
    setCurrentCSSTheme: (cssTheme: CSSTheme) => void;
}

export interface CSSThemeStoreDeps {
    idGenerator: IdGenerator;
    storeFactory: StoreFactory;
}

export interface CSSThemeStoreConfig {
    defaultCSSTheme: Omit<CSSTheme, 'id'>;
}

export function createCSSThemeStore({idGenerator, storeFactory}: CSSThemeStoreDeps, {defaultCSSTheme}: CSSThemeStoreConfig): CSSThemeStore {

    const defaultCSSThemeWithId = {
        id: idGenerator.generateId(),
        ...defaultCSSTheme
    };

    return storeFactory.createStore<CSSThemeStore>({

        name: CSS_THEME_STORE_NAME,

        state: {
            currentCSSTheme: null,
            cssThemes: []
        },

        getters: {

            getCurrentCSSTheme: ({state}): CSSTheme => {
                return state.currentCSSTheme ?? defaultCSSThemeWithId;
            }

        },

        setters: {

            setCurrentCSSTheme: ({state}, cssTheme: CSSTheme): CSSThemeStoreState => {
                return {
                    ...state,
                    currentCSSTheme: cssTheme
                };
            }

        }

    });
}