import {ComponentViewProps} from '@webaker/app';
import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {SlotComponent} from './slot-component';
import {SlotComponentViewCSS} from './slot-component-view-css';

export function SlotComponentView({
    render,
    childComponents,
    elementRef,
    className
}: ComponentViewProps<SlotComponent>) {

    const css = useThemedCSS(SlotComponentViewCSS);

    return (
        <div ref={elementRef} className={mergeClassNames(css['slot'], className)}>
            {render(childComponents)}
        </div>
    );

}