import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SideLayoutClassName = 'sideLayout';

export interface SideLayoutCSSProps extends CSSThemeProps {

}

export function SideLayoutCSS({}: SideLayoutCSSProps) {
    return css<SideLayoutClassName>`

        .sideLayout {
            align-items: stretch;
            display: flex;
            min-height: 100vh;
            overflow-x: hidden;
            overflow-y: auto;
        }

    `;
}