import {useThemedCSS} from '@webaker/package-css-theme';
import {useStore} from '@webaker/package-store';
import {ReactElement} from 'react';
import {AppStore} from './app-store';
import {PageRenderer} from './page/page-renderer';
import {ResetCSS} from './reset-css';

export interface AppRenderer {
    renderApp: (params?: RenderAppParams) => ReactElement;
}

export interface RenderAppParams {

}

export interface RenderAppProps extends RenderAppParams {

}

export interface AppRendererDeps {
    appStore: AppStore;
    pageRenderer: PageRenderer;
}

export function createAppRenderer({appStore, pageRenderer}: AppRendererDeps): AppRenderer {

    const RenderApp = ({}: RenderAppProps): ReactElement => {
        useThemedCSS(ResetCSS);
        const localAppStore = useStore(appStore);
        const page = localAppStore.getPage();
        return pageRenderer.renderPage(page);
    };

    const renderApp = ({}: RenderAppParams = {}): ReactElement => {
        return (
            <RenderApp/>
        );
    };

    return {
        renderApp
    };

}