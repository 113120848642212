import {Provider} from '@webaker/package-deps';
import {AppMode, DEVELOPMENT, IdGenerator, JSONEncoder, MemoFactory} from '@webaker/package-utils';
import {createLoggerStorePlugin} from '../plugins/logger-store-plugin';
import {createStoreDecorator, StoreDecorator} from '../store-decorator';
import {createStoreFactory, StoreFactory} from '../store-factory';
import {StorePlugin} from '../store-plugin';
import {createStoreRegistry, StoreRegistry} from '../store-registry';
import {createStoreWatcher, StoreWatcher} from '../store-watcher';
import {createDevtoolsStorePlugin} from './plugins/devtools-store-plugin';
import {createStateInjector, StateInjector} from './state-injector';
import {createStateSynchronizer, StateSynchronizer} from './state-synchronizer';

export interface ProvidedStoreClientDeps {
    devtoolsStorePlugin: StorePlugin;
    loggerStorePlugin: StorePlugin;
    stateInjector: StateInjector;
    stateSynchronizer: StateSynchronizer;
    storeDecorator: StoreDecorator;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
    storeWatcher: StoreWatcher;
}

export interface RequiredStoreClientDeps {
    idGenerator: IdGenerator;
    jsonEncoder: JSONEncoder;
    memoFactory: MemoFactory;
}

export interface StoreClientDeps extends ProvidedStoreClientDeps, RequiredStoreClientDeps {

}

export interface StoreClientConfig {
    appUrl: string;
    appMode: AppMode;
    appName: string;
    logStores: boolean;
    encodeState: boolean;
}

export type StoreClientProvider = Provider<StoreClientDeps, StoreClientConfig>;

export function createStoreClientProvider(): StoreClientProvider {
    return {

        registerDependencies: async ({register}, config) => {
            register('stateInjector', ({resolve}) => {
                return createStateInjector({
                    jsonEncoder: resolve('jsonEncoder'),
                    storeRegistry: resolve('storeRegistry'),
                }, {
                    encodeState: config.encodeState
                });
            });
            register('stateSynchronizer', () => {
                return createStateSynchronizer();
            });
            register('storeDecorator', () => {
                return createStoreDecorator();
            });
            register('storeFactory', ({resolve}) => {
                return createStoreFactory({
                    memoFactory: resolve('memoFactory')
                });
            });
            register('storeRegistry', ({resolve}) => {
                return createStoreRegistry({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('storeWatcher', ({resolve}) => {
                return createStoreWatcher({
                    storeDecorator: resolve('storeDecorator')
                });
            });
            register('devtoolsStorePlugin', () => {
                const {hostname} = new URL(config.appUrl);
                return createDevtoolsStorePlugin({
                    name: `${config.appName} (${hostname})`
                });
            });
            register('loggerStorePlugin', () => {
                return createLoggerStorePlugin();
            });
        },

        registerServices: async ({resolve}, config) => {
            const storeRegistry = resolve('storeRegistry');
            if (config.appMode === DEVELOPMENT) {
                const devtoolsStorePlugin = resolve('devtoolsStorePlugin');
                storeRegistry.registerPlugin(devtoolsStorePlugin);
            }
            if (config.logStores) {
                const loggerStorePlugin = resolve('loggerStorePlugin');
                storeRegistry.registerPlugin(loggerStorePlugin);
            }
        },

        runServices: async ({resolve}) => {
            const stateInjector = resolve('stateInjector');
            await stateInjector.injectState();
        }

    };
}