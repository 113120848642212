import {TextField} from './text-field';

export interface DatePickerProps {
    value: string | null;
    onChange?: (event: DatePickerChangeEvent) => void;
}

export interface DatePickerChangeEvent {
    value: string | null;
}

// TODO
export function DatePicker({value, onChange}: DatePickerProps) {

    return (
        <TextField value={value}
                   onChange={onChange}/>
    );

}