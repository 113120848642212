import {Api} from '@webaker/package-api';
import {Theme} from '@webaker/package-theme';

export const THEME_API_NAME = 'theme';

export interface ThemeApi extends Api {
    name: typeof THEME_API_NAME;
    getThemesByIds: (themesIds: Theme['id'][]) => Promise<Theme[]>;
    getAllThemes: () => Promise<Theme[]>;
    saveThemes: (themes: Theme[]) => Promise<void>;
}