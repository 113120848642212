// @ts-ignore
import {jsx as _jsx, jsxs as _jsxs} from 'react/jsx-runtime';
import {jsxMemo} from '../jsx-memo';
import {getOverriddenComponent, getOverriddenProps, isComponentOverridable} from '../jsx-override';

export function jsx(type: any, props: any, key?: string): any {
    if (isComponentOverridable(type, props)) {
        return _jsx(jsxMemo(getOverriddenComponent(type)), getOverriddenProps(props, key), key);
    }
    return _jsx(jsxMemo(type), getOverriddenProps(props), key);
}

export function jsxs(type: any, props: any, key?: string): any {
    if (isComponentOverridable(type, props)) {
        return _jsxs(jsxMemo(getOverriddenComponent(type)), getOverriddenProps(props, key), key);
    }
    return _jsxs(jsxMemo(type), getOverriddenProps(props), key);
}