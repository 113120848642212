import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {ModalButtonsCSS} from './modal-buttons-css';

export interface ModalButtonsProps {
    children?: ReactNode;
    className?: string;
}

export function ModalButtons({children, className}: ModalButtonsProps) {

    const css = useThemedCSS(ModalButtonsCSS);

    return (
        <div className={mergeClassNames(css['modalButtons'], className)}>
            {children}
        </div>
    );

}