export function camelCase(text: string): string {
    return text.toLowerCase().replace(/[^A-Z0-9]+([A-Z])/gi, (_match: string, letter: string) => {
        return letter.toUpperCase();
    }).replace(/[^A-Z0-9]+/gi, '');
}

export function dashCase(text: string): string {
    return text.replace(/[A-Z]+/g, (match: string) => {
        return '-' + match.toLowerCase();
    }).replace(/[^A-Z0-9]+/gi, '-').replace(/-{2,}/g, '-').replace(/^-|-$/g, '');
}

export function underscoreCase(text: string): string {
    return text.replace(/[A-Z]+/g, (match: string) => {
        return '_' + match.toLowerCase();
    }).replace(/[^A-Z0-9]+/gi, '_').replace(/-{2,}/g, '_').replace(/^_|_$/g, '');
}

export function wordsCase(text: string): string {
    return text.replace(/[A-Z]+/g, (match: string) => {
        return ' ' + match;
    }).replace(/[^A-Z0-9]+/gi, ' ').replace(/\s{2,}/g, '-').replace(/^\s|\s$/g, '');
}

export function capitalizedWordsCase(text: string): string {
    return wordsCase(text).replace(/(^|\s)([A-Z])/gi, (_match: string, prefix: string, letter: string) => {
        return prefix + letter.toUpperCase();
    });
}

export function capitalize<T extends string = string>(text: T): Capitalize<T> {
    return (text.charAt(0).toUpperCase() + text.slice(1)) as Capitalize<T>;
}
