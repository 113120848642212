import {DependencyContainer, logConfig, logContainer, Provider} from '@webaker/package-deps';
import {AppMode, DEVELOPMENT} from '../../utils/app-mode';
import {shareConfig} from '../../utils/share-config';
import {shareDependencies} from '../../utils/share-dependencies';

export interface BaseClientAppProvidedDeps {

}

export interface BaseClientAppRequiredDeps {

}

export interface BaseClientAppConfig {
    appMode: AppMode;
}

export type BaseClientAppDeps = BaseClientAppProvidedDeps & BaseClientAppRequiredDeps;

export type BaseClientAppProvider = Provider<BaseClientAppDeps, BaseClientAppConfig>;

export function createBaseClientAppProvider(): BaseClientAppProvider {
    return {

        runMain: async (container: DependencyContainer<any>, config: any) => {
            if (config.appMode === DEVELOPMENT) {
                shareDependencies(window, container);
                shareConfig(window, config);
                logContainer(container);
                logConfig(config);
            }
        }

    };
}