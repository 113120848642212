import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {HeaderCSS} from './header-css';

export interface HeaderProps {
    children: ReactNode;
    className?: string;
}

export function Header({children, className}: HeaderProps) {

    const css = useThemedCSS(HeaderCSS, {});

    return (
        <div className={mergeClassNames(css['header'], className)}>
            {children}
        </div>
    );
    
}