export interface ConfigNotRegisteredErrorDetails {
    key: string;
}

export class ConfigNotRegisteredError extends Error {

    name = 'CONFIG_NOT_REGISTERED';

    details: ConfigNotRegisteredErrorDetails;

    constructor(message: string, details: ConfigNotRegisteredErrorDetails) {
        super(message);
        this.details = details;
    }

}