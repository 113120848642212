import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {ButtonGroupCSS, ButtonGroupPrivateCSS} from './button-group-css';

export interface ButtonGroupProps {
    connect?: boolean;
    align?: ButtonGroupAlign;
    margin?: CSSThemeSize;
    className?: string;
    children?: ReactNode;
}

export type ButtonGroupAlign = 'start' | 'center' | 'stretch' | 'end';

export function ButtonGroup({
    connect = false,
    align = 'center',
    margin = 'none',
    className,
    children
}: ButtonGroupProps) {

    const css = useThemedCSSBundle([
        ButtonGroupCSS,
        ButtonGroupPrivateCSS
    ], {
        align,
        margin
    });

    return (
        <div className={mergeClassNames(
            css['buttonGroup'],
            connect && css['is-connected'],
            className
        )}>
            {children}
        </div>
    );

}