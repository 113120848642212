import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type EmptyIndicatorClassName = 'emptyIndicator';

export interface EmptyIndicatorCSSProps extends CSSThemeProps {

}

export function EmptyIndicatorCSS({theme}: EmptyIndicatorCSSProps) {
    return css<EmptyIndicatorClassName>`

        .emptyIndicator {
            position: absolute;
            inset: 0;
            pointer-events: none;
            z-index: -1;

            &:before, &:after {
                content: '';
                display: block;
                width: fit-content;
                aspect-ratio: 1;
                position: absolute;
                left: 50%;
                top: 50%;
                transform-origin: 100% 0;
                transform: translate(-100%, 0) rotate(45deg);
                border-width: 1px 1px 0 0;
                border-style: dashed;
                border-color: ${theme.color.gray};
            }

            &:after {
                transform-origin: 0 0;
                transform: translate(0, 0) rotate(-45deg);
                border-width: 1px 0 0 1px;
            }
        }

    `;
}

export interface EmptyIndicatorPrivateCSSProps {
    size: string;
}

export function EmptyIndicatorPrivateCSS({size}: EmptyIndicatorPrivateCSSProps) {
    return css<EmptyIndicatorClassName>`

        .emptyIndicator {
            &:before, &:after {
                height: ${size};
            }
        }

    `;
}