import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip, useToast} from '@webaker/package-ui';
import {useSafeHotkey} from '@webaker/package-utils';
import {useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';

export interface CopyFileButtonProps {

}

export interface CopyFileButtonDeps {
    fileManagerStore: FileManagerStore;
}

export function CopyFileButton({}: CopyFileButtonProps) {

    const fileManagerStore = useStore(useDependency<CopyFileButtonDeps>()('fileManagerStore'));
    const isAnyNodeSelected = fileManagerStore.getSelectedNodes().length > 0;
    const toast = useToast();

    const copyFiles = useCallback(() => {
        if (isAnyNodeSelected) {
            const selectedNodesText = fileManagerStore.getSelectedNodesText();
            fileManagerStore.copyNodes();
            toast(`${selectedNodesText} has been copied`);
        }
    }, [isAnyNodeSelected]);

    useSafeHotkey('Control+C', copyFiles);

    return (
        <Tooltip title="Copy"
                 color={isAnyNodeSelected ? 'dark' : 'gray'}>
            <Button color="info"
                    style="text"
                    icon={<MdIcon name="file_copy"/>}
                    onClick={copyFiles}
                    disabled={!isAnyNodeSelected}/>
        </Tooltip>
    );

}