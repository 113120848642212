import {IdGenerator} from '@webaker/package-utils';
import {MultiState} from './multi-state';
import {Store, StoreSource} from './store';
import {StorePlugin} from './store-plugin';

export interface StoreRegistry {
    registerStore: (store: Store) => void;
    registerPlugin: (plugin: StorePlugin) => void;
    getStoresNames: () => Store['name'][];
    getStoreByName: <S extends Store = Store>(name: S['name']) => S | null;
    getGlobalId: () => string;
    getGlobalState: (source?: StoreSource) => MultiState;
    setGlobalState: (state: MultiState, source?: StoreSource) => void;
}

export interface StoreRegistryDeps {
    idGenerator: IdGenerator;
}

export const STATE_ID_LENGTH: number = 32;

export function createStoreRegistry({idGenerator}: StoreRegistryDeps): StoreRegistry {

    let id = idGenerator.generateId(STATE_ID_LENGTH);

    const registeredStores: Map<string, Store> = new Map();

    const registeredPlugins: Set<StorePlugin> = new Set();

    const registerStore = (store: Store) => {
        if (registeredStores.has(store.name)) {
            return;
        }
        registeredStores.set(store.name, store);
        registeredPlugins.forEach((plugin: StorePlugin): void => {
            plugin(store);
        });
    };

    const registerPlugin = (plugin: StorePlugin) => {
        if (registeredPlugins.has(plugin)) {
            return;
        }
        registeredPlugins.add(plugin);
        registeredStores.forEach((store: Store): void => {
            plugin(store);
        });
    };

    const getStoresNames = (): Store['name'][] => {
        return Array.from(registeredStores.keys());
    };

    const getStoreByName = <S extends Store = Store>(name: S['name']): S | null => {
        return registeredStores.get(name) as S ?? null;
    };

    const getGlobalId = (): string => {
        return id;
    };

    const getGlobalState = (source?: StoreSource): MultiState => {
        return Array.from(registeredStores.values()).reduce((state: MultiState, store: Store): MultiState => {
            return {
                ...state,
                [store.name]: store.get(source)
            };
        }, {id});
    };

    const setGlobalState = (state: MultiState, source?: StoreSource): void => {
        if (state.id) {
            id = state.id;
        }
        Object.keys(state).forEach((name: string): void => {
            const store: Store | undefined = registeredStores.get(name);
            if (store) {
                store.set(state[name], source);
            }
        });
    };

    return {
        registerStore,
        registerPlugin,
        getStoresNames,
        getStoreByName,
        getGlobalId,
        getGlobalState,
        setGlobalState
    };

}