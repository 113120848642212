export type LowerAlphaKey =
    'a' |
    'b' |
    'c' |
    'd' |
    'e' |
    'f' |
    'g' |
    'h' |
    'i' |
    'j' |
    'k' |
    'l' |
    'm' |
    'n' |
    'o' |
    'p' |
    'q' |
    'r' |
    's' |
    't' |
    'u' |
    'v' |
    'w' |
    'x' |
    'y' |
    'z';

export type UpperAlphaKey =
    'A' |
    'B' |
    'C' |
    'D' |
    'E' |
    'F' |
    'G' |
    'H' |
    'I' |
    'J' |
    'K' |
    'L' |
    'M' |
    'N' |
    'O' |
    'P' |
    'Q' |
    'R' |
    'S' |
    'T' |
    'U' |
    'V' |
    'W' |
    'X' |
    'Y' |
    'Z';

export type NumericKeypadKey =
    'Decimal' |
    'Key11' |
    'Key12' |
    'Multiply' |
    'Add' |
    'Clear' |
    'Divide' |
    'Subtract' |
    'Separator' |
    '0' |
    '1' |
    '2' |
    '3' |
    '4' |
    '5' |
    '6' |
    '7' |
    '8' |
    '9';

export type WhitespaceKey =
    'Enter' |
    'Space' |
    'Tab';

export type ModifierKey =
    'Alt' |
    'Control' |
    'Shift';

export type ExtraModifierKey =
    'AltGraph' |
    'CapsLock' |
    'Fn' |
    'FnLock' |
    'Hyper' |
    'Meta' |
    'NumLock' |
    'ScrollLock' |
    'Super' |
    'Symbol' |
    'SymbolLock';

export type NavigationKey =
    'ArrowDown' |
    'ArrowLeft' |
    'ArrowRight' |
    'ArrowUp' |
    'End' |
    'Home' |
    'PageDown' |
    'PageUp';

export type EditingKey =
    'Escape' |
    'Backspace' |
    'Clear' |
    'Copy' |
    'CrSel' |
    'Cut' |
    'Delete' |
    'EraseEof' |
    'ExSel' |
    'Insert' |
    'Paste' |
    'Redo' |
    'Undo';

export type KeyboardKey =
    LowerAlphaKey
    | UpperAlphaKey
    | NumericKeypadKey
    | WhitespaceKey
    | ModifierKey
    | ExtraModifierKey
    | NavigationKey
    | EditingKey;