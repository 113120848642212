import {ApiClientFactory} from '@webaker/package-api/client';
import {WEBSITE_MANAGER_PAGE_API_NAME, WebsiteManagerPageApi} from '../website-manager-page-api';

export interface WebsiteManagerPageClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createWebsiteManagerPageClientApi({apiClientFactory}: WebsiteManagerPageClientApiDeps): WebsiteManagerPageApi {
    return apiClientFactory.createApi<WebsiteManagerPageApi>(WEBSITE_MANAGER_PAGE_API_NAME, {
        savePages: ['pages']
    });
}