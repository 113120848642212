import {GeneralError} from '@webaker/package-utils';
import {Event, EventOptions} from './event';
import {EventAbortedError} from './event-aborted-error';
import {EventActionCallback, EventActions, ExtendedEventActions} from './event-actions';
import {EventListener, EventListenerOptions} from './event-listener';

export interface EventBus {
    addEventListener: <E extends Event = Event>(eventName: E['name'], listener: EventListener<E>, options?: EventListenerOptions) => void;
    removeEventListener: <E extends Event = Event>(eventName: E['name'], listener: EventListener<E>) => void;
    dispatchEvent: <E extends Event = Event>(event: E, options?: EventOptions) => Promise<E>;
}

const REJECTED_PROMISE_STATUS = 'rejected';

export interface EventBusDeps {

}

export function createEventBus({}: EventBusDeps = {}): EventBus {

    const listenersSetMap = new Map<Event['name'], Set<EventListener<any>>>();
    const listenerOptionsMap = new Map<EventListener<any>, EventListenerOptions>();

    const addEventListener = <E extends Event = Event>(eventName: E['name'], listener: EventListener<E>, options: EventListenerOptions = {}): void => {
        if (!listenersSetMap.has(eventName)) {
            listenersSetMap.set(eventName, new Set<EventListener>());
        }
        listenersSetMap.get(eventName)?.add(listener);
        listenerOptionsMap.set(listener, options);
    };

    const removeEventListener = <E extends Event = Event>(eventName: E['name'], listener: EventListener<E>): void => {
        listenersSetMap.get(eventName)?.delete(listener);
        listenerOptionsMap.delete(listener);
    };

    const dispatchEvent = async <E extends Event = Event>(event: E, options: EventOptions = {}): Promise<E> => {
        const listeners = getEventListeners<E>(event);
        await removeDisposableListeners<E>(event, listeners);
        await runListeners<E>(event, listeners, options);
        return event;
    };

    const getEventListeners = <E extends Event = Event>(event: E): EventListener<E>[] => {
        return Array.from(listenersSetMap.get(event.name) ?? []);
    };

    const removeDisposableListeners = async <E extends Event = Event>(event: E, listeners: EventListener<E>[]): Promise<void> => {
        listeners.forEach((listener: EventListener<E>): void => {
            if (listenerOptionsMap.get(listener)?.once) {
                removeEventListener(event.name, listener);
            }
        });
    };

    const runListeners = async <E extends Event = Event>(event: E, listeners: EventListener<E>[], options: EventOptions = {}): Promise<void> => {
        const extendedActions = createExtendedEventActions();
        const actions = convertToEventActions(extendedActions);
        const promisesResults = await Promise.allSettled(
            listeners.map(async (listener: EventListener<E>): Promise<void> => {
                await listener(event, actions);
            })
        );
        const error = getPromisesError(event, promisesResults);
        if (error) {
            extendedActions.error();
            if (options.abortable ?? true) {
                throw error;
            }
        }
        extendedActions.success();
    };

    const createExtendedEventActions = (): ExtendedEventActions => {
        const successCallbacks = new Set<EventActionCallback>();
        const errorCallbacks = new Set<EventActionCallback>();
        const abort = (message?: string): void => {
            throw new GeneralError(message);
        };
        const onSuccess = (callback: EventActionCallback): void => {
            successCallbacks.add(callback);
        };
        const onError = (callback: EventActionCallback): void => {
            errorCallbacks.add(callback);
        };
        const success = (): void => {
            successCallbacks.forEach((callback: EventActionCallback): void => {
                callback();
            });
        };
        const error = (): void => {
            errorCallbacks.forEach((callback: EventActionCallback): void => {
                callback();
            });
        };
        return {
            abort,
            onSuccess,
            onError,
            success,
            error
        };
    };

    const convertToEventActions = (extendedActions: ExtendedEventActions): EventActions => {
        return {
            abort: extendedActions.abort,
            onSuccess: extendedActions.onSuccess,
            onError: extendedActions.onError
        };
    };

    const getPromisesError = (event: Event, promisesResults: PromiseSettledResult<void>[]): EventAbortedError | null => {
        const errors = promisesResults.map((result: PromiseSettledResult<void>): Error | null => {
            return result.status === REJECTED_PROMISE_STATUS ? result.reason : null;
        }).filter(Boolean) as Error[];
        if (errors.length === 0) {
            return null;
        }
        return new EventAbortedError(`Event [${event.name}] has been aborted`, {
            event,
            errors
        });
    };

    return {
        addEventListener,
        removeEventListener,
        dispatchEvent
    };

}