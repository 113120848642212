import {Api} from '@webaker/package-api';
import {User} from './user';

export const USER_API_NAME = 'user';

export interface UserApi extends Api {
    name: typeof USER_API_NAME;
    getLoggedUser: () => Promise<User | null>;
    loginUserByCredentials: (email: string, password: string) => Promise<User | null>;
    logoutUser: () => Promise<void>;
    getAllUsers: () => Promise<User[]>;
}