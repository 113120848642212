import {MutableRefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {Input, InputChangeEvent} from './input';

export interface PasswordFieldProps {
    value: string | null;
    onChange?: (event: PasswordFieldChangeEvent) => void;
    placeholder?: string | null;
    disabled?: boolean;
    validate?: (value: string | null) => boolean;
    className?: string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface PasswordFieldChangeEvent {
    value: string | null;
}

export function PasswordField({
    value,
    onChange,
    placeholder,
    disabled,
    validate,
    className,
    inputRef
}: PasswordFieldProps) {

    const [localValue, setLocalValue] = useState(value);

    const handleChange = useCallback((event: InputChangeEvent) => {
        setLocalValue(event.value);
        if (!validate || validate(event.value)) {
            onChange?.({value: event.value});
        }
    }, [onChange, validate]);

    const invalid = useMemo(() => {
        return validate && !validate(localValue);
    }, [localValue, validate]);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <Input className={className}
               value={localValue}
               onChange={handleChange}
               password={true}
               placeholder={placeholder}
               disabled={disabled}
               invalid={invalid}
               inputRef={inputRef}/>
    );

}