import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {FlexBox} from './flex-box';
import {mapFlexAlignToCSS, mapFlexDirectionToCSS, mapFlexJustifyToCSS, mapFlexWrapToCSS} from './flex-box-utils';

export type FlexBoxViewClassName = 'flexBox';

export interface FlexBoxViewCSSProps extends CSSThemeProps {
    flexBox: FlexBox;
}

export function FlexBoxViewCSS({flexBox}: FlexBoxViewCSSProps) {
    return css<FlexBoxViewClassName>`

        .flexBox {

            display: flex;
            flex-direction: ${mapFlexDirectionToCSS(flexBox.direction)};
            align-items: ${mapFlexAlignToCSS(flexBox.align)};
            justify-content: ${mapFlexJustifyToCSS(flexBox.justify)};
            flex-wrap: ${mapFlexWrapToCSS(flexBox.wrap)};
            gap: ${flexBox.gap};

            ${flexBox.justify === 'stretch' && (flexBox.direction === 'row' || flexBox.direction === 'rowReverse') ? `
                > * {
                    width: 100%;
                }
            ` : ``}

            ${flexBox.justify === 'stretch' && (flexBox.direction === 'column' || flexBox.direction === 'columnReverse') ? `
                > * {
                    height: 100%;
                }
            ` : ``}

        }
    }

    `;
}