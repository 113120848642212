export interface DependencyNotRegisteredErrorDetails {
    name: string;
    stack: string[];
}

export class DependencyNotRegisteredError extends Error {

    name = 'DEPENDENCY_NOT_REGISTERED';

    details: DependencyNotRegisteredErrorDetails;

    constructor(message: string, details: DependencyNotRegisteredErrorDetails) {
        super(message);
        this.details = details;
    }

}