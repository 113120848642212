import {ComponentFactoryFunction, OmitComponent, PartialComponent} from '@webaker/app';
import {ThemeComponent} from './theme-component';

export function createThemeComponentFactoryFunction(): ComponentFactoryFunction<ThemeComponent> {
    return (data: PartialComponent<ThemeComponent>): OmitComponent<ThemeComponent> => {
        return {
            themeId: data.themeId ?? undefined,
            sharedThemeId: data.sharedThemeId ?? undefined
        };
    };
}