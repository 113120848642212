import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {StoreFactory, StoreRegistry} from '@webaker/package-store';
import {IdGenerator} from '@webaker/package-utils';
import {CSSTheme} from '../css-theme';
import {createCSSThemeStore, CSSThemeStore} from '../css-theme-store';

export interface ProvidedCSSThemeClientDeps {
    cssThemeStore: CSSThemeStore;
}

export interface RequiredCSSThemeClientDeps {
    apiClientFactory: ApiClientFactory;
    idGenerator: IdGenerator;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
}

export interface CSSThemeClientDeps extends ProvidedCSSThemeClientDeps, RequiredCSSThemeClientDeps {

}

export interface CSSThemeClientConfig {
    defaultCSSTheme: Omit<CSSTheme, 'id'>;
}

export type CssThemeClientProvider = Provider<CSSThemeClientDeps, CSSThemeClientConfig>;

export function createCSSThemeClientProvider(): CssThemeClientProvider {
    return {

        registerDependencies: async ({register}, config) => {
            register('cssThemeStore', ({resolve}) => {
                return createCSSThemeStore({
                    idGenerator: resolve('idGenerator'),
                    storeFactory: resolve('storeFactory')
                }, {
                    defaultCSSTheme: config.defaultCSSTheme
                });
            });
        },

        registerServices: async ({resolve}) => {
            const storeRegistry = resolve('storeRegistry');
            const cssThemeStore = resolve('cssThemeStore');
            storeRegistry.registerStore(cssThemeStore);
        }

    };
}