import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type ModalInfoClassName = 'modalInfo' | 'is-centered';

export interface ModalInfoCSSProps extends CSSThemeProps {

}

export function ModalInfoCSS({theme}: ModalInfoCSSProps) {
    return css<ModalInfoClassName>`

        .modalInfo {
            margin: ${theme.spacing.large} 0;

            &:first-of-type {
                margin-top: 0;
            }

            &.is-centered {
                text-align: center;
            }

            ul, ol {
                padding-left: ${theme.spacing.large};
            }

            li {
                list-style-type: disc;
            }
        }

    `;
}