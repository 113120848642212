import {FSNode} from './fs-node';

export interface File extends FSNode {
    public?: boolean;
    alias?: string;
    size?: number;
    url?: string;
    contents?: Blob | Buffer;
}

export type WithContents<F extends File> = F & { contents: NonNullable<F['contents']> };

export type FileExtension = string;