import {isId, isNumber, isObject, isString, optional, ValidationErrorsBag, Validator} from '@webaker/package-validation';
import {Directory} from './directory';
import {FS_NODE_NAME_REGEXP, MAX_FS_NODE_NAME_LENGTH, MIN_FS_NODE_NAME_LENGTH} from './file-config';

export interface DirectoryValidator {
    validateDirectory: (file: Directory) => boolean;
    assertDirectory: (file: Directory) => void;
    getDirectoryErrors: (file: Directory) => ValidationErrorsBag;
}

export interface DirectoryValidatorDeps {
    validator: Validator;
}

export function createDirectoryValidator({validator}: DirectoryValidatorDeps): DirectoryValidator {

    // fix for TS2775
    const baseValidator: Validator = validator;

    const isDirectory = isObject<Directory>({
        properties: {
            id: isId(),
            parentDirectoryId: optional(isId()),
            name: isString({
                minLength: MIN_FS_NODE_NAME_LENGTH,
                maxLength: MAX_FS_NODE_NAME_LENGTH,
                match: FS_NODE_NAME_REGEXP
            }),
            size: optional(isNumber({min: 0}))
        }
    });

    const validateDirectory = (item: unknown): item is Directory => {
        return baseValidator.validate(item, isDirectory);
    };

    const assertDirectory = (item: unknown): asserts item is Directory => {
        baseValidator.assert(item, isDirectory);
    };

    const getDirectoryErrors = (item: unknown): ValidationErrorsBag => {
        return baseValidator.errors(item, isDirectory);
    };

    return {
        validateDirectory,
        assertDirectory,
        getDirectoryErrors
    };

}