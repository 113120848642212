import {ApiClientFactory} from '@webaker/package-api/client';
import {APP_API_NAME, AppApi} from '../app-api';

export interface AppClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createAppClientApi({apiClientFactory}: AppClientApiDeps): AppApi {

    return apiClientFactory.createApi<AppApi>(APP_API_NAME, {
        getPageContentById: ['id'],
        getPageContentByPath: ['path'],
        getPageContentByType: ['type']
    });

}