import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MouseEvent, ReactNode} from 'react';
import {OverlayCSS} from './overlay-css';
import {Portal} from './portal';

export interface OverlayProps {
    transparent?: boolean;
    children?: ReactNode;
    className?: string;
}

export function Overlay({transparent, children, className}: OverlayProps) {

    const css = useThemedCSS(OverlayCSS);
    const handleMouseDown = (event: MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <Portal fixed={true}>
            <div onMouseDown={handleMouseDown}
                 className={mergeClassNames(
                     css['overlay'],
                     transparent && css['is-transparent'],
                     className
                 )}>
                {children}
            </div>
        </Portal>
    );

}