import {FlexBoxValidationRule} from '@webaker/package-flex-box';
import {isBoolean, isEnum, isEqual, isId, isObject, isString, optional, ValidationErrorsBag, ValidationRule, ValidationRulesMap, Validator} from '@webaker/package-validation';
import {Component} from './component';
import {ComponentRegistry} from './component-registry';
import {OmitComponent} from './component-types';

export interface ComponentValidator {
    registerRules: <C extends Component = Component>(rules: ComponentValidationRules<C>) => void;
    registerRulesByType: <C extends Component = Component>(type: C['type'], rules: ComponentValidationRules<C>) => void;
    validateComponent: <C extends Component = Component>(item: unknown, options?: ComponentRuleOptions) => item is C;
    assertComponent: <C extends Component = Component>(item: unknown, options?: ComponentRuleOptions) => asserts item is C;
    getComponentErrors: (item: unknown, options?: ComponentRuleOptions) => ValidationErrorsBag;
}

export interface ComponentValidatorDeps {
    componentRegistry: ComponentRegistry;
    flexBoxValidationRule: FlexBoxValidationRule;
    validator: Validator;
}

export interface ComponentRuleOptions {
    partial?: boolean;
}

export interface ComponentRuleOptionsWithType<C extends Component = Component> extends ComponentRuleOptions {
    type?: C['type'];
}

export type ComponentValidationRules<C extends Component = Component> = ValidationRulesMap<OmitComponent<C>>;

export function createComponentValidator({
    componentRegistry,
    flexBoxValidationRule,
    validator: validatorAlias
}: ComponentValidatorDeps): ComponentValidator {

    const validationRulesSet: Set<ComponentValidationRules<any>> = new Set();
    const validationRulesByTypeMap: Map<string, ComponentValidationRules<any>> = new Map();

    // fix for TS2775
    const validator: Validator = validatorAlias;

    const registerRules = <C extends Component = Component>(rules: ComponentValidationRules<C>): void => {
        validationRulesSet.add(rules);
    };

    const registerRulesByType = <C extends Component = Component>(type: C['type'], rules: ComponentValidationRules<C>): void => {
        validationRulesByTypeMap.set(type, rules);
    };

    const validateComponent = <C extends Component = Component>(item: unknown, options: ComponentRuleOptions = {}): item is C => {
        return validator.validate(item, isComponent(options))
            && validator.validate(item, isComponent({
                ...options,
                type: item.type
            }));
    };

    const assertComponent = <C extends Component = Component>(item: unknown, options: ComponentRuleOptions = {}): asserts item is C => {
        validator.assert(item, isComponent(options));
        validator.assert(item, isComponent({
            ...options,
            type: item.type
        }));
    };

    const getComponentErrors = (item: unknown, options: ComponentRuleOptions = {}): ValidationErrorsBag => {
        const errors = validator.errors(item, isComponent(options));
        if (!errors.isEmpty()) {
            return errors;
        }
        return validator.errors(item, isComponent({
            ...options,
            type: (item as Component).type
        }));
    };

    const isComponent = <C extends Component = Component>({type, partial}: ComponentRuleOptionsWithType<C> = {}): ValidationRule<C> => {
        const baseRules = getBaseRules();
        const slotRules = getSlotRules(type);
        const extensionsRules = getExtensionsRules();
        const typeRules = getTypeRules(type);
        return isObject<Component>({
            properties: {
                ...baseRules,
                ...slotRules,
                ...extensionsRules,
                ...typeRules
            },
            strict: !!type,
            partial
        }) as ValidationRule<C>;
    };

    const getBaseRules = (type?: Component['type']): ValidationRulesMap<Component> => {
        return {
            id: isId(),
            type: type ? isEqual({
                to: type
            }) : isEnum({
                values: componentRegistry.getAllComponentsTypes()
            }),
            name: optional(isString()),
            share: optional(isBoolean()),
        };
    };

    const getSlotRules = (type?: Component['type']): ValidationRulesMap<Pick<Component, 'slot'>> => {
        const componentOptions = type ? componentRegistry.getComponentOptions(type) : null;
        return componentOptions?.slot ? {slot: flexBoxValidationRule} : {};
    };

    const getExtensionsRules = <C extends Component = Component>(): ComponentValidationRules<C> => {
        return Array.from(validationRulesSet.values()).reduce((
            result: ValidationRulesMap<Partial<C>>,
            rules: ComponentValidationRules<C>
        ): ValidationRulesMap<Partial<C>> => {
            return {...result, ...rules};
        }, {} as ValidationRulesMap<Partial<C>>) as ComponentValidationRules<C>;
    };

    const getTypeRules = <C extends Component = Component>(type?: C['type']): ComponentValidationRules<C> => {
        const typeRules = type ? validationRulesByTypeMap.get(type) : null;
        return typeRules ?? {};
    };

    return {
        registerRules,
        registerRulesByType,
        validateComponent,
        assertComponent,
        getComponentErrors
    };

}