import {ClassNamesMap} from './class-names-map';

export interface ClassNameRegistry {
    registerClassNames: (scope: string, classNames: ClassNamesMap) => void;
    unregisterClassNames: (scope: string) => void;
    getAllClassNames: () => Record<string, ClassNamesMap>;
    getClassNamesByScope: (scope: string) => ClassNamesMap;
    addEventListener: (eventName: ClassNameRegistryEventName, listener: ClassNameRegistryListener) => void;
    removeEventListener: (eventName: ClassNameRegistryEventName, listener: ClassNameRegistryListener) => void;
}

export type ClassNameRegistryListener = (event: ClassNameRegistryEvent) => void;

export interface ClassNameRegistryEvent {
    scope: string;
    classNames?: ClassNamesMap;
}

export type ClassNameRegistryEventName = '*' | string;

export interface ClassNameRegistryDeps {

}

export function createClassNameRegistry({}: ClassNameRegistryDeps = {}): ClassNameRegistry {

    const classNamesMaps: Map<string, ClassNamesMap> = new Map();
    const listenersMap: Map<ClassNameRegistryEventName, Set<ClassNameRegistryListener>> = new Map();

    const registerClassNames = (scope: string, classNames: ClassNamesMap): void => {
        classNamesMaps.set(scope, classNames);
        runEventListener({
            scope,
            classNames
        });
    };

    const unregisterClassNames = (scope: string): void => {
        classNamesMaps.delete(scope);
        runEventListener({
            scope
        });
    };

    const getAllClassNames = (): Record<string, ClassNamesMap> => {
        return Object.fromEntries(classNamesMaps.entries());
    };

    const getClassNamesByScope = (scope: string): ClassNamesMap => {
        return classNamesMaps.get(scope) ?? {};
    };

    const addEventListener = (eventName: ClassNameRegistryEventName, listener: ClassNameRegistryListener): void => {
        const listenersSet = listenersMap.get(eventName) ?? new Set();
        listenersSet.add(listener);
        listenersMap.set(eventName, listenersSet);
    };

    const removeEventListener = (eventName: ClassNameRegistryEventName, listener: ClassNameRegistryListener): void => {
        const listenersSet = listenersMap.get(eventName) ?? new Set();
        listenersSet.delete(listener);
        listenersMap.set(eventName, listenersSet);
    };

    const runEventListener = (event: ClassNameRegistryEvent): void => {
        listenersMap.get(event.scope)?.forEach((listener: ClassNameRegistryListener): void => {
            listener(event);
        });
        listenersMap.get('*')?.forEach((listener: ClassNameRegistryListener): void => {
            listener(event);
        });
    };

    return {
        registerClassNames,
        unregisterClassNames,
        getAllClassNames,
        getClassNamesByScope,
        addEventListener,
        removeEventListener
    };

}