import {Store, StoreFactory} from '@webaker/package-store';
import {File} from './file';

export const FILE_STORE_NAME = 'file';

export interface FileStoreState {
    files: File[];
}

export interface FileStore extends Store<FileStoreState> {
    name: typeof FILE_STORE_NAME;
    getFiles: () => File[];
    getFileById: (id: File['id']) => File | null;
    getFilesByIds: (ids: File['id'][]) => File[];
    setFiles: (files: File[]) => void;
    addFile: (file: File) => void;
    addFiles: (files: File[]) => void;
    deleteFileById: (id: File['id']) => void;
}

export interface FileStoreDeps {
    storeFactory: StoreFactory;
}

export function createFileStore({storeFactory}: FileStoreDeps): FileStore {
    return storeFactory.createStore<FileStore>({

        name: FILE_STORE_NAME,

        state: {
            files: []
        },

        getters: {

            getFiles: ({state}): File[] => {
                return state.files;
            },

            getFileById: ({state, memo}, id: File['id']): File | null => {
                return memo(() => {
                    return state.files.find((file: File): boolean => {
                        return file.id === id;
                    }) ?? null;
                }, [state.files], {id});
            },

            getFilesByIds: ({state, memo}, ids: File['id'][]): File[] => {
                return memo(() => {
                    return state.files.filter((file: File): boolean => {
                        return ids.includes(file.id);
                    });
                }, [state.files], {ids});
            }

        },

        setters: {

            setFiles: ({state}, files: File[]): FileStoreState => {
                return {
                    ...state,
                    files
                };
            },

            addFile: ({state}, file: File): FileStoreState => {
                const hasFile = state.files.some((stateFile: File): boolean => {
                    return stateFile.id === file.id;
                });
                return hasFile ? state : {
                    ...state,
                    files: [
                        ...state.files,
                        file
                    ]
                };
            },

            addFiles: ({state}, files: File[]): FileStoreState => {
                const existingFilesIds = state.files.map((file: File): File['id'] => {
                    return file.id;
                });
                const newFiles = files.filter((file: File): boolean => {
                    return !existingFilesIds.includes(file.id);
                });
                return newFiles.length === 0 ? state : {
                    ...state,
                    files: [
                        ...state.files,
                        ...newFiles
                    ]
                };
            },

            deleteFileById: ({state}, id: File['id']): FileStoreState => {
                return {
                    ...state,
                    files: state.files.filter((file: File): boolean => {
                        return file.id !== id;
                    })
                };
            }

        }

    });
}