import {useDependency} from '@webaker/package-deps';
import {CSSRegistry} from './css-registry';

export const GENERATED_CSS_ID = 'css';

export interface GeneratedCSSProps {

}

export interface GeneratedCSSDeps {
    cssRegistry: CSSRegistry;
}

export function GeneratedCSS({}: GeneratedCSSProps) {
    const cssRegistry = useDependency<GeneratedCSSDeps>()('cssRegistry');
    return (
        <style id={GENERATED_CSS_ID}
               dangerouslySetInnerHTML={{__html: cssRegistry.getCSSText()}}/>
    );
}