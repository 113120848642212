import {useDependency} from '@webaker/package-deps';
import {File, FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Modal, ModalButtons, ModalInfo} from '@webaker/package-ui';
import {useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';

export interface PublishModalProps {
    file: File;
    onClose: () => void;
    onPublish: () => void;
}

export interface PublishModalDeps {
    fileManagerStore: FileManagerStore;
    fileApi: FileApi;
}

export function PublishFileModal({file, onClose, onPublish}: PublishModalProps) {

    const fileApi = useDependency<PublishModalDeps>()('fileApi');
    const fileManagerStore = useStore(useDependency<PublishModalDeps>()('fileManagerStore'));

    // TODO: update also file in fileStore
    const publishFile = useCallback(async () => {
        fileManagerStore.startLoading();
        const savedFile = await fileApi.saveFile({
            ...file,
            public: true
        });
        fileManagerStore.updateFile(savedFile);
        fileManagerStore.stopLoading();
        onPublish();
    }, [file, onPublish]);

    return (
        <Modal title="File access"
               onEnter={publishFile}
               onEscape={onClose}>
            <ModalInfo center={true}>
                This file is not public. Do you want to publish it?
            </ModalInfo>
            <ModalButtons>
                <Button color="dark"
                        style="text"
                        onClick={onClose}>
                    Cancel
                </Button>
                <Button color="success"
                        style="solid"
                        icon={<MdIcon name="public"/>}
                        onClick={publishFile}>
                    Publish
                </Button>
            </ModalButtons>
        </Modal>
    );

}