import {ComponentType, ForwardedRef, forwardRef, useContext} from 'react';
import {JSXContext} from './jsx-context';

const __OVERRIDE = '__override';
const __KEY = '__key';

type OverridableComponent<P extends {} = {}> = ComponentType<P> & {
    [__OVERRIDE]: ComponentType<P>;
}

type PropsWithOverride<P extends {} = {}> = P & {
    [__OVERRIDE]?: boolean;
};

type PropsWithKey<P extends {} = {}> = P & {
    [__KEY]?: string;
};

declare global {
    namespace JSX {
        interface IntrinsicAttributes {
            [__OVERRIDE]?: boolean;
        }
    }
}

export function makeComponentOverridable<P extends {} = {}>(component: ComponentType<P>): asserts component is OverridableComponent<P> {
    if (isComponentOverridable(component)) {
        return;
    }
    const overridableComponent = component as OverridableComponent<P>;
    overridableComponent[__OVERRIDE] = forwardRef(({[__KEY]: key, ...props}: any, ref: ForwardedRef<any>) => {
        const jsxContext = useContext(JSXContext);
        const Component = jsxContext?.jsxRegistry.resolveComponent<P>(component) ?? component;
        return (
            <Component key={key} ref={ref} {...props} {...{[__OVERRIDE]: false}}/>
        );
    });
    overridableComponent[__OVERRIDE].displayName = `Overridable(${component.displayName ?? component.name ?? 'Component'})`;
}

export function isComponentOverridable<P extends {} = {}>(component: ComponentType<P>, props?: PropsWithOverride<P>): component is OverridableComponent<P> {
    return component
        && (typeof component === 'function' || typeof component === 'object')
        && __OVERRIDE in component
        && (!props || typeof props[__OVERRIDE] === 'undefined' || props[__OVERRIDE] === true);
}

export function getOverriddenComponent<P extends {} = {}>(component: OverridableComponent<P>): ComponentType<P> {
    return component[__OVERRIDE];
}

export function getOverriddenProps<P extends {} = {}>(props: P, key?: string): PropsWithKey<P> {
    if (__OVERRIDE in props) {
        // @ts-ignore
        delete props[__OVERRIDE];
    }
    if (key) {
        return {...props, [__KEY]: key};
    }
    return props;
}