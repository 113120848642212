declare global {
    interface Window {
        process: {
            env: Record<string, string>;
        };
    }
}

export function mockEnv(): void {
    if (typeof window !== 'undefined') {
        window.process = {
            env: {}
        } as any;
    }
}