import {useDebugValue, useEffect} from 'react';
import {hasActiveFocus} from '../active-focus';
import {KeyboardKey, ModifierKey} from '../types/keyboard-key';

export type Hotkey =
    `${KeyboardKey}` |
    `${ModifierKey}+${KeyboardKey}` |
    `${ModifierKey}+${ModifierKey}+${KeyboardKey}` |
    `${ModifierKey}+${ModifierKey}+${ModifierKey}+${KeyboardKey}`;

const MODIFIER_KEYS: ModifierKey[] = [
    'Alt',
    'Control',
    'Shift'
];

export type HotkeyHandler = () => void;

export function useHotkey(hotkey: Hotkey, handler: HotkeyHandler, ...deps: unknown[]): void {
    useDebugValue(hotkey);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (isHotkeyPressed(hotkey, event)) {
                event.preventDefault();
                handler();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [hotkey, ...[deps ?? [handler]]]);
}

export function useSafeHotkey(hotkey: Hotkey, handler: HotkeyHandler, ...deps: unknown[]): void {
    useDebugValue(hotkey);
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!hasActiveFocus() && isHotkeyPressed(hotkey, event)) {
                event.preventDefault();
                handler();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [hotkey, ...[deps ?? [handler]]]);
}

function isHotkeyPressed(hotkey: Hotkey, event: KeyboardEvent): boolean {
    const allKeys = hotkey.split('+');
    const modifierKeys = allKeys.slice(0, -1);
    const keyboardKey = allKeys[allKeys.length - 1].toUpperCase();
    const isProperKeyPressed = event.key.toUpperCase() === keyboardKey;
    const areProperModifiersPressed = MODIFIER_KEYS.every((key: ModifierKey): boolean => {
        return event.getModifierState(key) ? modifierKeys.includes(key) : !modifierKeys.includes(key);
    });
    return isProperKeyPressed && areProperModifiersPressed;
}