import {MutableRefObject, useState} from 'react';

export function useReactiveRef<T>(initialValue: T): MutableRefObject<T> {
    const [_, setSignal] = useState({});
    const [ref] = useState(() => {
        let value = initialValue;
        return {
            get current() {
                return value;
            },
            set current(newValue: T) {
                value = newValue;
                setSignal({});
            }
        };
    });
    return ref;
}