import {Api} from '@webaker/package-api';
import {PageContent} from './content/page-content';
import {Page} from './page/page';

export const APP_API_NAME = 'app';

export interface AppApi extends Api {
    name: typeof APP_API_NAME;
    getPageContentById: (id: Page['id']) => Promise<PageContent | null>;
    getPageContentByPath: (path: Page['path']) => Promise<PageContent | null>;
    getPageContentByType: (type: Page['type']) => Promise<PageContent | null>;
}