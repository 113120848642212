import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {ModalInfoCSS} from './modal-info-css';

export interface ModalInfoProps {
    center?: boolean;
    children?: ReactNode;
    className?: string;
}

export function ModalInfo({center, children, className}: ModalInfoProps) {

    const css = useThemedCSS(ModalInfoCSS);

    return (
        <div className={mergeClassNames(
            css['modalInfo'],
            center && css['is-centered'],
            className
        )}>
            {children}
        </div>
    );

}