export interface LogConfig {
    logMigrations: boolean;
    logStores: boolean;
}

export function getLogConfig(): LogConfig {

    const logMigrations = true;
    const logStores = false;

    return {
        logMigrations,
        logStores
    };

}