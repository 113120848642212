import {File, FileExtension, WithContents} from '../file';
import {FileFactory} from '../file-factory';
import {FilePicker} from '../file-picker';
import {FileRegistry} from '../file-registry';

export interface FilePickerClientDeps {
    fileFactory: FileFactory;
    fileRegistry: FileRegistry;
}

export function createFileClientPicker({fileFactory, fileRegistry}: FilePickerClientDeps): FilePicker {

    const selectFile = async <F extends File = File>(): Promise<F | null> => {
        return (await selectFilesFromInput<F>())[0] ?? null;
    };

    const selectFiles = async <F extends File = File>(): Promise<F[]> => {
        return await selectFilesFromInput<F>({
            multiple: true
        });
    };

    const selectFileByTypes = async <F extends File = File>(types: string[]): Promise<F | null> => {
        const extensions = fileRegistry.getFileExtensionsByTypes(types);
        return await selectFileByExtensions(extensions);
    };

    const selectFilesByTypes = async <F extends File = File>(types: string[]): Promise<F[]> => {
        const extensions = fileRegistry.getFileExtensionsByTypes(types);
        return await selectFilesByExtensions(extensions);
    };

    const selectFileByExtensions = async <F extends File = File>(extensions: FileExtension[]): Promise<F | null> => {
        return (await selectFilesFromInput<F>({
            accept: extensions.map((extension: FileExtension): string => {
                return '.' + extension;
            }).join(', ')
        }))[0] ?? null;
    };

    const selectFilesByExtensions = async <F extends File = File>(extensions: FileExtension[]): Promise<F[]> => {
        return await selectFilesFromInput<F>({
            accept: extensions.map((extension: FileExtension): string => {
                return '.' + extension;
            }).join(', '),
            multiple: true
        });
    };

    const selectFilesFromInput = <F extends File = File>(attributes: Partial<HTMLInputElement> = {}): Promise<F[]> => {
        return new Promise(resolve => {
            const input = document.createElement('input');
            input.type = 'file';
            Object.assign(input, attributes);
            input.style.display = 'none';
            document.body.appendChild(input);
            input.click();
            input.addEventListener('change', async () => {
                const files: F[] = [];
                if (input.files?.length) {
                    for (let i = 0; i < input.files.length; i++) {
                        const rawFile = input.files[i];
                        const blob = new Blob([rawFile], {type: rawFile.type});
                        const file = fileFactory.createFile({
                            name: rawFile.name,
                            size: rawFile.size,
                            contents: blob,
                            url: resolveFileUrl(blob)
                        }) as WithContents<F>;
                        await processFile(file);
                        files.push(file);
                    }
                }
                document.body.removeChild(input);
                resolve(files);
            });
        });
    };

    const resolveFileUrl = (contents: Blob): string => {
        return URL.createObjectURL(contents);
    };

    const processFile = async (file: WithContents<File>): Promise<void> => {
        const fileExtension = getFileExtension(file.name);
        const fileTypeOptions = fileRegistry.getFileTypeOptionsByExtension(fileExtension);
        if (fileTypeOptions?.process) {
            await fileTypeOptions.process(file);
        }
    };

    const getFileExtension = (fileName: string): string => {
        const parts = fileName.split('.');
        return parts[parts.length - 1];
    };

    return {
        selectFile,
        selectFiles,
        selectFileByExtensions,
        selectFilesByExtensions,
        selectFileByTypes,
        selectFilesByTypes
    };

}