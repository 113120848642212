import {MutableRefObject, useEffect} from 'react';

export function useClickOutside(
    ref: MutableRefObject<HTMLElement | null> | MutableRefObject<HTMLElement | null>[],
    handler: () => void,
    deps: unknown[] = []
): void {
    const refs = Array.isArray(ref) ? ref : [ref];
    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            let anyRefContainsTarget = refs.some((ref: MutableRefObject<HTMLElement | null>): boolean => {
                return !!ref.current?.contains(event.target as HTMLElement);
            });
            if (!anyRefContainsTarget) {
                handler();
            }
        };
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, [...refs, handler, ...deps]);
}