import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {SideContentCSS} from './side-content-css';

export interface SideContentProps {
    children?: ReactNode;
    className?: string;
}

export function SideContent({children, className}: SideContentProps) {

    const css = useThemedCSS(SideContentCSS, {});

    return (
        <div className={mergeClassNames(css['sideContent'], className)}>
            {children}
        </div>
    );

}