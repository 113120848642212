import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface StringRuleOptions {
    minLength?: number;
    maxLength?: number;
    contains?: string;
    match?: RegExp;
}

export function isString<S extends string = string>({minLength, maxLength, contains, match}: StringRuleOptions = {}): ValidationRule<S> {
    return (item: unknown): ValidationError[] => {
        if (typeof item !== 'string') {
            return [{message: `Value has to be string`}];
        }
        const errors: ValidationError[] = [];
        if (minLength && item.length < minLength) {
            errors.push({message: `String has to contain at least ${minLength} characters`});
        }
        if (maxLength && item.length > maxLength) {
            errors.push({message: `String has to contain at most ${maxLength} characters`});
        }
        if (contains && !item.includes(contains)) {
            errors.push({message: `String has to contain text [${contains}]`});
        }
        if (match && item.match(match) === null) {
            errors.push({message: `String does not match the schema`});
        }
        return errors;
    };
}