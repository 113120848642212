import {Api} from '@webaker/package-api';
import {Directory} from './directory';
import {DirectoryTree} from './directory-tree';
import {File} from './file';

export const FILE_API_NAME = 'file';

export interface FileApi extends Api {
    name: typeof FILE_API_NAME;
    getDirectoryTree: (directoryId: Directory['id'] | null) => Promise<DirectoryTree | null>;
    getDirectoryById: (directoryId: Directory['id']) => Promise<Directory | null>;
    getDirectoriesByIds: (directoriesIds: Directory['id'][]) => Promise<Directory[]>;
    getFileById: <F extends File = File>(fileId: F['id']) => Promise<F | null>;
    getFilesByIds: <F extends File = File>(filesIds: F['id'][]) => Promise<F[]>;
    saveDirectory: (directory: Directory) => Promise<Directory>;
    saveFile: <F extends File = File>(file: F) => Promise<F>;
    deleteDirectory: (directoryId: Directory['id']) => Promise<void>;
    deleteFile: (fileId: File['id']) => Promise<void>;
    moveDirectory: (directoryId: Directory['id'], parentDirectoryId: Directory['id'] | null) => Promise<Directory | null>;
    moveFile: <F extends File = File>(fileId: F['id'], parentDirectoryId: Directory['id'] | null) => Promise<F | null>;
    copyDirectory: (directoryId: Directory['id'], parentDirectoryId: Directory['id'] | null) => Promise<Directory | null>;
    copyFile: <F extends File = File>(fileId: F['id'], parentDirectoryId: Directory['id'] | null) => Promise<F | null>;
    getFileUsages: (fileId: File['id']) => Promise<string[]>;
    isAnyFileInUse: (filesIds: File['id'][]) => Promise<boolean>;
}