import {ComponentViewWrapperProps} from '@webaker/app';
import {mergeClassNames} from '@webaker/package-utils';
import {ThemeComponent} from './theme-component';

export interface ThemeComponentViewWrapperProps extends ComponentViewWrapperProps<ThemeComponent> {

}

export function ThemeComponentViewWrapper({Component, component, childComponents, render, className, elementRef}: ThemeComponentViewWrapperProps) {
    return <Component component={component}
                      childComponents={childComponents}
                      render={render}
                      className={mergeClassNames(component.themeId, component.sharedThemeId, className)}
                      elementRef={elementRef}/>;
}