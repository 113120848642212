import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export type ArrayRuleOptions<A extends unknown[] = unknown[]> = {
    rule?: ValidationRule<UnpackArray<A>>;
    minLength?: number;
    maxLength?: number;
}

type UnpackArray<A> = A extends (infer I)[] ? I : never;

export function isArray<A extends any[] = any[]>({rule, minLength, maxLength}: ArrayRuleOptions<A> = {}): ValidationRule<A> {
    return (item: unknown): ValidationError[] => {
        if (!Array.isArray(item)) {
            return [{message: `Value has to be array`}];
        }
        const errors: ValidationError[] = [];
        if (minLength && item.length < minLength) {
            errors.push({message: `Array has to contain at least ${minLength} ${minLength === 1 ? 'item' : 'items'}`});
        }
        if (maxLength && item.length > maxLength) {
            errors.push({message: `Array has to contain at most ${maxLength} ${maxLength === 1 ? 'item' : 'items'}`});
        }
        if (rule) {
            for (let index = 0; index < item.length; index++) {
                const nestedItem: unknown = item[index];
                const nestedErrors: ValidationError[] = rule(nestedItem);
                if (nestedErrors.length > 0) {
                    errors.push({key: index, errors: nestedErrors});
                }
            }
        }
        return errors;
    };
}