import {useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject, ReactNode} from 'react';
import {TileCSS, TilePrivateCSS} from './tile-css';

export interface TileProps {
    title?: ReactNode;
    subtitle?: ReactNode;
    align?: TileAlign;
    icon?: ReactNode;
    onClick?: () => void;
    className?: string;
    children?: ReactNode;
    elementRef?: MutableRefObject<HTMLDivElement | null>;
}

export type TileAlign = 'left' | 'center' | 'right';

export function Tile({
    title,
    subtitle,
    align = 'left',
    icon,
    onClick,
    children,
    className,
    elementRef
}: TileProps) {

    const css = useThemedCSSBundle([
        TileCSS,
        TilePrivateCSS
    ], {
        align
    });

    return (
        <div ref={elementRef}
             onClick={onClick}
             className={mergeClassNames(
                 css['tile'],
                 className
             )}>
            {title && (
                <header className={css['header']}>
                    <div className={css['title']}>
                        {icon}
                        {title}
                    </div>
                    {subtitle && (
                        <div className={css['subtitle']}>
                            {subtitle}
                        </div>
                    )}
                </header>
            )}
            {children && (
                <div className={css['content']}>
                    {children}
                </div>
            )}
        </div>
    );

}