import {useDebugValue} from 'react';
import {Dependencies} from '../dependencies';
import {RequiredKey} from '../dependency-key';
import {useContainer} from './use-container';

export function useDependency<D extends Dependencies = {}>() {
    return function useDependency<N extends RequiredKey<D> = RequiredKey<D>>(name: N): D[N] {
        useDebugValue(name);
        return useContainer<D>().resolve(name);
    };
}