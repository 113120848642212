import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, useReactiveRef} from '@webaker/package-utils';
import {MouseEvent as ReactMouseEvent, ReactNode, useCallback, useState} from 'react';
import {MdIcon} from './md-icon';
import {Portal} from './portal';
import {WindowCSS} from './window-css';

export interface WindowProps {
    title?: string;
    offset?: number;
    onClose?: () => void;
    children?: ReactNode;
    className?: string;
}

interface Position {
    left: number;
    top: number;
}

const ENTRY_OFFSET = 2000;

export function Window({
    title,
    offset = 50,
    onClose,
    children,
    className
}: WindowProps) {

    const css = useThemedCSS(WindowCSS, {});
    const elementRef = useReactiveRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState<Position>({
        left: 0,
        top: -ENTRY_OFFSET
    });

    const handleMouseDown = useCallback((event: ReactMouseEvent) => {
        if (!elementRef.current) {
            return;
        }
        setIsDragging(true);
        const startElementPosition = position;
        const starMousePosition = {left: event.clientX, top: event.clientY};
        const elementRect = elementRef.current?.getBoundingClientRect();
        const handleMouseMove = (event: MouseEvent) => {
            const minLeft = -elementRect.width + offset;
            const maxLeft = window.innerWidth - offset;
            const minTop = 0;
            const maxTop = window.innerHeight - offset;
            const left = Math.max(minLeft, Math.min(maxLeft, startElementPosition.left + (event.clientX - starMousePosition.left)));
            const top = Math.max(minTop, Math.min(maxTop, startElementPosition.top + (event.clientY - starMousePosition.top)));
            setPosition({left, top});
            event.preventDefault();
        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            setIsDragging(false);
            event.preventDefault();
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        event.preventDefault();
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [position]);

    const initRef = useCallback((element: HTMLDivElement): void => {
        elementRef.current = element;
        if (!element) {
            return;
        }
        const elementRect = element.getBoundingClientRect();
        setPosition({
            left: window.innerWidth / 2 - elementRect.width / 2,
            top: window.innerHeight / 2 - elementRect.height / 2
        });
    }, []);

    return (
        <Portal fixed={true}>
            <div ref={initRef}
                 className={mergeClassNames(css['window'], isDragging && css['is-dragging'], className)}
                 style={{left: `${position.left}px`, top: `${position.top}px`}}>
                <header className={css['header']}
                        onMouseDown={handleMouseDown}>
                    {title && (
                        <div className={css['title']}>
                            {title}
                        </div>
                    )}
                    {onClose && (
                        <div className={css['closeButton']}
                             onClick={onClose}>
                            <MdIcon name="close"/>
                        </div>
                    )}
                </header>
                <div className={css['content']}>
                    {children}
                </div>
            </div>
        </Portal>
    );

}