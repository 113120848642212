import {Store, StoreState} from './store';
import {StoreMethod, StoreMethodName} from './store-method';

export type StoreEventFunction<S extends Store = any> =
    ((event: WildcardStoreEventName, listener: StoreListener<S, any>) => void) &
    (<E extends StoreMethodName<S> = StoreMethodName<S>>(event: E, listener: StoreListener<S, E>) => void);

export type StoreListener<S extends Store = any, M extends StoreMethodName<S> = StoreMethodName<S>> = (event: StoreEvent<S, M>) => void;
export type StateListener<S extends Store = any> = (event: StateEvent<S>) => void;

export type StoreEventName<S extends Store = Store> = StoreMethod<S> | WildcardStoreEventName;
export type WildcardStoreEventName = '*' | '*:get' | '*:set';

export type StoreEvent<S extends Store = any, M extends StoreMethodName<S> = StoreMethodName<S>> = {
    state: StoreState<S>;
    method: M & string;
    params: Parameters<StoreMethod<S, M>>;
    result: ReturnType<StoreMethod<S, M>>;
};

export type StateEvent<S extends Store = any> = {
    state: StoreState<S>;
};