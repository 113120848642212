import {customAlphabet} from 'nanoid';

export const DEFAULT_ID_ALPHABET: string = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const DEFAULT_ID_LENGTH: number = 12;

export interface IdGenerator {
    generateId: (length?: number) => string;
}

export interface IdGeneratorConfig {
    alphabet?: string;
    defaultLength?: number;
}

export function createIdGenerator({alphabet = DEFAULT_ID_ALPHABET, defaultLength = DEFAULT_ID_LENGTH}: IdGeneratorConfig = {}): IdGenerator {

    const generators: Record<number, () => string> = {};

    const generateId = (length: number = defaultLength): string => {
        if (!generators[length]) {
            generators[length] = customAlphabet(alphabet, length);
        }
        return generators[length]();
    };

    return {
        generateId
    };

}

