import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {FormInfoCSS, FormInfoPrivateCSS} from './form-info-css';

export interface FormInfoProps {
    color?: CSSThemeColor;
    icon?: ReactNode;
    children?: ReactNode;
    className?: string;
}

export function FormInfo({color, icon, children, className}: FormInfoProps) {

    const css = useThemedCSSBundle([FormInfoCSS, FormInfoPrivateCSS], {
        color: color ?? 'info'
    });

    return (
        <div className={mergeClassNames(css['formInfo'], className)}>
            {icon && (
                <div className={css['icon']}>
                    {icon}
                </div>
            )}
            <div className={css['content']}>
                {children}
            </div>
        </div>
    );

}