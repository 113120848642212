import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {createContext, ReactNode} from 'react';
import {TabGroupCSS} from './tab-group-css';

export interface TabGroupProps {
    direction?: TabGroupDirection;
    children: ReactNode;
    className?: string;
}

export type TabGroupDirection = 'horizontal' | 'vertical';

export interface TabGroupContext {
    direction: TabGroupDirection;
}

export const TabGroupContext = createContext<TabGroupContext>(null!);

export function TabGroup({
    direction = 'horizontal',
    children,
    className
}: TabGroupProps) {

    const css = useThemedCSS(TabGroupCSS, {});

    return (
        <div className={mergeClassNames(css['tabGroup'], css[`direction-${direction}`], className)}>
            <TabGroupContext.Provider value={{direction}}>
                {children}
            </TabGroupContext.Provider>
        </div>
    );

}