import {DIRECTORY_SEPARATOR, FILE_EXTENSION_SEPARATOR, FS_NODE_EXTENSION_REG_EXP} from './file-config';

export interface FileNameParser {
    getFileName: (path: string) => string;
    getFileBaseName: (path: string) => string;
    getFileExtension: (path: string) => string;
}

export interface FileNameParserDeps {

}

export function createFileNameParser({}: FileNameParserDeps = {}): FileNameParser {

    const getFileName = (path: string): string => {
        const lastSlashIndex = path.lastIndexOf(DIRECTORY_SEPARATOR);
        return path.slice(lastSlashIndex + 1);
    };

    const getFileBaseName = (path: string): string => {
        const fileName = getFileName(path);
        return fileName.replace(FS_NODE_EXTENSION_REG_EXP, '');
    };

    const getFileExtension = (path: string): string => {
        const fileName = getFileName(path);
        return fileName.match(FS_NODE_EXTENSION_REG_EXP)?.[0].replace(FILE_EXTENSION_SEPARATOR, '') ?? '';
    };

    return {
        getFileName,
        getFileBaseName,
        getFileExtension,
    };

}