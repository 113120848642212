import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {File, FileExtension, FileNameParser, FileRegistry} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Alert, Button, MdIcon, Modal, ModalButtons} from '@webaker/package-ui';
import {useBooleanState} from '@webaker/package-utils';
import {useCallback, useMemo} from 'react';
import {FileManagerStore} from '../file-manager-store';
import {FileManager} from './file-manager';
import {FileModalCSS} from './file-modal-css';
import {PublishFileModal} from './modals/publish-file-modal';

export interface FileModalProps {
    file?: File | null;
    publish?: boolean;
    types?: string[];
    extensions?: FileExtension[];
    onChange?: (event: FileModalChangeEvent) => void;
    onClose: () => void;
}

export interface FileModalDeps {
    fileManagerStore: FileManagerStore;
    fileNameParser: FileNameParser;
    fileRegistry: FileRegistry;
}

export interface FileModalChangeEvent {
    file: File;
}

export function FileModal({file, publish, types, extensions, onChange, onClose}: FileModalProps) {

    const css = useThemedCSS(FileModalCSS);
    const fileManagerStore = useStore(useDependency<FileModalDeps>()('fileManagerStore'));
    const fileNameParser = useDependency<FileModalDeps>()('fileNameParser');
    const fileRegistry = useDependency<FileModalDeps>()('fileRegistry');
    const allowedExtensions = types ? fileRegistry.getFileExtensionsByTypes(types) : extensions;
    const [isPublishModalOpen, openPublishModal, closePublishModal] = useBooleanState(false);
    const allSelectedFiles = fileManagerStore.getSelectedFiles();
    const selectedFile = allSelectedFiles.length === 1 ? allSelectedFiles[0] : null;

    const canSelectFile = useMemo(() => {
        if (!selectedFile) {
            return false;
        }
        if (!allowedExtensions) {
            return true;
        }
        const selectedFileExtension = fileNameParser.getFileExtension(selectedFile.name);
        return allowedExtensions.includes(selectedFileExtension);
    }, [selectedFile, allowedExtensions]);

    const selectFile = useCallback(() => {
        if (!selectedFile || !canSelectFile) {
            return;
        }
        if (publish && !selectedFile.public) {
            openPublishModal();
            return;
        }
        onChange?.({file: selectedFile});
    }, [selectedFile, canSelectFile, publish, onChange]);

    const selectPublishedFile = useCallback(() => {
        if (!selectedFile || !canSelectFile) {
            return;
        }
        onChange?.({file: selectedFile});
    }, [selectedFile, canSelectFile, onChange]);

    return (
        <Modal title="Select file"
               className={css['fileModal']}
               onEscape={onClose}
               onEnter={selectFile}>

            {types ? (
                <Alert color="warning"
                       icon={<MdIcon name="info"/>}>
                    You can select only files of following types:
                    <ul>
                        {types.map((type: string, index) => {
                            const fileTypeOptions = fileRegistry.getFileTypeOptions(type);
                            return fileTypeOptions ? (
                                <li key={index}>{fileTypeOptions.name} (.{fileTypeOptions.extensions.join(' .')})</li>
                            ) : null;
                        })}
                    </ul>
                </Alert>
            ) : extensions ? (
                <Alert color="warning"
                       icon={<MdIcon name="info"/>}>
                    You can select only files with following extensions:
                    .{extensions.join(' .')}
                </Alert>
            ) : null}

            <FileManager file={file}
                         extensions={allowedExtensions}
                         className={css['fileManager']}/>

            <ModalButtons>
                <Button color="dark"
                        style="text"
                        onClick={onClose}>
                    Cancel
                </Button>
                <Button icon={<MdIcon name="check"/>}
                        color="success"
                        style="solid"
                        onClick={selectFile}
                        disabled={!canSelectFile}>
                    Select
                </Button>
            </ModalButtons>

            {isPublishModalOpen && selectedFile && (
                <PublishFileModal file={selectedFile}
                                  onPublish={selectPublishedFile}
                                  onClose={closePublishModal}/>
            )}

        </Modal>
    );

}