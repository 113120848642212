import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {createElement, ForwardedRef, ReactNode} from 'react';
import {FlexBox} from './flex-box';
import {FlexBoxViewCSS} from './flex-box-view-css';

export interface FlexBoxViewProps {
    flexBox: FlexBox;
    tagName?: string;
    className?: string;
    children?: ReactNode;
    elementRef?: ForwardedRef<HTMLElement | null>;
}

export function FlexBoxView({
    flexBox,
    tagName = 'div',
    className,
    children,
    elementRef
}: FlexBoxViewProps) {

    const css = useThemedCSS(FlexBoxViewCSS, {
        flexBox
    });

    return createElement(tagName, {
        ref: elementRef,
        className: mergeClassNames(css['flexBox'], className),
        children
    });

}