import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, parseNumber} from '@webaker/package-utils';
import {MouseEvent as ReactMouseEvent, ReactNode, useCallback} from 'react';
import {MdIcon} from './md-icon';
import {SliderButtonCSS} from './slider-button-css';

export interface SliderButtonProps {
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    stepSize?: number;
    disabled?: boolean;
    onChange?: (event: SliderButtonChangeEvent) => void;
    children?: ReactNode;
    className?: string;
}

export interface SliderButtonChangeEvent {
    value: number;
}

const SLIDER_RANGE = 100;

export function SliderButton({
    value = 0,
    min = -Infinity,
    max = Infinity,
    step = 1,
    stepSize = Math.max(1, SLIDER_RANGE / Math.abs(max - min)),
    disabled,
    onChange,
    children,
    className
}: SliderButtonProps) {

    const css = useThemedCSS(SliderButtonCSS, {});

    const handleMouseDown = useCallback((event: ReactMouseEvent) => {
        const mouseDownX = event.clientX;
        const handleMouseMove = (event: MouseEvent) => {
            const newValue = parseNumber(value + (event.clientX - mouseDownX) / stepSize * step, {min, max, step});
            onChange?.({value: newValue});
        };
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        event.preventDefault();
    }, [value, min, max, onChange]);

    return (
        <button className={mergeClassNames(css['sliderButton'], className)}
                onMouseDown={disabled ? undefined : handleMouseDown}
                disabled={disabled}
                tabIndex={-1}>
            {children ?? <MdIcon name="drag_handle" className={css['dragHandleIcon']}/>}
        </button>
    );

}