import {Page} from './page';
import {AsyncPageView} from './page-view';

export interface PageOptions<P extends Page = Page> {
    type: P['type'];
    static?: boolean;
    editor?: boolean;
    ssr?: boolean;
    view?: AsyncPageView<P>;
}

export const DEFAULT_PAGE_OPTIONS_SSR = true;