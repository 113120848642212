import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export type EnumRuleOptions<T = unknown> = {
    values?: T[] | readonly T[] | ((item: unknown) => T[]);
};

export function isEnum<T = unknown>({values}: EnumRuleOptions): ValidationRule<T> {
    return (item: unknown): ValidationError[] => {
        if (values) {
            if (typeof values === 'function') {
                values = values(item);
            }
            if (!values.length) {
                return [{message: 'Value is invalid'}];
            }
            if (!values.includes(item)) {
                return values.length > 1
                    ? [{message: `Value has to be one of: ${values.join(', ')}`}]
                    : [{message: `Value has to be equal to: ${values.join(', ')}`}];
            }
        }
        return [];
    };
}