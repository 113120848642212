import {Theme} from '../theme';
import {ThemeRenderer} from '../theme-renderer';
import {ThemeStore} from '../theme-store';

export interface ThemeInjector {
    injectThemesAndWatch: () => void;
    injectThemes: () => void;
}

export interface ThemeInjectorDeps {
    themeRenderer: ThemeRenderer;
    themeStore: ThemeStore;
}

const THEME_STYLE_ID_PREFIX = 'theme';
const SHARED_THEMES_CONTAINER_ID = 'shared-themes';
const PERSONAL_THEMES_CONTAINER_ID = 'personal-themes';

export function createThemeInjector({themeRenderer, themeStore}: ThemeInjectorDeps): ThemeInjector {

    const themeStylesMap: Map<Theme['id'], HTMLStyleElement> = new Map();

    let sharedThemesContainer: HTMLElement | null = null;
    let personalThemesContainer: HTMLElement | null = null;

    const injectThemes = (): void => {
        const themes = themeStore.getThemes();
        const themesIds = themes.map((theme: Theme): Theme['id'] => {
            return theme.id;
        });
        injectThemesContainers();
        themeStylesMap.forEach((style: HTMLStyleElement, id: Theme['id']): void => {
            if (!themesIds.includes(id)) {
                style.remove();
                themeStylesMap.delete(id);
            }
        });
        themes.forEach((theme: Theme): void => {
            const styleId = `${THEME_STYLE_ID_PREFIX}-${theme.id}`;
            const style = document.getElementById(styleId);
            const cssText = themeRenderer.renderTheme(theme);
            const container = theme.shared ? sharedThemesContainer : personalThemesContainer;
            if (style) {
                style.innerHTML = cssText;
            } else {
                const newStyle = document.createElement('style');
                newStyle.id = styleId;
                newStyle.innerHTML = cssText;
                container?.appendChild(newStyle);
                themeStylesMap.set(theme.id, newStyle);
            }
        });
    };

    const injectThemesAndWatch = (): void => {
        themeStore.addEventListener('*:set', injectThemes);
        injectThemes();
    };

    const injectThemesContainers = (): void => {
        if (!sharedThemesContainer) {
            sharedThemesContainer = document.createElement('div');
            sharedThemesContainer.id = SHARED_THEMES_CONTAINER_ID;
            document.body.appendChild(sharedThemesContainer);
        }
        if (!personalThemesContainer) {
            personalThemesContainer = document.createElement('div');
            personalThemesContainer.id = PERSONAL_THEMES_CONTAINER_ID;
            document.body.appendChild(personalThemesContainer);
        }
    };

    return {
        injectThemes,
        injectThemesAndWatch
    };

}