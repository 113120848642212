import {ClassNamesMap, CSSComponent, useCSSBundle} from '@webaker/package-css';
import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {TupleToIntersection, TupleToUnion} from '@webaker/package-utils';
import {CSSThemeProps} from './css-theme-props';
import {CSSThemeStore} from './css-theme-store';

type GetOptionalRest<T> = {} extends T ? [T?] : [T];
type GetBundleProps<T extends readonly CSSComponent<any, any>[]> = Omit<TupleToIntersection<{ [K in keyof T]: GetProps<T[K]> }>, keyof CSSThemeProps>;
type GetBundleClassName<T extends readonly CSSComponent<any, any>[]> = TupleToUnion<{ [K in keyof T]: GetClassName<T[K]> }>;
type GetProps<T> = T extends CSSComponent<infer P, any> ? P : {};
type GetClassName<T> = T extends CSSComponent<any, infer C> ? C : never;

export interface UseThemedCSSBundleDeps {
    cssThemeStore: CSSThemeStore;
}

export function useThemedCSSBundle<T extends readonly CSSComponent<any, any>[]>(
    themedCSSComponents: [...T],
    ...[optionalCSSProps]: GetOptionalRest<GetBundleProps<T>>
): ClassNamesMap<GetBundleClassName<T>> {
    const cssProps = optionalCSSProps ?? {};
    const cssThemeStore = useStore(useDependency<UseThemedCSSBundleDeps>()('cssThemeStore'));
    const {id, name, ...theme} = cssThemeStore.getCurrentCSSTheme() ?? {};
    return useCSSBundle(themedCSSComponents, {theme, ...cssProps} as any);
}