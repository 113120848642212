import {Event} from './event';

export interface EventAbortedErrorDetails<E extends Event = Event> {
    event: E;
    errors?: Error[];
}

export class EventAbortedError<E extends Event = Event> extends Error {

    name = 'EVENT_ABORTED';

    details: EventAbortedErrorDetails;

    constructor(message: string, details: EventAbortedErrorDetails<E>) {
        super(message);
        this.details = details;
    }

}