export interface PageViewNotLoadedErrorDetails {
    type: string;
}

export class PageViewNotLoadedError extends Error {

    name = 'PAGE_VIEW_NOT_LOADED';

    details: PageViewNotLoadedErrorDetails;

    constructor(message: string, details: PageViewNotLoadedErrorDetails) {
        super(message);
        this.details = details;
    }

}