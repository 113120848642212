import {useEmptyThemedCSS, useThemedCSS} from '@webaker/package-css-theme';
import {FlexBoxViewCSS} from '@webaker/package-flex-box';
import {useStore} from '@webaker/package-store';
import {mergeClassNames, ReactWrapper} from '@webaker/package-utils';
import {Fragment, MutableRefObject, ReactElement, useRef} from 'react';
import {AppStore} from '../app-store';
import {Component} from './component';
import {ComponentExtensionOptions} from './component-extension-options';
import {ComponentRegistry} from './component-registry';
import {ComponentView, ComponentViewProps, ComponentViewWrapper} from './component-view';
import {ComponentViewCSS} from './component-view-css';

export interface ComponentRenderer {
    renderComponent: RenderComponentFunction;
    renderComponents: RenderComponentsFunction;
}

export type RenderComponentFunction<C extends Component = Component> = (
    component: C | null,
    params?: RenderComponentParams
) => ReactElement;

export type RenderComponentsFunction<C extends Component = Component> = (
    components: C[],
    params?: RenderComponentsParams
) => ReactElement;

export interface RenderComponentParams {
    className?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
}

export interface RenderComponentsParams {
    type?: Component['type'];
    className?: string;
}

export interface RenderComponentProps extends RenderComponentParams {
    component: Component | null;
}

export interface ComponentRendererDeps {
    appStore: AppStore;
    componentRegistry: ComponentRegistry;
    reactWrapper: ReactWrapper;
}

export function createComponentRenderer({
    appStore,
    componentRegistry,
    reactWrapper
}: ComponentRendererDeps): ComponentRenderer {

    const RenderComponent = ({component, className, elementRef}: RenderComponentProps): ReactElement => {
        const localAppStore = useStore(appStore);
        const localElementRef = useRef<HTMLElement | null>(null);
        const css = useThemedCSS(ComponentViewCSS);
        const slotCSS = component?.slot ? useThemedCSS(FlexBoxViewCSS, {flexBox: component.slot}) : useEmptyThemedCSS();
        if (!component) {
            return <Fragment/>;
        }
        const ComponentView = getComponentView(component);
        const parentComponent = localAppStore.getParentComponent(component.id);
        const childComponents = localAppStore.getChildComponents(component.id);
        const newClassName = mergeClassNames(css['component'], !parentComponent && css['root'], slotCSS['flexBox'], className);
        return (
            <ComponentView key={generateComponentsKey([component])}
                           component={component}
                           childComponents={childComponents}
                           render={renderComponents}
                           elementRef={elementRef ?? localElementRef}
                           className={newClassName}/>
        );
    };

    const DefaultComponentView = ({render, childComponents, elementRef, className}: ComponentViewProps): ReactElement => {
        return (
            <div ref={elementRef} className={className}>
                {render(childComponents)}
            </div>
        );
    };

    const renderComponent = (component: Component | null, {className, elementRef}: RenderComponentParams = {}): ReactElement => {
        if (!component) {
            return <Fragment/>;
        }
        return (
            <RenderComponent key={generateComponentsKey([component])}
                             component={component}
                             className={className}
                             elementRef={elementRef}/>
        );
    };

    const renderComponents = (components: Component[], {type, className}: RenderComponentsParams = {}): ReactElement => {
        const filteredComponents = filterComponentsByType(components, type);
        return (
            <Fragment key={generateComponentsKey(filteredComponents)}>
                {filteredComponents.map((component: Component) => {
                    return (
                        <RenderComponent key={generateComponentsKey([component])}
                                         component={component}
                                         className={className}/>
                    );
                })}
            </Fragment>
        );
    };

    const getComponentView = (component: Component): ComponentView => {
        const componentView = componentRegistry.getComponentOptions(component.type).view ?? DefaultComponentView;
        const componentsViewWrappers = componentRegistry.getAllComponentsExtensionsOptions().map((
            componentExtensionOptions: ComponentExtensionOptions
        ) => {
            return componentExtensionOptions.wrapper;
        }).filter(Boolean) as ComponentViewWrapper[];
        return reactWrapper.wrapComponent<ComponentViewProps, 'Component'>({
            component: componentView,
            wrappers: componentsViewWrappers,
            originalProp: 'Component'
        });
    };

    const filterComponentsByType = (components: Component[], type?: Component['type']): Component[] => {
        return type ? components.filter((component: Component): boolean => {
            return component.type === type;
        }) : components;
    };

    const generateComponentsKey = (components: Component[]): string => {
        return components.map((component: Component): Component['id'] => {
            return component.id;
        }).join('/');
    };

    return {
        renderComponent,
        renderComponents
    };

}