export function deepClone<T>(item: T): T {
    if (Array.isArray(item)) {
        return item.map((subItem) => {
            return deepClone(subItem);
        }) as T;
    }
    if (item && typeof item === 'object') {
        return Object.entries(item).reduce((clonedItem, [key, value]) => {
            // @ts-ignore
            clonedItem[key] = deepClone(value);
            return clonedItem;
        }, {}) as T;
    }
    return item;
}