import {ApiClientFactory} from '@webaker/package-api/client';
import {THEME_API_NAME, ThemeApi} from '../theme-api';

export interface ClientThemeApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createThemeClientApi({apiClientFactory}: ClientThemeApiDeps): ThemeApi {

    return apiClientFactory.createApi<ThemeApi>(THEME_API_NAME, {
        getAllThemes: [],
        getThemesByIds: ['themesIds'],
        saveThemes: ['themes']
    });

}