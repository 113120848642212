import {Button, Modal, ModalButtons, ModalInfo} from '@webaker/package-ui';

export interface ChangeAccessErrorModalProps {
    info: string;
    onClose: () => void;
}

export function FileUsageErrorModal({info, onClose}: ChangeAccessErrorModalProps) {
    return (
        <Modal title="Change access error"
               onEnter={onClose}
               onEscape={onClose}>
            <ModalInfo>
                {info}
            </ModalInfo>
            <ModalButtons>
                <Button color="success"
                        style="text"
                        onClick={onClose}>
                    Ok
                </Button>
            </ModalButtons>
        </Modal>
    );
}