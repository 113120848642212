export interface ComponentNotFoundErrorDetails {
    id: string;
}

export class ComponentNotFoundError extends Error {

    name = 'COMPONENT_NOT_FOUND';

    details: ComponentNotFoundErrorDetails;

    constructor(message: string, details: ComponentNotFoundErrorDetails) {
        super(message);
        this.details = details;
    }

}