import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface PasswordRuleOptions {
    minLength?: number;
    requireNumber?: boolean;
    requireUppercaseLetter?: boolean;
    requireLowercaseLetter?: boolean;
    requireSpecialCharacter?: boolean;
}

export function isPassword<S extends string = string>(
    {
        minLength = 8,
        requireNumber = true,
        requireLowercaseLetter = true,
        requireUppercaseLetter = true,
        requireSpecialCharacter = true
    }: PasswordRuleOptions = {}
): ValidationRule<S> {
    return (item: unknown): ValidationError[] => {
        if (typeof item !== 'string') {
            return [{message: `Password has to be string`}];
        }
        const errors: ValidationError[] = [];
        if (item.length < minLength) {
            errors.push({message: `Password has to contain at least ${minLength} characters`});
        }
        if (requireNumber && item.match(/\d/) === null) {
            errors.push({message: 'Password has to contain a number'});
        }
        if (requireLowercaseLetter && item.match(/[a-z]/) === null) {
            errors.push({message: 'Password has to contain a lowercase letter'});
        }
        if (requireUppercaseLetter && item.match(/[A-Z]/) === null) {
            errors.push({message: 'Password has to contain a uppercase letter'});
        }
        if (requireSpecialCharacter && item.match(/[^a-zA-Z0-9]/) === null) {
            errors.push({message: 'Password has to contain a special character'});
        }
        return errors;
    };
}