export type Corner = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';

export interface CornerValues {
    topLeft: string | null;
    topRight: string | null;
    bottomRight: string | null;
    bottomLeft: string | null;
}

export function parseCornerValues(value: string): CornerValues {
    const parts = value.split(/\s+/g);
    return {
        topLeft: parts[0] ?? null,
        topRight: parts[1] ?? parts[0] ?? null,
        bottomRight: parts[2] ?? parts[0] ?? null,
        bottomLeft: parts[3] ?? parts[1] ?? parts[0] ?? null,
    };
}

export function stringifyCornerValues({topLeft, topRight, bottomRight, bottomLeft}: CornerValues): string | null {
    if (topLeft === null || topRight === null || bottomRight === null || bottomLeft === null) {
        return null;
    }
    if (topLeft === topRight && topRight === bottomRight && bottomRight === bottomLeft) {
        return `${topLeft}`;
    }
    if (topLeft === bottomRight && topRight === bottomLeft) {
        return `${topLeft} ${topRight}`;
    }
    if (topRight === bottomLeft) {
        return `${topLeft} ${topRight} ${bottomRight}`;
    }
    return `${topLeft} ${topRight} ${bottomRight} ${bottomLeft}`;
}