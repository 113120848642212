import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type ComponentViewClassName = 'component' | 'root';

export interface ComponentViewCSSProps extends CSSThemeProps {

}

export function ComponentViewCSS({}: ComponentViewCSSProps) {
    return css<ComponentViewClassName>`

        .component {
            position: relative;
            min-width: 1em;
            min-height: 1em;
        }

        .root {
            width: 100% !important;
            height: 100% !important;
        }

    `;
}