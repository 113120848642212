import {PageOptions} from '@webaker/app';
import {WEBSITE_MANAGER_PAGE_TYPE, WebsiteManagerPage} from './website-manager-page';

export interface WebsiteManagerPageOptions extends PageOptions<WebsiteManagerPage> {

}

export function createWebsiteManagerPageOptions(): WebsiteManagerPageOptions {
    return {

        type: WEBSITE_MANAGER_PAGE_TYPE,

        static: true,

        editor: true,

        ssr: false,

        view: async () => (await import('@webaker/module-website-manager-page/editor')).WebsiteManagerPageView

    };
}