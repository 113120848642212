import {Button, Modal, ModalButtons, ModalInfo} from '@webaker/package-ui';

export interface PasteFileErrorModalProps {
    onClose: () => void;
}

export function PasteFileErrorModal({onClose}: PasteFileErrorModalProps) {
    return (
        <Modal title="Paste error"
               onEnter={onClose}
               onEscape={onClose}>
            <ModalInfo>
                The destination folder is a subfolder of the source folder.
            </ModalInfo>
            <ModalButtons>
                <Button color="success"
                        style="text"
                        onClick={onClose}>
                    Ok
                </Button>
            </ModalButtons>
        </Modal>
    );

}