import {DependencyContainer} from '@webaker/package-deps';

export function shareDependencies(globalObject: any, container: DependencyContainer<any>): void {
    container.names().forEach((name: string) => {
        let instance: any = null;
        Object.defineProperty(globalObject, name, {
            get: () => instance ?? (instance = container.resolve(name)),
            configurable: true
        });
    });
}