import {OPEN_PAGE_EVENT, OpenPageEvent, PageContent} from '@webaker/app';
import {EventActions, EventBus, EventSubscriber} from '@webaker/package-event-bus';
import {File, FileApi, FileStore} from '@webaker/package-file';
import {Theme, ThemeStore} from '@webaker/package-theme';
import {ThemeApi} from './theme-api';
import {ThemeComponent} from './theme-component';

export interface ThemeEventSubscriber extends EventSubscriber {

}

export interface ThemeEventSubscriberDeps {
    eventBus: EventBus;
    fileApi: FileApi;
    fileStore: FileStore;
    themeApi: ThemeApi;
    themeStore: ThemeStore;
}

export function createThemeEventSubscriber({
    eventBus,
    fileApi,
    fileStore,
    themeApi,
    themeStore
}: ThemeEventSubscriberDeps): ThemeEventSubscriber {

    const subscribeEvents = (): void => {
        eventBus.addEventListener(OPEN_PAGE_EVENT, onOpenPage);
    };

    const unsubscribeEvents = (): void => {
        eventBus.removeEventListener(OPEN_PAGE_EVENT, onOpenPage);
    };

    const onOpenPage = async ({pageContent}: OpenPageEvent, {onSuccess}: EventActions): Promise<void> => {
        const componentThemesIds = getComponentThemesIds(pageContent);
        const componentSharedThemesIds = getComponentSharedThemesIds(pageContent);
        const themes = await themeApi.getThemesByIds([
            ...componentThemesIds,
            ...componentSharedThemesIds,
        ]);
        const filesIds = getFilesIds(themes);
        const files = filesIds.length ? await fileApi.getFilesByIds(filesIds) : [];
        onSuccess(() => {
            themeStore.addThemes(themes);
            fileStore.addFiles(files);
        });
    };

    const getComponentThemesIds = (pageContent: PageContent): Theme['id'][] => {
        return Array.from(new Set(
            pageContent.tree.components.map((component: ThemeComponent) => {
                return component.themeId;
            }).filter(Boolean)
        )) as Theme['id'][];
    };

    const getComponentSharedThemesIds = (pageContent: PageContent): Theme['id'][] => {
        return Array.from(new Set(
            pageContent.tree.components.map((component: ThemeComponent) => {
                return component.sharedThemeId;
            }).filter(Boolean)
        )) as Theme['id'][];
    };

    const getFilesIds = (themes: Theme[]): File['id'][] => {
        return Array.from(new Set(
            themes.reduce((filesIds: File['id'][], theme: Theme) => {
                return [...filesIds, ...theme.filesIds];
            }, [])
        ));
    };

    return {
        subscribeEvents,
        unsubscribeEvents
    };

}