import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {Directory, FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {MdIcon} from '@webaker/package-ui';
import {useCallback} from 'react';
import {FileManagerStore} from '../file-manager-store';
import {DirectoryBreadcrumbsCSS} from './directory-breadcrumbs-css';

export interface DirectoryBreadcrumbsProps {

}

export interface DirectoryBreadcrumbsDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function DirectoryBreadcrumbs({}: DirectoryBreadcrumbsProps) {

    const css = useThemedCSS(DirectoryBreadcrumbsCSS);
    const fileManagerStore = useStore(useDependency<DirectoryBreadcrumbsDeps>()('fileManagerStore'));
    const fileApi = useDependency<DirectoryBreadcrumbsDeps>()('fileApi');

    const workingDirectory = fileManagerStore.getWorkingDirectory();
    const breadcrumbs = workingDirectory ? [...fileManagerStore.getBreadcrumbs(), workingDirectory] : [];

    const openDirectory = useCallback(async (directoryId: Directory['id'] | null) => {
        fileManagerStore.startLoading();
        const directoryTree = await fileApi.getDirectoryTree(directoryId);
        fileManagerStore.openDirectory(directoryTree);
        fileManagerStore.stopLoading();
    }, []);

    return (
        <div className={css['directoryBreadcrumbs']}>
            <span className={css['root']}
                  onClick={() => openDirectory(null)}>
                <MdIcon name="folder"
                        className={css['icon']}/>
                <span className={css['name']}>
                    Root
                </span>
            </span>
            {breadcrumbs.map((directory) => {
                return (
                    <span key={directory.id}
                          className={css['breadcrumb']}
                          onClick={() => openDirectory(directory.id)}>
                        <MdIcon name="chevron_right"
                                className={css['icon']}/>
                        <span className={css['name']}>
                            {directory.name}
                        </span>
                    </span>
                );
            })}
        </div>
    );

}