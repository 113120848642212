import {ValidationError} from '../validation-error';
import {ValidationRule, ValidationRulesMap} from '../validation-rule';

export interface TupleRuleOptions<T = unknown> {
    rules?: ValidationRulesMap<T>;
}

export function isTuple<T extends readonly unknown[] = readonly unknown[]>({rules}: TupleRuleOptions<T> = {}): ValidationRule<T> {
    return (item: unknown): ValidationError[] => {
        if (!Array.isArray(item)) {
            return [{message: `Value has to be tuple`}];
        }
        const errors: ValidationError[] = [];
        if (rules) {
            if (item.length !== rules.length) {
                return [{message: `Value has to be tuple of ${rules.length} ${rules.length === 1 ? 'item' : 'items'}`}];
            }
            for (let index = 0; index < item.length; index++) {
                const nestedItem: unknown = item[index];
                const nestedRule: ValidationRule = rules[index];
                const nestedErrors: ValidationError[] = nestedRule(nestedItem);
                if (nestedErrors.length > 0) {
                    errors.push({key: index, errors: nestedErrors});
                }
            }
        }
        return errors;
    };
}