import {Memo} from '@webaker/package-utils';
import {State} from './state';
import {Store, StoreState} from './store';

export type StoreSchema<S extends Store = any> = {
    name: S['name'];
    state: StoreState<S>;
    getters: {
        [M in keyof Omit<S, keyof Store> as S[M] extends (...args: infer P) => infer R ? R extends void ? never : M : never]:
        S[M] extends (...args: infer P) => infer R
            ? (getterParams: GetterParams<StoreState<S>, ReturnType<S[M]>>, ...customParams: P) => ReturnType<S[M]>
            : never;
    };
    setters: {
        [M in keyof Omit<S, keyof Store> as S[M] extends (...args: infer P) => infer R ? R extends void ? M : never : never]:
        S[M] extends (...args: infer P) => void
            ? (setterParams: SetterParams<StoreState<S>>, ...customParams: P) => StoreState<S>
            : never;
    };
};

export type GetterParams<S extends State = {}, V = any> = {
    state: S;
    memo: Memo<V>;
};

export type SetterParams<S extends State = {}> = {
    state: S;
}