import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SideMenuSeparatorClassName = 'sideMenuSeparator';

export interface SideMenuSeparatorCSSProps extends CSSThemeProps {

}

export function SideMenuSeparatorCSS({}: SideMenuSeparatorCSSProps) {
    return css<SideMenuSeparatorClassName>`

        .sideMenuSeparator {
            flex: 1;
        }

    `;
}