import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {useCallback} from 'react';
import {CheckBoxCSS} from './check-box-css';
import {MdIcon} from './md-icon';

export interface CheckBoxProps {
    value: boolean;
    onChange?: (event: CheckBoxChangeEvent) => void;
    disabled?: boolean;
    className?: string;
}

export interface CheckBoxChangeEvent {
    value: boolean;
}

export function CheckBox({
    value,
    onChange,
    disabled,
    className
}: CheckBoxProps) {

    const css = useThemedCSS(CheckBoxCSS, {});

    const handleClick = useCallback(() => {
        onChange?.({
            value: !value
        });
    }, [onChange, value]);

    return (
        <button className={mergeClassNames(css['checkBox'], className)}
                disabled={disabled}
                onClick={handleClick}>
            {value && <MdIcon name="done"/>}
        </button>
    );

}