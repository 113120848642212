import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {SideLayoutCSS} from './side-layout-css';

export interface SiderLayoutProps {
    children?: ReactNode;
    className?: string;
}

export function SideLayout({children, className}: SiderLayoutProps) {

    const css = useThemedCSS(SideLayoutCSS, {});

    return (
        <div className={mergeClassNames(css['sideLayout'], className)}>
            {children}
        </div>
    );

}