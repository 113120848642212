import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {FileExtension, FSNode} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {useSafeHotkey} from '@webaker/package-utils';
import {FileManagerStore} from '../../file-manager-store';
import {NodeListContent} from './node-list-content';
import {NodeListCSS} from './node-list-css';
import {NodeListHeader} from './node-list-header';

export interface FileListProps {
    extensions?: FileExtension[];
}

export interface FileListDeps {
    fileManagerStore: FileManagerStore;
}

// TODO: optimize using react transitions
export function NodeList({extensions}: FileListProps) {

    const css = useThemedCSS(NodeListCSS);
    const fileManagerStore = useStore(useDependency<FileListDeps>()('fileManagerStore'));

    useSafeHotkey('ArrowUp', () => {
        const [previousNodeId] = getPreviousAndNextSelectionNodeId(fileManagerStore);
        if (previousNodeId) {
            fileManagerStore.selectNode(previousNodeId);
        }
    }, []);

    useSafeHotkey('ArrowDown', () => {
        const [_, nextNodeId] = getPreviousAndNextSelectionNodeId(fileManagerStore);
        if (nextNodeId) {
            fileManagerStore.selectNode(nextNodeId);
        }
    }, []);

    useSafeHotkey('Home', () => {
        const [firstNodeId] = getFirstAndLastSelectionNodeId(fileManagerStore);
        if (firstNodeId) {
            fileManagerStore.selectNode(firstNodeId);
        }
    });

    useSafeHotkey('End', () => {
        const [_, lastNodeId] = getFirstAndLastSelectionNodeId(fileManagerStore);
        if (lastNodeId) {
            fileManagerStore.selectNode(lastNodeId);
        }
    });

    useSafeHotkey('Control+A', () => {
        fileManagerStore.selectAllNodes();
    }, []);

    useSafeHotkey('Escape', () => {
        fileManagerStore.deselectAllNodes();
    }, []);

    return (
        <div className={css['nodeList']}>
            <NodeListHeader/>
            <NodeListContent extensions={extensions}/>
        </div>
    );

}

function getFirstAndLastSelectionNodeId(fileManagerStore: FileManagerStore): [FSNode['id'] | null, FSNode['id'] | null] {
    const filteredNodes = fileManagerStore.getFilteredNodes();
    const firstNodeId = filteredNodes[0]?.id ?? null;
    const lastNodeId = filteredNodes[filteredNodes.length - 1]?.id ?? null;
    return [firstNodeId, lastNodeId];
}

function getPreviousAndNextSelectionNodeId(fileManagerStore: FileManagerStore): [FSNode['id'] | null, FSNode['id'] | null] {
    const filteredNodesIds = fileManagerStore.getFilteredNodes().map((node: FSNode): string => {
        return node.id;
    });
    const selectedNodesIds = fileManagerStore.getSelectedNodes().map((node: FSNode): string => {
        return node.id;
    });
    const filteredSelectedNodesIds = filteredNodesIds.filter((nodeId: FSNode['id']): boolean => {
        return selectedNodesIds.includes(nodeId);
    });
    const firstSelectedNodeId = filteredSelectedNodesIds[0];
    const lastSelectedNodeId = filteredSelectedNodesIds[filteredSelectedNodesIds.length - 1];
    const firstSelectedNodeIdIndex = filteredNodesIds.indexOf(firstSelectedNodeId);
    const lastSelectedNodeIdIndex = filteredNodesIds.indexOf(lastSelectedNodeId);
    const previousNodeId = firstSelectedNodeIdIndex >= 0
        ? filteredNodesIds[firstSelectedNodeIdIndex - 1] ?? null
        : filteredNodesIds[filteredNodesIds.length - 1] ?? null;
    const nextNodeId = lastSelectedNodeIdIndex >= 0
        ? filteredNodesIds[lastSelectedNodeIdIndex + 1] ?? null
        : filteredNodesIds[0] ?? null;
    return [previousNodeId, nextNodeId];
}