import {useEffect} from 'react';

export function useDocumentEvent<N extends keyof DocumentEventMap>(
    event: N,
    listener: (event: DocumentEventMap[N]) => void,
    deps: unknown[] = [listener]
): void {
    useEffect(() => {
        document.addEventListener(event, listener, getEventListenerOptions(event));
        return () => {
            document.removeEventListener(event, listener);
        };
    }, deps);
}

function getEventListenerOptions(event: keyof DocumentEventMap): AddEventListenerOptions {
    if (event === 'wheel') {
        return {
            passive: false
        };
    }
    return {};
}