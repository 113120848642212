import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject, ReactNode} from 'react';
import {TreeCSS} from './tree-css';

export interface TreeProps {
    children: ReactNode;
    className?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
}

export function Tree({children, className, elementRef}: TreeProps) {

    const css = useThemedCSS(TreeCSS);

    return (
        <div className={mergeClassNames(css['tree'], className)}
             ref={elementRef as any}>
            {children}
        </div>
    );

}