import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type NodeNameFieldClassName = 'nodeNameField' | 'is-invalid';

export interface NodeNameFieldCSSProps extends CSSThemeProps {

}

export function NodeNameFieldCSS({theme}: NodeNameFieldCSSProps) {
    return css<NodeNameFieldClassName>`

        .nodeNameField {
            background: none;
            border: none;
            padding: 0;
            outline: none;
            text-overflow: clip;
            color: inherit;
            cursor: default;
            border-bottom: 1px dotted transparent;
            min-width: 10px;

            &:not(:read-only) {
                border-bottom-color: currentColor;
                cursor: text;
            }

            &::placeholder {
                color: inherit;
            }

            &:focus:not(:read-only)::placeholder {
                color: ${theme.color.gray};
            }

            &.is-invalid {
                color: ${theme.color.danger};
                border-bottom-color: ${theme.color.danger};
            }
        }

    `;
}