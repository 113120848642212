import {Api} from '../api';
import {ApiError} from '../api-error';
import {ApiMethod, ApiMethodName} from '../api-method';
import {ApiRequest} from '../api-request';
import {ApiErrorResponse, ApiResponse} from '../api-response';
import {ApiSchema} from '../api-schema';
import {ApiClientRunner} from './api-client-runner';

export interface ApiClientFactory {
    createApi: <A extends Api = any>(name: A['name'], schema: ApiSchema<A>) => A;
}

export interface ApiClientFactoryDeps {
    apiClientRunner: ApiClientRunner;
}

export function createApiClientFactory({apiClientRunner}: ApiClientFactoryDeps): ApiClientFactory {

    const createApi = <A extends Api = any>(name: A['name'], schema: ApiSchema<A>): A => {
        const api: Partial<A> = {};
        const methods: ApiMethodName<A>[] = Object.keys(schema) as ApiMethodName<A>[];
        methods.forEach(<M extends ApiMethodName<A>>(method: M): void => {
            api[method] = (async (...params: Parameters<ApiMethod<A, M>>): Promise<ReturnType<ApiMethod<A, M>>> => {
                return await runApiRequest<A, M>({name, method, params});
            }) as ApiMethod<A, M>;
        });
        return api as A;
    };

    const runApiRequest = async <A extends Api = any, M extends ApiMethodName<A> = ApiMethodName<A>>(
        request: ApiRequest<A, M>
    ): Promise<ReturnType<ApiMethod<A, M>>> => {
        const response: ApiResponse<A, M> = await apiClientRunner.makeApiRequest(request);
        if (response.error) {
            throwApiError(request, response);
        }
        return response.result!;
    };

    const throwApiError = (request: ApiRequest, {error}: ApiErrorResponse): never => {
        const apiError = new ApiError(error.name, error.message, error.details);
        apiError.stack += '\n' + error.stack?.replace(`${error.name}: ${error.message}\n`, '').replace(/(^|\n)(\s*at)/gis, '$1$2 server');
        throw apiError;
    };

    return {
        createApi
    };

}