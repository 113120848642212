import {useDependency} from '@webaker/package-deps';
import {File, FileFactory} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';

export interface CreateFileButtonProps {

}

export interface CreateFileButtonDeps {
    fileManagerStore: FileManagerStore;
    fileFactory: FileFactory;
}

export function CreateFileButton({}: CreateFileButtonProps) {

    const fileManagerStore = useStore(useDependency<CreateFileButtonDeps>()('fileManagerStore'));
    const fileFactory = useDependency<CreateFileButtonDeps>()('fileFactory');

    const createNewFile = useCallback(() => {
        const workingDirectory = fileManagerStore.getWorkingDirectory();
        const newFile = fileFactory.createFile<File>({
            parentDirectoryId: workingDirectory?.id
        });
        fileManagerStore.addFile(newFile);
        fileManagerStore.selectNode(newFile.id);
        fileManagerStore.editNode(newFile.id);
    }, []);

    return (
        <Tooltip title={'New file'}>
            <Button color="success"
                    style="text"
                    icon={<MdIcon name="note_add"/>}
                    onClick={createNewFile}/>
        </Tooltip>
    );

}