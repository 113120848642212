import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface ForbiddenRuleOptions {

}

export function isForbidden<T = unknown>({}: ForbiddenRuleOptions = {}): ValidationRule<T> {
    return (item: unknown): ValidationError[] => {
        if (typeof item !== 'undefined' || item !== null) {
            return [{message: 'Value is forbidden'}];
        }
        return [];
    };
}