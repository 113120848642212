import {Context} from 'react';

export interface ContextNotFoundDetails {
    context: Context<any>;
}

export class ContextNotFoundError extends Error {

    name = 'CONTEXT_NOT_FOUND';

    details: ContextNotFoundDetails;

    constructor(message: string, details: ContextNotFoundDetails) {
        super(message);
        this.details = details;
    }

}