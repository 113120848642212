const TEXT_PARAGRAPH: string[] = [
    'Lorem ipsum dolor sit amet consectetur adipiscing elit',
    'Vestibulum facilisis mi in lectus suscipit et tincidunt lacus ultricies',
    'In eleifend magna sed ipsum sagittis venenatis',
    'Morbi eu magna in lectus rhoncus consequat',
    'Mauris fringilla massa at vestibulum pulvinar',
    'Duis et magna in eros tincidunt eleifend quis vitae massa',
    'Cras at felis id purus aliquet mattis id non nisl',
    'Curabitur efficitur nulla non nulla pharetra dapibus',
    'Aliquam vulputate eros nec eros ornare efficitur',
    'Vestibulum eget quam sit amet velit rutrum lacinia',
    'In ac arcu tristique pellentesque augue id fringilla ipsum',
    'Nam maximus ex ac lectus sagittis non hendrerit magna interdum',
    'Integer tempus neque ut sapien tincidunt viverra ullamcorper tellus posuere',
    'Mauris ut elit id erat elementum tincidunt sed ut nisi',
    'Nam eu augue ultricies luctus turpis eu porttitor felis',
    'Proin ut odio interdum accumsan dolor et fringilla orci',
    'Suspendisse vitae diam hendrerit finibus tellus id finibus magna',
    'Vestibulum consequat tellus sed suscipit egestas',
    'Sed sit amet felis nec erat varius rutrum non ac magna',
    'Nulla mattis arcu nec nulla congue sit amet ornare elit feugiat',
    'Vivamus congue leo ut quam interdum iaculis',
    'Cras id arcu at nibh auctor pretium',
    'Phasellus ac nulla vitae quam pharetra tincidunt',
    'Nulla commodo diam vitae elit sodales egestas',
    'Curabitur auctor velit eu libero congue varius et sit amet magna',
    'Donec vitae lacus vitae ligula porta aliquam',
    'Maecenas quis erat dictum mattis dolor feugiat lacinia leo',
    'Fusce bibendum orci sagittis pulvinar mi a eleifend nisl',
    'Duis congue enim vel lectus lobortis vel vulputate ipsum varius',
    'Morbi gravida ligula laoreet odio tempor vulputate',
    'Morbi lacinia dolor facilisis bibendum ullamcorper',
    'Etiam malesuada erat et elit fringilla convallis',
    'Duis vel nunc nec erat consectetur bibendum ac non libero',
    'Morbi tincidunt massa quis auctor suscipit',
    'Nulla eu tortor imperdiet lobortis ante a tincidunt magna',
    'Fusce lobortis odio vel tellus tempus nec maximus mauris feugiat',
    'Pellentesque accumsan dui tincidunt ante fermentum commodo',
    'Sed nec dui ac nisl facilisis vulputate',
    'Nullam eu ipsum imperdiet scelerisque neque sed faucibus urna',
    'Vivamus vitae nisl fringilla consequat diam id ullamcorper nisi',
    'Sed placerat enim eu congue aliquet',
    'Nam mollis erat ac mauris finibus rutrum',
    'Quisque eleifend orci sit amet velit laoreet tempor',
    'Nulla faucibus est a turpis fringilla finibus',
    'Proin placerat libero eu hendrerit volutpat',
    'Nulla pharetra dolor et dictum consequat',
    'Nam lacinia justo et purus efficitur vulputate',
    'Mauris accumsan diam ut nibh luctus quis pharetra ante feugiat',
    'Proin viverra lectus at neque iaculis hendrerit',
    'Donec gravida eros ac elementum ultrices',
    'Praesent id elit eu eros congue malesuada at nec ante',
    'Maecenas molestie mauris sit amet porttitor ullamcorper',
    'Sed id eros quis nisi dignissim convallis eu a urna',
    'Vestibulum laoreet odio sit amet scelerisque lacinia',
    'Aliquam a nisi suscipit pretium nisi ornare volutpat metus',
    'Curabitur efficitur ligula sed quam fringilla porta',
    'Donec id augue ut ex vestibulum porta',
    'Nullam id ex at nisi accumsan posuere ut eu dolor',
    'Phasellus viverra velit sit amet dolor suscipit in fermentum nibh bibendum',
    'Curabitur aliquam massa a finibus placerat',
    'Praesent pellentesque nisl quis posuere ornare',
    'Ut a nulla ac eros maximus lacinia',
    'Donec porta tortor ac urna venenatis a pharetra felis pulvinar',
    'Phasellus tristique felis sed lorem pellentesque et bibendum urna sagittis',
    'Nullam sed eros a risus aliquet faucibus',
    'Aliquam pharetra quam at purus laoreet ultrices',
    'Cras id diam nec risus interdum semper ornare dignissim metus',
    'Pellentesque vel ipsum non lacus molestie commodo et accumsan ligula',
    'Nam et nisl viverra volutpat nisl vitae molestie metus',
    'Nunc tincidunt quam ut ullamcorper auctor',
    'Donec interdum urna suscipit nulla varius tempor',
    'Aenean accumsan eros in magna viverra at maximus erat iaculis',
];

export interface TextGenerator {
    generateText: (minWords: number, maxWords?: number) => string;
}

export function createTextGenerator(): TextGenerator {

    const generateText = (minWords: number, maxWords?: number): string => {
        const wordsCount = minWords + (maxWords ? Math.floor(Math.random() * (maxWords - minWords)) : 0);
        const generatedWords = [];
        while (generatedWords.length < wordsCount) {
            generatedWords.push(...getRandomWords());
        }
        return generatedWords.slice(0, wordsCount).join(' ');
    };

    const getRandomWords = (): string[] => {
        const randomParagraph = getRandomParagraph();
        return randomParagraph.split(' ');
    };

    const getRandomParagraph = (): string => {
        return TEXT_PARAGRAPH[Math.floor(Math.random() * TEXT_PARAGRAPH.length)];
    };

    return {
        generateText
    };

}