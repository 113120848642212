import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {Page} from './page';
import {OmitPage, PartialPage} from './page-types';

export type PageFactoryFunction<P extends Page = Page> = (data: PartialPage<P>) => OmitPage<P>;

export interface PageFactory {
    registerFactory: <P extends Page = Page>(factoryFunction: PageFactoryFunction<P>) => void;
    registerFactoryByType: <P extends Page = Page>(type: P['type'], factoryFunction: PageFactoryFunction<P>) => void;
    createPage: <P extends Page = Page>(data: PartialPage<P>) => P;
}

export interface PageFactoryDeps {
    idGenerator: IdGenerator;
}

export function createPageFactory({idGenerator}: PageFactoryDeps): PageFactory {

    const factoriesSet: Set<PageFactoryFunction<any>> = new Set();
    const factoriesByTypeMap: Map<string, PageFactoryFunction<any>> = new Map();

    const registerFactory = <P extends Page = Page>(factoryFunction: PageFactoryFunction<P>): void => {
        factoriesSet.add(factoryFunction);
    };

    const registerFactoryByType = <P extends Page = Page>(type: P['type'], factoryFunction: PageFactoryFunction<P>): void => {
        factoriesByTypeMap.set(type, factoryFunction);
    };

    const createPage = <P extends Page = Page>(data: PartialPage<P>): P => {
        const baseData = getBaseData(data);
        const extensionsData = getExtensionsData(data);
        const typeData = getTypeData(data);
        return omitUndefined({
            ...baseData,
            ...extensionsData,
            ...typeData
        }) as P;
    };

    const getBaseData = (data: PartialPage): Page => {
        return {
            id: data.id ?? idGenerator.generateId(),
            type: data.type,
            path: data.path ?? '',
            title: data.title ?? '',
            parentId: data.parentId,
            index: data.index
        };
    };

    const getExtensionsData = <P extends Page = Page>(data: PartialPage<P>): OmitPage<P> => {
        return Array.from(factoriesSet.values()).reduce((
            result: Partial<P>,
            factoryFunction: PageFactoryFunction<P>
        ): Partial<P> => {
            return {...result, ...factoryFunction(data)};
        }, {}) as OmitPage<P>;
    };

    const getTypeData = <P extends Page = Page>(data: PartialPage<P>): OmitPage<P> => {
        return factoriesByTypeMap.get(data.type)?.(data) ?? {};
    };

    return {
        registerFactory,
        registerFactoryByType,
        createPage
    };

}