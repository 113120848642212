import {ReactElement, ReactNode} from 'react';

export function isReactElement(node: ReactNode): node is ReactElement {
    return typeof node === 'object' && node !== null && 'type' in node && 'props' in node;
}

export function matchReactNode(node: ReactNode, matchNode: ReactNode): boolean {
    if (isReactElement(node) && isReactElement(matchNode)) {
        return node.key && node.key === matchNode.key ||
            node.type === matchNode.type &&
            matchReactProps(node.props, matchNode.props);
    }
    return node === matchNode;
}

export function matchReactProps<P extends {} = any>(props: P, matchProps: P): boolean {
    return Object.keys(matchProps).every((key: string): boolean => {
        // @ts-ignore
        return props[key] === matchProps[key];
    });
}