import {useConfig, useDependency, useOptionalDependency} from '@webaker/package-deps';
import {JSONEncoder} from '@webaker/package-utils';
import React from 'react';
import {StoreRegistry} from './store-registry';

export const STATE_ELEMENT_ID: string = 'state';

export interface StateInjectorProps {

}

export interface StateInjectorDeps {
    jsonEncoder?: JSONEncoder;
    storeRegistry: StoreRegistry;
}

export interface StateInjectorConfig {
    encodeState: boolean;
}

export function StateInjector({}: StateInjectorProps) {

    const storeRegistry = useDependency<StateInjectorDeps>()('storeRegistry');
    const encodeState = useConfig<StateInjectorConfig>()('encodeState');
    const jsonEncoder = useOptionalDependency<StateInjectorDeps>()('jsonEncoder');
    const globalState = storeRegistry.getGlobalState();
    const encodedJSON = encodeHTMLEntities(((encodeState ? jsonEncoder : null) ?? JSON).stringify(globalState));

    return (
        <script type="application/json"
                dangerouslySetInnerHTML={{__html: encodedJSON}}
                id={STATE_ELEMENT_ID}/>
    );

}

const LT_SIGN = '<';
const LT_REPLACEMENT = '__LT';
const GT_SIGN = '>';
const GT_REPLACEMENT = '__GT';

export function encodeHTMLEntities(text: string): string {
    return text.replaceAll(LT_SIGN, LT_REPLACEMENT).replaceAll(GT_SIGN, GT_REPLACEMENT);
}

export function decodeHTMLEntities(text: string): string {
    return text.replaceAll(LT_REPLACEMENT, LT_SIGN).replaceAll(GT_REPLACEMENT, GT_SIGN);
}