import {ValidationError} from '../validation-error';
import {ValidationRule} from '../validation-rule';

export interface BooleanRuleOptions {
    strict?: boolean;
}

export function isBoolean({strict = true}: BooleanRuleOptions = {}): ValidationRule<boolean> {
    return (item: unknown): ValidationError[] => {
        if (!strict) {
            if (item === 'false' || item === 0) {
                item = false;
            }
            if (item === 'true' || item === 1) {
                item = true;
            }
        }
        if (typeof item !== 'boolean') {
            return [{message: `Value has to be boolean`}];
        }
        return [];
    };
}