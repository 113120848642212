import {Fragment, FunctionComponent, ReactNode, useEffect, useState} from 'react';

export interface ClientOnlyProps {
    children: ReactNode;
}

export function ClientOnly({children}: ClientOnlyProps) {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return isMounted ? (
        <Fragment>
            {children}
        </Fragment>
    ) : null;
}

export function clientOnly<P extends {} = {}>(component: FunctionComponent<P>): FunctionComponent<P> {
    return (props: P) => {
        return (
            <ClientOnly>
                {component(props)}
            </ClientOnly>
        );
    };
}