export type Debounced<C extends Callback = Callback> = (...args: Parameters<C>) => Promise<ReturnType<C>>;

type Callback = (...args: any[]) => any;
type Resolver<C extends Callback = Callback> = (value: ReturnType<C>) => void;

export function debounce<C extends Callback = Callback>(callback: C, timeout: number = 0): Debounced<C> {
    let timeoutId: number = 0;
    let resolvers: Resolver<C>[] = [];
    return (...args: Parameters<C>): Promise<ReturnType<C>> => {
        return new Promise((resolve) => {
            resolvers.push(resolve);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                const result = callback(...args);
                resolvers.forEach(resolver => resolver(result));
                resolvers = [];
            }, timeout) as any;
        });
    };
}