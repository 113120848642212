import {getAppConfig} from '../config/app-config';
import {getChunksConfig} from '../config/chunks-config';
import {getCSSThemeConfig} from '../config/css-theme-config';
import {getExtensionsConfig} from '../config/extensions-config';
import {getFeaturesConfig} from '../config/features-config';
import {getLogConfig} from '../config/log-config';
import {getPublicDirsConfig} from '../config/public-dirs-config';
import {getRoutesConfig} from '../config/routes-config';
import {getUserConfig} from '../config/user-config';
import {mockEnv} from '../utils/mock-env';

export type BootClientConfig = ReturnType<typeof getClientConfig>;

export function getClientConfig() {

    mockEnv();

    return {
        ...getAppConfig(),
        ...getChunksConfig(),
        ...getCSSThemeConfig(),
        ...getExtensionsConfig(),
        ...getFeaturesConfig(),
        ...getLogConfig(),
        ...getPublicDirsConfig(),
        ...getRoutesConfig(),
        ...getUserConfig()
    } as const;

}