import {PageOptions} from '@webaker/app';
import {HOME_PAGE_TYPE, HomePage} from './home-page';

export interface HomePageOptions extends PageOptions<HomePage> {

}

export function createHomePageOptions(): HomePageOptions {
    return {
        type: HOME_PAGE_TYPE,
        static: true
    };
}