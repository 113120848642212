import {ApiClientFactory} from '@webaker/package-api/client';
import {FILE_API_NAME, FileApi} from '../file-api';

export interface FileClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createFileClientApi({apiClientFactory}: FileClientApiDeps): FileApi {
    return apiClientFactory.createApi<FileApi>(FILE_API_NAME, {
        getDirectoryTree: ['directoryId'],
        getDirectoryById: ['directoryId'],
        getDirectoriesByIds: ['directoriesIds'],
        getFileById: ['fileId'],
        getFilesByIds: ['filesIds'],
        saveDirectory: ['directory'],
        saveFile: ['file'],
        deleteDirectory: ['directoryId'],
        deleteFile: ['fileId'],
        moveDirectory: ['directoryId', 'parentDirectoryId'],
        moveFile: ['fileId', 'parentDirectoryId'],
        copyDirectory: ['directoryId', 'parentDirectoryId'],
        copyFile: ['fileId', 'parentDirectoryId'],
        getFileUsages: ['fileId'],
        isAnyFileInUse: ['filesIds']
    });
}