import {PageOptions} from '@webaker/app';
import {FILE_MANAGER_PAGE_TYPE, FileManagerPage} from './file-manager-page';

export interface FileManagerPageOptions extends PageOptions<FileManagerPage> {

}

export function createFileManagerPageOptions(): FileManagerPageOptions {
    return {

        type: FILE_MANAGER_PAGE_TYPE,

        static: true,

        editor: true,

        ssr: false,

        view: async () => (await import('@webaker/module-file-manager-page/editor')).FileManagerPageView

    };
}