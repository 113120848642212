export interface AggregatedErrorDetails {
    errors: Error[];
}

export class AggregatedError extends Error {

    name = 'AGGREGATED';

    details: AggregatedErrorDetails;

    constructor(message: string, details: AggregatedErrorDetails) {
        super(message);
        this.details = details;
    }

}