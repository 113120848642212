import {useCallback, useState} from 'react';

type BooleanSetter = () => void;

export function useBooleanState(initialValue: boolean = false): [boolean, BooleanSetter, BooleanSetter] {
    const [value, setValue] = useState(initialValue);
    const setTrue = useCallback(() => {
        setValue(true);
    }, []);
    const setFalse = useCallback(() => {
        setValue(false);
    }, []);
    return [
        value,
        setTrue,
        setFalse
    ];
}