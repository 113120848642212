import {useDebugValue} from 'react';
import {Dependencies} from '../dependencies';
import {OptionalKey} from '../dependency-key';
import {useContainer} from './use-container';

export function useOptionalDependency<D extends Dependencies = {}>() {
    return function useOptionalDependency<N extends OptionalKey<D> = OptionalKey<D>>(name: N): D[N] | undefined {
        useDebugValue(name);
        return useContainer<D>().resolveOptional(name);
    };
}