import {escapeRegExp} from './escape-regexp';

export const INDEX_BEGIN = 'begin';
export const INDEX_END = 'end';

const DEFAULT_PREFIX = ' (';
const DEFAULT_SUFFIX = ')';
const DEFAULT_POSITION = INDEX_END;

export interface UniqueNameOptions {
    prefix?: string;
    suffix?: string;
    position?: IndexPosition;
    skip?: RegExp | null;
}

export type IndexPosition = typeof INDEX_BEGIN | typeof INDEX_END;

interface ParsedName {
    getName: (newIndex?: number) => string;
    getBaseName: () => string;
    getIndex: () => number;
}

export function uniqueName(name: string, otherNames: string[], {
    prefix = DEFAULT_PREFIX,
    suffix = DEFAULT_SUFFIX,
    position = DEFAULT_POSITION,
    skip = null
}: UniqueNameOptions = {}): string {
    if (!otherNames.includes(name)) {
        return name;
    }
    const options = {prefix, suffix, position, skip};
    const parsedName = parseName(name, options);
    const maxIndex = otherNames.reduce((maxIndex: number, otherName: string): number => {
        const parsedOtherName = parseName(otherName, options);
        if (parsedOtherName.getBaseName() === parsedName.getBaseName() && parsedOtherName.getIndex() >= maxIndex) {
            return parsedOtherName.getIndex() || 1;
        }
        return maxIndex;
    }, 0);
    return maxIndex ? parsedName.getName(maxIndex + 1) : name;
}

export function parseName(name: string, {prefix, suffix, position, skip}: Required<UniqueNameOptions>): ParsedName {
    const begin = position === INDEX_BEGIN ? '^' : '';
    const end = position === INDEX_END ? '$' : '';
    const skipRegExp = skip ? new RegExp(`${begin}${skip.source}${end}`) : null;
    const skipPart = skipRegExp ? name.match(skipRegExp)?.[0] ?? '' : '';
    const trimmedName = skipRegExp ? name.replace(skipRegExp, '') : name;
    const indexRegExp = new RegExp(`${begin}${escapeRegExp(prefix)}([0-9]+)${escapeRegExp(suffix)}${end}`);
    const index = parseInt(trimmedName.match(indexRegExp)?.[1] ?? '0');
    const restPart = trimmedName.replace(indexRegExp, '');
    const beginPart = position === INDEX_BEGIN ? skipPart : restPart;
    const endPart = position === INDEX_END ? skipPart : restPart;
    return {
        getName: (newIndex: number = index): string => {
            return newIndex === 0 ? [
                beginPart,
                endPart
            ].join('') : [
                beginPart,
                prefix,
                newIndex,
                suffix,
                endPart
            ].join('');
        },
        getBaseName: (): string => {
            return [
                beginPart,
                endPart
            ].join('');
        },
        getIndex: (): number => {
            return index;
        }
    };
}