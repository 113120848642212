import {Provider} from '@webaker/package-deps';
import {createJSXRegistry, JSXRegistry} from '../jsx-registry';

export interface ProvidedJSXClientDeps {
    jsxRegistry: JSXRegistry;
}

export interface RequiredJSXClientDeps {

}

export interface JSXClientDeps extends ProvidedJSXClientDeps, RequiredJSXClientDeps {

}

export interface JSXClientConfig {

}

export type JSXClientProvider = Provider<JSXClientDeps, JSXClientConfig>;

export function createJSXClientProvider(): JSXClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('jsxRegistry', () => {
                return createJSXRegistry();
            });
        }

    };
}