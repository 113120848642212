import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {adjustColor} from '@webaker/package-utils';

export type TreeNodeClassName =
    'treeNode'
    | 'item'
    | 'arrow'
    | 'label'
    | 'sublabel'
    | 'buttons'
    | 'children'
    | 'is-active'
    | 'is-empty'
    | 'is-expandable'
    | 'is-open'
    | 'is-clickable';

export interface TreeNodeCSSProps extends CSSThemeProps {

}

export function TreeNodeCSS({theme}: TreeNodeCSSProps) {
    return css<TreeNodeClassName>`

        .treeNode {
            position: relative;
            color: ${theme.color.black};
        }

        .item {
            display: flex;
            flex-direction: row;
            margin: ${theme.spacing.small} 0;
            position: relative;
            cursor: default;
            transition: background-color ${theme.transition.long};
            border-radius: ${theme.borderRadius.medium};
        }

        .arrow {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 1.5em;;
        }

        .is-expandable .arrow {
            cursor: pointer;
        }

        .label {
            padding: ${theme.spacing.medium} ${theme.spacing.large} ${theme.spacing.medium} ${theme.spacing.small};
            width: 100%;
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: ${theme.spacing.small};
            flex: 1;

            > * {
                min-width: 1em;
            }
        }

        .sublabel {
            font-size: ${theme.fontSize.small};
            color: ${theme.color.light};
            display: flex;
            flex-direction: row;
            gap: ${theme.spacing.small};
            width: 100%;
        }

        .buttons {
            display: flex;
        }

        .children {
            display: none;
            position: relative;
            margin-left: ${theme.spacing.veryLarge};
        }

        .is-expandable > .children:before {
            content: '';
            display: block;
            position: absolute;
            left: -${theme.spacing.large};
            width: 1px;
            height: 100%;
            background: ${adjustColor(theme.color.black, {alpha: 0.2})};
        }

        .is-active > .item {
            color: ${theme.color.marked};
            font-weight: bold;

            .sublabel {
                color: ${theme.color.gray};
            }
        }

        .is-empty .arrow {
            cursor: default;
        }

        .is-empty .children {
            display: none;
        }

        .is-open > .children {
            display: block;
        }

        .is-clickable > .item {
            cursor: pointer;
        }

    `;
}

export interface TreeNodePrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor | null;
}

export function TreeNodePrivateCSS({theme, color}: TreeNodePrivateCSSProps) {
    return css<TreeNodeClassName>`

        .item:hover {
            background-color: ${color ? adjustColor(theme.color[color], {alpha: 0.2}) : 'none'};
            transition: background-color ${theme.transition.short};
        }

    `;
}