import {PageOptions} from '@webaker/app';
import {VIRTUAL_PAGE_TYPE, VirtualPage} from './virtual-page';

export interface VirtualPageOptions extends PageOptions<VirtualPage> {

}

export function createVirtualPageOptions(): VirtualPageOptions {
    return {
        type: VIRTUAL_PAGE_TYPE
    };
}