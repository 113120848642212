import {AppMode, getAppMode} from '../utils/app-mode';

export interface AppConfig {
    appMode: AppMode;
    appName: string;
    appUrl: string;
    appPort: number;
}

export function getAppConfig(): AppConfig {

    const appMode = getAppMode();
    const appName = process.env.APP_NAME ?? 'Webaker';
    const appPort = parseInt(process.env.APP_PORT || '') || 3000;
    const appUrl = process.env.APP_URL ?? `http://localhost:${appPort}`;

    return {
        appName,
        appMode,
        appPort,
        appUrl
    };

}