import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject, ReactNode} from 'react';
import {ContainerCSS} from './container-css';

export interface ContainerProps {
    className?: string;
    children?: ReactNode;
    elementRef?: MutableRefObject<HTMLDivElement | null>;
}

export function Container({children, className, elementRef}: ContainerProps) {

    const css = useThemedCSS(ContainerCSS);

    return (
        <div ref={elementRef}
             className={mergeClassNames(css['container'], className)}>
            {children}
        </div>
    );

}