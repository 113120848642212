import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type OverlayClassName = 'overlay' | 'is-transparent';

export function OverlayCSS({theme}: CSSThemeProps) {
    return css<OverlayClassName>`

        .overlay {
            align-items: center;
            backdrop-filter: blur(2px);
            background: radial-gradient(${color(theme.color.black, {alpha: 0.2})}, ${color(theme.color.black, {alpha: 0.4})});
            display: flex;
            height: 100%;
            justify-content: center;
            left: 0;
            position: fixed;
            width: 100%;
            top: 0;

            &.is-transparent {
                background: none;
            }
        }

    `;
}