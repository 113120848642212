import {memo} from 'react';

export function jsxMemo(type: any): any {
    if (typeof type === 'function') {
        if (!type.__memo) {
            type.__memo = memo(type);
        }
        return type.__memo;
    }
    return type;
}