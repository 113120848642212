export interface NodeAlreadyExistsErrorDetails {
    nodeName: string;
    parentDirectoryId: string | null;
}

export class NodeAlreadyExistsError extends Error {

    name: string = 'NODE_ALREADY_EXISTS';

    details: NodeAlreadyExistsErrorDetails;

    constructor(message: string, details: NodeAlreadyExistsErrorDetails) {
        super(message);
        this.details = details;
    }

}