export interface MemoFactory {
    createMemo: <V = any>() => Memo<V>;
}

export type Memo<V = any> = (resolver: MemoResolver<V>, deps?: MemoDeps, key?: MemoKey) => V;
export type MemoResolver<V = any> = () => V;
export type MemoDeps = unknown[];
export type MemoKey = object;

export function createMemoFactory(): MemoFactory {

    const createMemo = <V = any>(): Memo<V> => {

        const valuesMap: Map<string, any> = new Map();
        const depsMap: Map<string, any> = new Map();

        const areArraysEqual = (arrayA: unknown[], arrayB: unknown[]): boolean => {
            return arrayA.length === arrayB.length &&
                arrayA.every((_, index) => arrayA[index] === arrayB[index]);
        };

        return <V>(resolver: MemoResolver<V>, deps?: MemoDeps, key?: MemoKey): V => {
            const stringKey = JSON.stringify(key);
            if (valuesMap.has(stringKey)) {
                const memoizedDeps = depsMap.get(stringKey);
                if (deps && memoizedDeps && areArraysEqual(deps, memoizedDeps)) {
                    return valuesMap.get(stringKey)!;
                }
            }
            const value = resolver();
            valuesMap.set(stringKey, value);
            depsMap.set(stringKey, deps);
            return value;
        };

    };

    return {
        createMemo
    };

}