import {ValidationError} from '../validation-error';
import {ValidationModifier} from '../validation-modifier';
import {ValidationRule} from '../validation-rule';

export function required<T = unknown>(rule: ValidationRule<T>): ValidationModifier<T> {
    return (item: unknown): ValidationError[] => {
        if (typeof item === 'undefined' || item === null) {
            return [{message: 'Value is required'}];
        }
        return rule(item);
    };
}