import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {createContext, ReactNode} from 'react';
import {SideMenuCSS} from './side-menu-css';

export interface SideMenuProps {
    direction?: SideMenuDirection;
    align?: SideMenuAlignment;
    children?: ReactNode;
    className?: string;
}

export type SideMenuDirection = 'row' | 'column';

export type SideMenuAlignment = 'left' | 'right';

export interface SideMenuContext {
    align: SideMenuAlignment;
}

export const SideMenuContext = createContext<SideMenuContext>({
    align: 'left'
});

export function SideMenu({
    direction = 'column',
    align = 'left',
    children,
    className
}: SideMenuProps) {

    const css = useThemedCSS(SideMenuCSS);

    return (
        <nav className={mergeClassNames(
            css['sideMenu'],
            css[`direction-${direction}`],
            className
        )}>
            <SideMenuContext.Provider value={{align}}>
                {children}
            </SideMenuContext.Provider>
        </nav>
    );

}