import {useDependency} from '@webaker/package-deps';
import {Directory, FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {MdIcon} from '@webaker/package-ui';
import {formatBytes} from '@webaker/package-utils';
import {MouseEvent, useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';
import {NodeListItem} from './node-list-item';
import {NodeNameField} from './node-name-field';

export interface DirectoryListItemProps {
    muted?: boolean;
    directory: Directory;
}

export interface DirectoryListItemDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function DirectoryListItem({muted, directory}: DirectoryListItemProps) {

    const fileManagerStore = useStore(useDependency<DirectoryListItemDeps>()('fileManagerStore'));
    const fileApi = useDependency<DirectoryListItemDeps>()('fileApi');
    const isSelected = fileManagerStore.isNodeSelected(directory.id);

    const handleClick = useCallback(async (event: MouseEvent) => {
        if (event.ctrlKey) {
            fileManagerStore.toggleSelectNode(directory.id);
        } else if (event.shiftKey) {
            fileManagerStore.selectNodesRange(directory.id);
        } else {
            fileManagerStore.selectNode(directory.id);
        }
    }, []);

    const handleDoubleClick = useCallback(async () => {
        fileManagerStore.startLoading();
        const directoryTree = await fileApi.getDirectoryTree(directory.id);
        fileManagerStore.openDirectory(directoryTree);
        fileManagerStore.stopLoading();
    }, [directory.id]);

    const handleNameChange = useCallback(async (newDirectory: Directory) => {
        fileManagerStore.updateDirectory(newDirectory);
        const savedDirectory = await fileApi.saveDirectory(newDirectory);
        fileManagerStore.updateDirectory(savedDirectory);
    }, []);

    const handleNameCancel = useCallback(async (newDirectory: Directory) => {
        const originalDirectory = await fileApi.getDirectoryById(newDirectory.id);
        if (!originalDirectory) {
            fileManagerStore.deleteDirectory(newDirectory.id);
        }
    }, []);

    return (
        <NodeListItem onClick={handleClick}
                      onDoubleClick={handleDoubleClick}
                      selected={isSelected}
                      muted={muted}
                      iconColumn={<MdIcon name="folder"/>}
                      nameColumn={(
                          <NodeNameField node={directory}
                                         onChange={handleNameChange}
                                         onCancel={handleNameCancel}/>
                      )}
                      sizeColumn={directory.size !== undefined ? formatBytes(directory.size) : null}/>
    );

}