import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {useCallback} from 'react';
import {FileManagerStore} from '../../file-manager-store';

export interface RenameFileButtonProps {

}

export interface RenameFileButtonDeps {
    fileManagerStore: FileManagerStore;
}

export function RenameFileButton({}: RenameFileButtonProps) {

    const fileManagerStore = useStore(useDependency<RenameFileButtonDeps>()('fileManagerStore'));
    const selectedNodes = fileManagerStore.getSelectedNodes();
    const isOneNodeSelected = selectedNodes.length === 1;

    const renameFiles = useCallback(() => {
        fileManagerStore.editNode(selectedNodes[0].id);
    }, [selectedNodes]);

    return (
        <Tooltip title="Rename"
                 color={isOneNodeSelected ? 'dark' : 'gray'}>
            <Button color="success"
                    style="text"
                    icon={<MdIcon name="edit_square"/>}
                    onClick={renameFiles}
                    disabled={!isOneNodeSelected}/>
        </Tooltip>
    );

}