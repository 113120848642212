import {UnionToIntersection} from './tuple';

export type DotKey<T extends object> = keyof DotObject<T>;

export type DotValue<T extends object, K extends string> = K extends keyof T
    ? T[K]
    : K extends `${infer A}.${infer B}`
        ? A extends keyof T
            ? T[A] extends object
                ? DotValue<T[A], B>
                : never
            : never
        : never;

export type DotObject<T extends object> = Partial<MergeNested<{
    [K in keyof T]: NonNullable<T[K]> extends object
        ? NestedDotObject<NonNullable<T[K]>, K & string>
        : T[K];
}>>;

type NestedDotObject<T extends object, N extends string> = {
    [K in N]: T;
} & MergeNested<{
    [K in keyof T as `${N}.${K & string}`]: NonNullable<T[K]> extends object
        ? NestedDotObject<NonNullable<T[K]>, `${N}.${K & string}`>
        : T[K];
}>;

type MergeNested<T extends object> = UnionToIntersection<ValueOf<{
    [K in keyof T as NonNullable<T[K]> extends object ? K : never]-?: T[K];
}>> & {
    [K in keyof T as NonNullable<T[K]> extends object ? never : K]-?: T[K];
};

type ValueOf<T extends object> = T[keyof T];