export interface Serializer {
    serialize: (item: unknown) => string;
}

export interface SerializerConfig {
    minify?: boolean;
}

export function createSerializer({minify = false}: SerializerConfig = {}): Serializer {

    const serialize = (item: unknown): string => {
        try {
            if (minify) {
                return JSON.stringify(item);
            }
            return JSON.stringify(item, null, '\t');
        } catch {
            return '';
        }
    };

    return {
        serialize
    };

}