import {useThemedCSS} from '@webaker/package-css-theme';
import {adjustColor, Color, ColorFormat, isColor, mergeClassNames, parseColor, ParsedColor, stringifyParsedColor} from '@webaker/package-utils';
import {ChangeEvent, MouseEvent as ReactMouseEvent, useCallback, useEffect, useRef, useState} from 'react';
import {Button} from './button';
import {ColorPaletteCSS} from './color-palette-css';
import {MdIcon} from './md-icon';

export interface ColorPaletteProps {
    value: string | null;
    onChange?: (event: ColorPaletteChangeEvent) => void;
    format?: ColorFormat;
    input?: boolean;
    className?: string;
}

export interface ColorPaletteChangeEvent {
    value: string | null;
}

const DEFAULT_COLOR = '#fff';

export function ColorPalette({value, onChange, format = 'hex', input, className}: ColorPaletteProps) {

    const css = useThemedCSS(ColorPaletteCSS, {});
    const [hsv, setHSV] = useState(() => parseHSV(value ?? ''));
    const color = stringifyHSV(hsv, format);
    const colorFullA = stringifyHSV({...hsv, alpha: 1}, format);
    const colorFullSVA = stringifyHSV({...hsv, saturation: 100, value: 100, alpha: 1}, format);
    const svPaletteRef = useRef<HTMLDivElement | null>(null);
    const hPaletteRef = useRef<HTMLDivElement | null>(null);
    const aPaletteRef = useRef<HTMLDivElement | null>(null);
    const [inputValue, setInputValue] = useState<string>(value ? color : '');
    const [isEyeDropperAvailable, setIsEyeDropperAvailable] = useState(false);

    const handleSVStart = useCallback((event: ReactMouseEvent) => {
        const handleUpdate = (event: MouseEvent | ReactMouseEvent) => {
            if (svPaletteRef.current) {
                const elementRect = svPaletteRef.current.getBoundingClientRect();
                const saturation = Math.round(Math.max(0, Math.min(1, (event.clientX - elementRect.left) / elementRect.width)) * 100);
                const value = Math.round(Math.max(0, Math.min(1, 1 - (event.clientY - elementRect.top) / elementRect.height)) * 100);
                const newHSV = {...hsv, saturation, value};
                setHSV(newHSV);
                onChange?.({value: stringifyHSV(newHSV, format)});
                event.stopPropagation();
                event.preventDefault();
            }
        };
        const handleStop = () => {
            document.removeEventListener('mousemove', handleUpdate);
            document.removeEventListener('mouseup', handleStop);
        };
        document.addEventListener('mousemove', handleUpdate);
        document.addEventListener('mouseup', handleStop);
        handleUpdate(event);
    }, [hsv, onChange]);

    const handleHStart = useCallback((event: ReactMouseEvent) => {
        let newHSV = hsv;
        const handleUpdate = (event: MouseEvent | ReactMouseEvent) => {
            if (hPaletteRef.current) {
                const elementRect = hPaletteRef.current.getBoundingClientRect();
                const hue = Math.round(Math.max(0, Math.min(1, 1 - (event.clientY - elementRect.top) / elementRect.height)) * 360);
                newHSV = {...hsv, hue};
                setHSV(newHSV);
                onChange?.({value: stringifyHSV(newHSV, format)});
                event.stopPropagation();
                event.preventDefault();
            }
        };
        const handleStop = () => {
            document.removeEventListener('mousemove', handleUpdate);
            document.removeEventListener('mouseup', handleStop);
        };
        document.addEventListener('mousemove', handleUpdate);
        document.addEventListener('mouseup', handleStop);
        handleUpdate(event);
    }, [hsv, onChange]);

    const handleAStart = useCallback((event: ReactMouseEvent) => {
        let newHSV = hsv;
        const handleUpdate = (event: MouseEvent | ReactMouseEvent) => {
            if (aPaletteRef.current) {
                const elementRect = aPaletteRef.current.getBoundingClientRect();
                const alpha = Math.round(Math.max(0, Math.min(1, (event.clientX - elementRect.left) / elementRect.width)) * 100) / 100;
                newHSV = {...hsv, alpha};
                setHSV(newHSV);
                onChange?.({value: stringifyHSV(newHSV, format)});
                event.stopPropagation();
                event.preventDefault();
            }
        };
        const handleStop = () => {
            document.removeEventListener('mousemove', handleUpdate);
            document.removeEventListener('mouseup', handleStop);
        };
        document.addEventListener('mousemove', handleUpdate);
        document.addEventListener('mouseup', handleStop);
        handleUpdate(event);
    }, [hsv, onChange]);

    const handleInputChange = useCallback((event: ChangeEvent) => {
        const input = event.target as HTMLInputElement;
        setInputValue(input.value);
        if (!input.value) {
            onChange?.({value: null});
        }
        if (isColor(input.value)) {
            const newHSV = parseHSV(input.value);
            setHSV(newHSV);
            onChange?.({value: stringifyHSV(newHSV, format)});
        }
    }, [onChange, format]);

    const openEyeDropper = useCallback(async () => {
        const eyeDropper = new EyeDropper();
        const {sRGBHex} = await eyeDropper.open();
        if (isColor(sRGBHex)) {
            const newHSV = parseHSV(sRGBHex);
            setHSV(newHSV);
            onChange?.({value: stringifyHSV(newHSV, format)});
        }
    }, [onChange, format]);

    useEffect(() => {
        setInputValue(value ? color : '');
    }, [value, color]);

    useEffect(() => {
        setIsEyeDropperAvailable(typeof EyeDropper !== 'undefined');
    }, []);

    return (
        <div className={mergeClassNames(css['colorPalette'], className)}
             style={{
                 '--color': color,
                 '--color-full-a': colorFullA,
                 '--color-full-sva': colorFullSVA
             }}>
            {input && (
                <input type="text"
                       value={inputValue}
                       placeholder={color}
                       onChange={handleInputChange}
                       spellCheck={false}
                       autoFocus={true}
                       className={css['input']}/>
            )}
            <div ref={svPaletteRef}
                 className={css['svPalette']}
                 onMouseDown={handleSVStart}>
                {value && isColor(value) && (
                    <div className={css['svPicker']}
                         style={{
                             left: hsv.saturation + '%',
                             top: 100 - hsv.value + '%'
                         }}/>
                )}
            </div>
            <div ref={hPaletteRef}
                 className={css['hPalette']}
                 onMouseDown={handleHStart}>
                <div className={css['hPicker']}
                     style={{
                         top: 100 - hsv.hue / 360 * 100 + '%'
                     }}/>
            </div>
            <div ref={aPaletteRef}
                 className={css['aPalette']}
                 onMouseDown={handleAStart}>
                <div className={css['aPicker']}
                     style={{
                         left: hsv.alpha * 100 + '%'
                     }}/>
            </div>
            {isEyeDropperAvailable && (
                <div className={css['eyeDropper']}>
                    <Button style="text"
                            icon={<MdIcon name="colorize"/>}
                            onClick={openEyeDropper}/>
                </div>
            )}
        </div>
    );

}

interface HSL {
    hue: number;
    saturation: number;
    lightness: number;
    alpha: number;
}

interface HSV {
    hue: number;
    saturation: number;
    value: number;
    alpha: number;
}

function parseHSV(color: string): HSV {
    const parsedColor = parseColor(isColor(color) ? color : DEFAULT_COLOR);
    return convertHSLToHSV(parsedColor);
}

function stringifyHSV(hsv: HSV, format: ColorFormat): Color {
    const parsedColor = convertHSVToHSL(hsv) as ParsedColor;
    const stringColor = stringifyParsedColor(parsedColor, 'hsl');
    return adjustColor(stringColor, {format});
}

function convertHSLToHSV(hsl: HSL): HSV {
    const l = hsl.lightness / 100;
    const sl = hsl.saturation / 100;
    const v = l + sl * Math.min(l, 1 - l);
    const sv = v === 0 ? 0 : 2 * (1 - l / v);
    return {
        hue: hsl.hue,
        saturation: Math.round(sv * 100),
        value: Math.round(v * 100),
        alpha: hsl.alpha
    };
}

function convertHSVToHSL(hsv: HSV): HSL {
    const v = hsv.value / 100;
    const sv = hsv.saturation / 100;
    const l = v * (1 - sv / 2);
    const sl = l === 0 || l === 1 ? 0 : (v - l) / Math.min(l, 1 - l);
    return {
        hue: hsv.hue,
        saturation: Math.round(sl * 100),
        lightness: Math.round(l * 100),
        alpha: hsv.alpha
    };
}

declare class EyeDropper {
    open: () => Promise<{ sRGBHex: string }>;
}